import React from 'react';
import axios from "axios";
import {API_UI_ERROR} from "config/nw-api";
import {withTranslation} from 'react-i18next';
import {ReactComponent as Logo} from "assets/images/logo/nanoways.white.opt.svg";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state to indicate an error has occurred
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		const errorMessage = error.message;
			
		console.log('_________________________________________________________________________');
		console.log('Name:', error.name);
		console.log('Error Message:', errorMessage);
		console.log('_________________________________________________________________________');
		if (process.env.NODE_ENV !== 'development' && !errorMessage?.includes('The node to be removed is not a child of this node')) {
			axios.post(API_UI_ERROR, {
					title: document.title,
					name: error.name,
					errorMessage,
					errorLineNumber:  error.lineNumber,
					error,
					stackTrace: error.stack,
					info,
				});
		}
	}

	render() {
        const { t } = this.props;

		if (this.state.hasError) {
			return <>
                <nav className="navbar navbar-dark py-2 fixed-top" style={{height: "46px"}}>
                    <div className="container-fluid d-flex align-item-center">
                        <div className="align-items-center">
                            <div className="navbar-logo-middle text-center">    
                                <a href="/"><Logo className="navbar-logo"/></a>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className='vertical-center nw-bg-grey'>
                    <div className='bm-main text-center'>
                        {t("err.500")}
                        <div className='mt-5'>
                            <a href={t('link.root.url')}>
                                <button className="bm-btn-blue mb-2">{t("nw.btn.back")}</button>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="m-0 p-0">
                    <footer id="mainFooter" className="nw-footer text-center py-md-2 py-sm-0 fn-smtxt">
                        <a className='text-muted text-decoration-none fn-smtxt' href={t("link.legal.terms.url")}>
                            {t("link.legal.terms")}
                        </a>
                        <span className="text-muted mx-1">|</span>
                        <a className='text-muted text-decoration-none fn-smtxt' href={t("link.legal.privacy.url")}>
                            {t("link.legal.privacy")}
                        </a>
                        <span className="text-muted mx-1">|</span>
                        <a className='text-muted text-decoration-none fn-smtxt' href={t("link.legal.imprint.url")}>
                            {t("link.legal.imprint")}
                        </a>
                    </footer>
                </div>
            </>;
		}

		return this.props.children;
	}
}

export default withTranslation()(ErrorBoundary);