import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, Link } from "react-router-dom";
//import { useParams  } from 'react-router-dom';
import { useEffect } from "react";

export default function LicenseStatus(props) {
    
  const { t } = useTranslation();
  const location = useLocation();

  const navigate = useNavigate();

  document.title = t("selfreg.done")+ " – nanoways.com";
    
  /* eslint-disable */
  useEffect(()=>{
    !location.state && navigate(t("link.root.url"));
  }, [])

  /* eslint-enable */

  return (
    <div className="m-0 p-0 vertical-center">
      <div className="bm-main">
          {location.state &&
            <div className="bm-card bm-box-absolute">

              <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>

              <h1 className="mb-5">{t("selfreg.done")}</h1>

              <p dangerouslySetInnerHTML={{__html: t("selfreg.done.txt", {email: location.state.email})}}/>
              
              <div className="bm-nav-card-bottom">
                <Link to={t("link.root.url")}>
                    <button className="bm-btn-blue bm-btn-card-next">{t("nw.btn.proceed")}</button>
                </Link>
                <div className="bm-clearer"></div>
              </div>
            </div>
          }
      </div>
    </div>
  )
}
