import {  useRef } from "react";
//import { useTranslation } from "react-i18next";

export default function TokenInfo(props) {
    //const { t } = useTranslation();
    const closeBtn = useRef(null);
    //closeBtn.current.click();

    return (
        props.data ? 
            <> 
                <a href="/#" ref={props.showInfoRef} className="d-none" data-bs-toggle="modal" data-bs-target={`#${props.name}`}>[SHOW INFO]</a>

                <div className="modal fade" id={props.name} data-bs-backdrop="true" data-bs-keyboard="true" tabIndex="-1" aria-labelledby={`${props.name}Label`} aria-hidden="false">
                    <div className="modal-dialog vertical-center">
                        <div className="modal-content bm-card">
                            <h2 className="bm-card-header_small" id={`${props.name}Label`}>
                                HARDWAREINFO
                            </h2>
                            <a ref={closeBtn} href="/#" className="bm-close-x" id={`close${props.name}`} type="button" data-bs-dismiss="modal" aria-label="Close">&#10005;</a>
                            <div className="row">
                                <div className="col-12">ID: {props.data.id}</div>
                                <div className="col-12">createMode: {props.data.createMode}</div>
                                <div className="col-12">serial: {props.data.serial}</div>
                                <div className="col-12">manufacturer: {props.data.manufacturer}</div>
                                <div className="col-12">product: {props.data.product}</div>
                                {/*<div className="col">productId: {props.data.productId}</div>*/}
                                <div className="col-12">vendor: {props.data.vendor}</div>
                                {/*<div className="col">vendorId: {props.data.vendorId}</div>*/}
                                <div className="col-12">model: {props.data.model}</div>
                                <div className="col-12">revision: {props.data.revision}</div>
                                <div className="col-12">vpdSerial: {props.data.vpdSerial}</div>
                                <div className="col-12">wwid: {props.data.wwid}</div>
                                <div className="col-12">type: {props.data.type}</div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </>
        :
        <></>
    )
}
