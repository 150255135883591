import Spinner from 'Components/Loading/Spinner';

function ConfirmModal(props) {
	const id = props.id ?? "alertDialog";

	const handleAgree = (e) => {
		props.confirm(true);
		document.getElementById("closeAlertDialog2").click(); //Close Modal without setting toggle to avoid removing spinner button
  	};

  	const handleDisagree = (e) => {
		props.confirm(false);
		document.getElementById("closeAlertDialog").click();
  	};

  	return (
		<>
			<a ref={props.confirmRef} href="/#" style={props.btnStyles} data-bs-toggle="modal" data-bs-target={`#${id}`}> 
				<button disabled={props.disabled} className={`${props.classes??""} ${props.disabled?" pe-none" :""} d-flex justify-content-center`}>
					{props.toggle &&
						<Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-green-color)"}}/>
					}
					<span className={props.toggle ? "invisible" : ""}>{props.txt.label}</span> 
				</button>
			</a>
			<div className="modal fade" id={id} tabIndex="-1" data-bs-backdrop={props.backdrop} aria-labelledby={`${id}Label`} aria-hidden="true" >
				<div className="modal-dialog vertical-center">
					<div className="modal-content bm-card bg-light">
					
						<h2 className="bm-card-header_small" id={id}>{props.txt.title}</h2>

						<a href="/#" onClick={handleDisagree} className="bm-close-x" id="closeAlertDialog" type="button" data-bs-dismiss="modal" aria-label="Close">&#10005;</a>

						<a href="/#" className="bm-close-x invisible" id="closeAlertDialog2" type="button" data-bs-dismiss="modal" aria-label="Close">INVISIBLE_CLOSE</a>
		
						<p className="fn-txt bg-light text-black" dangerouslySetInnerHTML={{__html: props.txt.body}} />

						<div className="row">
							<div className="col-6">
								<button onClick={handleDisagree} className="bm-btn-mute bm-btn-card-prev px-3">
									{props.txt.no}
								</button>
							</div>
							<div className="col-6">
								<button onClick={handleAgree} className="bm-btn-blue bm-btn-card-next px-3" autoFocus>
									{props.txt.yes}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ConfirmModal;