
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
//import Field from "Components/Forms/Field";
//import { fixInput } from 'Services/utils';
import {SliceText} from 'Components/Tools/Text';
import {localeDatetime} from 'Components/Tools/DateTime';
import LICENSEAPI from 'Services/LicenseAPI';
import {ReactComponent as WarnSVG} from "assets/images/icon/exclamation.filled.svg";
import { useSelector } from 'react-redux';
import {ReactComponent as PlusInfoSVG}  from "assets/images/icon/plus.circle.svg";
import {ReactComponent as QuestionSVG}  from "assets/images/icon/question.svg";
import {languagesToList} from 'Components/Tools/Text';
import {ReactComponent as EditSvg} from "assets/images/icon/edit-pen.svg";
import {ReactComponent as Cancel}  from "assets/images/icon/cancel-x.svg";
import {ReactComponent as Check}  from "assets/images/icon/check.svg";
import DatePicker from 'react-date-picker';
import {ReactComponent as Calendar} from "assets/images/icon/calendar.svg";
import { i18nLocale } from "Components/Tools/DateTime";

export default function Panel(props) {
    const {t, i18n} = useTranslation();
    const locale = i18nLocale(i18n.language);
    const user = useSelector((state) => state.user);
    const [license, setLicense] = useState(null);
    const licenseCol = useRef({length: 1, classes: ''});
    const [rowData, setRowData] = useState(null);
    const [inputs, setInputs] = useState({
        anatPassword: {value: '', lastValue: '', err: ''},
        caption: {value: '', lastValue: '', err: ''}
    });
    const [isLoading, setIsLoading] = useState(false);
    const [lastSelectedInput, setLastSelectedInput] = useState({});
    const [lastInputErr, setLastInputErr] = useState({});

    const handleChangeInput = (e) => {
		e.preventDefault();
        const {name, value} = e.target;
        setInputs({...inputs, [name]: {...inputs[name], value: value, err: ''}});
    }

    const handleChangeSellDate = (date) => {
        setLastInputErr(null);
        const today = new Date();
        if (date > today) {
            setLastInputErr({name: lastSelectedInput?.name, err: "licenses.list.cols.action.editForm.sellDate.err.future"});

           return;
        }
        if (inputs.sellDate?.originValue && (date > inputs.sellDate?.originValue || date === null)) {
            setLastInputErr({name: lastSelectedInput?.name, err: "licenses.list.cols.action.editForm.sellDate.err.change"});

            return;
        }
        const stringDate = date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : null;
        
        setInputs({...inputs, sellDate: {...inputs.sellDate, originValue: stringDate, value: stringDate, err: ''}});
    }

    const handleKeyDown = (e) => {
        if ((e.key === 'Enter' || e.key === 'Tab')) {
            setLastSelectedInput({...lastSelectedInput, name:e.currentTarget.name, focus:true});
            e.currentTarget.blur();
        }
    }

    const [disableAnatInput, setDisableAnatInput] = useState(true);
    const anatPwdInputRef = useRef(null);
    const handleEnableInputAnatPwd = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setLastInputErr(null);
        if (disableAnatInput) {
            setDisableAnatInput(false);
            setTimeout(() => {
                anatPwdInputRef?.current?.focus();
			}, 200);
        }else{
            setInputs({...inputs, anatPassword: {...inputs.anatPassword, value:inputs.anatPassword.lastValue}});
            setDisableAnatInput(true);
        }
    }

    /* eslint-disable */
    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            const licenseData = props.data?.licenseDetails;
            const  inputsObj = {};
            if (user?.roles.includes('ROLE_68') && licenseData.license?.caption?.action?.includes('edit')) {
                const value = licenseData.license?.caption.value;
                inputsObj.caption = {
                    value: value,
                    lastValue: value,
                    err: '',
                    saved: false,
                    canEdit: true
                }
            }
            if (user?.roles.includes('ROLE_213') && licenseData.license?.anatPassword?.action?.includes('edit')) {
                const value = licenseData.license?.anatPassword.value ? t("licenses.list.detail.lic.pwd.set") :'';
                inputsObj.anatPassword = {
                    value: value,
                    lastValue: value,
                    err: '',
                    saved: false,
                    canEdit: true
                }
            }
            if (licenseData.license?.sellDate.action?.includes('edit')) {
                let value = licenseData.license?.sellDate.value;
                value = value || ""//!value || value?.includes('1970') ? "": value;
                inputsObj.sellDate = {
                    value: value,
                    lastValue: value,
                    originValue: value,
                    err: '',
                    saved: false,
                    canEdit: true
                }
            }
            setInputs(inputsObj);
            setRowData({...props.rowData});
            setLicense({...licenseData});
            const colLength = Object.keys(licenseData)?.length;
            const cols = {lg: 4, md: 12};
            if (colLength > 1) {
                cols.lg = 12/(colLength >= 4 ? 4 : colLength);
                cols.md = 12/(colLength >= 4 ? 4 : colLength);
            }
            licenseCol.current.length = colLength;
            licenseCol.current.classes = `col-lg-${cols.lg} col-md-${cols.md} col-sm-1 col-xs-1` ;
        }

		return () => {
            isMounted = false 
        };
    },[props.data.licenseDetails]);

    useEffect(() => {
        let isMounted = true;

        if (isMounted && lastSelectedInput?.focus === false && !lastInputErr?.err) {
            setIsLoading(true);
            setLastInputErr(null);

            const payload = Object.entries(inputs)?.reduce((licenseData, [inputName, input]) => {
                if (input.canEdit && input.value !== input.lastValue) {
                    licenseData[inputName] = input.value;
                }
                return licenseData;
            }, {});

            if (Object.keys(payload)?.length > 0) {
                payload.currentKey = rowData.currentKey;
                LICENSEAPI.updatePrivateData(payload)
                .then((res)=>{
                    setIsLoading(false);
                        const updatedInputs = {};

                        Object.entries(inputs)?.forEach(([key, input]) => {
                            updatedInputs[key] = {
                                ...input,
                                lastValue: input.value,
                                saved: lastSelectedInput.name === key ? true : input.saved,
                            };
                        });

                        if (lastSelectedInput.name === "anatPassword") {
                            setDisableAnatInput(true);
                        }

                        setInputs({...updatedInputs});
                        ["caption", "sellDate", "anatPassword"].includes(lastSelectedInput.name) && props.updateRow(payload, rowData);
                }).catch((err)=>{
                    setIsLoading(false);
                    console.log(err);
                    setLastInputErr({name: lastSelectedInput?.name, err: 'Can not be changed'});
                })
            }else{
                setIsLoading(false);
            }
        }

		return () => {
            isMounted = false 
        };
    },[lastSelectedInput]);
    /* eslint-enable */

    const valueOrDefaultTxt = (value) => {
        return value || t("gen.text.unavailable.abbr");
    }

    return (
        <>
        {license && licenseCol.current?.length > 0 && <div id='licenseDetailsPanel' className="px-3 pt-2 pb-2" style={{background: "var(--nw-green-light-color)"}}>
            <h2 className="py-3">{t("licenses.list.detail")}</h2>
            <div className="row">
                {license.license && <div className={licenseCol.current?.classes}>
                    <h4 className="mb-3">{t("licenses.list.detail.lic")}</h4>
                    {license.license.currentKey && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>{valueOrDefaultTxt(license.license.currentKey.value)}</div>
                        <label>{t("licenses.list.cols.key")}</label>
                    </div>}

                    {inputs.caption?.canEdit === true && <div className="bm-input-wrapper">
                        <input 
                            name="caption"
                            className={`
                                ${(lastInputErr?.name === 'caption' && lastInputErr?.err) ? "bm-input-is-invalid" : ""}
                                ${isLoading ? "disabledElt" : "bg-white"} 
                                bm-input nowrap
                            `}
                            value={inputs.caption?.value}
                            disabled={isLoading}
                            onChange={handleChangeInput}
                            onFocus={(e)=> {
                                setLastSelectedInput({name:e.currentTarget.name, focus:true});
                            }}
                            onBlur={(e)=> {
                                setLastSelectedInput({...lastSelectedInput, focus:false});
                            }}
                            onKeyDown={handleKeyDown}
                        />
                        <label style={{color: "var(--nw-grey-color)"}}>{t("licenses.list.detail.prod.cap")}</label>
                        <span
                            title={t('licenses.list.detail.prod.cap.tt')}
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "11.5px",
                                cursor: "auto",
                            }}
                        >
                            <QuestionSVG width="15px" height="15px" fill="var(--nw-grey-color)"/>
                        </span>
                        {(lastInputErr?.name === 'caption' && lastInputErr?.err) && <p className="fn-smtxt input-error ps-1" dangerouslySetInnerHTML={{__html: t(lastInputErr?.err)}}/>}
                    </div>}
                    {license.license.caption && !license.license.caption?.action?.includes('edit') && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>{valueOrDefaultTxt(license.license.caption.value)}</div>
                        <label>{t("licenses.list.cols.key")}</label>
                        <span
                            title={t('licenses.list.detail.prod.cap.tt')}
                            style={{
                                position: "absolute",
                                right: "10px",
                                top: "11.5px",
                                cursor: "auto",
                            }}
                        >
                            <QuestionSVG width="15px" height="15px" fill="var(--nw-grey-color)"/>
                        </span>
                    </div>}

                    {license.license.sellDate && !license.license.sellDate?.action?.includes('edit') && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>{license.license.sellDate.value ? license.license.sellDate.value?.includes('1970') ? t("gen.text.unavailable.abbr") : SliceText({text:localeDatetime(license.license.sellDate.value), length: 10, append:""}) : t("gen.text.unavailable.abbr")
                        }</div>
                        <label>{t("licenses.list.detail.lic.sellDate")}</label>
                    </div>}
                    
                    {inputs.sellDate?.canEdit &&
                        <div style={{marginBottom: "1.4rem", position: "relative", paddingTop: ".50rem"}}>
                            <label 
                                htmlFor="sellDate"
                                style={{
                                    position: "absolute",
                                    top: "-13px",
                                    color: `${lastSelectedInput.focus ? "var(--nw-blue-color)" : "var(--nw-grey-mute-light-color)"}`,
                                    background: "#ffffff",
                                    opacity: 1,
                                    transform: "scale(0.7)",
                                    transformOrigin: "left",
                                    padding: "2px 5px",
                                    marginLeft: "11px",
                                }}
                            >{t("licenses.list.cols.action.editForm.sellDate")}</label>
                            <span
                                title={t("licenses.list.cols.action.editForm.sellDate.tt")}
                                style={{
                                    padding: "14px 0px 14px 0px",
                                    border: `1px solid ${lastSelectedInput.focus ? "var(--nw-blue-color)" : "var(--nw-grey-mute-light-color)"}`,
                                    borderRadius: ".3rem",
                                    transition: "all 150ms ease-in-out",
                                    boxShadow: "none",
                                    outline: "none",
                                    backgroundColor: `${isLoading ? "rgb(240, 240, 240)" : "#ffffff"}`
                                }}
                            >
                                <DatePicker
                                    name="sellDate"
                                    className="sellDatePanel"
                                    maxDate={inputs.sellDate.lastValue ? new Date(inputs.sellDate.lastValue) : new Date()}
                                    minDate={new Date('01-01-1970')}
                                    value={inputs.sellDate.value}
                                    //calendarClassName=""
                                    //clearIcon={<Clear {...props} htmlColor="red"/> || null}
                                    //shouldOpenCalendar={(e,reason) => handleFocus(reason)}
                                    calendarIcon={<Calendar fill="var(--nw-blue-dark-color)"/>}
                                    disabled={isLoading}
                                    locale={locale}
                                    onChange={handleChangeSellDate}
                                    //onCalendarClose={onCalendarClose}
                                    shouldOpenCalendar={({ reason }) => {
                                        setLastSelectedInput({name:'sellDate', focus:true});
                                        return true;
                                    }}
                                    shouldCloseCalendar={({ reason }) => {
                                        setLastSelectedInput({...lastSelectedInput, focus:false});
                                        return setTimeout(() => {
                                            /*if (sellDate === sharedDate.current) {
                                                return true;
                                            }*/
                                            /*const e = {
                                                currentTarget: {
                                                    name: 'sellDate',
                                                    value: sharedDate.current
                                                }
                                            }
                                            handleChangeText(e);*/
                                            return true
                                        }, 300);
                                    }}
                                    onFocus={(event) => {
                                        setLastSelectedInput({name:'sellDate', focus:true});
                                    }}
                                    //shouldCloseCalendar ={({ reason }) => reason !== 'outsideAction'} //Function called before the calendar closes. reason can be "buttonClick", "escape", "outsideAction", or "select". If it returns false, the calendar will not close.
                                />
                            </span>
                            {(lastInputErr?.name === 'sellDate' && lastInputErr?.err)  && <p className="fn-smtxt input-error ps-1 pt-1" dangerouslySetInnerHTML={{__html: t(lastInputErr?.err)}}/>}
                        </div>
                    }

                    {license.license.expiredAt && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>{license.license.expiredAt.value? license.license.expiredAt.value?.includes('1970') ? t("gen.text.unavailable.abbr") : SliceText({text:localeDatetime(license.license.expiredAt.value), length: 10, append:""}) : t("gen.text.unavailable.abbr")
                        }</div>
                        <label>{t("licenses.list.detail.lic.expDate")}</label>
                    </div>}

                    {license.license.anatPassword && !license.license.anatPassword?.action?.includes('edit')  && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>{valueOrDefaultTxt(license.license.anatPassword.value)}</div>
                        <label>{t("licenses.list.detail.lic.expDate")}</label>
                    </div>}
                    {inputs.anatPassword?.canEdit === true && <div style={{display: 'flex',alignItems: 'start',}}>
                        <div className="bm-input-wrapper position-relative" style={{flex: 1}}>
                            <input
                                ref={anatPwdInputRef}
                                name="anatPassword"
                                style={{borderTopRightRadius: 0, borderBottomRightRadius:0}}
                                className={`${(lastInputErr?.name === 'anatPassword' && lastInputErr?.err) ? "bm-input-is-invalid" : ""} ${isLoading || disableAnatInput ? " disabledElt" : " bg-white"} bm-input nowrap`}
                                value={inputs.anatPassword?.value || (disableAnatInput && t('licenses.list.detail.lic.pwd.notset')) || ''}
                                disabled={isLoading || disableAnatInput}
                                onChange={handleChangeInput}
                                
                                onFocus={(e)=> {
                                    setLastSelectedInput({name:e.currentTarget.name, focus:true});
                                }}
                                onBlur={(e)=> {
                                    if(e.relatedTarget?.name !== 'anatDisabler'){
                                        setLastSelectedInput({...lastSelectedInput, focus:false});
                                        setDisableAnatInput(true);
                                    }
                                }}
                                onKeyDown={handleKeyDown}
                            />
                            <label style={{color: "var(--nw-grey-color)"}}>{t("licenses.list.detail.lic.pwd")}</label>
                            {inputs.anatPassword?.canEdit && <span
                                title={t("licenses.list.detail.lic.pwd.tt")}
                                //className='nw-opacity-6'
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "11.5px",
                                    cursor: "auto",
                                }}
                            >
                                {inputs.anatPassword?.saved ? <Check width="16px" height="16px" fill="var(--nw-green-dark-color)"/> : inputs.anatPassword?.value === t("licenses.list.detail.lic.pwd.set")? '' : <WarnSVG width="18px" height="18px" fill="var(--nw-orange-color)"/>}
                            </span>}
                            {(lastInputErr?.name === 'anatPassword' && lastInputErr?.err)  && <p className="fn-smtxt input-error ps-1" dangerouslySetInnerHTML={{__html: t(lastInputErr?.err)}}/>}
                        </div>
                        {inputs.anatPassword?.canEdit && <button
                            name="anatDisabler"
                            title={`${disableAnatInput ? t("licenses.list.detail.lic.pwd.btn.editTT") : t("licenses.list.detail.lic.pwd.btn.cancelTT")}`}
                            onClick={handleEnableInputAnatPwd}
                            className={`${disableAnatInput ? "nw-btn-greyBlue" : "bm-btn-red"}`}
                            style={{padding: '12px',borderTopLeftRadius: 0, borderBottomLeftRadius:0}}
                        >
                            {disableAnatInput ? <EditSvg width="16px" height="16px" fill="white"/> : <Cancel width="16px" height="16px" fill="white"/>}
                        </button>}
                    </div>
                    }

                </div>}
                {license.product && <div className={licenseCol.current?.classes}>
                    <h4 className="mb-3">{t("licenses.list.detail.prod")}</h4>
                    {license.product.name && <div className="div-input-wrapper">
                        <div className='div-input nowrap' >
                            {valueOrDefaultTxt(license.product.name.value)}
                        </div>
                        <label>{t("licenses.list.detail.prod.name")}</label>
                    </div>}
                    {license.product.language && <div className="div-input-wrapper">
                        <div 
                            className='div-input nowrap'
                            title={languagesToList(rowData.languages, rowData.language, t)}
                        >
                            {rowData.language ? t("gen.lang."+rowData.language) : t("gen.text.unavailable.abbr")}
                            
                            {rowData.languages?.length > 0 && <span
                                //className='nw-opacity-6'
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "11.5px",
                                    cursor: "auto",
                                }}
                            >
                                <PlusInfoSVG width="15px" height="15px" fill="var(--nw-grey-color)"/>
                            </span>}
                        </div>
                        <label>{t("licenses.list.detail.prod.lang")}</label>
                    </div>}
                    {license.product.ver && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>
                            {valueOrDefaultTxt(license.product.ver?.value)}
                        </div>
                        <label>{t("licenses.list.detail.prod.ver")}</label>
                    </div>}
                    {license.product.typeI18nKey && <div className="div-input-wrapper">
                        <div className='div-input nowrap' >
                            {license.product.typeI18nKey?.value ? t('st.'+license.product.typeI18nKey.value) : t("gen.text.unavailable.abbr")}
                        </div>
                        <label>{t("licenses.list.detail.prod.typ")}</label>
                    </div>}
                </div>}
                {license.user && <div className={licenseCol.current?.classes}>
                    <h4 className="mb-3">{t("licenses.list.detail.owner")}</h4>
                    {license.user.data1 && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>
                            {valueOrDefaultTxt(license.user.data1?.value)}
                        </div>
                        <label>{t("licenses.list.detail.owner.name")}</label>
                    </div>}
                    {license.user.data2_5 && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>
                            {license.user.data2_5.value[0] && <div>{license.user.data2_5.value[0]}</div>}
                            {license.user.data2_5.value[1] && <div>{license.user.data2_5.value[1]}</div>}
                            {license.user.data2_5.value[2] && <div>{license.user.data2_5.value[2]}</div>}
                            {license.user.data2_5.value[3] && <div>{t(`gen.country.${license.user.data2_5?.value[3]?.toUpperCase()}`)}</div>}
                            {(!license.user.data2_5.value[0] && !license.user.data2_5.value[1] && !license.user.data2_5.value[2] && !license.user.data2_5.value[3]) && t("gen.text.unavailable.abbr")}
                        </div>
                        <label>{t("licenses.list.detail.owner.address")}</label>
                    </div>}
                    {license.user.email && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>
                            {valueOrDefaultTxt(license.user.email.value)}
                        </div>
                        <label>{t("licenses.list.detail.owner.mail")}</label>
                    </div>}
                    {license.user.lang && <div className="div-input-wrapper">
                        <div className='div-input nowrap' >
                            {license.user.lang.value ? t("gen.lang."+license.user.lang.value) : t("gen.text.unavailable.abbr")}
                        </div>
                        <label>{t("licenses.list.detail.owner.lang")}</label>
                    </div>}
                </div>}
                {license.agent && <div className={licenseCol.current?.classes}>
                    <h4 className="mb-3">{t("licenses.list.detail.seller")}</h4>
                    {license.agent.name && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>
                            {valueOrDefaultTxt(license.agent.name.value)}
                        </div>
                        <label>{t("licenses.list.detail.seller.name")}</label>
                    </div>}
                    {license.agent.address && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>
                            {license.agent.address.value ? convertAddressToBlocks(license.agent.address.value, t) : t("gen.text.unavailable.abbr")}
                        </div>
                        <label>{t("licenses.list.detail.seller.address")}</label>
                    </div>}
                    {license.agent.email && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>
                            {valueOrDefaultTxt(license.agent.email.value)}
                        </div>
                        <label>{t("licenses.list.detail.seller.email")}</label>
                    </div>}
                    {license.agent.web && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>
                            {valueOrDefaultTxt(license.agent.web.value)}
                        </div>
                        <label>{t("licenses.list.detail.seller.web")}</label>
                    </div>}
                    {license.agent.phone && <div className="div-input-wrapper">
                        <div className='div-input nowrap'>
                            {valueOrDefaultTxt(license.agent.phone.value)}
                        </div>
                        <label>{t("licenses.list.detail.seller.phone")}</label>
                    </div>}
                </div>}
            </div>
        </div>}
        {license === null && !licenseCol.current !== null && <div className='text-center p-5 m-5'>{t('err.unexpected')}</div>}
        {props.data?.licenseDetails?.length === 0 &&  <div className="text-center p-3" style={{background: "var(--nw-green-light-color)"}}>{t('nw.list.err.empty')}</div>}
        {//Fix hide Datetime closeIcon 
            document.getElementsByClassName('react-date-picker__clear-button react-date-picker__button')[0]?.classList.remove('invisible')
        }
    </>
    )
}

const convertAddressToBlocks = (address, t) => {
    if (address?.trim()?.length>0) {
        address = address.replace(/\r/g, '\n');
        address = address.replace(/\n\n/g, '\n');
        const addressLines = address?.split('\n');
        if(addressLines?.length > 1){
            return addressLines?.map((line, idx, arr) => (
                <div key={idx}>{idx+1 === arr.length? t('gen.country.'+line) :line}</div>
            ))
        }
    }

    return address;
}