import React, { useEffect, useState } from "react";
import "./index.css";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import Checked from "assets/images/icon/checked.svg";
import {convertSize} from "Services/utils";

const Downloader = (props) => {
  	return (
        <ul className="list-group list-group-flush">
			<DownloadItem handleDownloadEnd={props.handleDownloadEnd} {...props.file} />
        </ul>
  );
};

//Download Props contains: { name, file, filename, filesize, filesizePow, handleDownloadEnd }
const DownloadItem = ({file, filename, filesize, filesizePow, handleDownloadEnd }) => {
	const { t } = useTranslation();
	const abortController = new AbortController(); //Use controller to abort download by error
	const [downloadProgress, setDownloadProgress] = useState({
		start: null,
		progress: 0,
		completed: false,
		total: 0,
		loaded: 0,
		hasError: false,
	});
  	/* eslint-disable */
	useEffect(() => {
		const options = {
			onDownloadProgress: ({ loaded, total }) => {
				setDownloadProgress((info) => {
					return {
						...info,
						start: info.start ??  new Date().toISOString(),
						progress: Math.floor((loaded * 100) / total),
						loaded,
						total,
						completed: false,
					}
				});
			},
    	};
		//Use test Controller to debug Error 
		//Axios.get("https://localhost:8000/api/v1/downloads/test/error", {responseType: "blob",...options, signal: abortController.signal})
		Axios.get(file, {responseType: "blob", ...options, signal: abortController.signal})
		.then(function (response) {
			const url = window.URL.createObjectURL(
				new Blob([response.data],
				{type: response.headers["content-type"]}
			));

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			const timer = setTimeout(() => {
				setDownloadProgress((info) => {
					if (info.progress === 100) {
						return {
							...info,
							completed: true,
							end: new Date().toISOString(),
						}
					}else{
						return {
							...info,
							completed: false,
							end: new Date().toISOString(),
							hasError: true,
						}
					}
				});
			}, 500);

      		return () => {
				clearTimeout(timer);
				window.URL.revokeObjectURL(url);
			};
    	}).catch(err => {
			console.log('Error downloading file:', err);
			setDownloadProgress((info) => {
				return {
					...info,
					completed: false,
					progress: (info.progress > 99) ? 99 : info.progress,
					end: new Date().toISOString(),
					hasError: true,
				}
			});
		});
  	}, []);

	useEffect(() => {
		if(downloadProgress.hasError){
			abortController?.abort();
		}
		if (downloadProgress.hasError || downloadProgress.completed) {
			handleDownloadEnd(downloadProgress)
		}
	}, [downloadProgress]);
  /* eslint-enable */

  	//const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  	const formatBytes = (bytes) => {
		const convertedSize = convertSize(bytes);
		return convertedSize ? convertedSize.size +" "+ t(`gen.var.prefix`).charAt(convertedSize.pow) + t(`gen.var.byte`) : t("gen.text.unavailable.abbr");
	}

	return (
		<li className="list-group-item p-0 m-0">
			<div className="row">
				{(!downloadProgress.completed &&
				<>
					<div className="col-12 text-center mb-4">
						{downloadProgress.loaded > 0 && 
							<>{formatBytes(downloadProgress.loaded)} / {formatBytes(downloadProgress.total)}</>
						}
						{downloadProgress.loaded === 0 && <>{t("download.init")}</>}
					</div>
					<div className="col-12">
						<div className="progress" style={{height: "37px"}}>
							<div
								className={`${downloadProgress.hasError ? "bg-danger":""} progress-bar progress-bar-striped progress-bar-animated`}
								role="progressbar"
								style={{width: `${downloadProgress.progress}%`}}
								aria-valuenow={downloadProgress.progress}
								aria-valuemin="0"
								aria-valuemax="100"
							>
								{`${downloadProgress.progress}%`}
							</div>
						</div>
					</div>
				</>
				) || (downloadProgress.completed &&
					<>
						<div className="fade-in col-12 mb-4 nw-green-dark">
							{t('download.end')}
						</div>
						<div className="col-12">
							<span className="nw-green-dark">
								<img
									src={process.env.PUBLIC_URL + Checked}
									className="fade-in"
									height="36"
									alt="Download Icon"
								/>
							</span>
						</div>
					</>
				)}
			</div>
    	</li>
	);
};

export default Downloader;
