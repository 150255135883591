import { useState, useEffect } from "react";
import UserAPI from "Services/UsersAPI";
import CheckApi from "Services/CheckAPI";
import Field from "../../Forms/Field";
import { useTranslation } from "react-i18next";
import { useSearchParams } from 'react-router-dom';
import MailSvg from 'assets/images/icon/mail-animation.gif';
import { DOMAIN_NAME } from "config/nw-api";
import PasswordChecklist from "react-password-checklist";
import { checkPwd } from 'Components/Tools/Text';
import Spinner from 'Components/Loading/Spinner';

export default function PwdResetModal(props) {
    const { t, i18n } = useTranslation();
    const pwdMinLength = 8;
    const [token, setToken] = useState("undefined");

    const [searchParams] = useSearchParams();
    /* eslint-disable */
    useEffect(() => {
        let token = searchParams.get('token_reset');
        if (token) { 
            const timer = setTimeout(() => {
                CheckApi.checkLink(searchParams.get('token_reset'))
                .then(response => {
                    setToken("valid");
                })
                .catch(error => {
                    if(!error.response || error.response.data.status === 500){
                        //setError(t("err.500"));
                    } else {
                        const data = error.response.data;
                        if (Object.keys(data).length !== 0 && data.type !== undefined) {
                            if (data.type === 'InvalidFormat' || data.type === 'Invalid') {
                                //setError(t("err.link.invalid"));
                                setToken("invalid");
                            } else if (data.type === 'Expired') {
                                //setError(t("nw.input.pwd.err.strong"));
                                setToken("expire");
                            }
                        } else {
                            //setError(t("err.unkown"));
                            setToken("expire");
                        }
                    }
                });
            }, 2000);
            return () => clearTimeout(timer);
        }  
    }, []);
    /* eslint-enable */

    const [user, setUser] = useState({
        password: "",
        passwordConfirm: "",
        token: "",
        emailLang: i18n.language
    });
    const [errors, setErrors] = useState({
        password: "",
        passwordConfirm: ""
    });

    const [isLoading, setIsLoading] = useState(false);
    const [generateLink, setGenerateLink] = useState(false);
    const [formAccepted, setFormAccepted] = useState({
        "status":false
    });

    const showForm = () => {
        setFormAccepted(formAccepted.status = false);
        document.getElementById('closePwdReset').click();
    }

    // Manage input changing in form
    const handleChange = (e) => {
        const {name, value} = e.currentTarget;
        setUser({...user, [name]: value});
    };

    // Manage form submission
    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        setErrors({});

        if (!user.password) {
            setErrors({...errors, password:t("nw.input.err.required")});
            setIsLoading(false);
        } else if (user.password !== user.passwordConfirm) {
            setErrors({...errors, password:t("nw.input.pwd.confirm.err")});
            setIsLoading(false);
        } else if (!checkPwd(user.password)) {
            setErrors({...errors, password:t("nw.input.pwd.err.strong", {"num": pwdMinLength})});
            setIsLoading(false);
        } else{
            user.token = searchParams.get('token_reset');
            user.urlCallback = {"login_link": DOMAIN_NAME + t("link.login")};

            UserAPI.pwdReset(user)
            .then(response => {
                setIsLoading(false);
                setUser({
                    password: "",
                    passwordConfirm: "",
                    token: "",
                    emailLang: i18n.language
                });
                
                setFormAccepted({...formAccepted,
                    status: true,
                });
            })
            .catch(error => {
                setIsLoading(false);
                
                if(!error.response || error.response.data.status === 500){
                    //TODO: return 500 error and show page 500
                    console.log(error.message);
                    setErrors({...errors, email:t("err.500")});
                } else {
                    const data = error.response.data;
                    if (Object.keys(data).length !== 0 && data.type !== undefined) {
                        if (data.type === 'LinkInvalid') {
                            setErrors({...errors, password:t("err.link.invalid")});
                            setGenerateLink(true);
                        } else if (data.type === 'TokenExpired') {
                            setErrors({...errors, password:t("err.link.expire")});
                            setGenerateLink(true);
                        } else if (data.type === 'InvalidValue') {
                            setErrors({...errors, password:t("nw.input.pwd.err.strong", {num: 8})});
                        }
                    } else {
                        setErrors({...errors, email:t("err.unkown")});
                    }
                }
            });
        };
    }

    return (
        <>            
            <div className="modal fade" id="pwdResetModal" tabIndex="-1" aria-labelledby="pwdResetModalLabel" aria-hidden="false">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">
                        <h2 className="bm-card-header_small" id="pwdResetModalLabel">
                            {t("user.pwd.reset")}
                        </h2>
                        <a href="/#" className="bm-close-x" id="closePwdReset" type="button" data-bs-dismiss="modal" aria-label="Close">&#10005;</a>

                            {token === "undefined" && 
                                <div className="d-flex justify-content-center py-2">
                                    <button disabled="disabled" className="bm-btn-blue d-flex justify-content-center">  
                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                        
                                        <span className="invisible">{t("gen.button.wait")}</span>
                                    </button>
                                </div>
                            }
                            {token === "valid" && !formAccepted.status && 
                                <form onSubmit={handleSubmit} className="pwd-Reset-form fn-smtxt">
                                    <Field
                                        label={t("nw.input.pwd")}
                                        id="resetPwd"
                                        name="password"
                                        placeholder={t("nw.input.pwd.hint")}
                                        value={user.password}
                                        onChange={handleChange}
                                        type="password"
                                        attrs={{autoComplete:"new-password"}}
                                        error={errors.password}
                                    />
                                    <Field
                                        name="passwordConfirm"
                                        label={t("nw.input.pwd.confirm")}
                                        type="password"
                                        placeholder={t("nw.input.pwd.confirm.hint")}
                                        value={user.passwordConfirm}
                                        onChange={handleChange}
                                        error={errors.passwordConfirm}
                                    />
                                    <div className="bm-register-checklist">
                                    <PasswordChecklist
                                        rules={["minLength","number","capital","lowercase","match"]}
                                        minLength={8}
                                        value={user.password}
                                        valueAgain={user.passwordConfirm}
                                        onChange={(isValid) => {}}
                                        messages={{
                                            capital: t("nw.checkPwd.capital"),
                                            lowercase: t("nw.checkPwd.lower"),
                                            number: t("nw.checkPwd.num"),
                                            minLength: t("nw.checkPwd.min", { num: 8}),
                                            match: t("nw.checkPwd.match"),
                                            //specialChar: t("nw.checkPwd.char"),
                                        }}
                                    />
                                    </div>
                                    <div className="py-2">
                                        <button type="submit" disabled={isLoading && "disabled"} className="bm-btn-blue float-end d-flex justify-content-center">
                                            {isLoading && 
                                                <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                            }
                                            <span className={isLoading ? "invisible" : ""}>{t("user.pwd.reset.btn")}</span>
                                        </button>

                                        {generateLink &&                     
                                            <a type="button" className="ms-1 mt-1 p-2 btn btn-primary fn-smtxt" href="/#" data-bs-toggle="modal" data-bs-target="#pwdForgotModal">
                                                {t("btn.newLink")}
                                            </a>
                                        }
                                        <div className="clearer"></div>
                                    </div>
                                </form>
                            }
                            {formAccepted.status && 
                                <div className="d-flex flex-column col-12 mt-0 pt-0 text-center">
                                    <img
                                        src={process.env.PUBLIC_URL + MailSvg}
                                        className="bm-signup-img"
                                        alt="Mail Icon"
                                        loading="lazy"
                                    />
                                    <p dangerouslySetInnerHTML={{__html: t('user.pwd.reset.ok')}} />
                                    <button type="button" className="bm-btn-blue bm-marg-top-30 bm-max-width-200 bm-margin-auto" onClick={showForm}>{t("gen.button.close")}</button>
                                </div>
                            }

                            {token === "expire" && 
                                <div className="d-flex flex-column col-12 mt-0 pt-0">
                                    <p className="mb-3">{ t("err.url.expire")}</p>
                                    
                                    <a href="/#" data-bs-toggle="modal" data-bs-target="#pwdForgotModal" >
                                        <button className="bm-btn-blue bm-btn-card-next">{t("err.url.btn")}</button>
                                    </a>
                                    <div className="bm-clearer"></div>
                                </div>
                            }

                            {token === "invalid" && 
                                <div className="d-flex flex-column col-12 mt-0 pt-0 text-center">
                                    <p className="mb-3">{ t("err.url.invalid")}</p>
                                    <button type="button" className="bm-btn-blue bm-margin-auto" onClick={showForm}>{t("gen.button.close")}</button>
                                </div>
                            }
                    </div>
                </div>
            </div>
        </>
    ) 
}
