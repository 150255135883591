import React from 'react';

const Field = ({
                    refInput,
                    disabled="",
                    title="",
                    hidden,
                    name,
                    id,
                    value,
                    onChange,
                    onFocus,
                    onBlur,
                    onKeyDown,
                    placeholder="",
                    type="text",
                    label,
                    error="",
                    attrs,
                    classes="",
                    inputClass="",
                    style={}
               }) => (
    
            <div className={"bm-input-wrapper " + classes}>
                <input
                    ref={refInput}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur ={onBlur && onBlur}
                    onFocus={onFocus && onFocus}
                    onKeyDown={onKeyDown && onKeyDown}
                    hidden={hidden || false}
                    name={name}
                    id={id}
                    title={title}
                    value={value}
                    type={type}
                    placeholder={placeholder || label} 
                    className={"bm-input " + inputClass + (error && " bm-input-is-invalid")}
                    style={style}
                    {...attrs}
                />
                {label && <label hidden={hidden || false} htmlFor={id || name}>{label}</label>}
                {error && <p className="fn-smtxt invalid-feedback text-start" dangerouslySetInnerHTML={{__html: error}}/>}
            </div>
);

export default Field;