import { useState, useRef, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import LicenseList from './LicenseList';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';
import {ReactComponent as ReloadSvg} from "assets/images/icon/reload-16.svg";
import { useSelector } from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import LICENSEAPI from 'Services/LicenseAPI';
import { useParams } from 'react-router-dom';


//Tabs order is important
const tabs = ["user", "reseller", "agent", "publisher", "sellerWait", "buyerWait", "sold"];
const defaultLimit = 10;

export default function ProfileLicenses() {
    const params = useParams();

    const { t } = useTranslation();
    document.title = t("licenses.title")+ " – nanoways.com";
    const user = useSelector((state) => state.user);
    const hasAccess = useRef(getAccess(user?.roles));
    const [searchInCols, setSearchInCols] = useState([]);
    const updateAccess = () => {
        const initAccess =  {
            add: false,
            info: false,
            download: false,
            manage: false,
            edit: false,
            details: false,
            editCaption: false,
            retract: false,
            reject: false,
            accept: false,
            selfRegSeller: false,
        };
        if(tabKey === 'user'){
            hasAccess.current = {...getAccess(user?.roles)};
        }else if (tabKey === 'publisher') {
            hasAccess.current = {...getAccess(user?.roles), editCaption: true, editSellDate: true};
            return;
        }else if(tabKey === 'agent'){
            hasAccess.current = {...getAccess(user?.roles)};
        }else if(tabKey === 'reseller'){
            hasAccess.current = {...getAccess(user?.roles), editSellDate: true};
        }else if(tabKey === 'sellerWait'){
            hasAccess.current = {...initAccess, retract: true};
        }else if(tabKey === 'buyerWait'){
            hasAccess.current = {...initAccess, reject: true, accept: true};
        }else if(tabKey === 'sold'){
            hasAccess.current = initAccess;
        }

        if (user?.roles?.length) {
            for (const role of user?.roles) {
                if(
                    (tabKey === 'user' && role === 'ROLE_68') ||
                    (tabKey === 'reseller' && role === 'ROLE_166') ||
                    (tabKey === 'agent' && role === 'ROLE_69')
                ){                    
                    hasAccess.current = {...hasAccess.current, editCaption: true};
                    return;
                }
            }
        }
    }

    const defaultTab = tabs.includes(params.tab) ? params.tab : null;
    
	const [tabKey, setTabKey] = useState(defaultTab ? defaultTab : user?.roles.includes('ROLE_63') ? tabs[0] : user?.roles.includes('ROLE_165') ? tabs[1] : user?.roles.includes('ROLE_65') ? tabs[2] :user?.roles.includes('ROLE_66') ? tabs[3] : null);// user
    const tabsLoadRef = useRef([]);
    const tabsTextRef = useRef([]);
    const handleMouseEnter = (name) => {
        tabsLoadRef.current[name]?.classList.remove('invisible');
        tabsTextRef.current[name]?.classList.add('invisible');
    };
    const handleMouseLeave = (name) => {
        tabsLoadRef.current[name]?.classList.add('invisible');
        tabsTextRef.current[name]?.classList.remove('invisible');
    };
    const tabsRef = useRef([]);
    const [activesTabs, setActivesTabs] = useState(['user']);
	const [licenses, setLicenses] = useState(undefined);
    const [licensesLength, setLicensesLength] = useState({});//Stock Tabs List length
	const [licensesLimit, setLicensesLimit] = useState(tabKey === tabs[0] ? 0: defaultLimit);// user
    const lastSearch = useRef('');
    const currentColsStructure = useRef(null);

    const changeTab = (key) =>{
        lastSearch.current = '';
        setTabKey(key);
        setLicenses(undefined);
        setLicensesLimit(key === tabs[0] ? 0: defaultLimit);//user
        if (!activesTabs.includes(key)) {
            setActivesTabs([...activesTabs, key]);
        }
    }

    const reloadTab = (e, table) =>{
        const eltId = e?.currentTarget?.id;
        lastSearch.current = '';
        if (table?.newLicense) {
            const updatedLicenses = [...licenses];
            const newList = [table.newLicense, ...updatedLicenses];
            setLicenses(newList);
            setLicensesLength({...licensesLength, [table.name]: newList.length});
            return;
        }else if (table?.editLicense) {
            const editLicense = table.editLicense;
            const updateLicenses = licenses?.map(license => ({
                ...license,
                caption: license.currentKey === editLicense.currentKey ? editLicense.caption : license.caption,
                sellDate: license.currentKey === editLicense.currentKey ? editLicense.sellDate : license.sellDate,
                backgroundColor: ((license.currentKey === editLicense.currentKey && !license.sellDate?.includes(editLicense.sellDate)) || license.backgroundColor) ? 'var(--nw-green-light-color)' : null,
            }));
        
            setLicenses([...updateLicenses]);
        }else if (eltId?.startsWith('selectLimit')) {// Reload by change limit
            lastSearch.current = table.search;
            setLicensesLimit(table.limit);
            setLicenses(undefined);
        }else if (table?.search) {//Reload by search
            lastSearch.current = table.search;
            setLicenses(undefined);
        }else{
            lastSearch.current = '';
            setLicenses(undefined);
        }
    }

    /* eslint-disable */
    useEffect(() => {
        updateAccess();
        let isMounted = true; //note mutable flag to clear error boundaries
        if (isMounted && licenses === undefined) {
            LICENSEAPI.licenses({tabname:tabKey, limit: licensesLimit, search: lastSearch.current})
            .then((res) => {
                const {licenses, countAll, colsStructure, searches, tabName} = res.data;
                if (tabName === tabKey) {
                    setSearchInCols(searchColumns(searches));
                    currentColsStructure.current = colsStructure;
                    setLicensesLength({...licensesLength, [tabKey]: countAll});
                    setLicenses([...licenses]);
                }
            }).catch((err)=>{
                const {response} = err;
                if (response) {
                    console.log(response);
                    setLicensesLength({...licensesLength, [tabKey]: 0});
                    setLicenses([]);
                } else {
                    console.log("network error like timeout, connection refused etc...");
                    //setError("network error like timeout, connection refused etc...");
                }
            });
        }

        return () => { isMounted = false }; // cleanup toggles value, if unmounted
        
    }, [tabKey, licenses]);
    /* eslint-enable */
    
    return (
        <div className="p-5 bg-white min-vh-100">
            <div className="row mb-5">
                <div className="col-12 px-0 mx-0 text-center">
                    <h1>{t("licenses.title")}</h1>
                    {tabKey ? 
                        <div className="col-12 tabs">
                            <Container className="default">
                                <Row>
                                    <Tabs id="controlled-tab" activeKey={tabKey} onSelect={(k) => changeTab(k)}>
                                        {//"user", "reseller", "agent", "publisher"
                                            tabs.map((tabName)=>{
                                                if (
                                                    (tabName === "user" && user?.roles.includes('ROLE_63')) ||

                                                    (tabName === "reseller" && user?.roles.includes('ROLE_165') && (user?.functions.includes("sellerB2B") || user?.functions.includes("sellerB2C") || user?.functions.includes("publisher"))) ||

                                                    (tabName === "agent" && user?.roles.includes('ROLE_65') && user?.functions.includes("agent")) ||

                                                    (tabName === "publisher" && user?.roles.includes('ROLE_66') && user?.functions.includes("publisher")) ||

                                                    (tabName === "sellerWait" && (user?.functions.includes("sellerB2B") || user?.functions.includes("sellerB2C") || user?.functions.includes("publisher"))) ||

                                                    (tabName === "buyerWait" && (user?.functions.includes("sellerB2B") || user?.functions.includes("sellerB2C") || user?.functions.includes("publisher"))) ||

                                                    (tabName === "sold" && (user?.functions.includes("sellerB2B") || user?.functions.includes("sellerB2C") || user?.functions.includes("publisher")))
                                                ) {
                                                    return  (
                                                        <Tab
                                                            disabled={licenses === undefined ? true :false}
                                                            tabClassName={`${tabKey === tabName && "nw-bg-blue-light"} "rounded-0 rounded-top"`} 
                                                            key={uuidv4()} eventKey={tabName} 
                                                            title={
                                                                <span
                                                                    title={t(`licenses.list.tabs.${tabName}.tt`)}
                                                                    className='position-relative'
                                                                    onMouseEnter={licenses && tabKey === tabName ? (e)=>handleMouseEnter(tabName): null}
                                                                    onMouseLeave={licenses && tabKey === tabName ? (e)=>handleMouseLeave(tabName) : null}
                                                                >
                                                                    {t(`licenses.list.tabs.${tabName}`)}
                                                                    <span
                                                                        ref={el => tabsLoadRef.current[tabName] = el}
                                                                        name={tabName}
                                                                        style={{marginTop: "-4px"}}
                                                                        className={`${licenses === undefined && tabKey === tabName? '': 'invisible '} position-absolute end-0`}
                                                                        onClick={() => setLicenses(undefined)}
                                                                    >
                                                                        {tabKey === tabName && 
                                                                            <ReloadSvg className={`${licenses === undefined && tabKey === tabName? 'loader-elt ' : ''}`} fill="var(--nw-blue-color)"/>
                                                                        }
                                                                    </span>
                                                                    <span 
                                                                        ref={el => tabsTextRef.current[tabName] = el}
                                                                        className={`${licenses === undefined && tabKey === tabName? 'invisible': ''} ms-1`}
                                                                    >
                                                                        {licensesLength[tabName] !== undefined ?"(" +licensesLength[tabName]+")": tabKey === tabName ? "(0)" : ""}
                                                                    </span>
                                                                </span>
                                                            }
                                                        >
                                                            {tabKey === tabName &&
                                                                <LicenseList
                                                                    name={tabName}
                                                                    searchInCols={searchInCols}
                                                                    ref={el => tabsRef.current[tabName] = el}
                                                                    licenses={licenses}
                                                                    licensesLength={licensesLength[tabName]}
                                                                    colsStructure={currentColsStructure.current}
                                                                    reloadTab={reloadTab}
                                                                    hasAccess={hasAccess.current}
                                                                    searchText={lastSearch.current}
                                                                />
                                                            }
                                                        </Tab>
                                                    )
                                                }
                                                return '';
                                            })
                                        }                                        
                                    </Tabs>
                                </Row>
                            </Container>
                        </div>
                        : 
                        <div>{t("nw.list.err.empty")}</div>
                    }
                </div>
            </div>
        </div>
    )
}


const searchColumns = (searchs) => {
    if (!searchs) {
        return [];
    }
    //i18n keys used to parse columns name
    const cols = {
        searchCaption: 'licenses.list.cols.caption',
        searchLicenseKey:  'licenses.list.cols.key',
        searchProductName:  'licenses.list.cols.prod',
        searchEmail: 'licenses.list.opt.search.in.licEmail',
        searchTokenNumber: 'licenses.list.opt.search.in.token',
        searchMsaNumber: 'licenses.list.opt.search.in.Msa',
        searchSystemName: 'licenses.list.opt.search.in.Sys',
        searchUserdata1: 'licenses.list.opt.search.in.userdata1',
    }
    return searchs?.map((search) => {
        const colKey = cols[search];
        return colKey ? colKey : null;
    });
}

const getAccess = (roles) => {
    const access = {
        add: false,
        info: true,
        download: false,
        manage: false,
        edit: false,
        details: false,
        editCaption: false,
        retract: false,
        reject: false,
        accept: false,
        selfRegSeller: false,
    }
    if (roles?.length) {
        for (const role of roles) {
            switch (role) {
                case 'ROLE_74':
                    access.add = true;
                    break;
                case 'ROLE_123':
                    access.download = true;
                    break;
                case 'ROLE_124':
                    access.manage = true;
                    break;
                case 'ROLE_125':
                    access.edit = true;
                    break;
                case 'ROLE_156':
                    access.details = true;
                    break;
                case 'ROLE_214':
                    access.panel = true;
                    break;
                case 'ROLE_223':
                    access.selfRegSeller = true;
                    break;
                default:
                    break;
            }
        }
    }
    return access;
}