import {API_UPDATE_ACT_TX} from 'config/nw-api';
import AxiosPrivate from 'Services/Common/AxiosPrivate'

function update({id, status}) {
    return AxiosPrivate.put(API_UPDATE_ACT_TX.replace(":id", id).replace(":status", status));
}

const ActTxAPI = {update};

export default ActTxAPI;

