import { useState, createContext, useContext } from "react";

const FlashContext = createContext();

export const FlashProvider = ({children}) => {
    const [flash, setFlash] = useState(null);

    return (
        <FlashContext.Provider value={{flash, setFlash}}>
            {children}
        </FlashContext.Provider>
    )  
};

export const useFlashContext = () => useContext(FlashContext);
