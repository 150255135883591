import {v4 as uuidv4} from 'uuid';
import { useTranslation } from "react-i18next";

export default function Select(props) {
    const { t } = useTranslation();
    const classes = props.className ? props.className : "";
    const selected = props.options.filter(option => {return props.value == option.value});// eslint-disable-line
    let titlePrefix = selected.length > 0 ? `` + t(`clear.${selected[0].i18nKeyLabel}`) + `\n–––––\n`  : "";
    titlePrefix += props.title ? "" : t(`clear.${selected[0].i18nKeyLabel}.tt`);

    return (
                <select
                    disabled={props.hidden || props.disabled}
                    name={props.name}
                    id={props.id}
                    value={props.value}
                    onChange={props.onChange}
                    className={classes +(props.hidden || props.disabled ? " nw-select-disabled" : "")}
                    data-parentid={props.ParentId}
                    title={titlePrefix + (props.title ? props.title : "")}
                >
                    {
                        props.options.map((option)=>{
                            return  (
                                <option 
                                    key={uuidv4()}
                                    value={option.value}
                                    title={`${t(`clear.${option.i18nKeyLabel}`)}\n–––––\n${t(`clear.${option.i18nKeyLabel}.tt`)}\n`}
                                    //title={t(`clear.${option.i18nKeyLabel}.tt`)}
                                    className="fn-code"
                                >
                                    {t(`clear.${option.i18nKeyLabel}`)}
                                </option>
                            )
                        })
                    }
                </select>
    )
}
