import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const usePublicRoutes = () => {
    const {t} = useTranslation();
    const location = useLocation();
    //Add Public routes
    const PUBLIC_ROUTES = [
        "esd",
        t("link.lic.clear.lic"),
        t("link.download.url"),
        t("link.lic.upgrade.code"),
        t("link.lic.userdata"),
        t("link.lic.wait"),
        t("link.err.url"),
        t("link.legal").replace(/{{.*?}}/g, ''),
        t("link.ras.vnc.url")
    ];

    // Check if the current pathname starts with any of the public route strings
    const isPublicRoute = PUBLIC_ROUTES.some(route => location.pathname?.includes(route));

    return isPublicRoute;
};

export default usePublicRoutes;