import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LicenseAPI from "Services/LicenseAPI";
import Select from "Components/Forms/Select";
import {v4 as uuidv4} from 'uuid';
import ConfirmModal from 'Components/ConfirmModal';
import './index.css';
import {ReactComponent as InfoSVG}  from "assets/images/icon/info.svg";
import {convertSize} from "Services/utils";
import Spinner from 'Components/Loading/Spinner';
import { useSelector } from 'react-redux';
import { handleCheckboxDivClick } from 'Services/utils';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import { checkEmail } from 'Components/Tools/Text';
//import SelectEmail from './SelectEmail';
import InputDropDown from 'Components/Forms/InputDropDown';
import {localeDatetime} from 'Components/Tools/DateTime';
import {useFlashContext}  from 'Contexts/FlashContext';


export default function LicenseOptions() {
	const {isAuthenticated} = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state) => state.user);
    /* eslint-disable */
    useEffect(()=>{
        if (isAuthenticated && !location.state?.default?.hasLoggedUser) {
            const clearLicenselink = data.product?.key ? t("link.lic.clear.lic")+"/"+data.product?.key : t("link.lic.clear.lic");
            navigate(clearLicenselink, {replace: true});
        }
    }, [isAuthenticated])
    /* eslint-enable */
    const { t } = useTranslation();
    const {setFlash} = useFlashContext();
    const [hideOptBtn, setHideOptBtn] = useState(false);
    const [hideSize, setHideSize] = useState(false);
    const [filesize, setFilesize] = useState();
	const showOptBtn = useRef(null);
	const sizeTranslation = useRef(null);
    const [data, setData] = useState(location.state?.default);
    const template = useRef(location.state?.default.lastTemplate);
    const [saveTemplate, setSaveTemplate] = useState({show: false, checked: false});
    const [release, setRelease] = useState();
    const [methods, setMethods] = useState({
        "options":[],
        "value": ""
    });
    const [alternateMail, setAlternateMail] = useState({
        value: location.state?.default?.emails?.length > 0 ? location.state?.default?.emails[0] : location.state?.default?.product?.email,
        err: {}
    });
    const [components, setComponents] = useState([]);

    const [toggle, setToggle] = useState(false);
    const [toggleUpdate, setToggleUpdate] = useState(false);
    const [agreeContract, setAgreeContract] = useState(location.state?.default?.product?.isContractAgreed ? true: false);
    
    const [checkboxAgreeStyle, setCheckboxAgreeStyle] = useState({width:"40px", marginTop: "1px"});
    const handleHover = (e)=>{
        if (e.type === "mouseenter" && !agreeContract) {
            setCheckboxAgreeStyle({...checkboxAgreeStyle, background: "#ffe0e0", borderColor: "#ff0000"})
        }else if(e.type === "mouseleave"){
            setCheckboxAgreeStyle({width:"40px", marginTop: "1px"})
        }
    }

    const toggleOption = (e) => {
        e.preventDefault();
        setHideOptBtn(true);
    }

    document.title = t("clear.title")+ " – nanoways.com ";

    // Used by child ConfirmModal
    const confirm = (value) => {
        if (value) {
            updateProduct();
        }
    }

    //Delete Email from localstorage
    const deleteEmail = (removedEmail) => {
        try {
            let emailsLocalstorage = localStorage.getItem('emailsEsd');
            if (emailsLocalstorage) {
                emailsLocalstorage = JSON.parse(emailsLocalstorage);
                if(emailsLocalstorage?.length>0){
                    const filtredEmails = emailsLocalstorage.filter(email => email !== removedEmail);
                    localStorage.setItem('emailsEsd', JSON.stringify([...filtredEmails]));
                }
            }
        }catch (error) {
            console.error('Error parsing JSON:', error.message);
        }
    }

    /* __START__ UPDATE */
    const updateProduct =  () => {
        setToggleUpdate(true);
        let key = data.product?.key;
        let shortName = data.updateInfo?.shortName;
        LicenseAPI.licenseUpdateAndOptions(key, shortName, isAuthenticated)
        .then(response => {
            setData(response.data);
            setRelease(response.data.releases[0]);
            setToggleUpdate(false);
        })
        .catch(error => {
            if(!error.response || error.response.data.status === 500){
                console.log(error.message);
                setFlash({message: t("err.500"),alert: "danger"});
            } else {
                if(error.response.data.message === undefined){
                    console.log(error.response.data);
                    setFlash({message: t("err.unkown"),alert: "danger"});
                } else {
                    const {message} = error.response.data;
                    setFlash({message: t("err.unkown")+`: ${message}`,alert: "danger"});
                    console.log(message);
                }
            }
            setToggleUpdate(false);
        })
    }
    /* __END__ UPDATE */
    /* PRODUCT FORM HANDLING */
    /* eslint-disable */
    useEffect(()=>{
        setHideSize(true);
        let currentComponents = convertComponentsToPostData(components);
        currentComponents = Object.keys(currentComponents)?.length === 0 ? [] : currentComponents;
        const currentMethod = parseInt(methods?.value)
        //Remove afgter tests not used anymore
        if(template.current === undefined && release && currentMethod >=0 && currentComponents){
            template.current = {
                //release: release.release,
                method: currentMethod,
                components: currentComponents,
                language: location.state?.default?.product?.defaultLanguage
            }
            /*if (location.state?.default?.product?.defaultLanguage) {
                template.current.language = location.state?.default?.product?.defaultLanguage;
            }*/
        }
        if(
            //release?.release === template.current?.release && 
            (location.state?.default?.product?.defaultLanguage === template.current?.language) && 
            currentMethod === template.current?.method && 
            JSON.stringify(currentComponents) === JSON.stringify(template.current?.components)
        ) {
            setSaveTemplate({...saveTemplate, show:false}); 
        }else if(template.current) {
            setSaveTemplate({...saveTemplate, show:true});
        }
    }, [data, release, methods, components]);
    useEffect(()=>{
        // Set default value of Release
        function updateRelease(selectedRelease){
            if(selectedRelease?.options.length>0){
                const method = selectedRelease.options.find((opt)=>parseInt(opt.value) ===  parseInt(lastmethod.current));
                setMethods({
                    options: selectedRelease.options,
                    value: location.state?.post?.method ?? method?.value ?? selectedRelease.options.sort((a, b) => a.order - b.order)[0].value
                });
            } else {
                setMethods({"options":[],"value": ""});
            }
            if(selectedRelease?.components.length>0){
                //Prepare components
                const comps = selectedRelease.components.map((item) => {
                    let comp = [];
                    if(location.state?.post?.comp){
                        if (Object.keys(location.state?.post?.comp).includes(String(item.value))) { 
                            comp["checked"] = true;
                        }else{
                            comp["checked"] = false;
                        }
                        for (const child of item.children) {
                            if (Object.values(location.state?.post?.comp).includes(String(child.value))) {
                                comp["value"] = child.value;
                            }
                        }
                    }else{
                        comp["checked"] = item.checked;
                        comp["value"] = item.children ? item.children.sort((a, b) => a.order - b.order)[0].value : "";
                    }
                    comp["content"] = item;
                
                    return comp
                });
                //Select last components config
                const lastComps = [];
                comps.forEach(comp => {
                    const newComp = lastComponent.current?.find((lastComp) => lastComp.content.value === comp.content.value) ?? comp;
                    lastComps.push(newComp);
                });

                setComponents(lastComps);
            }else{
                setComponents([]);
            }
        }
        
        if (location.state?.post?.release) {
            //Set Release from location
            setRelease(location.state?.post?.release);
            if (location.state.post.hideOptBtn && !hideOptBtn) {
                showOptBtn.current?.click();
            }
        }else if (!release) {
            //Set Release from Data
            let selectedRelease = data?.releases.find(item=>item.release === data?.defaultRelease);
            setRelease(selectedRelease || data?.releases[0]);
        }
        updateRelease(release);
    }, [release]);
    useEffect(()=>{
        const timer = setTimeout(()=>{
            setHideSize(false);
            setFilesize(calculateSize(release, selectedCompAsStr(components), methods));
        },250);
            
        return () => clearTimeout(timer);
        
    }, [hideSize]);
    useEffect(()=>{        
        // Return to previous page (form) if user come direct to options page
        if (!location.state) {
            navigate(t("link.lic.clear.lic"));
        }
    }, [])
    /* eslint-enable */
    
    function selectedCompAsStr(components){
        let obj = {}
        components.forEach(el => {
            if (el.checked === true) {
                obj[el.content.value] = parseInt(el.value) || true
            }
        });

        return JSON.stringify(obj);
    }

    function calculateSize(selectedRelease, compAsStr, methods){
        let bytes = 0;
        if(selectedRelease?.sizes){
            selectedRelease.sizes.forEach(el => {
                let count = 0;
                for (const key in compAsStr) {
                    if (compAsStr[key] === el.componentString[key]) {
                        count++;
                    }
                }
                if (Object.keys(el.componentString).length === count && el.optionValue ===  parseInt(methods.value)) {
                    bytes = el.size;
                }
            });

            const convertedSize = convertSize(bytes);
            return convertedSize ? convertedSize.size + "\u00A0" + t(`gen.var.prefix`).charAt(convertedSize.pow)+t(`gen.var.byte`) : t("gen.text.unavailable.abbr");
        }

        return t("gen.text.unavailable.abbr");
    }

    // select release
    const handleReleaseChange = ({currentTarget}) => {
        const {value} = currentTarget;
        let selectedRelease = data.releases.filter(item => {
            return parseInt(item.release) === parseInt(value)
        });

        if(selectedRelease.length>0){
            setRelease(selectedRelease[0]);
        }else{
            setRelease({});
        }
        if (location.state.post) {
            location.state.post = null;
        }
    };

    // Select Method
    const lastmethod = useRef(null);
    const handleMethodChange = ({currentTarget}) => {
        const {value} = currentTarget;
        lastmethod.current = value;
        setMethods({...methods, value: value});
    };

    //Enable/disable Component
    const lastComponent = useRef(null);
    const handleComponentChange = ({currentTarget}) => {
        let comps = components.filter(comp => {
        if (parseInt(currentTarget.id.match(/\d+/)[0]) === comp.content.value) {
            comp.checked = !comp.checked;
        }
            return comp;
        });

        lastComponent.current = comps;
        setComponents(comps);
    };
        
    // Select component value
    const handleComponentChildChange = ({currentTarget}) => {
        const {value} = currentTarget;
        
        let comps = components.filter(comp => {
        if (parseInt(currentTarget.dataset.parentid) === comp.content.value) {
            comp.value = value;
        }
            return comp;
        });
        setComponents(comps);
    };

    const handleSubmit = async event => {
        event.preventDefault();
        setToggle(true);
        const postData = {};
        //Slice only checked components
        const arr = convertComponentsToPostData(components)
        /*components.forEach(el => {
            if (el.checked === true) {
                arr[el.content.value] = el.value || true;
            }
        });*/

        setAlternateMail({...alternateMail, err: {}});
        if (alternateMail.value && user?.roles.includes('ROLE_129')) {
            const email = alternateMail.value;
            if (!checkEmail(email)) {
                setToggle(false);
                setAlternateMail({
                    ...alternateMail,
                    err: {msg: "nw.input.mail.err.invalid"}
                });
                return;
            }

            //Uncomment to active check domains. Domain should be returned from API
            const authorizedDomains = location.state?.default?.domainsEmail;
            const alternateMailDomain = email.match(/@(.+)$/)[1]; //email?.substring(email.indexOf('@')+1);
            if (!canAddAllEmails && canAddOnlyDomainsEmail && !authorizedDomains.includes(alternateMailDomain)) {
                setToggle(false);
                setAlternateMail({
                    ...alternateMail,
                    err: {
                        //key should be added to i18n with {{domains}}
                        msg: "clear.select.opt.email.err.domain", 
                        vars:{domains:authorizedDomains.join(', ')}
                    }
                });
                return ;  
            }
        }

        if (data.alternateLanguage) {
            postData["alternateLanguage"] = data.alternateLanguage;
        }

        //Prepare data 
        postData["key"] = data.product?.key;
        postData["releaseId"] = release.release;
        postData["releaseVer"] = release.version;
        postData["downloadMethod"] = methods.value;
        postData["downloadBuildComponent"] = arr;
        let timezone = new Date().toString().match(/([-+][0-9]+)/g);
        postData["timezone"] = timezone?.[0] ?? null;
        postData["server"] = (data.server && data?.server !== "default") ? parseInt(data.server) : null;
        postData["isContractAgreed"] = agreeContract;
        if (isAuthenticated && alternateMail?.value) {
            postData["alternateMail"] = alternateMail?.value?.trim();
        }
        postData["saveAsTemplate"] = saveTemplate.show && saveTemplate.checked;

        await LicenseAPI.licenseClearWithOption(postData, isAuthenticated)
            .then(response => {
                postData.alternateMail && saveNewEmail(postData.alternateMail, dataEmails);
                response.data.lastKey = location.state.default?.product?.key;
                response.data.downloadInfo.methodI18N = methods.options.find(
                        option =>option.value === parseInt(methods.value)
                    )?.i18nKeyLabel;
                response.data.default = data;
                response.data.apiData = postData;
                response.data.post = {release:release, method: methods.value, comp: arr, hideOptBtn:hideOptBtn};
                navigate(t("link.lic.clear.done"), {state:response.data});
            })
            .catch(error => {
                setToggle(false);
                console.log(error);
                if(!error.response || error.response.data.status === 500){
                    setFlash({message: t("err.500"),alert: "danger"});
                } else {
                    let errData = error.response?.data;
                    const msg = errData?.message?.toLowerCase();
                    if(/^license|product/.test(msg)) {
                        navigate(t("link.err.url"), {replace:true, state:{errGroup: "noLK", docTitleI18n: "clear.title"}});
                    } else if(msg === "download not allowed") {
                        navigate(t("link.err.url"), {replace:true, state:{errGroup: "forbid", docTitleI18n: "clear.title"}});
                    } else if(msg === "maxclear") {
                        navigate(t("link.err.url"), {state:{errGroup:'maxclear', docTitleI18n: "clear.title"}});
                    } else if(msg === "expired") {
                        navigate(t("link.err.url"), {state:{errGroup:'expired', info:errData?.info, lastKey:errData?.product?.key, docTitleI18n: "clear.title"}, replace: true});
                    }else if(msg === "norelease") {
                        navigate(t("link.err.url"), {state:{errGroup:errData?.i18nKey, info:errData?.info, lastKey:data.product?.key, docTitleI18n: "clear.title"}, replace: true});
                    } else if(msg === "barred") {
                        navigate(t("link.err.url"), {state:{errGroup:errData?.i18nKey, info:errData?.info, lastKey:data.product?.key, docTitleI18n: "clear.title"}, replace: true});
                    } else if(msg === "empty user data"){
                        navigate(t("link.lic.userdata"), {state:{license:data.product?.key, product: errData?.info.product, oldData: data.info.license}, replace: true});
                    } else if(msg === "invalid license sold date"){
                        navigate(t("link.err.url"), {state:{errGroup:'info',docTitleI18n: "clear.title",info:data?.info}});
                    } else if(errData?.err?.toLowerCase() === "unsold"){
                        const errGroup = msg === 'invalid license sold date'? 'invalid' : 'missing';
                        navigate(t("link.err.url"), {state:{errGroup:errGroup, docTitleI18n: "clear.title",info:errData?.info}});
                    }  else if(errData?.err?.toLowerCase() === "email"){
                        setAlternateMail({
                            ...alternateMail,
                            err: {msg: "clear.select.opt.email.err"}
                        });
                    } else {
                        console.log(t("err.unknown") + " 😥");
                    }
                }
            })
        ;
    }
    /* __END__ PRODUCT FORM HANDLING */

    const handleServerChange = (e) => {
        setData({...data, server: e.currentTarget.value});
    }

    const handleAlternateLanguageChange = (e) => {
        template.current.language = e.currentTarget.value;
        setData({...data, alternateLanguage: e.currentTarget.value});
    }

    if (data && data.updateInfo) {
        var dialogTxt = {
            label:t("clear.select.update.free"),
            title:t("clear.select.update.free"),
            body:t("clear.select.update.confirm", {
                    productName: data.updateInfo.productName,
                    yes:t("clear.select.update.confirm.yes"),
                    no:t("clear.select.update.confirm.no")
                }),
            yes:t("clear.select.update.confirm.yes"),
            no:t("clear.select.update.confirm.no"),
        }   
    }

    const dataEmails = location.state?.default?.emails ?? [];
    
    const canAddAllEmails = user?.roles.includes('ROLE_204');
    const canAddOnlyDomainsEmail = user?.roles.includes('ROLE_203') ||  user?.roles.includes('ROLE_185');
    const hasEditEmail = user?.roles.includes('ROLE_186') && (canAddAllEmails || canAddOnlyDomainsEmail);
    const emails = hasEditEmail ? [...mergeWithStorage(dataEmails)] : dataEmails;

    return (<div className="m-0 p-0 vertical-center">
            <div className="bm-main">
                {data && data.updateInfo &&
                <div className="nw-bg-green-dark alert alert-dismissible fade show px-box py-4" role="alert">
                    <div className="row align-items-center">
                        <div className="col-12 mb-2">
                            <h1 className="mb-1 text-white">{t("clear.select.update")}</h1>
                        </div>
                        <div className="col-12 mb-3">{data.updateInfo.productName}</div>
                        <div className="col-12">
                            {/* For Debugging update Button Change below line to {!data.updateInfo.isFree ? */}
                            {data.updateInfo.isFree ?                            
                                <ConfirmModal toggle={toggleUpdate} disabled={toggle || toggleUpdate} txt={dialogTxt} confirm={confirm} classes="float-end bm-btn-white"/>
                                :
                                <a className="float-end" href={data.updateInfo.link} target="_blank" rel="noopener noreferrer">
                                    <button className="bm-btn-white">{t("clear.select.update.buy")}</button>
                                </a>
                            }
                        </div>
                        <button type="button" className="btn-close close-x btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </div>
                }
   
                <div className="bm-card">
                    <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
                    <h1>{ t("clear.title") }</h1>
                    <div>
                        {(data && 
                            <>
                                <h2 className="bm-sep-1">{t("clear.select.info")}</h2>
                                <div className="row nw-license-detail">
                                    <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                        {t("clear.select.info.lk")}
                                    </div>
                                    <div title={data.product?.key} className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                        {data.product?.key}                                           
                                    </div>
                                </div>
                                <div className="row nw-license-detail">
                                    <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                        {t("clear.select.info.name")}
                                    </div>
                                    <div title={data.product.name} className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                        {/*<span dangerouslySetInnerHTML={{__html: data.product.name.replace(/\./g, "&#8203;.")}} />*/}
                                        {data.product?.name}
                                    </div>
                                </div>
                                {data.product.caption &&

                                    <div className="row nw-license-detail">
                                        <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                            {t("clear.select.info.cap")}
                                        </div>
                                        <div title={data.product.caption}  className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                            {data.product.caption}
                                        </div>
                                    </div>
                                }

                                <form onSubmit={handleSubmit}> 

                                    {release && data.releases.length > 1 && 
                                    <>
                                        <div className="row mx-0">
                                            <div className="col-sm-4 col-xs-12 px-0">
                                                <label className="fn-smtxt-bold" htmlFor="prodVer">{t("clear.select.info.ver")}</label>
                                            </div>
                                            <div className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0">
                                                <select
                                                    title={
                                                        release.version + 
                                                        " "+
                                                        t("clear.select.opt.stage."+release.releaseI18NKey+".tt", {date:localeDatetime(release.changedAt, false)}) +
                                                        (release.isNew ? ", "+t("clear.select.opt.stage.new") : "")
                                                    }
                                                    id="prodVer" 
                                                    disabled={toggle}
                                                    className={`${release.isNew ? "fw-bold " : ""} ${release.stage > 0 ? "nw-blue" : ""} fn-code float-end nowrap`} 
                                                    value={release.release}
                                                    onChange={handleReleaseChange}
                                                >
                                                {
                                                    data.releases.map((item)=>{
                                                        let name = "release"+ item.release;
                                                        let optTxt = item.version + " " + t("clear.select.opt.stage."+item.releaseI18NKey+".tt", {date:localeDatetime(item.changedAt, false)})
                                                        optTxt += item.isNew ? ", "+t("clear.select.opt.stage.new") : "";
                                                        
                                                        return (
                                                            <option
                                                                key={uuidv4()}
                                                                id={name}
                                                                name={name}
                                                                title={optTxt}
                                                                value={item.release}
                                                                className={`${item.isNew ? "fw-bold " : ""} ${item.stage > 0 ? "nw-blue" : "nw-color-normal"} fn-code nowrap`} 
                                                            >
                                                                {item.version}
                                                            </option>
                                                        )
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                    }
                                    {release && data.releases.length === 1 && 
                                    <>
                                        <div className="row nw-license-detail">
                                            <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                                {t("clear.select.info.ver")}
                                            </div>
                                            {/*title={`${release.version} (${t("clear.select.opt.stage."+release.releaseI18NKey)} ${release.isNew ? ", "+t("clear.select.opt.stage.new") : ""})`}*/}
                                            <div
                                                title={
                                                    release.version +
                                                    " "
                                                    + t("clear.select.opt.stage."+release.releaseI18NKey+".tt", {date:localeDatetime(release.changedAt, false)})+
                                                    (release.isNew ? ", "+t("clear.select.opt.stage.new"):"")
                                                }
                                                className={`${release.isNew ? "fn-smtxt-bold" : ""} ${release.stage > 0 ? "nw-blue" : ""} col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap`}
                                            >
                                                {release.version}
                                            </div>
                                        </div>
                                    </>
                                    }
                                    {methods && methods.length !== 0 &&
                                        <div className="row mx-0">
                                            <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                                <label className="fn-smtxt-bold" htmlFor="downloadMethod">{t("clear.select.opt.pkg")}</label>
                                                <InfoSVG fill="#a0a0a0" style={{verticalAlign: "bottom"}} className="ms-1" title={t("clear.select.opt.pkg.sat")} width={16}/>
                                            </div>
                                            <div className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0">
                                                {methods.options.length > 1 && 
                                                    <Select 
                                                        id="downloadMethod"
                                                        name="downloadMethod"
                                                        title={t("clear.select.opt.pkg.sat")}
                                                        className="fn-code float-end nowrap"
                                                        value={methods.value}
                                                        options={methods.options}
                                                        onChange={handleMethodChange}
                                                        disabled={toggle} 
                                                    />
                                                }
                                                {methods.options.length === 1 && 
                                                    <div 
                                                        className="nowrap mb-2" 
                                                        title={t(`clear.${methods.options[0].i18nKeyLabel}`) + `\n`+t(`clear.${methods.options[0].i18nKeyLabel}.tt`)}
                                                    >
                                                        {t(`clear.${methods.options[0].i18nKeyLabel}`)}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {//languages from Release
                                    user?.roles.includes('ROLE_175') && release?.languages?.length>0 &&
                                        <SelectAlternateLanguage value={data.alternateLanguage || data.product?.defaultLanguage} onChange={handleAlternateLanguageChange} languages={release.languages} disabled={toggle} />
                                    }
                                    
                                    {//languages from License
                                    user?.roles.includes('ROLE_174') && !user?.roles.includes('ROLE_175') && data.product?.languagesLicense?.length>0 &&
                                    release &&
                                        <SelectAlternateLanguage value={data.alternateLanguage || data.product?.defaultLanguage} onChange={handleAlternateLanguageChange} languages={release.languages} supportedLanguages={data.product?.languagesLicense} disabled={toggle} />
                                    }
                                    {/*user?.roles.includes('ROLE_129') &&
                                        <div className="row mx-0" title={t("clear.select.opt.email.tt")}>
                                            <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                                <label className="fn-smtxt-bold" htmlFor="alternateMail">{t("clear.select.opt.email")}</label>
                                                <InfoSVG fill="#a0a0a0" style={{verticalAlign: "bottom"}} className="ms-1" title={t("clear.select.opt.email.tt")} width={16}/>
                                            </div>
                                            <div className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0">
                                                <input
                                                    type="email"
                                                    name="alternateMail"
                                                    id="alternateMail"
                                                    disabled={user?.roles.includes('ROLE_129') ? false : true}
                                                    tittle={t("clear.select.opt.email.tt")}
                                                    placeholder={t("clear.select.opt.email.hint")}
                                                    onChange={(e)=> {
                                                        setAlternateMail({...alternateMail, value:e.currentTarget.value});
                                                    }}
                                                    className={"fn-code float-end nowrap input-small " + (alternateMail.err && " bm-input-is-invalid ")}
                                                    value={alternateMail.value}
                                                    list=""
                                                />
                                                <datalist id="optionsEmail">
                                                  {emails?.map((option, index) => (
                                                    <option key={index} value={option.value} />
                                                  ))}
                                                </datalist>
                                                {alternateMail.err.length > 0 && <div className="fn-smtxt invalid-feedback mb-1" dangerouslySetInnerHTML={{__html: alternateMail.err}}/>}
                                            </div>
                                        </div>
                                    */}
                                    {user?.roles.includes('ROLE_129') && 
                                    <div className="row mx-0">
                                        <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                            <label className="fn-smtxt-bold" htmlFor="alternateMail">{t("clear.select.opt.email")}</label>
                                            <InfoSVG fill="#a0a0a0" style={{verticalAlign: "bottom"}} className="ms-1" title={t("clear.select.opt.email.tt")} width={16}/>
                                        </div>
                                        <div className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0">
                                        {hasEditEmail && 
                                            <InputDropDown
                                                name="alternateMail"
                                                type="email"
                                                placeholder={t("clear.select.opt.email.hint")}
                                                disabled={toggle}
                                                boldOptions={[location.state?.default?.product?.email]}
                                                delete={deleteEmail}
                                                cantDelete={[location.state?.default?.product?.email, user.email]}
                                                error={alternateMail.err}
                                                options={emails ?? []}
                                                value={alternateMail.value}
                                                //user={user}
                                                setOriginValue={(value)=> {
                                                    setAlternateMail({...alternateMail, value:value});
                                                }}
                                            />
                                        }
                                        {!hasEditEmail && emails?.length > 1 && 
                                            <select 
                                                className="fn-code float-end nowrap"
                                                value={alternateMail.value}
                                                disabled={toggle}
                                                onChange={(e) => {
                                                    setAlternateMail({...alternateMail, value:e.currentTarget.value})
                                                }}>
                                                    {emails.map((opt)=>{
                                                        return (
                                                            <option className="fn-code nowrap" key={uuidv4()} value={opt}>
                                                                {opt}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        }
                                        {!hasEditEmail && emails?.length === 1 &&
                                            <input 
                                                type="email"
                                                name="alternateMail"
                                                id="alternateMail"
                                                disabled
                                                tittle={t("clear.select.opt.email.tt")}
                                                placeholder={t("clear.select.opt.email.hint")}
                                                className={"fn-code float-end nowrap input-small"}
                                                value={emails[0]}
                                            />
                                        }
                                        </div>
                                    </div>}
                                    {//Emails Dropdown
                                    /*user?.roles.includes('ROLE_129') && emails.length > 0 &&
                                        <div className="row mx-0" >
                                            <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                                <label className="fn-smtxt-bold" htmlFor="alternateMail">{t("clear.select.opt.email")}</label>
                                                <InfoSVG fill="#a0a0a0" style={{verticalAlign: "bottom"}} className="ms-1" title={t("clear.select.opt.email.tt")} width={16}/>
                                            </div>
                                            <div className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0">                
                                                <SelectEmail
                                                    //isClearable={true}
                                                    name="alternateMail"
                                                    placeholder={t("clear.select.opt.email.hint")}
                                                    classNamePrefix="EnterSelectEmail"
                                                    disabled={toggle}
                                                    domains={location.state?.default?.domainsEmail}
                                                    error={alternateMail.err}
                                                    licenseEmail={location.state?.default?.product?.email}
                                                    options={emails ?? []}
                                                    value={{value:alternateMail.value, label: alternateMail.value}}
                                                    user={user}
                                                    access={{
                                                        allEmails: canAddAllEmails,
                                                        domainEmails: canAddOnlyDomainsEmail,
                                                        editEmail: hasEditEmail
                                                    }}
                                                    setMail={(e)=> {
                                                        setAlternateMail({...alternateMail, value:e.value});
                                                    }}
                                                    isSearchable={false}
                                                    selectIcon={<span className="arrowDownIcon"></span>}
                                                    //isOptionDisabled={(option)=> input.disabled.includes(option.value)} //use for creatable select
                                                    //noOptionsMessage={() => t("nw.multiSel.noOpt")}
                                                />
                                            </div>
                                        </div>
                                    */}
                                    {user?.roles.includes('ROLE_126') && data.servers &&
                                        <div className="row mx-0">
                                            <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                                <label className="fn-smtxt-bold" htmlFor="servers">{t("clear.select.opt.srv")}</label>
                                            </div>
                                            <div className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0">
                                                {user.roles.includes("ROLE_127") ?
                                                    <select disabled={toggle} title={data?.server && data?.server !== 'default' ? t(`clear.select.opt.srv.${data?.server}.tt`) : ""} id="servers" className="fn-code float-end nowrap" value={data?.server} onChange={handleServerChange}>
                                                        <option className="fn-code nowrap" value="default">
                                                            {t("clear.select.opt.srv.sel")}
                                                        </option>
                                                        {data.servers?.map((server)=>{
                                                            return (
                                                                <option title={t(`clear.select.opt.srv.${server}.tt`)} className="fn-code nowrap" name={`server-${server}`} id={`server-${server}`} key={uuidv4()} value={server}>
                                                                    {t(`clear.select.opt.srv.${server}`)}
                                                                </option>
                                                            )
                                                        })
                                                        }
                                                    </select>
                                                    :
                                                    <div 
                                                        className="nowrap mb-2" 
                                                        title={data?.server && data?.server !== 'default' ? t(`clear.select.opt.srv.${data?.server}.tt`) : ""}
                                                    >
                                                        {t(`clear.select.opt.srv.${data?.server}`)}
                                                    </div>
                                                    }
                                            </div>
                                        </div>
                                    }
                                    
                                    {components && components.length > 0 &&

                                        <div className="collapse" id="collapseTarget">
                                            <h2 className="bm-sep-1">{t("clear.select.opt")}</h2>

                                            {/*<div className="bm-two-columns-left fn-smtxt-bold mt-2 mb-3">{t("clear.select.opt.comp")}</div>*/}
                                            {components.map((item) => {
                                                return <div key={uuidv4()}>
                                                    <div className="bm-two-columns">
                                                        <div title={t(`clear.${item.content.i18nKeyLabel}.tt`)} className="bm-two-columns-left pb-2 " onClick={handleCheckboxDivClick}>
                                                            <input disabled={toggle} className="form-check-input" checked={item.checked} onChange={handleComponentChange} type="checkbox" id={"checkbox-"+item.content.value} name={"checkbox-"+item.content.value} value={item.content.value}/>
                                                            {/*style={{whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", maxWidth:"88%"}}*/}
                                                            <label htmlFor={"checkbox-"+item.content.value}>
                                                                {t(`clear.${item.content.i18nKeyLabel}`, {family: data.product.family}) }
                                                            </label>
                                                        </div>
                                                        <div className="bm-two-columns-right">
                                                            {item.content.children && 
                                                            <Select disabled={toggle} hidden={!item.checked} ParentId={item.content.value} id={"checkboxChild-"+item.content.value} name={"checkboxChild-"+item.content.value} options={item.content.children} onChange={handleComponentChildChange} value={item.value} className="fn-code float-end nowrap"/>
                                                            }
                                                        </div>
                                                    </div>
                                            </div>
                                            })}
                                        </div>
                                    }
                                    
                                    {release &&
                                        <div className="row">
                                            <div className="bm-sep-2"/>

                                            <div className="col-md-6">
                                                {saveTemplate.show && 
                                                    <div
                                                        title={t("clear.select.template.tt")}
                                                        className="d-flex"
                                                        style={{marginTop: "26px"}}
                                                        onClick={(e) => handleCheckboxDivClick(e,"saveTemplate")}
                                                    >
                                                        <input disabled={toggle} className="form-check-input" checked={saveTemplate.checked} onChange={()=>setSaveTemplate({...saveTemplate, checked: !saveTemplate.checked})} type="checkbox" id="saveTemplate" name="saveTemplate" value={saveTemplate.checked}/>
                                                        {/*style={{whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", maxWidth:"88%"}}*/}
                                                        <label className="form-check-label" htmlFor="saveTemplate">
                                                            <span dangerouslySetInnerHTML={{__html: t("clear.select.template")}}/>
                                                        </label>
                                                    </div> 
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <div className="bm-note-nav-card-bottom">
                                                    <label>{t("clear.select.opt.size")}</label>
                                                    <span ref={sizeTranslation} filesize={filesize} className={ hideSize ? " nw-trans nw-trans-hide" : "nw-trans"}>
                                                        <span className="invisible">00.0 MB</span>                                                
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    
                                    {!location.state?.default?.product?.isContractAgreed && 
                                        <div style={{marginTop: "35px"}} className="d-flex" onClick={(e) => handleCheckboxDivClick(e,"agreeContract")}>
                                            <input style={checkboxAgreeStyle} disabled={toggle} className="form-check-input" checked={agreeContract} onChange={()=>setAgreeContract(!agreeContract)} type="checkbox" id="agreeContract" name="agreeContract" value={agreeContract}/>
                
                                            <label className="form-check-label" htmlFor="agreeContract">
                                                <span dangerouslySetInnerHTML={{__html: t("nw.input.agree")}}/>
                                            </label>
                                        </div> 
                                    }

                                    <div className="bm-nav-card-bottom">
                                        <span onMouseEnter={handleHover} onMouseLeave={handleHover}>
                                            <OverlayTrigger placement="top" trigger={!agreeContract ? ["hover", "focus", "click"] : []} rootClose={true} rootCloseEvent="mousedown" overlay={<Tooltip className="nw-tooltip">{t("nw.input.agree.tt")}</Tooltip>}>
                                                <button
                                                    type="submit" disabled={(toggle || toggleUpdate || !agreeContract) && "disabled"}
                                                    className={`${ !agreeContract ? "btn-disabled" : "bm-btn-blue"} bm-btn-card-next d-flex justify-content-center ms-1 mb-2`}
                                                    //title={!agreeContract ? t("clear.select.btn.agreeTT") : ""}
                                                >
                                                    {toggle && 
                                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                                    }
                                                    <span className={toggle ? "invisible" : ""}>{t("clear.select.btn")}</span>
                                                </button>
                                            </OverlayTrigger>
                                        </span>

                                        {components && components.length > 0 &&
                                            <button ref={showOptBtn} data-bs-target="#collapseTarget" data-bs-toggle="collapse" hidden={(hideOptBtn === true || toggle) ? "hidden" : ""} onClick={(e)=> toggleOption(e)} id="showOptions" className="mt-sm-0 bm-btn-blue-out bm-btn-card-next">
                                                {t("clear.select.btnOpt")}
                                            </button>
                                        }

                                        {/*<p className="bm-counter-1">{t("info.step", {current: 2, total: 2})}</p>*/}
                                        <div className="bm-clearer"></div>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )  
}

const convertComponentsToPostData = (components) => {
    const data = {}
    components.forEach(el => {
        if (el.checked === true) {
            data[el.content.value] = el.value || true;
        }
    });

    return data;
}

function SelectAlternateLanguage(props) {
    const { t } = useTranslation();
    let filteredLanguages = props.languages;

    if (props.supportedLanguages) {
        filteredLanguages = props.languages?.filter(language => props.supportedLanguages.includes(language));
    }

    if (filteredLanguages.length > 0) {

        return (
            <div className="row mx-0">
                <div className="col-sm-4 col-xs-12 px-0">
                    <label className="fn-smtxt-bold" htmlFor="alternateLanguage">{t("clear.select.info.lang")}</label>
                </div>
                {filteredLanguages.length === 1 ?
                    <div name="alternateLanguage" className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap pb-sm-2">
                        {t(`gen.lang.${filteredLanguages[0]}`)}
                    </div>
                :
                    <div className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0">
                        <select disabled={props.disabled}  name="alternateLanguage" className={`fn-code float-end nowrap ${props.disabled ? " nw-select-disabled" : ""}`} value={props.value} onChange={props.onChange}>
                            {filteredLanguages.map((lang) => {
                                const txt = t(`gen.lang.${lang}`);
                                const title = txt + ` (${lang})`;
                                return (
                                    <option title={title} key={uuidv4()} value={lang}>
                                        {txt}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                }
            </div>
        )
    }

    return null;
}

const mergeWithStorage = (data = []) => {
    try {
        let emailsLocalstorage = localStorage.getItem('emailsEsd');
        if (emailsLocalstorage) {
            emailsLocalstorage = JSON.parse(emailsLocalstorage);
            if(emailsLocalstorage?.length>0){
                return Array.from(new Set([...data, ...emailsLocalstorage]));
            }
        }
    }catch (error) {
        console.error('Error parsing JSON:', error.message);
    }
    return data;
}
const saveNewEmail = (email, originEmailList) => {
    localStorage.removeItem('esdEmails');//Remove old storage
    if (originEmailList?.includes(email)) {
        return;
    }
    try {
        let emails = localStorage.getItem('emailsEsd');
        if (!emails) {
            localStorage.setItem('emailsEsd', JSON.stringify([email]));
            emails = [];
        }else {
            emails = JSON.parse(emails);
        }

        if (!emails.includes(email)) {
            emails = [...emails, email];
        }
        
        if(emails && emails?.length>10){
            emails.pop();
        }
        localStorage.setItem('emailsEsd', JSON.stringify(emails));
    } catch (error) {
        localStorage.removeItem('emailsEsd');
        console.error('Error parsing JSON:', error.message);
    }
}

/*const removeInvalidEmails = () => {
    try {
        let emailsLocalstorage = localStorage.getItem('esdEmails');
        if (emailsLocalstorage) {
            emailsLocalstorage = JSON.parse(emailsLocalstorage);
            if(emailsLocalstorage?.length>0){
                const filtredEmails = emailsLocalstorage.filter(email => !email.error);
                localStorage.setItem('esdEmails', JSON.stringify([...filtredEmails]));
                return filtredEmails;
            }
        }
    }catch (error) {
        console.error('Error parsing JSON:', error.message);
    }
    return [];
}*/