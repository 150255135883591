import { useState, useEffect, useRef } from 'react';
import Field from 'Components/Forms/Field';
//Import translation to find all countries
import DATA_I18N from 'Translations/i18n/translation.json';
import LicenseAPI from "Services/LicenseAPI";
import { useNavigate, useLocation, Link, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {v4 as uuidv4} from 'uuid';
import { SUPPORTEDLNGS } from "config/nw-api";
import FLAGS from 'assets/images';
import { checkEmail } from 'Components/Tools/Text';
import Spinner from 'Components/Loading/Spinner';
import { handleCheckboxDivClick } from 'Services/utils';
import { useSelector } from 'react-redux';

export default function LiceseAddData(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    document.title = t("selfreg.enterData")+ " – nanoways.com";
	const {isAuthenticated} = useSelector((state) => state.auth);
    const closeLangModal = useRef(null);
    const openLangModal = useRef(null);
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);

    /* eslint-disable */
    useEffect(()=>{
        let country = [];
        let lang = [];
        Object.keys(DATA_I18N).forEach(function(key){
            if (key.includes("gen.country.")) {
                country.push(DATA_I18N[key]);
            }
            if(key.includes("languages.")){
                lang.push(DATA_I18N[key]);
            }
            lang = lang.sort(function (a, b) {
                if (t(`languages.${a}`) < t(`languages.${b}`)) {
                    return -1;
                }
                if (t(`languages.${a}`) > t(`languages.${b}`)) {
                    return 1;
                }
                return 0;
            });
            setLanguages(lang);
            setCountries(country);
        });
    }, [])
    /* eslint-enable */

    const [data, setData] = useState({
        name: location.state?.oldData?.userdata1??"",
        address: location.state?.oldData?.userdata2?? "",
        address2: location.state?.oldData?.userdata3?? "",
        zipCity: location.state?.oldData?.userdata4?? "",
        country: location.state?.oldData?.userdata5?.length > 0? location.state?.oldData?.userdata5.toUpperCase() : "default",
        email: location.state?.oldData?.email?? "",
        userCult: location.state?.oldData?.userCult?.length > 0? location.state?.oldData?.userCult :  "default",
        lang: location.state?.oldData?.userLang?? "",
        terms: false
    });

    const[tempLang, setTemLang] = useState("");
    const [errors, setErrors] = useState({
        name: "",
        address: "",
        address2: "",
        zipCity: "",
        country: "",
        email: "",
        userCult: "",
        terms: "",
        api:""
    });
    const [toggle, setToggle] = useState(false);

    //return to ClearLicense page if state not exists
    if (!location.state) {
        return <Navigate to={t("link.lic.clear.lic")} replace />
    } 
    // Manage input changing in form
    const handleChange = (e) => {
        e.currentTarget.classList.remove('nw-select-disabled')
        const {name, value} = e.currentTarget;
        if (name === "terms") {
            data.terms = !data.terms;
            setData({...data});
        }else{
            setData({...data, [name]: value});
        }
    };

    // Manage language select option changing
    const handleUserCultChange = (e) => {
        e.currentTarget.classList.remove('nw-select-disabled')
        const {value} = e.currentTarget;
        if (!SUPPORTEDLNGS.includes(value)) {
            setTemLang(value);
            setData({...data, userCult: "disabled", lang:""});
            openLangModal.current.click();
        } else {
            setData({...data, userCult: value, lang:value});
        }
    };

    // Manage language select option changing
    const handleSelectLang = (e) => {
        const value = e.currentTarget.getAttribute("data-lang");
        setData({...data, userCult: tempLang, lang: value});
        closeLangModal.current.click();
    };

    // Manage form submission
    const handleSubmit = async event => {
        setToggle(true);
        setErrors({});
        let currentErrors = {};
        event.preventDefault();

        for (const name in data) {
            if ((data[name] === "" || data[name] === "disabled") && name !== "address2") {
                currentErrors[name] = t("nw.input.err.required");
            }
            if (data.terms === false) {
                currentErrors.terms = t("nw.input.err.required");
            }
            if (data.email === "") {
                currentErrors.terms = t("nw.input.err.required");
            }
            if (data.userCult ===  "default") {
                currentErrors.userCult = t("nw.input.err.required");
            }
            if (data.country ===  "default") {
                currentErrors.country = t("nw.input.err.required");
            }
        }

        if (data.email !== "" && !checkEmail(data.email)) {
            currentErrors.email = t("nw.input.mail.err.invalid");
        }
        setErrors(currentErrors);
      
        if (Object.keys(currentErrors).length === 0) {
            data.key = location.state.license;
            data.url = window.location.href;
            //Clear License with or without options
            try{
                await LicenseAPI.licenseUpdateData(data, isAuthenticated);
                navigate(t("link.lic.wait"), {state:{email: data.email}});
            } catch(error) {
                setToggle(false);
                if(!error.response || error.response?.data?.status === 500){
                    console.log(error.message);
                    setErrors({...errors, api:t("err.500")});
                } else {
                    if(error.response.data?.err === undefined){
                        console.log(error.response.data);
                        setErrors({...errors, api:t("err.unknown")});
                    } else {
                        const {err, message, info} = error.response.data;
                        const msg = message?.toLowerCase();
                        if(["JsonErr", "ParamNotFound"].includes(err)) {
                            setErrors({...errors, api:t("err.unexpected")});
                        } else if(err?.toLowerCase() === "unsold"){
                            const errGroup = msg === 'invalid license sold date'? 'invalid' : 'missing';
                            navigate(t("link.err.url"), {state:{errGroup:errGroup, docTitleI18n: "clear.title",info:info}});
                        }  else if(err === "NotAuthorized") {
                            //TODO: translate Terms accept error
                            setErrors({...errors, api:t("err.terms")});
                        } else if(msg === "license is not found") {
                            setErrors({...errors, api:t("clear.enterlk.err.invalid")});
                        } else if(msg === "product is not found") {
                            setErrors({...errors, api:t("clear.enterlk.err.noProd")});
                        } else{
                            setErrors({...errors, api:t("err.unknown")});
                        }
                        console.log(message);
                    }
                }
            }
        }else{
            setToggle(false);
        }
    };
  
    return (
        <>
        <a href="/#" ref={openLangModal}  id="openUserDataLang" className="invisible" data-bs-toggle="modal" data-bs-target="#userDataLang">Open Select language</a>
                
        <div className="modal fade" id="userDataLang" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="userDataLangLabel" aria-hidden="true">
            <div className="modal-dialog vertical-center">
                <div className="modal-content bm-card">
                    <h2 className="bm-card-header_small " id="userDataLang">
                        {t("selfreg.selectLang")}
                    </h2>
                <a href="/#" ref={closeLangModal} className="bm-close-x invisible" id="closeUserDataLang" type="button" data-bs-dismiss="modal" aria-label="Close">&#10005;</a>
                    <p dangerouslySetInnerHTML={{__html: t("selfreg.selectLang.txt")}} />
                    <div className="d-flex justify-content-center align-content-center flex-wrap">
                        {SUPPORTEDLNGS.map((lang)=>{
                        return  (
                            <div 
                            key={uuidv4()}
                            className="bm-lang-img-modal"
                            data-lang={lang}
                            onClick={handleSelectLang}
                            role="button"
                            >
                            <img 
                                key={uuidv4()}
                                src={FLAGS.svg[lang]}
                                className=""
                                height="40"
                                alt="default language"
                                loading="lazy"
                            /> 
                            <div>{t("lang."+lang)}</div>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        </div>

        <div className="m-0 p-0 vertical-center">
            <div className="bm-main">
                <div className="bm-card">
                    <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
                    <h1>{t("selfreg.enterData")}</h1>

                    <h2 className="bm-sep-1">{t("clear.select.info.name")} {location.state && location.state.product}</h2>
                    
                    <p className="mb-4" dangerouslySetInnerHTML={{__html: t("selfreg.enterData.desc")}}/>

                    {errors.api && <p className="fn-smtxt invalid-feedback d-block" dangerouslySetInnerHTML={{__html: errors.api}}/>}
                        
                    <form onSubmit={handleSubmit} autoComplete="on">
                        <Field
                            name="name"
                            disabled={location.state.oldData?.userdata1 || toggle}
                            attrs={{autoFocus:"on"}}
                            label={t("selfreg.enterData.name")}
                            placeholder={t("selfreg.enterData.name.hint")}
                            id="name"
                            value={data.name}
                            onChange={handleChange}
                            error={errors.name}
                            title={location.state.oldData?.userdata1 ? t("nw.input.off") : ""}
                        />
                        <Field
                            name="address"
                            disabled={location.state.oldData?.userdata2 || toggle}
                            label={t("selfreg.enterData.addr")}
                            placeholder={t("selfreg.enterData.addr.hint")}
                            id="address"
                            value={data.address}
                            onChange={handleChange}
                            error={errors.address}
                            title={location.state.oldData?.userdata2 ? t("nw.input.off") : ""}
                        />
                        <Field
                            name="address2"
                            disabled={location.state.oldData?.userdata3 || toggle}
                            label={t("selfreg.enterData.addr2")}
                            placeholder={t("selfreg.enterData.addr2.hint")}
                            id="address2"
                            value={data.address2}
                            onChange={handleChange}
                            error={errors.address2}
                            title={location.state.oldData?.userdata3 ? t("nw.input.off") : ""}
                        />
                        <Field
                            name="zipCity"
                            disabled={location.state.oldData?.userdata4 || toggle}
                            label={t("selfreg.enterData.zip")}
                            placeholder={t("selfreg.enterData.zip.hint")}
                            id="zipCity"
                            value={data.zipCity}
                            onChange={handleChange}
                            error={errors.zipCity}
                            title={location.state.oldData?.userdata4 ? t("nw.input.off") : ""}
                        />
                        <div className="bm-two-columns">
                            <div className="bm-two-columns-left bm-input-wrapper">
                            <select
                                name="country"
                                disabled={location.state.oldData?.userdata5 || toggle}
                                id="country"
                                value={data.country}
                                onChange={handleChange}
                                className={`nw-select ${errors.country ? "bm-input-is-invalid" : ""} `}
                                title={location.state.oldData?.userdata5 ? t("nw.input.off") : ""}
                            >
                                <option value="default" disabled>{t("selfreg.enterData.country")}</option>
                                {
                                Object.values(countries).map((country)=>{
                                    return  (
                                    <option 
                                    key={uuidv4()}
                                    value={country}
                                    title={country}
                                    className="bm-marg-bott-1rem text-black"
                                    >
                                        {t(`gen.country.${country}`)}
                                    </option>
                                    )
                                })
                                }
                            </select>
                            {data.country !== "default" && <label htmlFor="country">{t("selfreg.enterData.country.label")}</label>}
                            {errors.country && <p className="fn-smtxt invalid-feedback mb-1">{errors.country}</p>}
                            </div>

                            <div className="bm-two-columns-right bm-input-wrapper">
                            <select
                                name="userCult"
                                disabled={location.state.oldData?.userCult || toggle}
                                id="userCult"
                                value={data.userCult}
                                onChange={handleUserCultChange}
                                className={`nw-select  ${errors.userCult ? "bm-input-is-invalid" : ""}`}
                                title={location.state.oldData?.userCult ? t("nw.input.off") : ""}
                            >
                                <option value="default" disabled>{t("selfreg.enterData.userCult")}</option>
                                {
                                    Object.values(languages).map((lang)=>{
                                    return  (
                                    <option 
                                        key={uuidv4()}
                                        value={lang}
                                        title={lang}
                                        className="bm-marg-bott-1rem text-black"
                                    >
                                        {t(`languages.${lang}`)}
                                    </option>
                                    )
                                })
                                }
                            </select>
                            {data.userCult !== "default" && <label htmlFor="userCult">{t("selfreg.enterData.userCult")}</label>}                     
                            {errors.userCult && <p className="fn-smtxt invalid-feedback mb-1">{errors.userCult}</p>}
                            </div>
                        </div>
                        
                        <Field
                            name="email"
                            disabled={location.state.oldData?.email || toggle}
                            type="email"
                            label={t("selfreg.enterData.email")}
                            id="email"
                            value={data.email}
                            onChange={handleChange}
                            error={errors.email}
                            title={location.state.oldData?.email ? t("nw.input.off") : ""}
                        />

                        <div className="d-flex align-items-center" onClick={handleCheckboxDivClick}>
                            <input disabled={toggle} className="form-check-input" checked={data.terms} onChange={handleChange} type="checkbox" id="terms" name="terms" value={data.terms}/>

                            <label className="form-check-label ms-2 fn-smtxt-bold" htmlFor="terms">
                            <span dangerouslySetInnerHTML={{__html: t("selfreg.enterData.terms", {link: t("link.legal.terms.url")})}}/>
                            </label>
                        </div>  
                        
                        {errors.terms && <p className="fn-smtxt invalid-feedback d-block">{errors.terms}</p>}

                        <div className="bm-marg-top-10">
                            <p dangerouslySetInnerHTML={{__html: t("selfreg.enterData.txt")}}/>  
                        </div>

                        <div className="bm-nav-card-bottom">

                            <button type="submit" disabled={toggle} className="mb-2 ms-5 bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                            {toggle &&  
                                <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                            }
                            <span className={toggle ? "invisible" : ""}>{t("selfreg.enterData.btn")}</span>
                            </button>

                            <Link to={t("link.lic.clear.lic")} state={{lastKey:location.state?.license}}>
                                <button className="bm-btn-mute bm-btn-card-prev float-sm-start float-end">{t("gen.button.back")}</button>
                            </Link>
                            <div className="bm-clearer"></div>
                        </div>
                    </form>
                </div> 
            </div>
        </div>
        </>
    )
}
