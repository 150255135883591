import { createSlice }  from "@reduxjs/toolkit";

const initialState = {
	accessToken: null,
	rTokenExp: 0,
	isAuthenticated: false,
	isLoading: false,
	error: null,
	showLogin: false,
	disabled: false,
	impersonate: false,
};

const AuthSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		loginRequest: (state) => {
			state.isLoading = true;
			state.error = null;
			state.isAuthenticated = false;
			state.accessToken = null;
			state.showLogin = false;
			state.disabled = false;
			state.rTokenExp = 0;
			state.impersonate = false;
		},
		toggleLogin: (state, action) => {
			state.showLogin = action.payload;
		},
		loginSuccess: (state, action) => {
			state.accessToken = action.payload.accessToken;
			state.rTokenExp = action.payload.rTokenExp;
			state.isLoading = false;
			state.isAuthenticated = true;
			state.error = null;
			state.showLogin = false;
			state.disabled = false;
		},
		impersonateSuccess: (state, action) => {
			state.accessToken = action.payload.accessToken;
			state.rTokenExp = action.payload.rTokenExp;
			state.isAuthenticated = true;
			state.impersonate = action.payload.impersonate;
		},
		loginFailure: (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
			state.isAuthenticated = false;
			state.accessToken = null;
			state.disabled = false;
			state.rTokenExp = 0;
			state.impersonate = false;
		},
		logoutSuccess: (state, action) => {
			state.isLoading = false;
			state.error = null;
			state.isAuthenticated = false;
			state.accessToken = null;
			state.showLogin = action.payload || false;
			state.disabled = false;
			state.rTokenExp = 0;
			state.impersonate = false;
		}
	}
});

export default AuthSlice;
export const { 
	loginRequest,
	toggleLogin,
	loginSuccess,
	loginFailure,
	logoutSuccess,
	impersonateSuccess
} = AuthSlice.actions;
