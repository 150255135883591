import { useState, useRef } from "react";
import Field from "Components/Forms/Field";
import { useTranslation } from "react-i18next";
import useFocusModal from "Hook/useFocusModal";
import { checkEmail } from 'Components/Tools/Text';

export default function AddEmailModal(props) {
    const { t } = useTranslation();

    const [input, setInput] = useState("");
    const emailModal = useRef(null);
    const emailInput = useRef(null);
    const closeBtn = useRef(null);
    const [errors, setErrors] = useState({input: ""});

    useFocusModal(emailModal, emailInput);

    const handleChange = (e) => {
        setInput(e.currentTarget.value);
    };

    const close = (e) => {
        if (e.currentTarget.dataset.sentForm === 'false') {
            props.setToggle(false);
        }
    }
    
    const handleSubmit = async event => {
        event.preventDefault();
        setErrors({});
        
        if (!input) {
            setErrors({...errors, input:"nw.input.err.required"});
        } else if (!checkEmail(input)) {
            setErrors({...errors, input:"nw.input.mail.err.invalid"});
        } else{
            props.setEmail(input);
            closeBtn.current.dataset.sentForm = 'true'; //Add data to close btn in order to prevent set toggle
            closeBtn.current.click();
        };
    }
    
    const resetForm = () => {
        closeBtn.current.dataset.sentForm = 'false'; 
    }
    const cancel = () => {
        closeBtn.current.dataset.sentForm = 'false';
        closeBtn.current.click();
    }

    return (
        <>   
            <a ref={props.refBtn} onClick={resetForm} href="/#" className="d-none" data-bs-toggle="modal" data-bs-target={`#addEmail`}>AddEmail</a>         
            <div ref={emailModal} className="modal fade" id="addEmail" tabIndex="-1"data-bs-backdrop="static" aria-labelledby="addEmailModalLabel" aria-hidden="false">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">

                        <h2 className="bm-card-header_small" id="addEmailModalLabel">{t("upgrade.enter.email")}</h2>

                        <a href="/#" className="bm-close-x" ref={closeBtn} onClick={close} id="closeAddEmail" type="button" data-bs-dismiss="modal" aria-label="Close">&#10005;</a>

                        <p>{t("upgrade.enter.email.txt")}</p>
                        <form onSubmit={handleSubmit} className="pwd-forgot-form fn-smtxt">
                            <Field
                                refInput={emailInput}
                                label={t("nw.input.mail")}
                                placeholder={t("nw.input.mail.hint")}
                                id="email"
                                name="email"
                                type="email"
                                value={input}
                                onChange={handleChange}
                                error={t(errors.input)}
                                classes="mb-5"
                            />

                            <button id="emailSubmitBtn" type="submit" className="bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                                {t("gen.button.continue")}
                            </button>

                            <button onClick={cancel} className="bm-btn-mute bm-btn-card-prev float-sm-start float-end">{t("gen.button.cancel")}</button>

                            <div className="clearer"></div>
                        </form>      
                    </div>
                </div>
            </div>
        </>
    )
}