import {
    API_LICENSE_SALES_BUYER,
    API_LICENSE_CUSTOMER_SALE,
    API_LICENSE_RESELLER_SALE,
    API_LICENSE_SALE_ACTION,
} from "config/nw-api";
import AxiosPrivate from "./Common/AxiosPrivate";


function checkBuyer(data) {
    return AxiosPrivate.get(API_LICENSE_SALES_BUYER?.replace(':email', data.email)?.replace(':saleType', data.saleType));
}
function sellToCustomer(data) {
    return AxiosPrivate.put(API_LICENSE_CUSTOMER_SALE, data);
}
function sellToReseller(data) {
    return AxiosPrivate.put(API_LICENSE_RESELLER_SALE, data);
}
function action(data) {
    return AxiosPrivate.post(API_LICENSE_SALE_ACTION.replace(':action', data.action), data);
}

const LicenseSalesAPI = {
    checkBuyer,
    sellToCustomer,
    sellToReseller,
    action,
};

export default LicenseSalesAPI;