import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Logo } from "assets/images/logo/nanoways.white.opt.svg";
import UserOn  from "assets/images/icon/user.on.white.opt.svg";
import UserOff  from "assets/images/icon/user.off.white.opt.svg";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher";
import "Translations/i18n";
import SignUpModal from "./Modals/SignUpModal";
import SignInModal from "./Modals/SignInModal";
import PwdForgotModal from "./Modals/PwdForgotModal";
import PwdResetModal from "./Modals/PwdResetModal";
import { logoutUser } from 'Reduxx/Reducers/Thunks/AuthThunk';
import {useEffect, useRef, useState} from 'react';
import useTabCommunication from 'Hook/useTabCommunication';
import usePublicRoutes from 'Hook/usePublicRoutes';
import {switchUser} from "Reduxx/Reducers/Thunks/AuthThunk";

export default function Navbar() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isAuthenticated, impersonate} = useSelector((state) => state.auth);
    const user = useSelector((state) => state.user);
    const {sendTabsData} = useTabCommunication('NAVBAR');
    const isPublicRoute = usePublicRoutes();
  
    const handleLogout = (e) => {
        e.preventDefault();
        try {
            const email = user?.email;
            dispatch(logoutUser());
            console.log('Logout Tabs');
            sendTabsData('logout', email);
        } catch (error) {
            console.log(error);
            console.log('LOGOUT ERROR');
        }
        if (!isPublicRoute){
            navigate(t("link.root.url"), {replace: true});
        }
    };

    const handleClick = (e) => {
        document.getElementById("close-offcanvas").click();
    }

    const navbarElt = useRef(null);
    const menuBurger = useRef(null);
    const logoNW = useRef(null);
    const [usernameWidth, setUserNameWidth] = useState(0);

    useEffect(() => {
        const handleWindowResize = () => {
            let maxWidth = navbarElt.current?.clientWidth - menuBurger.current?.clientWidth - logoNW.current?.clientWidth - 22 - 21 - 150;
            setUserNameWidth(maxWidth/2);
        };

        if (usernameWidth === 0) {
            handleWindowResize();
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const impersonateUser = (e, exit = false) =>{
        e.preventDefault();
        const email = e.target?.getAttribute('data-email');

        if(email){
            dispatch(switchUser({email, exit}))
                .then((res)=>{
					navigate(t("link.root.url"), {replace: true});
                }).catch((err) => {
                    console.log(err);
                })
        }
    }

    return (
        <nav id="mainNavbar" className="navbar navbar-dark py-2 fixed-top">
            <div ref={navbarElt} className="container-fluid d-flex align-item-center">
                <a className="menu"
                    role="button"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasMenu"
                    href="/#"
                >
                    {<svg ref={menuBurger} viewBox="0 0 30 10" fill="white" width="30" height="30">
                        <rect y="-2" width="62%" height="2"></rect>
                        <rect y="4" width="62%" height="2"></rect>
                        <rect y="10" width="62%" height="2"></rect>
                    </svg>}
                </a>
                <div className="offcanvas offcanvas-start-lg text-white" tabIndex="-1" id="offcanvasMenu"
                    aria-labelledby="offcanvasMenuLabel" data-bs-scroll="true" //data-bs-backdrop="false"
                >
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title text-white me-1" id="offcanvasMenuLabel">NANOWAYS</h5>
                        <button id="close-offcanvas" type="button" className="btn-close btn-close-white text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body p-lg-0 fn-smtxt">
                        <ul className="navbar-nav ms-3">
                            <li className="nav-item">
                                <NavLink className="nav-link h4 py-0" aria-current="page" 
                                    to={t("link.lic.clear.lic")} 
                                    onClick={handleClick}
                                    style={({isActive})=>isActive ? {color: "white"} : {}}
                                >
                                    {t("link.lic.clear")}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link h4 py-0" aria-current="page"
                                    to={t("link.lic.upgrade.code")} 
                                    onClick={handleClick}
                                    style={({isActive})=>isActive ? {color: "white"} : {}}
                                >
                                    {t("link.lic.upgrade")}
                                </NavLink>
                            </li>
                            {/*ras.menu*/}
                            {isAuthenticated && user?.roles.includes("ROLE_50") && 
                                <li className="nav-item">
                                    <NavLink className="nav-link h4 py-0" aria-current="page"
                                        to={t("link.ras.url")} 
                                        onClick={handleClick}
                                        style={({isActive})=>isActive ? {color: "white"} : {}}
                                    >
                                        {t("link.ras")}
                                    </NavLink>
                                </li>
                            }
                            {/*acc.menu*/}
                            {isAuthenticated && user?.roles.includes("ROLE_76") && 
                                <li className="nav-item">
                                    <NavLink className="nav-link h4 py-0" aria-current="page"
                                        end
                                        to={t("link.access.url")} 
                                        onClick={handleClick}
                                        style={({isActive})=> isActive ? {color: "white"} : {}}
                                    >
                                        {t("link.access")}
                                    </NavLink>
                                </li>
                            }
                            {/*users.menu*/}
                            {isAuthenticated && user?.roles.includes("ROLE_84") && 
                                <li className="nav-item">
                                    <NavLink className="nav-link h4 py-0" aria-current="page"
                                        end
                                        to={t("link.accounts.url")} 
                                        onClick={handleClick}
                                        style={({isActive})=> isActive ? {color: "white"} : {}}
                                    >
                                        {t("link.accounts")}
                                    </NavLink>
                                </li>
                            }
                            {/*lic.menu*/}
                            {isAuthenticated && user?.roles.includes("ROLE_61") && 
                                <li className="nav-item">
                                    <NavLink className="nav-link h4 py-0" aria-current="page"
                                        to={t("link.licenses.url")} 
                                        onClick={handleClick}
                                        style={({isActive})=> isActive ? {color: "white"} : {}}
                                    >
                                        {t("link.licenses")}
                                    </NavLink>
                                </li>
                            }
                            {/*journal.menu*/}
                            {isAuthenticated && user?.roles.includes("ROLE_176") && 
                                <li className="nav-item">
                                    <NavLink className="nav-link h4 py-0" aria-current="page"
                                        to={t("link.journal.url")} 
                                        onClick={handleClick}
                                        style={({isActive})=> isActive ? {color: "white"} : {}}
                                    >
                                        {t("link.journal")}
                                    </NavLink>
                                </li>
                            }
                        </ul>
                    </div>
                </div>

                <div className="align-items-center">
                    <div className="navbar-logo-middle text-center">    
                        <Link to="/"><Logo ref={logoNW} className="navbar-logo"/></Link>
                    </div>
                </div>

                <div className="d-flex align-items-center fn-smtxt-regular">
                    <div className="dropdown ms-3 me-3">
                        <a
                            className="d-flex align-items-center hidden-arrow text-decoration-none text-dark"
                            href="/#"
                            id="navbarDropdownMenuAvatar"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >                            
                            {(isAuthenticated && user && <>
                                <span style={{ maxWidth: `${usernameWidth}px` }} className="z-1 me-3 d-none d-sm-block text-truncate text-white">
                                    {user.publicName || user.name || user.email}
                                </span>
                                <img
                                    src={process.env.PUBLIC_URL + UserOn}
                                    height="20"
                                    alt="User On Icon"
                                    loading="lazy"
                                />
                            </>                         
                                    
                            ) || 
                                <img
                                    src={process.env.PUBLIC_URL + UserOff}
                                    height="20"
                                    alt="User Off Icon"
                                    loading="lazy"
                                />       
                            }                    
                        </a>
                        <ul
                            className="dropdown-menu dropdown-menu-end p-0"
                            aria-labelledby="navbarDropdownMenuAvatar"
                        >
                            {(isAuthenticated && user && (
                                <>
                                    <li>
                                        <span className="nw-unset-link dropdown-item">{user.name || user.email}</span>
                                    </li>
                                    {/*create.user.edit*/}
                                    {user?.roles.includes('ROLE_92') &&
                                    <li>
                                        <a className="dropdown-item" href="/#" data-bs-toggle="modal" data-bs-target="#signUpModal">
                                            {t("user.menu.signUp")}
                                        </a>
                                    </li>}
                                    {/*user?.roles.includes('ROLE_202') &&
                                    <li>
                                        <a data-email="account@mail.com" className="dropdown-item" href="/#" onClick={impersonateUser}>
                                            {'Impersonate => account@mail.com'}
                                        </a>
                                </li>*/}
                                    {impersonate &&
                                    <li>
                                        <a data-email={impersonate} className="dropdown-item" href="/#" onClick={(e) => impersonateUser(e, true)}>
                                            {t("user.switch.back", {email: impersonate})}
                                        </a>
                                    </li>}
                                    
                                    {/*profile.menu*/}
                                    {user?.roles.includes('ROLE_35') &&
                                    <li>
                                        <NavLink end  className="dropdown-item" aria-current="page" 
                                            to={t("link.profil.url")} 
                                            onClick={handleClick}
                                            style={({isActive})=>isActive ? {color: "white", backgroundColor: '#848484'} : {}}
                                        >
                                            {t("link.profil")}
                                        </NavLink>
                                    </li>}

                                    {/*profile.pwd.edit*/}
                                    {user?.roles.includes('ROLE_48') &&
                                    <li>
                                        <NavLink end  className="dropdown-item" aria-current="page" 
                                            to={t("link.profil.pwd.url")} 
                                            onClick={handleClick}
                                            style={({isActive})=>isActive ? {color: "white", backgroundColor: '#848484'} : {}}
                                        >
                                            {t("link.profil.pwd")}
                                        </NavLink>
                                    </li>}  
                                    <li>
                                        <a id="logoutBtn" onClick={(e)=>handleLogout(e)} className="logout nw-bold dropdown-item" href="/#">
                                            {t("user.menu.logout")}
                                        </a>
                                    </li>
                                </>
                                )) || (                                    
                                <>  
                                    <li>
                                        <a className="dropdown-item" href="/#" data-bs-toggle="modal" data-bs-target="#signInModal">
                                            {t("user.menu.signIn")}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/#" data-bs-toggle="modal" data-bs-target="#signUpModal">
                                            {t("user.menu.signUp")}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/#" data-bs-toggle="modal" data-bs-target="#pwdForgotModal">
                                            {t("user.menu.pwdForgot")}
                                        </a>
                                    </li>
                                    <li>
                                        {/* //eslint-disable-next-line */}
                                        <a href="/#" className="dropdown-item d-none" data-bs-toggle="modal" data-bs-target="#pwdResetModal">Reset Password</a>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                    <LanguageSwitcher />
                    {!isAuthenticated && 
                        <>
                            <SignUpModal />
                            <SignInModal />
                            <PwdForgotModal />
                            <PwdResetModal />
                        </>
                    }
                    {isAuthenticated && user?.roles.includes('ROLE_102') &&
                        <SignUpModal />
                    }
                </div>
            </div>
        </nav>
    )
}