import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer(){
	const { t } = useTranslation();
	return (
		<div className="m-0 p-0">
			<footer id="mainFooter" className="nw-footer text-center py-md-2 py-sm-0 fn-smtxt">
				{/* <span className="text-white">Copyright © Nanoways</span> */}
				<Link 
					className='text-muted text-decoration-none fn-smtxt' 
					to={t("link.legal.terms.url")}
				>
					{t("link.legal.terms")}
				</Link>
				<span className="text-muted mx-1">|</span>
				<Link 
					className='text-muted text-decoration-none fn-smtxt' 
					to={t("link.legal.privacy.url")}
				>
					{t("link.legal.privacy")}
				</Link>
				<span className="text-muted mx-1">|</span>
				<Link 
					className='text-muted text-decoration-none fn-smtxt' 
					to={t("link.legal.imprint.url")}
				>
					{t("link.legal.imprint")}
				</Link>
			</footer>
		</div>
	)
}
