import {
    API_ACCESSES,
    API_ACCESS_ROLEGRP_SWITCH,
    API_ACCESSES_FOR_USERS,
    API_ACCESSES_NODE,
    API_ACCESSES_RENAME,
    API_ACCESSES_CREATE,
    API_ACCESSES_REMOVE,
    API_ACCESS_TREE_UPDATE,
    API_ACCESS_UPDATE
    } from 'config/nw-api';
import AxiosPrivate from 'Services/Common/AxiosPrivate'

function getAll(data) {
    //let url = suffix ? API_ACCESSES + suffix : API_ACCESSES;
    return AxiosPrivate.post(API_ACCESSES, data);
}

function getAllForUsers() {
    return AxiosPrivate.get(API_ACCESSES_FOR_USERS);
}

function getNodes(id) {
    return AxiosPrivate.get(API_ACCESSES_NODE.replace(":id", id));
}

function rename(data) {
    return AxiosPrivate.put(API_ACCESSES_RENAME, data);
}

function create(data) {
    return AxiosPrivate.post(API_ACCESSES_CREATE, data);
}

function remove(id, data = []) {
    return AxiosPrivate.post(API_ACCESSES_REMOVE+`${id}`, data);
}

function accessTreeUpdate(data) {
    return AxiosPrivate.post(API_ACCESS_TREE_UPDATE, data);
}

function accessUpdate(accessId, data) {
    return AxiosPrivate.post(
        API_ACCESS_UPDATE.replace(":id", accessId),
        data
    );
}

function switchRoleGroup(userId, data) {
    return AxiosPrivate.put(
        API_ACCESS_ROLEGRP_SWITCH.replace(":id", userId),
        data
    );
}


const AccessAPI = {
    getAll,
    getAllForUsers,
    getNodes,
    rename,
    create,
    remove,
    accessTreeUpdate,
    accessUpdate,
    switchRoleGroup
};

export default AccessAPI;

