import {  useRef } from "react";
import { useTranslation } from "react-i18next";
import TokenInfo from "./TokenInfo";
import { localeDatetime } from "Components/Tools/DateTime";
import {ReactComponent as InfoSvg} from "assets/images/icon/info.svg";

export default function TokenDetails(props) {
    const { t } = useTranslation();
    //const closeBtn = useRef(null);
    //closeBtn.current.click();

	const showInfoRef = useRef([]);
	const showTokenHardwareInfo = (e, idx) => {
		showInfoRef.current[idx]?.click();
	}

    return (
        <div className="my-3">
            <h3 className="text-center h2">{t("licenses.list.detail.token")}</h3>
            {props.data.license?.dongles && <>
                <div className="row pt-2 m-0">
                    <div className="fw-bold col-2 nowrap">Created At</div>
                    <div className="fw-bold col-1 nowrap">Type</div>
                    <div className="fw-bold col-2 nowrap">Value</div>
                    <div className="fw-bold col-1 nowrap">Mediatype</div>
                    <div className="fw-bold col-2 nowrap">Medianum</div>
                    <div className="fw-bold col-2 nowrap">PackNum</div>
                    <div className="fw-bold col-1 nowrap">Status</div>
                    <div className="fw-bold col-1 d-flex justify-content-end">Action</div>
                </div>
                <div className="bm-sep-2 mt-1"/>
                {
                    props.data.license?.dongles?.map((token,idx) => {
                        return <div key={'token'+idx} className={`${token.status !== 1 && "nw-red"} row py-2 m-0`}>
                            <div className="col-2 nowrap">
                                {localeDatetime(token.time)}
                            </div>
                            <div className="col-1 nowrap">
                                {token.type?.name}		
                            </div>
                            <div className="col-2 nowrap">
                                {token.val}		
                            </div>
                            <div className="col-1 nowrap">
                                {token.mediaType?.name}		
                            </div>
                            <div className="col-2 nowrap">
                                {token.mediaNum}		
                            </div>
                            <div className="col-2 nowrap">
                                {token.packNum}		
                            </div>
                            <div className="col-1 nowrap">
                                {token.status === 1 ? "aktive" : "inaktiv"}		
                            </div>
                            <div className="col-1 d-flex justify-content-end">
                                <InfoSvg
                                    type="button"
                                    className={`${token.hardwareInfo ? "bg-transparent" : ""} m-0 p-0 border-0`}
                                    fill={`${token.hardwareInfo ?"var(--nw-blue-color)": "var(--nw-grey-color)" }`}
                                    onClick={(e) => showTokenHardwareInfo(e, idx)}
                                />
                                {token.hardwareInfo && 
                                    <TokenInfo
                                        showInfoRef={el => showInfoRef.current[idx] = el}
                                        name={'tokenModal'+idx}
                                        data={token.hardwareInfo}
                                    />
                                }
                            </div>
                        </div>
                    })
                }
            </>}
        </div>
    )
}
