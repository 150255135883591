import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
//import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import AuthSlice from './Reducers/AuthSlice';
import UserSlice from './Reducers/UserSlice';
import { combineReducers } from 'redux';

const userPersistConfig = {
    key: 'user',
    storage,//: storageSession,
    //whitelist: ['auth'],
};

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['isAuthenticated', 'accessToken', 'rTokenExp', 'impersonate'], // Only persist the relevant state from the auth reducer
  };

const persistedAuthReducer = persistReducer(authPersistConfig, AuthSlice.reducer);
const persistedUserReducer = persistReducer(userPersistConfig, UserSlice.reducer);

const persistedRootReducer = combineReducers({
    auth: persistedAuthReducer,
    user: persistedUserReducer
});
const store = configureStore({
    reducer: persistedRootReducer,
    middleware: [thunkMiddleware],
});
const persistor = persistStore(store, null, () => {
  return Promise.resolve();
});

export { store, persistor };