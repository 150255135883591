import { useTranslation } from "react-i18next";
import { useParams, Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import UserAPI from "Services/UsersAPI";
import { DOMAIN_NAME } from "config/nw-api";
import Spinner, {LoadingError} from "Components/Loading/Spinner";
import { useSelector } from 'react-redux';


export default function ConfirmRegistration(props) {
    const { t, i18n } = useTranslation();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const user = useSelector((state) => state.user);
    const [newEmail, setNewEmail] = useState("");
    const [error, setError] = useState();
    const [btn, setBtn] = useState("signIn");
    const [status, setStatus] = useState(null);
    
    const [reload, setReload] = useState(false);
    const [serverError, setServerError] = useState(null);
    const reloadPage = () => {
        setServerError(null);
        setReload(true);
    }
    let params = useParams();

    document.title = t("user.accConfirm")+ " – nanoways.com";
    
    /* eslint-disable */
    useEffect(()=>{
        if (reload) {
            const callback = {"confirm_registration":DOMAIN_NAME + t("link.signupConfirm"), "login_link": DOMAIN_NAME + t("link.login")};
            UserAPI.confirmRegister(params[props.token], i18n.language, callback)
            .then(response => {
                setNewEmail(response.data.email);
                setServerError(null);
            })
            .catch(error => {
                if(!error.response || error.response?.data?.status === 500){
                    setError("err.500");
                    setServerError("nw.err.server.txt");
                } else {
                    const data = error.response?.data;
                    if (Object.keys(data).length !== 0 && data.type !== undefined) {
                        if (data.type === 'TokenInvalid' || data.type === 'AccountNotFound') {
                            setError('user.accConfirm.err.notFound'); 
                            setBtn("signUp");            
                        } else if (data.type === 'AccountEnable') {
                            setNewEmail(data.email);
                            // For msg out use t('user.accConfirm.activate')
                        } else if (data.type === 'TokenExpired') {
                            setError('user.accConfirm.err.expired');
                        }
                    } else {
                        setError("err.unknown");
                        setServerError("err.unknown");
                    }
                }
            })
            .finally(()=>{
                setStatus(true);
                setReload(false);
            });
        }
    }, [reload])
    /* eslint-enable */
    return (serverError ? 
        <LoadingError h2={t("nw.err.server")} txt={t(serverError)} reload={reloadPage} btn={t("nw.err.server.btn")}/>
        :
        <div className="m-0 p-0 vertical-center">
            <div className="bm-main">
                <div className="bm-card">
                    <div>
                        <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
                        <h1 className="">
                            {t("user.accConfirm")}
                        </h1>
                        {!status ? <>
                            <h2 className="bm-sep-1">
                                {t('user.accConfirm.check')}
                            </h2>
                            
                            <p dangerouslySetInnerHTML={{__html: t('user.accConfirm.txt')}} />

                            <div className="bm-nav-card-bottom">
                                {/*<a href={t("link.root.url")} role="button">
                                    <button className="bm-btn-mute bm-btn-card-prev">{t("nw.btn.goto")}</button>
                                </a>*/}
                                <button  
                                    onClick={(e) => {
                                        setReload(true);
                                    }} 
                                    disabled={reload && "disabled"} 
                                    className="bm-btn-blue bm-btn-card-next d-flex justify-content-center"
                                >
                                    {reload &&
                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                    }
                                    <span className={reload ? "invisible" : ""}>{t("user.accConfirm.btn")}</span>
                                </button>
                            </div>
                        </>
                        :
                        <>
                            <h2 className={`${error ? "bm-text-danger" : "bm-text-success"}  bm-sep-1`}>
                                {(error && t('user.accConfirm.err')) || t('user.accConfirm.ok')}
                            </h2>
                            
                            <p dangerouslySetInnerHTML={{__html: 
                                error ? t(error) : isAuthenticated ?
                                `${t('user.accConfirm.ok.txt') +"<br/> <br/> "+t('user.accConfirm.ok.logout', {email: user.email?.length>0 ? user.email : t("gen.text.unavailable.abbr"), email_new: newEmail})}` : t('user.accConfirm.ok.txt')}} 
                            />

                            <div className="bm-nav-card-bottom">
                                <a href={t("link.root.url")} role="button">
                                    <button className="bm-btn-mute bm-btn-card-prev">{t("nw.btn.goto")}</button>
                                </a>
                                {!isAuthenticated && (
                                    btn === "signUp" ? 
                                        <a href="/#" data-bs-toggle="modal" data-bs-target="#signUpModal" ><button className="bm-btn-card-next bm-btn-blue">{t("user.signUp.btn")}</button></a>
                                    :
                                        <a href="/#" data-bs-toggle="modal" data-bs-target="#signInModal" ><button className="bm-btn-card-next bm-btn-blue">{t("user.signIn.btn")}</button></a>
                                )}
                            </div>
                        </>}                        
                        <div className="bm-clearer"></div>
                    </div>
                </div>
            </div>
        </div>
    )
    /*Check token before show page 
    return (serverError ? 
        <LoadingError h2={t("nw.err.server")} txt={t(serverError)} reload={reloadPage} btn={t("nw.err.server.btn")}/>
        :
        <>
            {reload ? <Spinner className={"overlay"}/> :
            <div className="m-0 p-0 vertical-center">
                <div className="bm-main">
                    <div className="bm-card">
                        <div>
                            <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
                            <h1 className="">
                                {t("user.accConfirm")}
                            </h1>
                            <h2 className={`${error ? "bm-text-danger" : "bm-text-success"}  bm-sep-1`}>
                                {(error && t('user.accConfirm.err')) || t('user.accConfirm.ok')}
                            </h2>
                            
                            <p dangerouslySetInnerHTML={{__html: 
                                error ? t(error) : isAuthenticated ?
                                `${t('user.accConfirm.ok.txt') +"<br/> <br/> "+t('user.accConfirm.ok.logout', {email: user.email?.length>0 ? user.email : t("gen.text.unavailable.abbr"), email_new: newEmail})}` : t('user.accConfirm.ok.txt')}} 
                            />

                            <div className="bm-nav-card-bottom">

                                <a href={t("link.root.url")} role="button">
                                    <button className="bm-btn-mute bm-btn-card-prev">{t("nw.btn.goto")}</button>
                                </a>
                                {!isAuthenticated && (
                                    btn === "signUp" ? 
                                        <a href="/#" data-bs-toggle="modal" data-bs-target="#signUpModal" ><button className="bm-btn-card-next bm-btn-blue">{t("user.signUp.btn")}</button></a>
                                    :
                                        <a href="/#" data-bs-toggle="modal" data-bs-target="#signInModal" ><button className="bm-btn-card-next bm-btn-blue">{t("user.signIn.btn")}</button></a>
                                )}
                            </div>
                            
                            <div className="bm-clearer"></div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )*/
}
