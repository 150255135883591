import { useState, useEffect, useRef } from "react";
import LicenseSalesAPI from 'Services/LicenseSalesAPI';
import Field from "Components/Forms/Field";
import { useTranslation } from "react-i18next";
import Spinner from 'Components/Loading/Spinner';
import useFocusModal from "Hook/useFocusModal";
import {DOMAIN_NAME} from "config/nw-api";
import {checkEmail} from "Components/Tools/Text";
import { useSelector } from 'react-redux';
import DATA_I18N from 'Translations/i18n/translation.json';
import {v4 as uuidv4} from 'uuid';
import { handleCheckboxDivClick } from 'Services/utils';

export default function SoldToCustomerModal(props) {
    const { t } = useTranslation();
	const user = useSelector((state) => state.user);
    const modalRef = useRef(null);
    const closeRef = useRef(null);
    const emailInput = useRef(null);
    useFocusModal(modalRef, emailInput);
    const [email, setEmail] = useState({value: "", err:"", isValid: false});
    const [buyer, setBuyer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState(null);

    const handleChangeEmail = ({currentTarget}) => {
        const {value} = currentTarget;
        setEmail({...email, value, err:""});
    }

    const onCloseModal = () => {
        setIsLoading(false);
        setInfo(null);
        setEmail({value: "", err:""});
        setErrors({api: ""});
        setBuyer(null);
        setData({
            name: "",
            address: "",
            address2: "",
            zipCity: "",
            country: "default",
            userCult: "default",
            noUpdate: false
        });
        info && props.reloadTab();
    }

    // Manage form submission
    const handleCheckEmail = async e => {
        e.preventDefault();
        setIsLoading(true);
        
        if (!email.value) {
            setEmail({...email, err: "nw.input.err.required"});
            setIsLoading(false);
        } else if (!checkEmail(email.value)) {
            setEmail({...email, err: "nw.input.mail.err.invalid"});
            setIsLoading(false);
        }else if(user.email?.trim() === email.value?.trim()){
            setEmail({...email, err: "licenses.list.opt.sell.form.err.self"});
            setIsLoading(false);
        } else{
            LicenseSalesAPI.checkBuyer({email:email.value, saleType:'customer'})
            .then((res)=>{
                setEmail({...email, isValid: true});
                setBuyer(res.data);
            }).catch((err)=>{
                const msg = err?.response?.data?.msg;
                if(msg?.toLowerCase() === 'buyer and seller e-mails are equal'){
                    setEmail({...email, err: "licenses.list.opt.sell.form.err.self"});
                }else if(msg?.toLowerCase() === 'buyer is not a customer'){
                    setEmail({...email, err: "licenses.list.opt.sell.form.err.invalid.customer"});
                }else{
                    setEmail({...email, isValid: true});
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
    };
    
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);

    /* eslint-disable */
    useEffect(()=>{
        let country = [];
        let lang = [];
        Object.keys(DATA_I18N).forEach(function(key){
            if (key.includes("gen.country.")) {
                country.push(DATA_I18N[key]);
            }
            if(key.includes("languages.")){
                lang.push(DATA_I18N[key]);
            }
            lang = lang.sort(function (a, b) {
                if (t(`languages.${a}`) < t(`languages.${b}`)) {
                    return -1;
                }
                if (t(`languages.${a}`) > t(`languages.${b}`)) {
                    return 1;
                }
                return 0;
            });
            setLanguages(lang);
            setCountries(country);
        });
    }, [])
    /* eslint-enable */

    const [data, setData] = useState({
        name: "",
        address: "",
        address2: "",
        zipCity: "",
        country: "default",
        userCult: "default",
        noUpdate: false
    });

    const [errors, setErrors] = useState({
        name: "",
        address: "",
        address2: "",
        zipCity: "",
        country: "",
        userCult: "",
        noUpdate: "",
        api: ""
    });
    
    const handleChangeData = (e) => {
        const {name, value} = e.currentTarget;
        if (name === "noUpdate") {
            setData({...data, noUpdate: !data.noUpdate});
        }else{
            setData({...data, [name]: value});
        }
    };

    const handleSoldConfirm = async e => {
        e.preventDefault();
        setErrors({});
        let currentErrors = {};
        if (!data.noUpdate) {
            if (!data.name) {
                currentErrors.name = t("nw.input.err.required");
            }
            if (!data.address) {
                currentErrors.address = t("nw.input.err.required");
            }
            if (!data.zipCity) {
                currentErrors.zipCity = t("nw.input.err.required");
            }
            if (data.country === 'default') {
                currentErrors.country = t("nw.input.err.required");
            }
            if (data.userCult === 'default') {
                currentErrors.userCult = t("nw.input.err.required");
            }
        }
        setErrors(currentErrors);

        if (Object.keys(currentErrors)?.length === 0 && props.data?.licenses?.length>0 && email.value) {
            setIsLoading(true);
            let selfRegData = null;
            if (!data.noUpdate) {
                selfRegData = {...data};
                selfRegData.userCult = selfRegData.userCult === 'default' ? '' : selfRegData.userCult;
                selfRegData.country = selfRegData.country === 'default' ? '' : selfRegData.country;
            }else{
                selfRegData = {noUpdate: true};
            }
            //const callbackDomain = buyer ? DOMAIN_NAME.t('link.licenses.url') : DOMAIN_NAME+t("link.download.url");
            LicenseSalesAPI.sellToCustomer({
                licenses: props.data?.licenses,
                callbackDomain: DOMAIN_NAME+t("link.download.url"),
                buyerEmail: email.value,
                selfRegData
            }).then((res)=>{
                setInfo("licenses.list.opt.sell.form.customer.info");
            }).catch((err)=>{
                console.log(err);
                if(!err.response || err.response.data.status === 500){
                    setErrors({api:"err.500"});
                }else{
                    const msg = err?.response?.data?.msg;
                    if(msg?.toLowerCase() === 'buyer account not found'){
                        setErrors({api: "licenses.list.opt.sell.form.err.invalidCustomer"});
                    }else if (msg?.toLowerCase() === 'license_sales not found'){
                        setErrors({api: "licenses.list.opt.sell.form.err.invalidLic"});
                    }else if (msg?.toLowerCase() === 'invalid userdata'){
                        setErrors({api: "nw.input.err.required"});
                    }else {
                        setErrors({api:"err.unkown"});
                    }
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
    };

    return (
        <>            
            <div ref={modalRef} className="modal fade" id="customerModal" tabIndex="-1" aria-labelledby="customerModalLabel" aria-hidden="false" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">
                        <h2 className="bm-card-header_small" id="customerModalLabel">
                            {t("licenses.list.opt.sell.form.customer")}
                        </h2>
                        <a
                            ref={closeRef}
                            disabled={isLoading}
                            href="/#"
                            className="bm-close-x"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={onCloseModal}
                        >
                            &#10005;
                        </a>
                        
                        <h3 className="bm-sep-1 mt-0 text-start">{t("licenses.list.opt.sell.form.prod")}</h3>
                        <div className="mb-3 text-start">{t("licenses.list.opt.sell.form.prod.txt")}</div>
                        <div className="div-input-wrapper">
                            <div style={{maxHeight: '140px', overflow: 'auto',backgroundColor: "var(--nw-grey-light-color)", borderColor: "var(--nw-grey-color)", color:"var(--nw-grey-color)"}} className='div-input bg-grey'>
                                {props.data?.products?.map((product) => {
                                    const productName = t("licenses.list.opt.sell.form.prod.count", {count:product.countKeys, name: product.name});
                                    return <div title={productName} className="text-start nowrap" key={uuidv4()}>
                                        {productName}
                                    </div>
                                })}
                            </div>
                            <label style={{backgroundColor: "#fff", color:"var(--nw-grey-color)"}}>{t("licenses.list.opt.sell.form.prod.label", {products: props.data?.products?.length, keys: props.data?.licenses?.length})}</label>
                        </div>

                        <h3 className="bm-sep-1 mt-0 text-start">{t("licenses.list.opt.sell.form.customer.buyer")}</h3>
                        <div className="mb-3 text-start">{t("licenses.list.opt.sell.form.customer.buyer.txt")}</div>

                        <form onSubmit={handleCheckEmail} autoComplete="on">
                            <Field
                                refInput={emailInput}
                                label={t("nw.input.mail")}
                                id="customerEmail"
                                name="customerEmail"
                                placeholder={t("nw.input.mail.hint")}
                                value={email.value}
                                onChange={handleChangeEmail}
                                disabled={isLoading || email.isValid}
                                type="email"
                                error={t(email.err)}
                            />
                            {email.isValid && !buyer && <div className="fn-smtxt text-start text-warning" dangerouslySetInnerHTML={{__html: t("licenses.list.opt.sell.form.err.notFound")}}/>}
                            {email.isValid && buyer && <>
                                <div className="text-start">
                                    {buyer.name && <Field
                                        label={t("profile.data.name")}
                                        id="usrName"
                                        name="usrName"
                                        placeholder={t("profile.data.name.hint")}
                                        value={buyer.name || ''}
                                        disabled
                                        type="text"
                                    />}
                                    {buyer.publicName && <Field
                                        label={t("profile.data.publicName")}
                                        id="usrName"
                                        name="usrName"
                                        placeholder={t("profile.data.publicName.hint")}
                                        value={buyer.publicName || ''}
                                        disabled
                                        type="text"
                                    />}
                                </div>
                            </>}
                            {!email.isValid && <div className="bm-nav-card-bottom">
                                <button type="submit" disabled={isLoading} className="bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                                    {isLoading &&
                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                    }
                                    <span className={isLoading ? "invisible" : ""}>{t("licenses.list.opt.sell.form.btn.check")}</span>
                                </button>
                            </div>}
                        </form>
                        
                        {email.isValid && <>
                            <h3 className="bm-sep-1 mt-3 text-start">{t("licenses.list.opt.sell.form.customer.buyer.selfReg")}</h3>
                            <div className="mb-3 text-start">{t("licenses.list.opt.sell.form.customer.buyer.selfReg.txt")}</div>
                            <form className="text-start" onSubmit={handleSoldConfirm} autoComplete="on">
                                {props.access.selfRegSeller && 
                                <div title={t("list.opt.sell.form.customer.buyer.selfReg.on.tt")} className="d-flex align-items-center mb-3" onClick={handleCheckboxDivClick}>
                                    <input disabled={isLoading || info} className="form-check-input" checked={data.noUpdate} onChange={handleChangeData} type="checkbox" id="noUpdate" name="noUpdate" value={data.noUpdate}/>
        
                                    <label className="form-check-label" htmlFor="noUpdate">
                                        <span dangerouslySetInnerHTML={{__html: t("licenses.list.opt.sell.form.customer.buyer.selfReg.on")}}/>
                                    </label>
                                </div>}
                                <Field
                                    name="name"
                                    disabled={isLoading || data.noUpdate || info}
                                    attrs={{autoFocus:"on"}}
                                    label={t("selfreg.enterData.name")}
                                    placeholder={t("selfreg.enterData.name.hint")}
                                    id="name"
                                    value={data.name}
                                    onChange={handleChangeData}
                                    error={errors.name}
                                />
                                <Field
                                    name="address"
                                    disabled={isLoading || data.noUpdate || info}
                                    label={t("selfreg.enterData.addr")}
                                    placeholder={t("selfreg.enterData.addr.hint")}
                                    id="address"
                                    value={data.address}
                                    onChange={handleChangeData}
                                    error={errors.address}
                                />
                                <Field
                                    name="address2"
                                    disabled={isLoading || data.noUpdate || info}
                                    label={t("selfreg.enterData.addr2")}
                                    placeholder={t("selfreg.enterData.addr2.hint")}
                                    id="address2"
                                    value={data.address2}
                                    onChange={handleChangeData}
                                    error={errors.address2}
                                />
                                <Field
                                    name="zipCity"
                                    disabled={isLoading || data.noUpdate || info}
                                    label={t("selfreg.enterData.zip")}
                                    placeholder={t("selfreg.enterData.zip.hint")}
                                    id="zipCity"
                                    value={data.zipCity}
                                    onChange={handleChangeData}
                                    error={errors.zipCity}
                                />
                                <div className="bm-two-columns">
                                    <div className="bm-two-columns-left bm-input-wrapper mb-0">
                                    <select
                                        name="country"
                                        disabled={isLoading || data.noUpdate || info}
                                        id="country"
                                        value={data.country}
                                        onChange={handleChangeData}
                                        className={`nw-select ${errors.country ? "bm-input-is-invalid" : ""} `}
                                    >
                                        <option value="default" disabled>{t("selfreg.enterData.country")}</option>
                                        {
                                        Object.values(countries).map((country)=>{
                                            return (<option 
                                                key={uuidv4()}
                                                value={country}
                                                title={country}
                                                className="bm-marg-bott-1rem text-black"
                                            >
                                                {t(`gen.country.${country}`)}
                                            </option>
                                            )
                                        })
                                        }
                                    </select>
                                    {data.country !== "default" && <label htmlFor="country">{t("selfreg.enterData.country.label")}</label>}
                                    {errors.country && <p className="fn-smtxt invalid-feedback mb-1">{errors.country}</p>}
                                    </div>
        
                                    <div className="bm-two-columns-right bm-input-wrapper mb-0">
                                        <select
                                            name="userCult"
                                            disabled={isLoading || data.noUpdate || info}
                                            id="userCult"
                                            value={data.userCult}
                                            onChange={handleChangeData}
                                            className={`nw-select  ${errors.userCult ? "bm-input-is-invalid" : ""}`}
                                        >
                                            <option value="default" disabled>{t("selfreg.enterData.userCult")}</option>
                                            {
                                                Object.values(languages).map((lang)=>{
                                                return (<option 
                                                    key={uuidv4()}
                                                    value={lang}
                                                    title={lang}
                                                    className="bm-marg-bott-1rem text-black"
                                                >
                                                    {t(`languages.${lang}`)}
                                                </option>
                                                )
                                            })
                                            }
                                        </select>
                                        {data.userCult !== "default" && <label htmlFor="userCult">{t("selfreg.enterData.userCult")}</label>}
                                        {errors.userCult && <p className="fn-smtxt invalid-feedback mb-1">{errors.userCult}</p>}
                                    </div>
                                </div>

                                {errors.api && <p className="fn-smtxt invalid-feedback d-block">{t(errors.api)}</p>}
        
                                <div className="bm-nav-card-bottom">
                                    {!info && 
                                        <button type="submit" disabled={isLoading} className="mb-2 ms-5 bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                                            {isLoading &&  
                                                <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                            }
                                            <span className={isLoading ? "invisible" : ""}>{t("licenses.list.opt.sell.form.btn.confirm")}</span>
                                        </button>
                                    }
                                    <div className="bm-clearer"></div>
                                </div>
                            </form>
                            {info && <>
                                <div className="text-start">
                                    <div dangerouslySetInnerHTML={{__html: t(info)}}/>
                                </div>
                                <div className="bm-nav-card-bottom">
                                    <button 
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            closeRef.current?.click();
                                        }}
                                        className="mb-2 ms-5 bm-btn-mute bm-btn-card-next"
                                    >
                                        {t("gen.button.close")}
                                    </button>
                                </div>
                            </>}
                        </>}
                    </div>
                </div>
            </div>
        </>
    )
}
