import { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { Container, Row, Tabs, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import LICENSEAPI from 'Services/LicenseAPI';
import DownloadDetails from './DownloadDetails';
import ActivationDetails from './ActivationDetails';
import TokenDetails from './TokenDetails';
import { useParams, Navigate } from 'react-router-dom';


const tabs = ["download", "activation", "token"];

export default function DetailsLicenses() {
    const { t } = useTranslation();
    const params = useParams();

    document.title = t("licenseDetails")+ " – nanoways.com";
    const user = useSelector((state) => state.user);
	const [tabKey, setTabKey] = useState(params.tab || tabs[0]);
    const [activesTabs, setActivesTabs] = useState(['download']);
    const [data, setData] = useState(undefined);
    const [dataLength, setDataLength] = useState({});//Stock Tabs List length

    const changeTab = (key) =>{
        setData(undefined);
        setTabKey(key);
        if (!activesTabs.includes(key)) {
            setActivesTabs([...activesTabs, key]);
        }
    }


    /* eslint-disable */
    const reloadTab = (e, table) =>{
        const newData = [];

        setDataLength({...dataLength, [table.name]: newData.length});
    }
    useEffect(() => {
        let isMounted = true; //note mutable flag to clear error boundaries
        if (params.key && isMounted && data === undefined) {
            LICENSEAPI.licensesTab({key:params.key, tab: tabKey})
            .then((res) => {
                setData(res.data);
                //setDataLength({...dataLength, [tabKey]: downloads.length});
            }).catch((err)=>{
                console.log(err);
                const {response} = err;
                if (response) {
                    setData([]);
                    //setDataLength({...dataLength, [tabKey]: 0});
                } else {
                    console.log("network error like timeout, connection refused etc...");
                    //setError("network error like timeout, connection refused etc...");
                }
            });
        }

        return () => { isMounted = false }; // cleanup toggles value, if unmounted
        
    }, [tabKey]);
    /* eslint-enable */
    
    return (
        <>
            {(!params.tab || tabs.includes(params.tab) ) ? 
                <div className="p-5 bg-white min-vh-100">
                    <div className="row mb-5">
                        <div className="col-12 px-0 mx-0 text-center">
                            <h1>{t("lic.detail.title", {key: params.key})}</h1>
                            
                            <div className="col-12 tabs">
                                <Container className="default">
                                    <Row>
                                        <Tabs id="controlled-tab" activeKey={tabKey} onSelect={(k) => changeTab(k)}>
                                            {tabs.map((tabName)=>{
                                                if (
                                                    (tabName === "download" && user?.roles.includes('ROLE_157')) ||
                                                    (tabName === "activation" && user?.roles.includes('ROLE_159')) ||
                                                    (tabName === "token" && user?.roles.includes('ROLE_158'))
                                                ) {
                                                    
                                                    return  (
                                                        <Tab 
                                                            tabClassName={`${tabKey === tabName && "nw-bg-blue-light"} "rounded-0 rounded-top"`} 
                                                            key={uuidv4()} eventKey={tabName} 
                                                            title={t(`lic.detail.tab.${tabName}`)}
                                                        >
                                                            {tabName === "download" && data && <DownloadDetails data={data}/>}
                                                            {tabName === "activation" && data && <ActivationDetails data={data}/>}
                                                            {tabName === "token" && data && <TokenDetails data={data}/>}
                                                        </Tab>
                                                    )
                                                }
                                                return '';
                                            })}                                        
                                        </Tabs>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <Navigate 
                    to={t('link.root.url')}
                    state={{
                        alert: "danger",
                        message: t("err.404", {url: window.location.href}),
                    }} 
                    replace
                />
            }
        </>
    )
}