import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import {Routes, Route } from "react-router-dom";
import { useEffect } from 'react';
import ProtectedRoute from "./Components/ProtectedRoute";
//import {ToastContainer} from "react-toastify";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home";
//import Dashboard from "./Pages/Dashboard/Dashboard";
import Access from "./Pages/Admin/Access/ManageAccesses/AccessTreeList";
import ManageAccounts from "./Pages/Admin/Accounts/ManageAccounts";
import Profile from "./Pages/Admin/Accounts/Profile/index.js";
import PwdUpdate from "./Pages/Admin/Accounts/Pwd/index.js";
import RasList from "./Pages/Admin/Ras/index.js";
import LicenseClear from "./Pages/ClearLicense/LicenseClear";
import LicenseOptions from "./Pages/ClearLicense/LicenseOptions";
import LicenseClearDone from "./Pages/ClearLicense/LicenseClearDone";
import LicenseAddUserConfirm from "./Pages/LicenseAddUserConfirm";
import DownloadsList from "./Pages/Downloads/DownloadsList";
import LicenseUpgrade from "./Pages/UpgradeLicense/LicenseUpgrade";
import LicenseUpgradeDone from "./Pages/UpgradeLicense/LicenseUpgradeDone";
import LicenseAddData from "./Pages/LicenseData/LicenseAddData";
import LicenseStatus from "./Pages/LicenseData/LicenseStatus";
import AccountLicenses from "./Pages/Admin/License/index.js";
import Journal from "./Pages/Admin/Journal/index.js";
import LicenseDetails from "./Pages/Admin/License/Details/index.js";
import ConfirmRegistration from "./Pages/ConfirmRegistration";
import Footer from './Components/Footer';
import { useTranslation } from "react-i18next";
import Legal from "./Pages/Legal";
//import 'react-toastify/dist/ReactToastify.css'
import Error from "./Pages/Error";
import RedirectOldLoginUrl from './Components/RedirectOldLoginUrl';
import { logoutUser } from 'Reduxx/Reducers/Thunks/AuthThunk';
import { useDispatch, useSelector } from 'react-redux';
//import TestComponent from "TestComponent";
import VNC from "./Pages/VNC";
import useTabCommunication from 'Hook/useTabCommunication';
import Flash from "Components/Flash";
import {FlashProvider} from "Contexts/FlashContext";


function App() {
	const { t } = useTranslation();
	const {isAuthenticated, rTokenExp} = useSelector((state) => state.auth);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	useTabCommunication('NAVBAR');

	/*useEffect(() => {
	// Check if URL doesn't contain https://www.
	if (!/^https:\/\/www\./i.test(window.location.href) && process.env.NODE_ENV !== 'development' && !window.location.href.includes("newdev")) {
		window.location.href = `https://www.${window.location.hostname}${window.location.pathname}${window.location.search}`;//Redirect to the updated URL
	}
	}, []);*/

	/* eslint-disable */
	//logout if RefreshToken is expired
	useEffect(() => {
		const isRefreshTokenExpired = (rTokenExp) => {
			try {
				return Math.floor(Date.now()) > rTokenExp*1000 ;
			} catch (error) {
				// Handle any decoding or other errors
				console.error('Error checking token expiration:', error);
				return false; // Treat the token as not expired in case of error
			}
		};

		const handleVisibilityChange = () => {
			if (document.visibilityState === 'hidden') {
				//console.log('Tab is being left');
			} else if (document.visibilityState === 'visible') {
				//console.log('Tab is being returned');
				if (isAuthenticated && isRefreshTokenExpired(rTokenExp)){
					dispatch(logoutUser(true));
				}
			}
		};
		const handleLoadPage = () => {
			if (isAuthenticated && isRefreshTokenExpired(rTokenExp)){
				//console.log('Page loaded');
				dispatch(logoutUser(true));
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);
		window.addEventListener('load', handleLoadPage);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
			window.removeEventListener('load', handleLoadPage);
		};
	});
	useEffect(()=>{
		if (typeof window !== 'undefined') {
			const storedTabId = sessionStorage?.getItem('tabId');
			if (!storedTabId) {
				const tabId = Date.now();
				sessionStorage.setItem('tabId', tabId);
			}
		}
        //Remove Footer from VNC Page
        if(window?.location?.href?.includes(t("link.ras.vnc.url")+"/")){
            document.getElementById("mainFooter")?.classList.add('invisible');
            document.getElementById("mainNavbar")?.classList.add('invisible');
        }else{
            document.getElementById("mainFooter")?.classList.remove('invisible');
            document.getElementById("mainNavbar")?.classList.remove('invisible');
        }
	}, [])
	/* eslint-enable */

	return (<>
        <FlashProvider >
		    <Navbar />
            <Flash/>
            <Routes>
                {/*<Route index path="/test" element={<TestComponent />} />*/}
                {/* Dashboard */}
                {/*<Route element={<ProtectedRoute isAllowed={isAuthenticated} />}>
                    <Route path={t("link.dashboard.accounts")+":index"} element={<Dashboard />} />
                </Route>*/}
                {/*profile.page */}
                <Route element={<ProtectedRoute isAllowed={!!user && user?.roles.includes('ROLE_34')} isAuthenticated={isAuthenticated} />}>
                    <Route path={t("link.profil.url")} element={<Profile/>} exact/>
                </Route>
                
                {/*profile.pwd.edit*/}
                <Route element={<ProtectedRoute isAllowed={!!user && user?.roles.includes('ROLE_48')} isAuthenticated={isAuthenticated}/>}>
                    <Route path={t("link.profil.pwd.url")} element={<PwdUpdate/>} exact/>
                </Route>
                
                {/*ras.page*/}
                <Route element={<ProtectedRoute isAllowed={!!user && user?.roles.includes('ROLE_51')} isAuthenticated={isAuthenticated} />}>
                    <Route path={t("link.ras.url")} element={<RasList />}>
                        <Route index element={<RasList />} />
                        <Route path="team/:team/:handle" element={<RasList />} />
                    </Route>
                </Route>

                {/*acc.page*/}
                <Route element={<ProtectedRoute isAllowed={!!user && user?.roles.includes('ROLE_77')} isAuthenticated={isAuthenticated} />}>
                    <Route path={t("link.access.url")} element={<Access/>} />
                </Route>

                {/*users.page*/}
                <Route element={<ProtectedRoute isAllowed={!!user && user?.roles.includes('ROLE_85')} isAuthenticated={isAuthenticated} />}>
                    <Route path={t("link.accounts.url")} element={<ManageAccounts/>} />
                </Route>

                {/*license.details.page*/}
                <Route element={<ProtectedRoute isAllowed={!!user && user?.roles.includes('ROLE_156')} isAuthenticated={isAuthenticated} />}>
                    <Route path={t("link.licenseDetails.url")} element={<LicenseDetails/>} />
                    <Route path={t("link.licenseDetails.url")+"/:tab"} element={<LicenseDetails/>} />
                </Route>

                {/*lic.page*/}
                <Route element={<ProtectedRoute isAllowed={!!user && user?.roles.includes('ROLE_62')} isAuthenticated={isAuthenticated} />}>
                    {/*<Route path={t("link.licenses.url")} element={<AccountLicensesList />} />*/}
                    <Route path={t("link.licenses.url")} element={<AccountLicenses />} />
                    <Route path={t("link.licenses.url")+"/:tab"} element={<AccountLicenses />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={!!user && user?.roles.includes('ROLE_177')} isAuthenticated={isAuthenticated} />}>
                    <Route path={t("link.journal.url")} element={<Journal />} />
                </Route>

                <Route index path={t("link.root.url")} element={<Home />} />
                <Route path={t("link.ras.vnc.url")+"/:handle"} element={<VNC />} />
                <Route path={t("link.login")} element={<Home login={true}/>} />
                
                <Route path={t("link.pwdForgot.url")} element={<Home pwdForgot={true} />} />
                <Route path={t("link.pwdReset")} element={<Home pwdReset={true} />} />
                <Route path={t("link.signupConfirm") +':token'} element={<ConfirmRegistration token='token' />} />

                <Route path={t("link.lic.clear.lic")} element={<LicenseClear />} />
                <Route path={t("link.lic.clear.lic")+"/:key"} element={<LicenseClear />} />
                <Route path={t("link.lic.clear.opt")} element={<LicenseOptions />} />
                <Route path={t("link.lic.clear.done")} element={<LicenseClearDone />} />
                <Route path={t("link.lic.confirmUser")} element={<LicenseAddUserConfirm />} />

                {/*Start Redirect old Nanoways Links*/}
                <Route path={"/esd/login"} element={<LicenseClear />} />
                <Route path={"/esd/login/:eKeyOrLicenseKey"} element={<RedirectOldLoginUrl />} />
                {/*End Redirect old Nanoways Links*/}

                <Route path={t("link.download.url")+"/:code"} element={<DownloadsList/>} />

                <Route path={t("link.lic.upgrade.code")} element={<LicenseUpgrade />} />
                <Route path={t("link.lic.upgrade.code")+"/:code"} element={<LicenseUpgrade />} />
                <Route path={t("link.lic.upgrade.code")+"/:code/:licenseKey"} element={<LicenseUpgrade />} />
                <Route path={t("link.lic.upgrade.done")} element={<LicenseUpgradeDone />} />
                
                {/*Redirect Old Links */}
                <Route path="esd/download/:code" element={<DownloadsList/>} />
                <Route path="esd/clear/license" element={<LicenseClear />} />
                <Route path="esd/clear/license/:key" element={<LicenseClear />} />
                <Route path="esd/upgrade/license" element={<LicenseUpgrade />} />
                <Route path="esd/upgrade/license/:code" element={<LicenseUpgrade />} />
                <Route path="esd/upgrade/license/:code/:licenseKey" element={<LicenseUpgrade />} />

                <Route path={t("link.lic.userdata")} element={<LicenseAddData />} />
                <Route path={t("link.lic.wait")} element={<LicenseStatus />} />
                <Route path={t("link.err.url")} element={<Error />} />
                <Route path={t("link.err.url")+"/:type/:pathname"} element={<Error data={{h1:"download.title", h2: "download.err", p: {txt:"download.err.expire"}, group:'dl'}} />} />
                
                <Route path={t("link.legal", {page: ":i18nKey"})} element={<Legal/>} exact/>
                <Route path="*" element={<Home notFound={{
                        "alert": "danger",
                        "message": t("err.404",{"url":window.location.href})
                    }}/>} 
                />
            </Routes>
		    <Footer />
        </FlashProvider >
		{/*<ToastContainer
		//theme= "colored"
		position="bottom-right"
		autoClose={5000}
		hideProgressBar={false}
		newestOnTop={false}
		closeOnClick
		rtl={false}
		pauseOnFocusLoss
		draggable
		pauseOnHover
		/>*/}
	</>);
}

export default App;
