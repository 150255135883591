import { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Field from "Components/Forms/Field";
import LicenseAPI from "Services/LicenseAPI";
import { useTranslation } from "react-i18next";
import { fixInput } from 'Services/utils';
import ConfirmModal from 'Components/ConfirmModal';
import Spinner from 'Components/Loading/Spinner';
import { handleCheckboxDivClick } from 'Services/utils';
import AddEmailModal from "./AddEmailModal";
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import { DOMAIN_NAME } from "config/nw-api";
import { useSelector } from 'react-redux';

export default function LicenseUpgrade() {
	const {isAuthenticated} = useSelector((state) => state.auth);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    document.title = t("upgrade.title")+ " – nanoways.com";

    const [license, setLicense] = useState({
        value: fixInput({value:params.licenseKey, maxLength:23}),
        placeholder: " ",
        error: "",
        errVar: "",
        newEmail: null
    });
    const [upgradeCode, setUpgradeCode] = useState({
        value: fixInput({value:params.code, maxLength: 11}),
        placeholder: " ",
        error: "",
        requiredProduct: ""
    });
    const [cursor, setCursor] = useState({
        target: undefined,
        position: 0
    });
    const [toggle, setToggle] = useState(false);
    const codeBtn = useRef(); 
    
    const [checkboxAgreeStyle, setCheckboxAgreeStyle] = useState({width:"40px", marginTop: "1px"});
    const handleHover = (e)=>{
        if (e.type === "mouseenter" && !agreeContract) {
            if (!agreeContract) {
                setCheckboxAgreeStyle({...checkboxAgreeStyle, background: "#ffe0e0", borderColor: "#ff0000"});
            }
        }else if(e.type === "mouseleave"){
            setCheckboxAgreeStyle({width:"40px", marginTop: "1px"})
        } 
    }

    /* eslint-disable */
    useEffect(() => {
        // After change Input value return cursor to the last position.
        cursor.target && (cursor.target.selectionEnd = cursor.position);
    },[license, upgradeCode]);
    useEffect(() => {
        if (params.code) {
            codeBtn.current.click();
        }
    },[]);
    /* eslint-enable */

    const handleChange = (e) => {
        Object.defineProperty(e.target, "defaultValue", {
          configurable: true,
          get() { return "" },
          set() {},
        });
        const {name, value, selectionStart} = e.currentTarget;

        let cursorPosition = selectionStart;
        let valueLength = value.length;

        let newValue = "";

        if (name === 'upgradeCode') {
            params.code = "";
            newValue = fixInput({value:value, maxLength: 11});
            setUpgradeCode({...upgradeCode,
                value: newValue,
                placeholder: (newValue+t("upgrade.enter.upgrade.code.hint").slice(newValue.length))
            });
        }else if(name === 'license'){
            newValue = fixInput({value:value, maxLength: 23});
            setLicense({...license,
                value: newValue,
                placeholder: (newValue+t("clear.enterlk.key.hint").slice(newValue.length))
            });
        }

        if(valueLength === cursorPosition){
            cursorPosition = newValue.length;
        }

        setCursor({...cursor, target: e.target, position: cursorPosition});
    };
    
    const inputFocus = (e) => {
        if ('license' === e.target.name && !e.target.value) {
            setLicense({...license, placeholder: t("clear.enterlk.key.hint")});
        }
        if ('upgradeCode' === e.target.name && !e.target.value) {
            setUpgradeCode({...upgradeCode, placeholder: t("upgrade.enter.upgrade.code.hint")});
        }
    };
    
    const inputFocusout = (e) => {
        if (!e.target.value && 'license' === e.target.name) {
            setLicense({...license, placeholder: ""});
        }
        if (!e.target.value && 'upgradeCode' === e.target.name) {
            setUpgradeCode({...upgradeCode, placeholder: ""});
        }
    };

    const handleUpgradeCodeSubmit = async event => {
        event.preventDefault();
        setToggle(true);
        let hasError = false;

        if (!upgradeCode.value) {
            setUpgradeCode({...upgradeCode, error: "nw.input.err.required"});
            hasError = true;
        } else if (!upgradeCode.value.match(/^([A-Za-z0-9]{5}-?){1}[A-Za-z0-9]{5}$/)) {
            setUpgradeCode({...upgradeCode, error: "clear.enterlk.err.invalid"});
            setToggle(false);
        }
        
        if(!hasError) {
            try{
                const {data} = await LicenseAPI.CheckUpgradeCode(upgradeCode.value);
                setRequirements({...requirements, upgradeCodeEmail: data.upgradeCodeEmail?.toLowerCase()});
                setUpgradeCode({...upgradeCode, ...data});
                setAgreeContract(data.isContractAgreed);
                setToggle(false);
            }
            catch(error) {
                setToggle(false);
                if(!error.response || error.response.data.status === 500){
                    console.log(error.message);
                    setUpgradeCode({...upgradeCode, error: "err.500"});
                } else if(error.response.status === 404) {
                    setUpgradeCode({...upgradeCode, error: "upgrade.enter.upgrade.err.notFound"});
                } else if(error.response.status === 403) {
                    setUpgradeCode({...upgradeCode, error: "upgrade.enter.err.applied"});
                } else if(error.response.status === 422) {
                    setUpgradeCode({...upgradeCode, error: "upgrade.enter.err.status"});
                } else {
                    console.log(error.message);
                    setUpgradeCode({...upgradeCode, error: "err.500"});
                }
            }
        }else{
            setToggle(false);
        }
    };

    const handleResponseApiErr = (data) => {
        const msg = data?.message?.toLowerCase();
        if(msg === "license is not found") {
            setLicense({...license, error:"clear.enterlk.err.invalid"});
        } else if(msg === "product is not found") {
            setLicense({...license, error:"clear.enterlk.err.noProd"});
        }  else if(msg === "upgrade not allowed") {
            setLicense({...license, error:"upgrade.enter.key.err.mixed"});
        } else if(msg === "expired") {
            setLicense({...license, error:"upgrade.enter.key.err.expire"});
        } else if(msg === "barred") {
            setLicense({...license, error:"upgrade.enter.key.err.barred"});
        }else if (msg === 'enhancement applied') {
            setLicense({...license, error: "upgrade.enter.err.applied"});
        }else if (msg === 'enhancement applied & not match') {
            setLicense({...license, error: "upgrade.enter.err.applied.nomatch"});
        }else if (msg === 'enhancement applied & same') {
            setLicense({...license, error: "upgrade.enter.err.applied.same", errVar: t("link.lic.clear.lic") });
        }else if(msg?.startsWith("status: ")) {
            setLicense({...license, error: "upgrade.enter.err.status"});
        }else if(msg === 'update requires at least') {
	    setLicense({...license, error: "upgrade.enter.err.insufficient", errVar: data.info});
        }else if(msg === 'update requires same licensetype') {
	    data.info.licAvailable = t("st."+data.info.licAvailable);
	    data.info.licRequired = t("st."+data.info.licRequired);
	    setLicense({...license, error: "upgrade.enter.err.licensetypes", errVar: data.info});
        }else if (msg === 'subtype not match') {
            if (user?.roles.includes('ROLE_201')) {
                setRequirements({...requirements, subTypLic: 'mixed'});
                confirmRef.current.click();
                setToggle(true);
            }else{
                setLicense({...license, error: "upgrade.enter.key.err.mixed"})
            }  
        }else{
            setLicense({...license, error: "err.unknown"});
        }
    }

    const confirmRef = useRef();
    const emailModalRef = useRef();
    const [requirements, setRequirements] = useState({
        hasEmail: true,
        isSameProduct: false,
        upgradeCodeEmail: null,
        licenseEmail: null,
    });
    const [newEmail, setNewEmail] = useState(null);

    /* eslint-disable */
    useEffect(()=>{
        if (newEmail) {
            if (requirements.isSameProduct) {
                confirmRef.current.click();
            }else{
                confirm(true);
            }
        }
        if (!toggle && newEmail) {
            setNewEmail(null);
        }
    },[newEmail, toggle])
    /* eslint-enable */

    const confirm = (value) => {
        if (value) {
            upgradeLicense();
        }else{
            setToggle(false);
        }
    }

    const handleCheckProducts = async event => { 
        event.preventDefault();
        setToggle(true);
        let hasError = false;

        if (!license.value) {
            setLicense({...license, error: "nw.input.err.required"});
            hasError = true;
        } else if (!license.value.match(/^([A-Za-z0-9]{5}-?){3}[A-Za-z0-9]{5}$/)) {
            setLicense({...license, error: "clear.enterlk.err.invalid"});
            hasError = true;
        }
        
        if(!hasError) {
            try{
                setLicense({...license, error: ""});
                const response = await LicenseAPI.checkUpgradeProducts(license.value, upgradeCode.value, isAuthenticated);
                const licenseEmail = response.data['licenseEmail']?.toLowerCase();
                const isNotSameEmail = licenseEmail && requirements.upgradeCodeEmail && licenseEmail !== requirements.upgradeCodeEmail;
                setRequirements({...requirements, hasEmail: response.data['hasEmail'], isSameProduct: response.data['sameProduct'], licenseEmail, isNotSameEmail});
                if (!response.data['hasEmail']) {
                    emailModalRef.current.click();
                }else if(response.data['sameProduct'] || isNotSameEmail){
                    confirmRef.current.click();
                }else{
                    confirm(true);
                }
            }
            catch(error) {
                setToggle(false);
                if(error.response.data.message === undefined){
                    setLicense({...license, error: "err.unknown"});
                } else {
                    handleResponseApiErr(error.response.data);
                }
                console.log(error.response.data);
            }
        }else{
            setToggle(false);
        }
    }
    
    const upgradeLicense = async () => {
        try{
            const data = {
                key: license.value,
                eKey: upgradeCode.value,
                urlCallback: DOMAIN_NAME + t("link.lic.clear.lic"),
                isContractAgreed: agreeContract,
                isSubTypChecked: true,
            };
            if (newEmail) {
                data.newEmail = newEmail;
            }

            const response = await LicenseAPI.licenseUpgrade(data, isAuthenticated);
            navigate(t("link.lic.upgrade.done"), {state:response.data});
        }
        catch(error) {
            setToggle(false);
            if(!error.response || error.response.data.status === 500){
                console.log(error.message);
                setLicense({...license, error: "err.500"});
            } else {
                if(error.response.data.message === undefined){
                    console.log(error.response.data);
                    setLicense({...license, error: "err.unknown"});
                } else {
                    handleResponseApiErr(error.response.data);
                }
            }
        }
    };

    const removeLicenseInput = (e) => {
        setUpgradeCode({...upgradeCode,
            error: "",
            requiredProduct: ""
        })
    }

    const codeAttr = {
        //minLength: 10,//maxLength: 11,//title:t("upgrade.enter.code")
        pattern:'([A-Za-z0-9]{5}-?){1}[A-Za-z0-9]{5}',
        disabled: upgradeCode.requiredProduct || toggle ? "disabled" : "",
        autoComplete:"on",
        autoFocus:"on"
    }
    const [agreeContract, setAgreeContract] = useState(true);

    const user = useSelector((state) => state.user);

    return (
        <div className="m-0 p-0 vertical-center">
            <ConfirmModal 
                confirmRef={confirmRef} 
                confirm={confirm} 
                txt={{
                    title:t("upgrade.enter.confirm"),
                    body: requirements.isSameProduct && requirements.isNotSameEmail ? 
                            t("upgrade.enter.confirm.emailAndProd", {upgradeCodeEmail: requirements.upgradeCodeEmail, licenseEmail: requirements.licenseEmail})
                        : 
                            requirements.isNotSameEmail ?
                                t("upgrade.enter.confirm.email", {upgradeCodeEmail: requirements.upgradeCodeEmail, licenseEmail: requirements.licenseEmail})
                            :
                                requirements.subTypLic === 'mixed' ?
                                t("upgrade.enter.confirm.subTypLic")
                                :
                                t("upgrade.enter.confirm.prod")
                            ,
                    yes:t("upgrade.enter.confirm.yes"),
                    no:t("upgrade.enter.confirm.no"),
                }} 
                backdrop={"static"} 
                id="confirmUpgrade" 
                classes="nw-rm-btn p-0"
            />

            <AddEmailModal refBtn={emailModalRef} setEmail={setNewEmail} setToggle={setToggle} />
            
            <div className="bm-main">
                <div className="bm-card">
                    <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
                    
                    <h1>{ t("upgrade.title") }</h1>
                    
                    <h2 className="bm-sep-1">{t("upgrade.enter.upgrade")}</h2>
                    <p>{t("upgrade.enter.upgrade.txt")}</p>

                    {!upgradeCode.requiredProduct &&
                        <form onSubmit={handleUpgradeCodeSubmit} autoComplete="on">

                            <div className="autocomplete-container">
                                <div className="autocomplete">{upgradeCode.placeholder}</div>
                                <Field
                                    name="upgradeCode"
                                    label={t("upgrade.enter.upgrade.code")}
                                    id="upgradeCode"
                                    value={upgradeCode.value}
                                    onChange={handleChange}
                                    onFocus={inputFocus}
                                    onBlur={inputFocusout}
                                    error={t(upgradeCode.error)}
                                    attrs={codeAttr}
                                    placeholder=" "
                                    inputClass={params.code ? "nw-green-dark" : ""}
                                />
                            </div>

                            <div className="bm-nav-card-bottom">
                                <button ref={codeBtn} type="submit" disabled={toggle && "disabled"} className="bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                                    {toggle && 
                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                    }
                                    <span className={toggle ? "invisible" : ""}>{t("upgrade.enter.upgrade.btn")}</span>
                                </button>

                                <p className="bm-counter-1">{t("info.step", {current: 1, total: 2})}</p>

                                <div className="bm-clearer"></div>
                                <div className="bm-clearer"></div>
                            </div>
                        </form>
                    }

                    {upgradeCode.requiredProduct && 
                    <>
                        <Field
                            name="upgradeCode"
                            label={t("upgrade.enter.upgrade.code")}
                            id="upgradeCode"
                            value={upgradeCode.value}
                            onChange={handleChange}
                            onFocus={inputFocus}
                            onBlur ={inputFocusout}
                            attrs={codeAttr}
                            placeholder=" "
                            classes="icon-rtl"
                        />

                        <h2 className="bm-sep-1 fade-in">{t("upgrade.enter.key")}</h2>
                        <p className="fade-in" dangerouslySetInnerHTML={{__html: t("upgrade.enter.key.txt")}}/>
                        <p className="fade-in">
                            <span dangerouslySetInnerHTML={{__html: t("upgrade.enter.key.req", {prodRequired: upgradeCode.requiredProduct}) }}/>
                        </p>
                        
                        <form onSubmit={handleCheckProducts} autoComplete="on">
                            <div className="autocomplete-container">
                                <div className="autocomplete">{license.placeholder}</div>
                                <Field
                                    name="license"
                                    label={t("clear.enterlk.key")}
                                    id="license"
                                    value={license.value}
                                    disabled={toggle}
                                    onChange={handleChange}
                                    onFocus={inputFocus}
                                    onBlur ={inputFocusout}
                                    error={(license.errVar && t(license.error, {prodRequired: license.errVar.prodRequired, prodAvailable: license.errVar.prodAvailable, licRequired: license.errVar.licRequired, licAvailable: license.errVar.licAvailable})) || t(license.error)}
                                    attrs={{pattern:'([A-Za-z0-9]{5}-?){3}[A-Za-z0-9]{5}',autoComplete:"on",autoFocus:"on"}}
                                    classes="fade-in"
                                    placeholder=" "
                                />
                            </div>
                            
                            {!upgradeCode.isContractAgreed &&
                                <div className="d-flex" onClick={handleCheckboxDivClick}>
                                    <input style={checkboxAgreeStyle} disabled={toggle} className="form-check-input" checked={agreeContract} onChange={()=>setAgreeContract(!agreeContract)} type="checkbox" id="agreeContract" name="agreeContract" value={agreeContract}/>
        
                                    <label className="form-check-label" htmlFor="agreeContract">
                                        <span dangerouslySetInnerHTML={{__html: t("nw.input.agree")}}/>
                                    </label>
                                </div> 
                            }

                            <div className="bm-nav-card-bottom">
                                <a onClick={(e) => removeLicenseInput(e)} href="/#">
                                    <button className="bm-btn-mute mb-2">{t("gen.button.back")}</button>
                                </a>
                                 
                                <span onMouseEnter={handleHover} onMouseLeave={handleHover}>              
                                    <OverlayTrigger placement="top" trigger={(!agreeContract) ? ["hover", "focus", "click"] : []} rootClose={true} rootCloseEvent="mousedown" overlay={<Tooltip className="nw-tooltip">{t("nw.input.agree.tt")}</Tooltip>}>
                                        <button
                                            type="submit"
                                            disabled={(toggle || !agreeContract) ? "disabled" : ""}
                                            className={`${(!agreeContract) ? "btn-disabled" : "bm-btn-blue"} bm-btn-card-next d-flex justify-content-center`}
                                            //title={!agreeContract ? t("clear.select.btn.agreeTT") : ""}
                                        >
                                            {toggle &&
                                                <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                            }
                                            <span className={toggle ? "invisible" : ""}>{t("upgrade.enter.key.btn")}</span>
                                        </button>
                                    </OverlayTrigger>
                                </span>
                                <p className="bm-counter-1">{t("info.step", {current: 2, total: 2})}</p>
                                <div className="bm-clearer"></div>
                            </div>
                        </form>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}
