import {useState} from 'react';
import {ReactComponent as EyeHideSvg} from "assets/images/icon/eye.hide.svg";
import {ReactComponent as EyeShowSvg} from "assets/images/icon/eye.show.svg";

const PasswordField = (props) => {
    const [type, setType] = useState('password');

    const onChangeType = (e) => {
        const newType = type === 'password'? 'text' :'password'; 
        setType(newType);
        if (props.shareType) {
            props.shareType(newType);
        }
    }
    return (
        <div className={"bm-input-wrapper " + props.classes}>
            <input
                ref={props.refInput}
                disabled={props.disabled}
                onChange={props.onChange}
                onBlur ={props.onBlur  && props.onBlur }
                onFocus={props.onFocus && props.onFocus}
                onKeyDown={props.onKeyDown && props.onKeyDown}
                hidden={props.hidden || false}
                name={props.name}
                id={props.id}
                title={props.title}
                value={props.value}
                type={type}
                placeholder={props.placeholder || props.label} 
                className={"bm-input " + props.inputClass + (props.error && " bm-input-is-invalid")}
                style={{paddingRight: "38px" }}
                {...props.attrs}
            />
            <label hidden={props.hidden || false} htmlFor={props.id || props.name}>{props.label}</label>
            {props.error && <p className="fn-smtxt invalid-feedback" dangerouslySetInnerHTML={{__html: props.error}}/>}
            <span 
                className='nw-opacity-6'
                style={{
                    position: "absolute",
                    right: "10px",
                    top: props.error ? "15%" : "24%",
                    cursor: props.disabled ? "auto" : "pointer",
                }}
                onClick={props.disabled ? null: onChangeType}
            >
                {type === 'password' ? <EyeShowSvg fill={props.disabled ? "var(--nw-grey-very-light-color)":"var(--nw-blue-color)"}/> : <EyeHideSvg fill={props.disabled ? "var(--nw-grey-very-light-color)":"var(--nw-blue-dark-color)"}/>}
            </span>
        </div>
    )
}

export default PasswordField;