// src/store/thunks.js
import { createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {API_LOGIN, API_INVALIDATE_TOKEN} from "config/nw-api";
import {loginSuccess, loginFailure, logoutSuccess, impersonateSuccess} from 'Reduxx/Reducers/AuthSlice';
import {getUser, removeUser} from 'Reduxx/Reducers/UserSlice';
import jwtDecode from "jwt-decode";
import USERS_API from "Services/UsersAPI";


export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async ({credentials,closeForm}, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(API_LOGIN, credentials, {withCredentials: true});
            closeForm?.click();
            dispatch(loginSuccess({accessToken:response.data?.token, rTokenExp: response.data.rTokenExp}));
            dispatch(getDataFromToken());
            // close Flash message
            document.querySelector('[data-bs-dismiss="alert"]')?.click();
        } catch (error) {
            if(!error.response || error?.response?.data?.status === 500){
                dispatch(loginFailure({msg: "err.500"}));
            } else {
                const {message} = error.response.data;
                console.log('Error Response',error.response);
                if(message.startsWith("Invalid credentials")) {
                    dispatch(loginFailure({msg:"user.err.invalidAccount"}));
                }else if (message.startsWith("Too many failed login attempts")) {
                    dispatch(loginFailure({msg:"nw.input.mail.err.maxTry", args:  { time: 20}}));
                }else if (message.startsWith("Account is disabled")) { //TODO: change message to "Account is disabled, new Activation mail has been send."
                    dispatch(loginFailure({msg:"user.err.disabled"}));
                } else if (message.startsWith("Account is disabled, new Activation mail will be later send")) {
                    dispatch(loginFailure({msg:"user.err.disabled"}));
                } else if (message.startsWith("Account is blocked")) {
                    dispatch(loginFailure({msg:"user.err.block"}));
                } else {
                    dispatch(loginFailure({msg:"err.unkown"}));
                }
            }
        }
    }
);


export const switchUser = createAsyncThunk(
    'auth/switchUser',
    async ({email, exit, prevImpersonator}, { dispatch, rejectWithValue }) => {
        try {
            const response = await USERS_API.impersonateTo(email, exit, prevImpersonator);
            sessionStorage.setItem('switchedAs', email);
            dispatch(impersonateSuccess({accessToken:response.data?.token, rTokenExp: response.data.rTokenExp, impersonate: response.data?.impersonate}));
            dispatch(getDataFromToken());
        } catch (error) {
            /*if(!error.response || error?.response?.data?.status === 500){
                dispatch(loginFailure({msg: "err.500"}));
            } else {
                const {message} = error.response.data;
                console.log('Error Response',error.response);
                if(message.startsWith("Invalid credentials")) {
                    dispatch(loginFailure({msg:"user.err.invalidAccount"}));
                }
            }*/
        }
    }
);

export const logoutUser = createAsyncThunk(
    'auth/logoutUser',
    async (showLogin = false, { dispatch, rejectWithValue }) => {
        dispatch(logoutSuccess(showLogin));  
        dispatch(removeUser());
        try {
            await axios.post(API_INVALIDATE_TOKEN, null, {withCredentials: true})
            .then((resp)=> {
                return rejectWithValue('token invalidate');
            }).catch((err)=>{
                return rejectWithValue('token not invalidate');
            })
        } catch (error) {
            console.error(error);
            return rejectWithValue('dispatch err');
        }
    }
);

export const getDataFromToken = createAsyncThunk(
    'user/getDataFromToken',
    async (_, { getState, dispatch }) => {
        const { accessToken } = getState().auth;

        if (accessToken) {
            dispatch(getUser(jwtDecode(accessToken)));
        }else{
            dispatch(removeUser());
        }
    }
);