import 'variables.css';
import { useTranslation } from "react-i18next";
import {useState, useEffect, useRef} from 'react';
import MaterialTable, { MTableToolbar, MTableAction } from '@material-table/core';
import { forwardRef } from 'react';
import {ReactComponent as CopySvg} from "assets/images/icon/copy.svg";
import {ReactComponent as DlCheckSvg} from "assets/images/icon/journal/download.ok.16.svg";
import {ReactComponent as DlFailSvg} from "assets/images/icon/journal/download.err.16.svg";
import {ReactComponent as DlSvg} from "assets/images/icon/journal/download.16.svg";
import {ReactComponent as BuildCheckSvg} from "assets/images/icon/journal/gear.ok.16.svg";
import {ReactComponent as BuildWaitSvg} from "assets/images/icon/journal/gear.16.svg";
import {ReactComponent as BuildFailSvg} from "assets/images/icon/journal/gear.err.16.svg";
import {ReactComponent as BuildRunSvg} from "assets/images/icon/journal/gear.work.16.svg";
import {ReactComponent as AccountInSvg} from "assets/images/icon/journal/user.known.ok.16.svg";
import {ReactComponent as AccountOutSvg} from "assets/images/icon/journal//user.known.16.svg";
import {ReactComponent as AccountNoneSvg} from "assets/images/icon/journal/user.anon.16.svg";
//import {ReactComponent as LicenseSvg} from "assets/images/icon/journal/seal.16.svg";
import {ReactComponent as LicenseNewSvg} from "assets/images/icon/journal/seal.add.16.svg";
import {ReactComponent as LicenseSoldSvg} from "assets/images/icon/journal/seal.coin.16.svg";
import {ReactComponent as LicenseUserConfirmSvg} from "assets/images/icon/journal/seal.list.16.svg";
import {ReactComponent as LicenseAppliedSvg} from "assets/images/icon/journal/seal.ok.16.svg";
//import {ReactComponent as EnhSvg} from "assets/images/icon/journal/up.16.svg";
import {ReactComponent as EnhNewSvg} from "assets/images/icon/journal/up.add.16.svg";
import {ReactComponent as EnhSoldSvg} from "assets/images/icon/journal/up.coin.16.svg";
import {ReactComponent as EnhAppliedSvg} from "assets/images/icon/journal/up.ok.16.svg";
import {ReactComponent as WinSvg} from "assets/images/icon/journal/win.svg";
import {ReactComponent as ZipSvg} from "assets/images/icon/journal/zip.svg";
import {ReactComponent as BinSvg} from "assets/images/icon/journal/package-alt.svg";
import {ReactComponent as IsoSvg} from "assets/images/icon/journal/cd.svg";
import ViewColumn from '@material-ui/icons/ViewColumn';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
//import { SliceText, compareVersions } from 'Components/Tools/Text';
import {localeDatetime} from 'Components/Tools/DateTime';
import useScreenSize from 'Hook/useScreenSize';
import SearchInput from "Components/MaterialTable/SearchInput";
import {convertSize} from "Services/utils";
import FLAGS from "assets/images";
import {JOURNAL_LIST_NUMBER} from 'config/nw-api';
import ChangeLimit from "Components/MaterialTable/ChangeLimit";
import {getNotExpiredLocalStorage, setHideColsLocalStorage} from "Services/utils";

let tableIcons = {
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{marginTop:"1px"}} className="nw-blue"/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} data-hide="small" ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} data-hide="small" ref={ref} />),
};
//First import custom Css variables before read rootStyles
const blueColor = getComputedStyle(document.documentElement).getPropertyValue('--nw-blue-color');

const theme = createTheme({
    palette: {
		primary: {
		  main: blueColor,
		},
		fade: {
		  main: blueColor,
		},
      /*secondary: {
        main: '#ff9100',
      },*/
    },
	typography: {
		//fontSize: 20,
	},
});
const searchInputId = 'searchJournal';
const HIDDEN_COLS_EXP_SECONDS = 1209600; //14 Days in seconds

const JournalList = forwardRef((props, ref) => {
  const { t } = useTranslation();
	const [columns, setColumns] = useState([]);
    const storageHidddenCols = getNotExpiredLocalStorage('journal_hide_', props.name);
	/***********Manage license Key copy clipboard***********/
  const copyIconsRef = useRef([]);
	const handleMouseUp = (e, currentIndex) => {
		for (const idx in copyIconsRef.current) {
			if (idx === currentIndex) {
				copyIconsRef.current[idx]?.setAttribute('fill', 'var(--nw-green-color)');
			}else{
				copyIconsRef.current[idx]?.setAttribute('fill', 'var(--nw-blue-color)');
			}
		}
	}
	const handleMouseDown = (e, license) => {
		navigator.clipboard.writeText(license);
	}
	/***********Manage license Key copy clipboard***********/
	const screenSize = useScreenSize();
	
    /* eslint-disable */
    useEffect(() => {
		if (props.colsStructure) {
			const cols = [];
			const hiddenCols = [];
			for (const colName in props.colsStructure) {
				const data = props.colsStructure[colName];
				if (colName === 'col_type') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("type");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("type") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("journal.list.cols.type.titleTT")}>{t("journal.list.cols.type")}</span>,
						field: "type",
						width:`${data[1]}%`,
						//cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
						initialEditValue: "",
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
            //Change label by Grouping
            //groupRender: (data) => {console.log(data); return "Text"},
						render: function(rowData){
              let tooltip = '';
              let icon = '';
							if (rowData.type === 'clear') {
                const {buildStart, buildEnd, buildError} = rowData.datesInfo;
                tooltip += `${t("journal.list.cols.dates.buildStartTT")}${buildStart ? localeDatetime(buildStart) : '-'}\n`;
                tooltip += `${t("journal.list.cols.dates.buildEndTT")}${buildEnd ? localeDatetime(buildEnd) : '-'}\n`;
                tooltip += `${t("journal.list.cols.type.clear.buildErrTT")}${buildError ? localeDatetime(buildError) : '-'}`;
                icon = buildError ?  <BuildFailSvg width="16" /> :  buildEnd ? <BuildCheckSvg width="16" /> : buildStart ? <BuildRunSvg width="16" /> : <BuildWaitSvg width="16" />;
							}else if (rowData.type === 'dl'){
                tooltip = t("journal.list.cols.type.dl.fromTT");
                tooltip += rowData.dlUI ? t("journal.list.cols.type.dl.uiTT") : rowData.dlLink ? t("journal.list.cols.type.dl.linkTT") : "-";
                tooltip += "\n";
                tooltip += `${t("journal.list.cols.type.dl.errTT")}${rowData.type.dlError ? '✔' : '-'}`;
                icon = rowData.dlError ?  <DlFailSvg width="16" /> :  rowData.datesInfo?.downloadStart ? <DlCheckSvg width="16" /> : <DlSvg width="16" />;
              }else if (rowData.type === 'new'){
                icon = rowData.dataType === 'enh' ? <EnhNewSvg width="16" /> : <LicenseNewSvg width="16" />;
                //tooltip = rowData.type;
              }else if (rowData.type === 'sold'){
                icon = rowData.dataType === 'enh' ? <EnhSoldSvg width="16" /> : <LicenseSoldSvg width="16" />;
                //tooltip = rowData.type;
              }else if (rowData.type === 'applied'){
                icon = rowData.dataType === 'enh' ? <EnhAppliedSvg width="16" /> : <LicenseAppliedSvg width="16" />;
                //tooltip = rowData.type;
              }else if (rowData.type === 'confirmed'){
                icon = rowData.dataType === 'enh' ? <EnhNewSvg width="16" /> : <LicenseUserConfirmSvg width="16" />;
                //tooltip = rowData.type;
              }

							return <div className='nowrap' title={tooltip}>{icon}</div>;
						}
					})
				}
				if (colName === 'col_dates') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("dates");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("dates") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("journal.list.cols.dates.titleTT")}>{t("journal.list.cols.dates")}</span>,
						field: "dates",
						width: `${data[1]}%`,
						//cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
						initialEditValue: "",
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						render: function(rowData){
              let date = '';
              let tooltip = '';
              if (['dl', 'clear'].includes(rowData.type)) {
                const {create, buildStart, buildEnd, downloadStart, downloadEnd} = rowData.datesInfo;
                const createdToday = isToday(rowData.dates);
                date = localeDatetime(rowData.dates, createdToday, !createdToday);
  
                tooltip = `${t("journal.list.cols.dates.create")}${localeDatetime(create)}\n`;
                tooltip += `${t("journal.list.cols.dates.buildStartTT")}${buildStart? localeDatetime(buildStart) : '-'}\n`;
                tooltip += `${t("journal.list.cols.dates.buildEndTT")}${buildEnd? localeDatetime(buildEnd) : '-'}\n`;
                tooltip += `${t("journal.list.cols.dates.dlStartTT")}${downloadStart? localeDatetime(downloadStart) : '-'}\n`;
                tooltip += `${t("journal.list.cols.dates.dlEndTT")}${downloadEnd? localeDatetime(downloadEnd) : '-'}`;
              }else if (['new', 'sold', 'applied', 'confirmed'].includes(rowData.type)) {
                const {createdAt, soldAt, applied} = rowData;
                const createdToday = isToday(rowData.dates);
                date = localeDatetime(rowData.dates, createdToday, !createdToday);
  
                tooltip = `${t("journal.list.cols.dates.create")}${localeDatetime(createdAt)}\n`;
                tooltip += `${t("journal.list.cols.dates.sold")}${localeDatetime(soldAt)}\n`
                tooltip += rowData.dataType === 'lic' ? `${t("journal.list.cols.dates.apply")}${localeDatetime(applied)}`: `${t("journal.list.cols.dates.selfReg")}${localeDatetime(applied)}`
              }

							return <div className='nowrap' title={tooltip}>{date}</div>;
						}
					})
				}
				if (colName === 'col_productName') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("productName");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("productName") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.prod.titleTT")}>{t("licenses.list.cols.prod")}</span>,
						field: "productName",
						width: `${data[1]}%`,
						//cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
						initialEditValue: <span></span>,//Return empty span to avoid displaying emptyValue
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						render: function(rowData){
							return <div className='nowrap'>
								<span title={rowData.productName}>{rowData.productName}</span>
							</div>
						},
					})
				}
				if (colName === 'col_version') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("ver");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("ver") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.ver.titleTT")}>{t("licenses.list.cols.ver")}</span>,
						field: "ver",
						width: `${data[1]}%`,
						//cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
						initialEditValue: <span></span>,//Return empty span to avoid displaying emptyValue
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						render: function(rowData){
							return <div className='nowrap'>
								<span title={rowData.ver}>{rowData.ver}</span>
							</div>
						},
					})
				}
				if (colName === 'col_licenseKey') {
					let hide = false;

					if (data[0] === '') {
						hiddenCols.push("currentKey");						
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("currentKey") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.key.titleTT")}>{t("licenses.list.cols.key")}</span>,
						field: "currentKey",
						disableClick:true,
						grouping: false,
						width: `${data[1]}%`,
						cellStyle: {position: "relative", paddingTop:"20px",paddingBottom:"20px"},
						initialEditValue: "",
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						render: function(rowData){
							const license = rowData.currentKey;
			
							if (license) {
                const currentIndex = rowData.uiid+license;

								return <>
								<div className='nowrap pe-2 mt-0'>
									<span
										className='me-1'
										title={t("licenses.list.cols.key.tt", {key: license})}
										onMouseUp={(e) => handleMouseUp(e, currentIndex)}
										onMouseDown={(e) => handleMouseDown(e, license)}
									>
										<CopySvg
											ref={el => copyIconsRef.current[currentIndex] = el}
											className='isNotClickable m-0 p-0 border-0 bg-transparent'
											width="24"
											fill="var(--nw-blue-color)"
										/>
									</span>
									<span title={license}>{license}</span>
								</div>
								
								</>
							}
							return null
						},
					});
				}
				if (colName === 'col_licensee') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("licensee");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("licensee") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("journal.list.cols.licensee.titleTT")}>{t("journal.list.cols.licensee")}</span>,
						field: "licensee",
						width: `${data[1]}%`,
						//cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
						initialEditValue: <span></span>,
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						render: function(rowData){
              if (rowData.dataType === 'enh') {
                return <div className='nowrap'></div>
              }
              let tooltip = t("selfreg.enterData.name") + ': ';
              tooltip += rowData.licensee?.length > 0 ? rowData.licensee : '-';
              tooltip += `\n${t("selfreg.enterData.addr")}: `;
              let address = rowData.address?.length > 0 ? rowData.address : '';
              address += rowData.address?.length > 0 && rowData.addressAdditional?.length > 0 ? '\n\t' : '';
              address += rowData.addressAdditional?.length > 0 ? `${rowData.addressAdditional}` : '';
              tooltip += address.length > 0 ? address : '-';
              tooltip += `\n${t("selfreg.enterData.zip")}: `;
              tooltip += rowData.addressZip?.length > 0 ? rowData.addressZip : '-';
              tooltip += `\n${t("selfreg.enterData.country.label")}: `;
              tooltip += rowData.country?.length > 0 ? t("gen.country."+rowData.country) : '-';
              const hasName = rowData.licensee?.length > 0;

							return <div className='nowrap'>
								<span className={`${!hasName && "nw-grey-dark" }`} title={tooltip}>{hasName ? rowData.licensee : t("gen.text.unavailable.abbr")}</span>
							</div>
						},
					})
				}
				if (colName === 'col_account') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("account");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("account") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("journal.list.cols.account.titleTT")}>{t("journal.list.cols.account")}</span>,
						field: "account",
						width: `${data[1]}%`,
						//cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
						initialEditValue: <span></span>,
						emptyValue: <AccountNoneSvg width="16" />,
						render: function(rowData){
              let tooltip = `${t("journal.list.cols.account.licTT")}${rowData.accountLicense ?? '-'}\n`;
              tooltip += `${t("journal.list.cols.account.createTT")}${rowData.accountCreate ?? '-'}\n`;
              tooltip += `${t("journal.list.cols.account.tragetMailTT")}${rowData.targetEmail?.length > 0 ? rowData.targetEmail : '-'}`;
              const icon = !rowData.accountSrc ?  <AccountNoneSvg width="16" /> : rowData.accountSrc === 'download' ? <AccountInSvg width="16" /> : <AccountOutSvg width="16" />;

							return <div className='nowrap'>
								<span title={tooltip}>{icon}</span>
							</div>
						},
					})
				}
        if (colName === 'col_packet') {
          let hide = false;
          if (data[0] === '') {
            hiddenCols.push("packet");
            hide = false;
          }
          hide = storageHidddenCols ? storageHidddenCols.includes("packet") : hide;
          cols.push({
            hidden: hide,
						title: <span title={t("journal.list.cols.packet.titleTT")}>{t("journal.list.cols.packet")}</span>,
            field: "packetName",
            width: `${data[1]}%`,
            //cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
            initialEditValue: <span></span>,
            emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
            render: function(rowData){
              const convertedSize = rowData.packetSize ? convertSize(rowData.packetSize) : '-';
              const size = convertedSize?.size ? convertedSize.size + "\u00A0" + t(`gen.var.prefix`).charAt(convertedSize.pow)+t(`gen.var.byte`) : '-';
              const packetName = getFileExtension(rowData.packetName)?.toLowerCase();
              let tooltip = '';
              tooltip = t("journal.list.cols.packet.typTT");
              tooltip += rowData.packetTypI18nKey ? `${t("clear."+rowData.packetTypI18nKey)}\n` : `-\n`;
              tooltip += t("journal.list.cols.packet.nameTT");
              tooltip += packetName ? `${rowData.packetName}\n` : `-\n`;
              tooltip += t("journal.list.cols.packet.sizeTT");
              tooltip += rowData.packetSize ? `${size}` : '-';
              let packet = t("gen.text.unavailable.abbr");

              switch (packetName) {
                case 'zip':
                  packet = <ZipSvg width="20" height="20" fill="var(--nw-blue-color)"/>
                  break;
                case 'iso':
                  packet = <IsoSvg width="24" height="24"  fill="var(--nw-blue-color)"/>
                  break;
                case 'exe':
                  packet = <WinSvg width="18" height="18" fill="var(--nw-blue-color)"/>
                  break;
                case 'bin':
                  packet = <BinSvg width="24" height="24" fill="var(--nw-blue-color)"/>
                  break;
                default:
                  packet = t("gen.text.unavailable.abbr");
              }

              return <div className='nowrap'>
                <span title={tooltip}>{packet}</span>
              </div>
            },
          })
        }
        if (colName === 'col_language') {
          let hide = false;
          if (data[0] === '') {
            hiddenCols.push("language");
            hide = false;
          }
          hide = storageHidddenCols ? storageHidddenCols.includes("language") : hide;
          cols.push({
            hidden: hide,
						title: <span title={t("journal.list.cols.lang.titleTT")}>{t("journal.list.cols.lang")}</span>,
            field: "language",
            width: `${data[1]}%`,
            //cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
            initialEditValue: <span></span>,
            emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
            render: function(rowData){
              if (rowData.language === '') {
                return '';
              }

              return <div className='nowrap'>
                  <img
                    title={t("languages."+rowData.language)}
                    src={FLAGS.svg[rowData.language]}
                    className="flag"
                    height="16" 
                    alt={t("languages."+rowData.language)}
                    loading="lazy"
                  />
              </div>
            },
          })
        }
        if (colName === 'col_server') {
          let hide = false;
          if (data[0] === '') {
            hiddenCols.push("server");
            hide = false;
          }
          hide = storageHidddenCols ? storageHidddenCols.includes("server") : hide;
          cols.push({
            hidden: hide,
						title: <span title={t("journal.list.cols.srv.titleTT")}>{t("journal.list.cols.srv")}</span>,
            field: "server",
            width: `${data[1]}%`,
            //cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
            initialEditValue: <span></span>,
            emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
            render: function(rowData){
              const server = t(`clear.select.opt.srv.${rowData.server}`);
              const serverShortName = server?.match(/SRV-\d+/);

              return <div className='nowrap'>
                  <span title={server}>{serverShortName ? serverShortName[0] : '-'}</span>
              </div>
            },
          })
        }
        if (colName === 'col_licenseType') {
          let hide = false;
          if (data[0] === '') {
            hiddenCols.push("licenseType");
            hide = false;
          }
          hide = storageHidddenCols ? storageHidddenCols.includes("licenseType") : hide;
          cols.push({
            hidden: hide,
						title: <span title={t("journal.list.cols.licType.titleTT")}>{t("journal.list.cols.licType")}</span>,
            field: "licenseType",
            width: `${data[1]}%`,
            //cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
            initialEditValue: <span></span>,
            emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
            render: function(rowData){
              //TODO: Use Icon
              const type = rowData.licenseType?.length > 0 ? t("st."+rowData.licenseType) : '';
              return <div className='nowrap'>
                  <span title={type}>{type}</span>
              </div>
            },
          })
        }
				if (colName === 'col_keyCode') {
					let hide = false;

					if (data[0] === '') {
						hiddenCols.push("keyCode");						
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("keyCode") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("journal.list.cols.keyCode.titleTT")}>{t("journal.list.cols.keyCode")}</span>,
						field: "keyCode",
						disableClick:true,
						grouping: false,
						width: `${data[1]}%`,
						cellStyle: {position: "relative", paddingTop:"20px",paddingBottom:"20px"},
						initialEditValue: "",
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						render: function(rowData){
							const keyCode = rowData.keyCode;
              const tt = rowData.dataType === 'enh' ? rowData.appliedLicense ? rowData.appliedLicense : '' : keyCode;
			
							if (keyCode) {
                const currentIndex = rowData.uiid+keyCode;

								return <div className='nowrap pe-2 mt-0'>
									<span
										className='me-1'
										title={t("licenses.list.cols.key.tt", {key: keyCode})}
										onMouseUp={(e) => handleMouseUp(e, currentIndex)}
										onMouseDown={(e) => handleMouseDown(e, keyCode)}
									>
										<CopySvg
											ref={el => copyIconsRef.current[currentIndex] = el}
											className='isNotClickable m-0 p-0 border-0 bg-transparent'
											width="24"
											fill="var(--nw-blue-color)"
										/>
									</span>
									<span title={tt}>{keyCode}</span>
								</div>
							}
							return null
						},
					});
				}
        if (colName === 'col_country') {
          let hide = false;
          if (data[0] === '') {
            hiddenCols.push("country");
            hide = false;
          }
          hide = storageHidddenCols ? storageHidddenCols.includes("country") : hide;
          cols.push({
            hidden: hide,
						title: <span title={t("journal.list.cols.country.titleTT")}>{t("journal.list.cols.country")}</span>,
            field: "country",
            width: `${data[1]}%`,
            //cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
            initialEditValue: <span></span>,
            emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
            render: function(rowData){
              if (rowData.country === '') {
                return '';
              }

              return <div className='nowrap'>
                  <img
                    title={t("gen.country."+rowData.country)}
                    src={FLAGS.svg[rowData.country?.toLowerCase()]}
                    className="flag"
                    height="16" 
                    alt={t("gen.country."+rowData.country)}
                    loading="lazy"
                  />
              </div>
            },
          })
        }
        if (colName === 'col_email') {
          let hide = false;
          if (data[0] === '') {
            hiddenCols.push("email");
            hide = false;
          }
          hide = storageHidddenCols ? storageHidddenCols.includes("email") : hide;
          cols.push({
            hidden: hide,
						title: <span title={t("journal.list.cols.email.titleTT")}>{t("journal.list.cols.email")}</span>,
            field: "email",
            width: `${data[1]}%`,
            //cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
            initialEditValue: <span></span>,
            emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
            render: function(rowData){
              if (rowData.dataType === 'enh') {
                return '';
              }
              return <div className='nowrap'>
                  <span title={rowData.email}>{rowData.email}</span>
              </div>
            },
          })
        }
        if (colName === 'col_publisher') {
          let hide = false;
          if (data[0] === '') {
            hiddenCols.push("publisher");
            hide = false;
          }
          hide = storageHidddenCols ? storageHidddenCols.includes("publisher") : hide;
          cols.push({
            hidden: hide,
						title: <span title={t("journal.list.cols.publisher.titleTT")}>{t("journal.list.cols.publisher")}</span>,
            field: "publisher",
            width: `${data[1]}%`,
            //cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
            initialEditValue: <span></span>,
            emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
            render: function(rowData){
              return <div className='nowrap'>
                  <span title={rowData.publisher}>{rowData.publisher}</span>
              </div>
            },
          })
        }
        if (colName === 'col_agent') {
          let hide = false;
          if (data[0] === '') {
            hiddenCols.push("agent");
            hide = false;
          }
          hide = storageHidddenCols ? storageHidddenCols.includes("agent") : hide;
          cols.push({
            hidden: hide,
						title: <span title={t("journal.list.cols.agent.titleTT")}>{t("journal.list.cols.agent")}</span>,
            field: "agent",
            width: `${data[1]}%`,
            //cellStyle: {paddingTop:"20px",paddingBottom:"20px"},
            initialEditValue: <span></span>,
            emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
            render: function(rowData){
              return <div className='nowrap'>
                  <span title={rowData.agent}>{rowData.agent}</span>
              </div>
            },
          })
        }
      }
			if (!storageHidddenCols) {
                setHideColsLocalStorage('journal_hide_', props.name, hiddenCols, HIDDEN_COLS_EXP_SECONDS);
			}
			setColumns(cols);
		}
	}, []);
    /* eslint-enable */
  
  const [tableData, setTableData] = useState(props.list); /* eslint-disable-line */
	const count = props.list?.length;
	const paginationSize = (limit, order = 'asc') => {
		let options = [5,10,25,50,100,250];
		if (JOURNAL_LIST_NUMBER>0) {
			options = [...options, JOURNAL_LIST_NUMBER]?.sort((a, b) => a - b);
		}
		const newOptions = [];
		for (const option of options) {
			if ( (order === 'asc' && option < limit)||(order === 'desc' && option > limit)) {
				newOptions.push(option)
			}
		}
		newOptions.push(limit);

		return newOptions;
	}
	const options = count > 0 ? {
		pageSize: JOURNAL_LIST_NUMBER > count ? count : JOURNAL_LIST_NUMBER,
	} : {};
	const tableRef = useRef();
	const [isLoadingDetails, setIsLoadingDetails] = useState({key: '',show:false}); /* eslint-disable-line */

  return (columns &&
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          tableRef={tableRef}
          icons={tableIcons}
          columns={columns} 
          data={tableData?? []}
          style={{padding:0}}
          title={t(`journal.list.tabs.${props.name}.desc`)}
          isLoading={tableData === undefined || isLoadingDetails.show}
          totalCount={count}
          options={{
            ...options,
            tableLayout: "fixed",
            grouping: true,
            draggable: true,
            //showGroupingCount: true,
            paging: true,//tableData?.length>0? true:false,
            paginationType: 'stepped',
            pageSizeOptions: paginationSize(parseInt(count)),
            paginationPosition:"both", //"top" | "bottom"
            //paginationAlignment: "flex-end", //"flex-start" | "center"
            search: true,
            actionsColumnIndex: -1,
            headerStyle: {fontFamily: "roboto-bold", fontSize: "1rem", backgroundColor: "var(--nw-blue-light-color)", overflow: "hidden", textOverflow: "... ", whiteSpace: "nowrap"},
            emptyRowsWhenPaging:false,
            padding: "dense",//'default',
            columnsButton: true,
            //detailPanelType: "single",//only one panel will be open at any given time.
            //actionsCellStyle: {width: "10%",paddingLeft: 0,paddingRight: 0},
            //Change color of row
            /*rowStyle: (rowData) => {
              let style = {backgroundColor: '#fff'};
              if (rowData.backgroundColor) {
                style = {backgroundColor: rowData.backgroundColor};
              }
              return style;
            },*/
          }}
          
          components={{
            Action: propsAction => {
              if (propsAction.action?.type !== "search") {
                return <MTableAction {...propsAction} />;
              }
              return <SearchInput tooltipList={props.searchInCols} inputId={searchInputId} text={props.searchText} selectAll={!!props.searchText} reloadTab={props.reloadTab}/>;
            },
            Toolbar: (props) => {
              return <div className="nw-toolBar" style={{backgroundColor: "var(--nw-blue-light-color)"}}>
                  <MTableToolbar {...props} />
                </div>;
            }
          }}
          
          onChangeColumnHidden={(column, hidden) =>{
            column.hidden = hidden;
            const storageHidddenCols = getNotExpiredLocalStorage('journal_hide_', props.name);

            if (storageHidddenCols) {
              let hiddenCols = JSON.parse(storageHidddenCols);

              if (hidden && !hiddenCols?.includes(column.field)) {
                hiddenCols.push(column.field)
              }else{
                let filetred = hiddenCols?.filter(function(value){ 
                  return value !== column.field;
                });
                hiddenCols = filetred;
              }
              setHideColsLocalStorage('journal_hide_', props.name, hiddenCols, HIDDEN_COLS_EXP_SECONDS);
            }
          }}
          actions={[
            {
              isFreeAction: true,
              icon: () => <ChangeLimit count={count} name={props.name} reloadTab={props.reloadTab} list={[5, 10, 25, 50, 100, 250]} optsuffixId='selectLimit' searchTextId={searchInputId}/>
            },
            /*{
              isFreeAction: true,
              icon: () =>
              <OverlayTrigger placement="left" trigger={["hover", "focus"]} overlay={<Tooltip className="nw-tooltip-dark">{t("nw.list.opt.load.tt")}</Tooltip>}>
                <div className="dropdown m-0 p-0" style={{top: "-1.4px"}} >
                  <span className="dropdown-toggle p-1 m-0 badge nw-bg-blue border border-0 rounded-1  nw-opacity-6" data-bs-toggle="dropdown" aria-expanded="true">
                    {count}
                  </span >
                  <ul className="dropdown-menu">
                  {[5, 10, 25, 50, 100, 250].map((option) => { //add 0 to array to make all active
                    return (
                      <li key={uuidv4()}>
                        <span
                          id={`selectLimit${option}`}
                          style={option === count ? {backgroundColor: "var(--nw-blue-light-color)"} : {}}
                          className="dropdown-item"
                          data-limit={option}
                          onClick={(e) => {
                            const data = {name:props.name, limit:option}
                            const searchValue = document.getElementById(searchInputId)?.value;

                            if(searchValue){
                              data.search = searchValue;
                            }
                            props.reloadTab(e,data);
                          }}
                          //title={t("nw.list.opt.load.tt.open", {limit: option})}
                          title={t("nw.list.opt.load.tt.open", {limit: option === 0 ? t("nw.list.opt.load.tt.openall"): option})}
                        >
                          {option === 0 ? t("nw.list.opt.load.tt.openall") : option}
                        </span>
                      </li>
                    )
                  })}
                  </ul>
                </div>
              </OverlayTrigger>
              ,
            },*/
            //Action element is added in components.Action (MaterialTable props)
            props.searchInCols?.length > 0 ?
            {
              isFreeAction: true,
              type: "search",
            } : null,
          ]}
          localization={{
            header: {//style={{marginTop: "18px"}}
              actions: t("licenses.list.cols.action")
            },
            toolbar:{
              addRemoveColumns: t("nw.list.opt.col"),
              showColumnsTitle: t("nw.list.opt.col.tt"),
              searchPlaceholder: t("nw.list.opt.filter"),
              searchTooltip: t("nw.list.opt.filter.tt"),
            },
            grouping: {
              placeholder: t("nw.list.opt.group.hint"),
              groupedBy: t("nw.list.opt.group.by")
            },
            body: {
              addTooltip: t("licenses.list.opt.add.tt"),
              editTooltip: t("licenses.list.cols.action.tt.edit"),
              editRow:{
                saveTooltip: t("licenses.list.cols.action.tt.save"),
                cancelTooltip: t("licenses.list.cols.action.tt.cancel")
              },
              emptyDataSourceMessage: tableData ? 
                t("nw.list.err.empty")
                : 
                <table style={{width:"100%", margin: "15px 0 15px 0"}}>
                  <tbody><tr><td style={{height: "300px"}}></td></tr></tbody>
                </table>
              ,
            },
            pagination:{
              labelRowsPerPage: screenSize.width > 650 ? t("nw.paging.rows.label"): "",
              labelRows: t("nw.paging.rows"),
              //labelRowsSelect: t("nw.paging.rows.label"),
              //labelDisplayedRows: ({from, to, count}) => `${from}-${to} ${t("paging.of")} ${count}`,
              labelDisplayedRows: "{from}-{to} / {count}",
              firstAriaLabel: t("nw.paging.first"),
              firstTooltip: t("nw.paging.first"),
              previousAriaLabel: t("nw.paging.prev"),
              previousTooltip: t("nw.paging.prev"),
              nextAriaLabel: t("nw.paging.next"),
              nextTooltip: t("nw.paging.next"),
              lastAriaLabel: t("nw.paging.last"),
              lastTooltip: t("nw.paging.last"),
            }
          }}
        />
			</MuiThemeProvider>
  )
})

export default JournalList;

/*const isLessThan24HoursAway = (dateString) => {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentDate - givenDate;

  // Define the maximum difference allowed (24 hours in milliseconds)
  const maxDifference = 24 * 60 * 60 * 1000;

  // Check if the difference is not greater than 24 hours
  return timeDifference < maxDifference;

}*/

const isToday = (dateTime) => {
  const date = new Date(dateTime);
  const currentDate = new Date();

  return date.toISOString().slice(0, 10) === currentDate.toISOString().slice(0, 10);
}

const getFileExtension = (fileName) => {
  if (fileName) {
    const parts = fileName.split('.');
    const extension = parts[parts.length - 1];
    
    return extension?.toLowerCase();
  }
  return null;
}
