import { useState, useRef } from "react";
import LicenseSalesAPI from 'Services/LicenseSalesAPI';
import { useTranslation } from "react-i18next";
import Spinner from 'Components/Loading/Spinner';
import {DOMAIN_NAME} from "config/nw-api";
import {useFlashContext}  from 'Contexts/FlashContext';

export default function ConfirmModal(props) {
    const { t } = useTranslation();
	//const user = useSelector((state) => state.user);
    const {setFlash} = useFlashContext();
    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState(false);
    const modalRef = useRef(null);
    const closeRef = useRef(null);
    const [errors, setErrors] = useState({api: ""});

	const handleAgree = (e) => {
        handleConfirm(e);
  	};

  	const handleDisagree = (e) => {
		closeRef.current?.click();
  	};

    const handleConfirm = async e => {
        setIsLoading(true);
        e.preventDefault();
        if (['retracted','rejected', 'accepted'].includes(props.data?.actionss)) {
            setIsLoading(true);
            LicenseSalesAPI.action({
                id:props.data?.rowData?.id,
                action:props.data?.action,
                callbackDomain: DOMAIN_NAME+t('link.licenses.url'),
            }).then((res)=>{
                setInfo(true);
            }).catch((err)=>{
                if(!err.response || err.response.data.status === 500){
                    console.log(err.message);
                    setErrors({api:"err.500"});
                }else{
                    const msg = err?.response?.data?.msg?.toLowerCase();
                    if(msg === 'sales not found'){
                        setErrors({api: "licenses.list.opt.sell.confirm.err.notFound"});
                    }else if (msg === 'invalid licenses'){
                        setErrors({api: "licenses.list.opt.sell.form.err.invalid.lic"});
                        console.log(err?.response?.data?.invalidKeys);
                        setFlash({
                            stop:true,
                            alert: "warning",
                            message: t("licenses.list.opt.sell.form.err.invalid.lic"),
                            list: err?.response?.data?.invalidKeys
                        });
                    }else{
                        setErrors({api:"err.unkown"});
                    }
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
    };

    return (
        <div ref={modalRef} className="modal fade" id="confirmModal" tabIndex="-1" aria-labelledby="confirmModalLabel" aria-hidden="false" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog vertical-center">
                <div className="modal-content bm-card">
                    <h2 className="bm-card-header_small" id="confirmModalLabel">
                        {t(props.data?.modal?.header)}
                    </h2>
                    <a
                        ref={closeRef}
                        disabled={isLoading}
                        href="/#"
                        className="bm-close-x"
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={()=>{setInfo(false); setErrors({api: ""})}}
                    >
                        &#10005;
                    </a>
                    <h3 className="bm-sep-1 mt-0 text-start">{t(props.data?.modal?.title)}</h3>
                    <div  className="mb-3">
                    <p className="fn-txt text-start text-black" dangerouslySetInnerHTML={{__html: t(props.data?.modal?.body)}} />

                        {errors.api && <p className="fn-smtxt invalid-feedback d-block">{t(errors.api)}</p>}

                        <div className="row">
                            <div className="col-6">
                                <button onClick={handleDisagree} className="bm-btn-mute bm-btn-card-prev px-3">
                                    {t(props.data?.modal?.no)}
                                </button>
                            </div>
                            <div className="col-6">
                                <button onClick={handleAgree} disabled={info} className={`${info ? "btn-disabled-home" : "bm-btn-blue"} mb-2 ms-5 bm-btn-card-next d-flex justify-content-center`}>
                                    {isLoading && 
                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                    }
                                    <span className={isLoading ? "invisible" : ""}>{t(props.data?.modal?.yes)}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
