import {useState, useRef} from "react";
import Field from "Components/Forms/Field";
import PasswordField from "Components/Forms/PasswordField";
import {useTranslation} from "react-i18next";
import {DOMAIN_NAME} from "config/nw-api";
import Spinner from "Components/Loading/Spinner";
import {useDispatch, useSelector} from "react-redux";
import {loginRequest, logoutSuccess} from "Reduxx/Reducers/AuthSlice";
import {loginUser} from "Reduxx/Reducers/Thunks/AuthThunk";
import useFocusModal from "Hook/useFocusModal";
import {checkEmail} from "Components/Tools/Text";
import useTabCommunication from "Hook/useTabCommunication";

export default function SignInModal() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth?.isLoading);
    const errorAccount = useSelector((state) => state.auth?.error);
    const {sendTabsData} = useTabCommunication('NAVBAR');
    
    const [credentials, setCredentials] = useState({
        email: localStorage.getItem("lastLoggedEmail") ?? '',
        password: ""
    });
    const [inputErrors, setInputErrors] = useState({
        email: "",
        password: "",
        account: ""
    });
    const closeForm = useRef(null);
    const signInModal = useRef(null);
    const emailInput = useRef(null);
    useFocusModal(signInModal, emailInput);

    // Manage input fields
    const handleChange = ({currentTarget}) => {
        const {value, name} = currentTarget;
        setCredentials({...credentials, [name]: value});
        setInputErrors({});
    };

    // Manage submit
    const handleSubmit = async event => {
        event.preventDefault();
        dispatch(loginRequest());
    
        const currentErr = {}
        
        if (!credentials.email) {
            currentErr.email = "nw.input.err.required";
        } else if (!checkEmail(credentials.email)) {
            currentErr.email = "nw.input.mail.err.invalid";
        }
        if(!credentials.password) {
            currentErr.password = "nw.input.err.required";
        }
        
        setInputErrors(currentErr);

        if (currentErr?.constructor === Object && Object.keys(currentErr)?.length !== 0) {
            dispatch(logoutSuccess());

            return;
        }

        credentials.urlCallback = {"confirm_registration":DOMAIN_NAME + t("link.signupConfirm")};

        dispatch(loginUser({credentials, closeForm: closeForm.current}))
            .then((res)=>{
                localStorage.setItem("lastLoggedEmail", credentials.email);
                if (typeof window !== 'undefined') {
                    sendTabsData('login', sessionStorage?.getItem('tabId'));
                }
            })
    };

    return (
        <>
            <div ref={signInModal} className="modal fade" id="signInModal" tabIndex="-1" aria-labelledby="signInModalLabel" aria-hidden="true">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">
                        <h2 className="bm-card-header_small" id="signInModalLabel">
                            {t("user.signIn")}
                        </h2>
                        <a href="/#" ref={closeForm} className="bm-close-x" id="closeSignIn" type="button" data-bs-dismiss="modal" aria-label="Close">&#10005;</a>
                        <form onSubmit={handleSubmit} className="sign-in-form fn-smtxt">
                            {errorAccount && <p className="fn-smtxt invalid-feedback d-block" dangerouslySetInnerHTML={{__html: t(errorAccount.msg, errorAccount.args)}}/>}
                            <Field
                                refInput={emailInput}
                                label={t("nw.input.mail")}
                                id="signInEmail"
                                name="email"
                                placeholder={t("nw.input.mail.hint")}
                                value={credentials.email}
                                onChange={handleChange}
                                disabled={isLoading && "disabled"}
                                type="email"
                                error={t(inputErrors.email)}
                            />
                            <PasswordField
                                label={t("nw.input.pwd")}
                                id="signInPassword"
                                name="password"
                                placeholder={t("nw.input.pwd.hint")}
                                value={credentials.password}
                                onChange={handleChange}
                                disabled={isLoading && "disabled"}
                                attrs={{autoComplete:"current-password"}}
                                error={t(inputErrors.password)}
                            />
                            <div className="row">
                                <div className="col-7 align-self-center">
                                    <a className="bm-p-mute" href="/#"  data-bs-toggle="modal" data-bs-target="#pwdForgotModal">
                                        {t("user.signIn.txt")}
                                    </a>
                                </div>
                                <div className="col-5">
                                    <button type="submit" disabled={isLoading && "disabled"} className="bm-btn-blue float-end d-flex justify-content-center">
                                        {isLoading && 
                                            <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                        }
                                        
                                        <span className={isLoading ? "invisible" : ""}>{t("user.signIn.btn")}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
