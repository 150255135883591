import { useRef, useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'Reduxx/Reducers/Thunks/AuthThunk';
import {useFlashContext}  from 'Contexts/FlashContext';
import { useTranslation } from "react-i18next";
import {v4 as uuidv4} from 'uuid';


export default function Flash() {
    const { t } = useTranslation();
	const navigate = useNavigate();
    const dispatch = useDispatch();
    const closeBtn = useRef(null);
    const {flash, setFlash } = useFlashContext();
    
    const redirect = (e) => {
        e.preventDefault();
        dispatch(logoutUser());
        if (flash?.redirect.path === window.location.href) {
            window.location.reload();
        }else{
            navigate(flash?.redirect.path);
        }
    };
    
    /* eslint-disable */
    useEffect(()=>{
        if (flash?.alert && !flash.redirect && flash.message !== t("err.email") && !flash.stop) {
            const timer = setTimeout(() => {
                setFlash(null);
			}, 5000);

      		return () => clearTimeout(timer);
        }
        //remove invalid url from browser input
        /*if (data.message === t("err.404", {url: window.location.href})) {
            console.log(data.message === t("err.404", {url: window.location.href}));
            navigate('/');
        }*/
    }, [flash]);
    /* eslint-enable */

    const hideHandle = () => {
        setFlash(null);
    }

    if (!flash) {
        return null;
    }

    return (
        <div style={{...flash.style?.div, zIndex: 2}} className={`nw-flash text-center alert alert-dismissible fade show rounded-0 alert-${flash.alert}`} role="alert">
            <span dangerouslySetInnerHTML={{__html: flash.message}} />
            <br />
            {flash?.redirect && 
                <a href="/#" onClick={redirect}>{flash.redirect?.name}</a>
            }
            {flash?.list?.length > 0 &&
                <div className="div-input-wrapper mt-3">
                    <div style={{maxHeight: '110px', overflow: 'auto', backgroundColor: "var(--nw-red-light-color)", borderColor: "var(--nw-grey-color)", color:"var(--nw-grey-color)"}} className='div-input'>
                        {flash?.list?.map((item) => {
                            return <div title={item} className="text-start nowrap" key={uuidv4()}>
                                {item}
                            </div>
                        })}
                    </div>
                </div>
            }
            <button ref={closeBtn} onClick={hideHandle} type="button" className={`${flash.alert === "warning" ? "warnAlert " :""} btn-close btnAlert`} data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    )  
}