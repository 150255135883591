import { useState, useRef, useEffect } from "react";
import LicenseSalesAPI from 'Services/LicenseSalesAPI';
import Field from "Components/Forms/Field";
import { useTranslation } from "react-i18next";
import Spinner from 'Components/Loading/Spinner';
import useFocusModal from "Hook/useFocusModal";
import {DOMAIN_NAME} from "config/nw-api";
import {checkEmail} from "Components/Tools/Text";
import { useSelector } from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import { handleCheckboxDivClick } from 'Services/utils';
import {ReactComponent as QuestionSVG}  from "assets/images/icon/question.svg";
import {useFlashContext}  from 'Contexts/FlashContext';

export default function SoldToResellerModal(props) {
    const { t } = useTranslation();
	const user = useSelector((state) => state.user);
    const {setFlash} = useFlashContext();
    const modalRef = useRef(null);
    const closeRef = useRef(null);
    const emailInput = useRef(null);
    useFocusModal(modalRef, emailInput);
    const [email, setEmail] = useState({value: "", err:"", isValid: false});
    const [buyer, setBuyer] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState(null);

    const handleChangeEmail = ({currentTarget}) => {
        const {value} = currentTarget;
        setEmail({...email, value, err:""});
    }

    const onCloseModal = () => {
        setInfo(null);
        setEmail({value: "", err:""});
        setBuyer(null);
        setErrors({api: ""});
        setData({
            sellRef: {value: "", err: "", length: 64},
            buyRef: {value: "", err: "", length: 64},
            hops: {value: "", err: "", max: 0, first: 0},
            newAgent: true
        });
        info && props.reloadTab();
    }

    // Manage form submission
    const handleCheckEmail = async e => {
        e.preventDefault();
        setIsLoading(true);
        setEmail({...email, err: ''});
        
        if (!email.value) {
            setEmail({...email, err: "nw.input.err.required"});
            setIsLoading(false);
        } else if (!checkEmail(email.value)) {
            setEmail({...email, err: "nw.input.mail.err.invalid"});
            setIsLoading(false);
        }else if(user.email?.trim() === email.value?.trim()){
            setEmail({...email, err: "licenses.list.opt.sell.form.err.self"});
            setIsLoading(false);
        } else{
            LicenseSalesAPI.checkBuyer({email:email.value, saleType:'reseller'})
            .then((res)=>{
                setEmail({...email, isValid: true});
                setBuyer(res.data);
            }).catch((err)=>{
                setEmail({...email, err: 'licenses.list.opt.sell.form.err.notFound'})
            }).finally(() => {
                setIsLoading(false);
            })
        }
    };
    const [data, setData] = useState({
        sellRef: {value: "", err: "", length: 64},
        buyRef: {value: "", err: "", length: 64},
        hops: {value: "", err: "", max: 0, first: 0},
        newAgent: true
    });

	/* eslint-disable */
    useEffect(() => {
        let maxhops = 1000;
        props.data?.licenses?.forEach(lic => {
            if (lic.hops<maxhops) {
                maxhops = lic.hops;
            }
        });
        setData({...data, hops: {...data.hops, value: maxhops, first: maxhops}});
    }, [props.data.licenses]);

    /* eslint-enable */
    
    const handleChangeData = (e) => {
        const {name, value} = e.currentTarget;
        if (name === "newAgent") {
            setData({...data, newAgent: !data.newAgent});
        }else{
            setData({...data, [name]: {...data[name], value}});
        }
    };
    const [errors, setErrors] = useState({
        api: ""
    });

    const handleSoldConfirm = async e => {
        e.preventDefault();
        setErrors({api:""});
        setData({
            ...data,
            sellRef: {...data.sellRef, err: ""},
            buyRef: {...data.buyRef, err: ""},
        });
        let currentErrors = null;

        if (!currentErrors && email.value && props.data?.licenses?.length>0 && email.value) {
            setIsLoading(true);
            LicenseSalesAPI.sellToReseller({
                licenses: props.data?.licenses?.map(license => license.currentKey),
                callbackDomain: DOMAIN_NAME+t('link.licenses.url'),
                buyerEmail: email.value,
                sellRef: data.sellRef?.value,
                buyRef: data.buyRef?.value,
                newAgent: data.newAgent,
                hops: data.hops.value
            }).then((res)=>{
                setInfo("licenses.list.opt.sell.form.reseller.info");
            }).catch((err)=>{
                console.log(err);
                if(!err.response || err.response.data.status === 500){
                    setErrors({api:"err.500"});
                } else{
                    const msg = err?.response?.data?.msg?.toLowerCase();
                    if(msg === 'invalid buyer'){
                        setErrors({api: "licenses.list.opt.sell.form.err.invalid.reseller"});
                    }else if (msg === 'invalid licenses'){
                        setErrors({api: "licenses.list.opt.sell.form.err.invalid.lic"});
                        console.log(err?.response?.data?.invalidKeys);
                        setFlash({
                            stop:true,
                            alert: "warning",
                            message: t("licenses.list.opt.sell.form.err.invalid.lic"),
                            list: err?.response?.data?.invalidKeys
                        });
                    }else{
                        setErrors({api:"err.unkown"});
                    }
                } 
            }).finally(() => {
                setIsLoading(false);
            })
        }
    };

    return (
        <>            
            <div ref={modalRef} className="modal fade" id="resellerModal" tabIndex="-1" aria-labelledby="resellerModalLabel" aria-hidden="false" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">
                        <h2 className="bm-card-header_small" id="resellerModalLabel">
                            {t("licenses.list.opt.sell.form.reseller")}
                        </h2>
                        <a
                            ref={closeRef}
                            disabled={isLoading}
                            href="/#"
                            className="bm-close-x"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={onCloseModal}
                        >
                            &#10005;
                        </a>
                        <h3 className="bm-sep-1 mt-0 text-start">{t("licenses.list.opt.sell.form.prod")}</h3>
                        <div className="mb-3 text-start">{t("licenses.list.opt.sell.form.prod.txt")}</div>
                        <div className="div-input-wrapper">
                            <div style={{maxHeight: '140px', overflow: 'auto', backgroundColor: "var(--nw-grey-light-color)", borderColor: "var(--nw-grey-color)", color:"var(--nw-grey-color)"}} className='div-input bg-grey'>
                                {props.data?.products?.map((product) => {
                                    const productName = t("licenses.list.opt.sell.form.prod.count", {count:product.countKeys, name: product.name});
                                    return <div title={productName} className="text-start nowrap" key={uuidv4()}>
                                        {productName}
                                    </div>
                                })}
                            </div>
                            <label style={{backgroundColor: "#fff", color:"var(--nw-grey-color)"}}>{t("licenses.list.opt.sell.form.prod.label", {products: props.data?.products?.length, keys: props.data?.licenses?.length})}</label>
                        </div>

                        <h3 className="bm-sep-1 mt-0 text-start">{t("licenses.list.opt.sell.form.reseller.buyer")}</h3>
                        <div className="mb-3 text-start">{t("licenses.list.opt.sell.form.reseller.buyer.txt")}</div>

                        <form onSubmit={handleCheckEmail} autoComplete="on">
                            <Field
                                refInput={emailInput}
                                label={t("nw.input.mail")}
                                id="resellerEmail"
                                name="resellerEmail"
                                placeholder={t("nw.input.mail.hint")}
                                value={email.value}
                                onChange={handleChangeEmail}
                                disabled={isLoading || email.isValid}
                                type="email"
                                error={t(email.err)}
                            />
                            {email.isValid && buyer &&
                                <div className="text-start">
                                    {buyer.name && <Field
                                        label={t("profile.data.name")}
                                        id="usrName"
                                        name="usrName"
                                        placeholder={t("profile.data.name.hint")}
                                        value={buyer.name}
                                        disabled
                                        type="text"
                                    />}
                                    {buyer.publicName && <Field
                                        label={t("profile.data.publicName")}
                                        id="usrName"
                                        name="usrName"
                                        placeholder={t("profile.data.publicName.hint")}
                                        value={buyer.publicName}
                                        disabled
                                        type="text"
                                    />}
                                </div>
                            }
                            {!email.isValid && <div className="bm-nav-card-bottom">
                                <button type="submit" disabled={isLoading} className="bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                                    {isLoading &&
                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                    }
                                    <span className={isLoading ? "invisible" : ""}>{t("licenses.list.opt.sell.form.btn.check")}</span>
                                </button>
                            </div>}
                        </form>

                        {email.isValid && buyer && <>
                            <h3 className="bm-sep-1 mt-3 text-start">{t("licenses.list.opt.sell.form.reseller.data")}</h3>
                            <div className="mb-3 text-start">{t("licenses.list.opt.sell.form.reseller.data.txt")}</div>
                            <form className="text-start" onSubmit={handleSoldConfirm} autoComplete="on">
                                {buyer.newAgent &&
                                    <div className="d-flex align-items-center mb-3" onClick={handleCheckboxDivClick}>
                                        <input disabled={isLoading || info} className="form-check-input" checked={data.noUpdate} onChange={handleChangeData} type="checkbox" id="newAgent" name="newAgent" value={data.newAgent}/>
            
                                        <label className="form-check-label" htmlFor="newAgent">
                                            <span dangerouslySetInnerHTML={{__html: t("licenses.list.opt.sell.form.reseller.data.newAgent")}}/>
                                        </label>
                                    </div>
                                }
                                
                                {/*<Field
                                    label={t("licenses.list.opt.sell.form.reseller.data.hops")}
                                    id="hops"
                                    name="hops"
                                    placeholder={t("licenses.list.opt.sell.form.reseller.data.hops.hint")}
                                    value={data.hops.value}
                                    onChange={handleChangeData}
                                    disabled={isLoading || info}
                                    type="number"
                                    error={t(data.hops.err)}
                                    attrs={user.functions?.includes('sellerB2B') && {max: 23}}
                                />*/}

                               <div className="bm-input-wrapper">
                                    <input 
                                        name="hops"
                                        id="hops"
                                        type="number"
                                        className={`
                                            ${data.hops.err ? "bm-input-is-invalid" : ""}
                                            ${isLoading ? "disabledElt" : "bg-white"} 
                                            bm-input nowrap
                                        `}
                                        value={data.hops?.value}
                                        disabled={isLoading}
                                        onChange={handleChangeData}
                                        max={user.functions?.includes('publisher') ? 1000 : data.hops?.first }
                                        min="0"
                                    />
                                    <label style={{color: "var(--nw-grey-color)"}}>{t("licenses.list.opt.sell.form.reseller.data.hops")}</label>
                                    <span
                                        title={t("licenses.list.opt.sell.form.reseller.data.hops.tt")}
                                        style={{
                                            position: "absolute",
                                            right: "40px",
                                            top: "11.5px",
                                            cursor: "auto",
                                        }}
                                    >
                                        <QuestionSVG width="15px" height="15px" fill="var(--nw-grey-color)"/>
                                    </span>
                                    {data.hops?.err && <p className="fn-smtxt input-error ps-1" dangerouslySetInnerHTML={{__html: t(t(data.hops.err))}}/>}
                                </div>
                                
                                <Field
                                    label={t("licenses.list.opt.sell.form.reseller.data.sellRef")}
                                    id="sellRef"
                                    name="sellRef"
                                    placeholder={t("licenses.list.opt.sell.form.reseller.data.sellRef.hint")}
                                    value={data.sellRef.value}
                                    onChange={handleChangeData}
                                    disabled={isLoading || info}
                                    type="text"
                                    attrs={{maxLength: 64}}
                                    error={t(data.sellRef.err)}
                                />
                                <Field
                                    label={t("licenses.list.opt.sell.form.reseller.data.buyRef")}
                                    id="buyRef"
                                    name="buyRef"
                                    placeholder={t("licenses.list.opt.sell.form.reseller.data.buyRef.hint")}
                                    value={data.buyRef.value}
                                    onChange={handleChangeData}
                                    disabled={isLoading || info}
                                    attrs={{maxLength: 64}}
                                    type="text"
                                    error={t(data.buyRef.err)}
                                />

                                {errors.api && <p className="fn-smtxt invalid-feedback d-block">{t(errors.api)}</p>}

                                <div className="bm-nav-card-bottom">
                                    {!info && 
                                        <button  type="submit" disabled={isLoading} className="mb-2 ms-5 bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                                        {isLoading &&  
                                            <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                        }
                                        <span className={isLoading ? "invisible" : ""}>{t("licenses.list.opt.sell.form.btn.confirm")}</span>
                                        </button>
                                    }
                                </div>
                            </form>
                            {info && <>
                                <div className="text-start">
                                    <div dangerouslySetInnerHTML={{__html: t(info)}}/>
                                </div>
                                <div className="bm-nav-card-bottom">
                                    <button 
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            closeRef.current?.click();
                                        }}
                                        className="mb-2 ms-5 bm-btn-mute bm-btn-card-next"
                                    >
                                        {t("gen.button.close")}
                                    </button>
                                </div>
                            </>}
                        </>}
                    </div>
                </div>
            </div>
        </>
    )
}