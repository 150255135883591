import "./index.css";
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";

export const Spinner = (props) => {

    const svg = <svg 
            className="spinner"
            height={props?.iconStyle?.radius || "250px"}
            viewBox="0 0 72 72"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                className="path"
                stroke={props?.iconStyle?.borderColor || "#ffffff"}
                fill="none"
                strokeWidth={(props.for === "btn") ? 14 : 4}
                cx="36" cy="36" r="28"
            ></circle>
    </svg>

    if(props.as === "span"){
        return (
            <span className={props.className}>
                {svg}
            </span>
        )
    }
    return (
        <div className={`${props.className || ""} d-flex justify-content-center`} style={{ position: "absolute"}}>
            {svg}
        </div>
    )
}

export const LoadingError = (props) => {
    const { t } = useTranslation();

    return (
        <div className="overlay d-flex justify-content-center" style={{ position: "absolute"}}>
            <div className="text-center" style={{backgroundColor: "transparent"}}>
                <div className="vertical-center">
                    <div>
                        <h2 className="bm-card-header_small text-white">{props.h2}</h2>
                        <p className="text-white mb-5" dangerouslySetInnerHTML={{__html: props.txt}}/>
                        {props.reload ? 
                            <button onClick={props.reload} className="nw-overlay-btn-grey">{props.btn}</button>
                        :                        
                            <Link to={props.link ?? t("link.root.url")}>
                                <button className="nw-overlay-btn-grey">{props.btn ?? t("nw.btn.goto")}</button>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Spinner;
