import {v4 as uuidv4} from 'uuid';
import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import USERAPI from "Services/UsersAPI";
import ACCESSAPI from "Services/AccessAPI";
import Access from "./Access";
import { useDispatch, useSelector } from 'react-redux';
import {switchUser} from "Reduxx/Reducers/Thunks/AuthThunk";
import Spinner, {LoadingError} from "Components/Loading/Spinner";
import Field from 'Components/Forms/Field';
import MultiSelect from 'Components/Forms/MultiSelect';
import ManageTeams from "./ManageTeams";
import { sortArray } from 'Components/Tools/Array';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import {ReactComponent as CalendarSVG} from "assets/images/icon/calendar.svg";
import { i18nLocale } from "Components/Tools/DateTime";
import {useNavigate} from "react-router-dom";
import {useFlashContext}  from 'Contexts/FlashContext';


const statusI18NKeys = ['off', 'on', 'lock'];
const DEFAULT_SELECT = {id:"default"};
const DEFAULT_TREE_PLUGINS = ["search","types","unique","changed"];
const ACCOUNT_TYPES = ['private', 'business', 'corporation', 'government', 'educational', 'military'];

export default function ManageAccounts() {
	const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const {impersonate} = useSelector((state) => state.auth);

    document.title = t("users.title")+ " – nanoways.com";
	
	const statusSearchOptions = [
		{value: 'on', label: <span title={t(`users.opt.sel.status.on.tt`)}>{t(`users.opt.sel.status.on`)}</span>, color: "var(--nw-green-dark-color)", bgColor: "var(--nw-green-light-color)"},
		{value: 'off', label: <span title={t(`users.opt.sel.status.off.tt`)}>{t(`users.opt.sel.status.off`)}</span>, color: "var(--nw-orange-dark-color)", bgColor: "var(--nw-orange-light-color)"},
		{value: 'lock', label: <span title={t(`users.opt.sel.status.lock.tt`)}>{t(`users.opt.sel.status.lock`)}</span>, color: "var(--nw-red-dark-color)", bgColor: "var(--nw-red-light-color)"}
	];
	
	const [users, setUsers] = useState(null);
    const {setFlash} = useFlashContext();
	const [selectedUser, setSelectedUser] = useState(DEFAULT_SELECT);
	const [adhoc, setAdhoc] = useState({checked: false, date: '', disabled: false});
	const [disableSelectStatus, setDisableSelectStatus] = useState(false);
	const [access, setAccess] = useState(null);
	const hasPageAccess = user.roles?.includes('ROLE_89');//users.acc
	const [toggle, setToggle] = useState(false);
	const [filter, setFilter] = useState({
		txt: "",
		status: [statusSearchOptions[0]],
	});
	const [errors, setErrors] = useState(null);

    const [reload, setReload] = useState(false);
    const [serverError, setServerError] = useState(false);
    const reloadPage = () => {
        setServerError(false);
        setReload(!reload);
    }
    const [teams, setTeams] = useState(null);
/*DateAdhoc*/
	const locale = i18nLocale(i18n.language);
    const [focusAdhocDate, setFocusAdhocDate] = useState(false);
    const lastAdhocDate = useRef('');
/*DateAdhoc*/
    /* eslint-disable */
	useEffect(() => {
		const date = findAdhocDate(selectedUser.rasAdhocExp);
		if (selectedUser.id !== DEFAULT_SELECT.id && selectedUser?.rasAdhocExp !== date) {
			setSelectedUser({...selectedUser, rasAdhocExp: date});
			setAdhoc({...adhoc, checked: selectedUser.rasAdhocExp? true:false, date: date});
			lastAdhocDate.current = date;
		}
	}, [selectedUser])

	useEffect(() => {
		let isMounted = true;
		if (!access && isMounted && hasPageAccess) {
			ACCESSAPI.getAllForUsers()
			.then((res)=>{
				setAccess(res.data?.accesses);
			}).catch((err)=>{
				console.log('response: ', err.response);
				console.log('err: ', err);
				if(err.message?.includes('Network Error')){
					setServerError(true);
				} else {
                    setFlash({alert:"danger", message: t("err.unknown")});                    
                }
			})
		}

		return () => { isMounted = false }; // cleanup toggles value, if unmounted
	}, [reload])
    /* eslint-enable */

	const handleChangeStatus = ({currentTarget}) => {
		setDisableSelectStatus(true);
		setFlash(null);
		const oldStatus = selectedUser.status;
		setSelectedUser({...selectedUser, status: Number(currentTarget.value)});
		const newStatus = parseInt(currentTarget.value);
		USERAPI.usersUpdateData(selectedUser.id, {status: newStatus})
			.then((res)=>{
				// Update user in the users List
				setUsers(users.map(user => {
					if (user.id === selectedUser.id) {
						user.status = newStatus;
					}
					return user;
				}))
			}).catch((err)=>{
				console.log(err);
				setSelectedUser({...selectedUser, status: oldStatus});
				setFlash({alert:"danger", message: t("err.500")});
			}).finally(() => {
				setDisableSelectStatus(false);
			})
	}
	const handleChangeAccountType = ({currentTarget}) => {
		setToggle(true);
		setFlash(null);
		const oldType = selectedUser.type;
		const newType = currentTarget.value;
		setSelectedUser({...selectedUser, type: newType});
		USERAPI.usersUpdateData(selectedUser.id, {type: newType})
			.then((res)=>{
				// Update user in the users List
				setUsers(users.map(user => {
					if (user.id === selectedUser.id) {
						user.type = newType;
					}
					return user;
				}))
			}).catch((err)=>{
				console.log(err);
				setSelectedUser({...selectedUser, status: oldType});
				setFlash({alert:"danger", message: t("err.500")});
			}).finally(() => {
				setToggle(false);
			})
	}

    //Handle Account functions
	const FunctionOptions = [
		{value: 'customer', label: <span title={t(`users.conf.func.customer.tt`)}>{t(`users.conf.func.customer`)}</span>, bgColor: "var(--nw-green-light-color)", hideRemove: true},
		{value: 'sellerB2B', label: <span title={t(`users.conf.func.sellerB2B.tt`)}>{t(`users.conf.func.sellerB2B`)}</span>, bgColor: "var(--nw-green-light-color)"},
		{value: 'sellerB2C', label: <span title={t(`users.conf.func.sellerB2C.tt`)}>{t(`users.conf.func.sellerB2C`)}</span>, bgColor: "var(--nw-green-light-color)"},
		{value: 'agent', label: <span title={t(`users.conf.func.agent.tt`)}>{t(`users.conf.func.agent`)}</span>, bgColor: "var(--nw-green-light-color)"},
		{value: 'publisher', label: <span title={t(`users.conf.func.publisher.tt`)}>{t(`users.conf.func.publisher`)}</span>, bgColor: "var(--nw-green-light-color)"}
	];
    const [functions, setFunctions] = useState({
		txt: "", //Not used
		options: [],
	});
    
    /* eslint-disable */
	useEffect(() => {
        const userFunctions = FunctionOptions?.filter(func => selectedUser.functions?.includes(func.value));
		if (selectedUser.id !== DEFAULT_SELECT.id) {
            setFunctions({...functions, options: userFunctions});
		}
	}, [selectedUser])
    /* eslint-enable */

	const handleChangeAccountFunctions = (options, action)=>{
		setFunctions({...functions, options});
	}
	const handleSaveFunctions = (e)=>{
        setToggle(true);
		setFlash(null);
        const newFunctions = functions?.options?.map(opt => opt.value);

		USERAPI.usersUpdateData(selectedUser.id, {functions:newFunctions})
			.then((res)=>{
                setSelectedUser({...selectedUser, functions: newFunctions});
				setUsers(users.map(user => {
					if (user.id === selectedUser.id) {
						user.functions = newFunctions;
					}
					return user;
				}))
			}).catch((err)=>{
				console.log(err);
				setFlash({alert:"danger", message: t("err.500")});
			}).finally(() => {
                setToggle(false);
			}) 
	}

	const handleChangeAdhoc = (e) => {
        setToggle(true);
		let data = {}
		const {name, value, checked} = e.currentTarget;
		if (name === 'adhoc') {
			data = {...adhoc, checked, disabled: true};
		}else if(name === 'adhocDate'){
			data = {...adhoc, date: value, disabled: true};
		}
		setAdhoc({...data});
		setFlash(null);
		USERAPI.usersUpdateData(selectedUser.id, {adHoc:data})
			.then((res)=>{
				// Update user adHoc
				const date = findAdhocDate(res.data.rasAdhocExp);
				setSelectedUser({...selectedUser, rasAdhocExp: date});
			}).catch((err)=>{
				console.log(err);
				setFlash({alert:"danger", message: t("err.500")});
			}).finally(() => {
				setAdhoc((prev) => {return {...prev, disabled: false}});
                setToggle(false);
			})
	}

	const fetchUserAccesses = (id) => {
		USERAPI.userAccountData(id)
		.then((res)=>{
			let userAccess = res.data.accesses;
			let newChildren = access.children.map(access => {
				//First Remove access styles(green color from implicit rights & '◉' from explicit rights)
				access.state.selected = false;
				access.text = access.text.replace(' ◉','');
				access.a_attr.class = access.a_attr.class.replace('nw-green-dark','');
				for (const idx in userAccess) {
					if (userAccess[idx]?.id === access.id_origin) {
						access.state.selected = true;
						access.text = access.text.includes(" ◉") ? access.text : access.text + " ◉";
					}

					if (userAccess[idx]?.children?.includes(access.id_origin)) {
						access.a_attr.class = 'nw-green-dark bold';
					}
				}
				access.state.disabled = (user.id === id);
				
				return access;
			});

			let newAccess = {...access, children: newChildren};
			setAccess({...newAccess});
			setTeams(res.data.teams);

		}).catch((err)=>{
			console.log(err);
			setFlash({alert:"danger", message: t("err.500")});
		})
	}
	const handleChangeUser = ({currentTarget}) => {
		const foundUser = users.find(element => element.id === parseInt(currentTarget.value));
		if (foundUser) {
			setSelectedUser(foundUser);
			fetchUserAccesses(foundUser.id);
		}else{
			setSelectedUser(DEFAULT_SELECT);
		}
	};
	const handleChangeFilterText = (e) => {
		const {name, value} = e.currentTarget;
		setFilter({...filter, [name]:value});
	};

    const handleKeyPressFilter = (e) => {
        if (e.key === 'Enter') {
            handleBlur();
        }
    };

	const handleMultiSelectChange = (options, action)=>{
		setFilter({...filter, status: options});
	}
	const handleSubmit = async e => {
        e.preventDefault();
		searchUserList();
	};

	const searchUserList = () => {
        setToggle(true);
        setErrors({});
		const selectedStatus = filter.status.map(status =>
			 status.value === statusSearchOptions[0].value ? 1 
			: status.value === statusSearchOptions[1].value ? 0 
			: status.value === statusSearchOptions[2].value ? 2 
			: null
		);
		const data = { text: filter.txt, status: selectedStatus};

		USERAPI.usersAccess(data)
		.then((res)=>{
			const sortedArr = [...res.data];
			if (res.data?.length === 1) {
				const foundUser = res.data[0];
				setSelectedUser(foundUser);
				fetchUserAccesses(foundUser.id);
			}else if(res.data?.length > 1){
				setSelectedUser(DEFAULT_SELECT);
				sortArray(sortedArr, 'nameOrEmail');
			}else if(sortedArr?.length === 0){
				setSelectedUser(DEFAULT_SELECT);
            }
			
			setUsers(sortedArr);
		}).catch((err)=>{
			console.log('response: ', err.response);
			console.log('err: ', err);
			if(err.message.includes('Network Error')){
				setFlash({alert:"danger", message: t("err.500")});  
			} else {
				setFlash({alert:"danger", message: t("err.unknown")});                    
			}
		}).finally(()=>{
			setToggle(false);
		})
	}
	
    /* eslint-disable */
	//Retrieve user if FilterStatus change
	useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            searchUserList();
        }

		return () => { isMounted = false };
	}, [filter.status])
    /* eslint-enable */
	

    useEffect(()=>{
		const dateClear = document.querySelector('.react-date-picker__clear-button');
        !adhoc.date ? dateClear?.classList.add('invisible') : dateClear?.classList.remove('invisible');
    })

    const selectUserRef = useRef(null);
    useEffect(() => {
        let timer = null;
        timer = setTimeout(function(){
          selectUserRef.current.focus();
      },150);
    
        return () => {
            clearTimeout(timer);
        };
    }, [users]);

    if(!hasPageAccess){
        return <LoadingError h2={t("nw.err.noAccess")} txt={t("nw.err.noAccess.txt")}/>
    }

    const handleBlur = () => {
		searchUserList();
    };

	const impersonateUser = (e, email) => {
        e.preventDefault();
		setToggle(true);
        if(email){
            dispatch(switchUser({email, exit: false, prevImpersonator: impersonate || null}))
                .then((res)=>{
					navigate(t("link.root.url"), {replace: true});
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
					setToggle(false);
				})
        }
	}

	return (
		<>
			{serverError ? 
				<LoadingError h2={t("nw.err.server")} txt={t("nw.err.server.txt")} reload={reloadPage} btn={t("nw.err.server.btn")}/>
				:
				<>
				{access === null && <Spinner className={"overlay"}/>}
				<div className="bg-white">
					<div className="py-5 min-vh-100 nw-body-container">
						<div className="row mb-5">
							<div className="col-12 px-0 mx-0 text-center">
								<h1>{t("users.title")}</h1>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<form onSubmit={handleSubmit}>
									<div className="row align-items-center">
										<div className="col-lg-6 col-md-6 my-3">
											<Field
												disabled={toggle ?"disabled":""}
												label={t("users.opt.acc.filter")}
												id="txt"
												name="txt"
												placeholder={t("users.opt.acc.filter.hint")}
												value={filter.txt}
												type="text"
												classes="mb-0"
												error={errors?.txt}
												attrs={{autoFocus:"on"}}
												onChange={handleChangeFilterText}
												onBlur={handleBlur}
												onKeyDown={handleKeyPressFilter}
											/>
										</div>
										<div className="col-lg-6 col-md-6 my-3">
											<MultiSelect 
												name="statusSearch"
												placeholder={t("users.opt.sel.status.filter")}
												isLoading={toggle}
												value={filter.status}
												defaultValue={statusSearchOptions[0]}
												options={statusSearchOptions}
												onChange={handleMultiSelectChange}
											/>
										</div>
									</div>
								</form>
							</div>

							<div className="col-md-12">
								<div className="row">
									<div className="col-lg-6 col-md-6">
										<select
                                            ref={selectUserRef}
											name="usersAccess"
											id="usersAccess"
											value={selectedUser?.id || 'default'}
                                            title={selectedUser?.email}
											disabled={(toggle || !users) ?"disabled":""}
											className={`${selectedUser?.id === DEFAULT_SELECT.id ? "nw-grey-dark":""} nw-select`}
											onChange={handleChangeUser}
										>
											<option className="nw-grey-dark" disabled value="default" name={t("users.opt.sel.user")}>
												{`${t("users.opt.sel.user")} (${users?.length??0})`}&nbsp;&nbsp;&nbsp;
											</option>
											{users?.length > 0 && users.map((forgeinUser)=>{
												return  (
													<option 
														title={forgeinUser.email}
														key={uuidv4()}
														value={forgeinUser.id}
														className={`${forgeinUser.id === user.id ? "nw-green-dark":"text-black"} fn-code`}
														data-status={forgeinUser.status}
													>
														{forgeinUser.email}
                                                        &nbsp;&nbsp;&nbsp;
                                                        {forgeinUser.name ? "-" : ""}
                                                        &nbsp;&nbsp;&nbsp;
                                                        {forgeinUser.name}
													</option>
												)
											}, user)}
										</select>
									</div>
									<div className="col-lg-4 col-md-4">
										{selectedUser?.id !== 'default' && 
											<select
												name="usersStatus"
												id="usersStatus"
												value={selectedUser?.status}
												onChange={handleChangeStatus}
												className={`${selectedUser?.status === 0 ? "text-warning" : selectedUser?.status === 1 ? "nw-green":"nw-red"} nw-select`}
												disabled={toggle || disableSelectStatus || selectedUser?.id === user.id}
												title={t(`users.opt.sel.status.${statusI18NKeys[selectedUser?.status]}.tt`)} 
											>
												{statusI18NKeys.map((key, idx)=>{
													return  <option 
															title={t(`users.opt.sel.status.${key}.tt`)} 
															key={uuidv4()} 
															value={idx}
															className={`${idx === 0 ? "nw-orange-dark" : idx === 1 ? "nw-green-dark":"nw-red-dark"} fn-code`}
														>
															{t(`users.opt.sel.status.${key}`)}&nbsp;&nbsp;&nbsp;
														</option>
													
												})}
											</select>
										}
									</div>
									<div className="col-lg-2 col-md-2">
										{user?.roles.includes('ROLE_202') && selectedUser?.id && !['default',  user.id].includes(selectedUser?.id) && selectedUser?.email?.length >  0 && selectedUser?.status === 1 &&
											<button
												disabled={toggle? "disabled" : ""}
												style={{minHeight: "49.33px"}}
												className={`bm-btn-blue p-2 float-end w-100`}
												onClick={(e) => impersonateUser(e, selectedUser?.email)}
											>
												{toggle ?
													<Spinner as="span" for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
													:
													t("user.switch.btn")
												}
											</button>
										}
									</div>
								</div>							
							</div>
						</div>
						
						{selectedUser?.id !== DEFAULT_SELECT.id && 
							<>
								<div className="row">
									<div className="col-12">
										<h2 className="bm-sep-1">{t(`users.conf`)}</h2>
									</div>
									<div className="col-12">
										<div className="d-flex align-items-center mb-1">
											<span title={t('users.conf.adhoc.tt')}>
												<input
													disabled={adhoc.disabled}
													name="adhoc"
													id="adhoc"
													type="checkbox"
													className="form-check-input"
													checked={adhoc.checked}
													onChange={(e) => handleChangeAdhoc(e)}
													value={adhoc.date}
												/>
												<label htmlFor="adhoc" className="form-check-label me-2 mb-2 fn-smtxt-bold">
													<span dangerouslySetInnerHTML={{__html: t('users.conf.adhoc')}}/>
												</label>
												<span title={t('users.conf.adhoc.date.tt')}></span>

												<span>
													<span
														style={{
															padding: "6px",
															border: `1px solid ${focusAdhocDate ? "var(--nw-blue-color)" : "var(--nw-grey-mute-light-color)"}`,
															borderRadius: ".3rem",
															transition: "all 150ms ease-in-out",
															boxShadow: "none",
															outline: "none",
															backgroundColor: `${!adhoc.checked || adhoc.disabled ? "rgb(240, 240, 240)" : "#ffffff"}`
														}}
													>
														<DatePicker
															name="sellDate"
															value={adhoc.date}
															minDate={new Date('01-01-1900')}
															//calendarClassName=""
															//clearIcon={<Clear {...props}htmlColor="red"/> || null}
															calendarIcon={<CalendarSVG fill="var(--nw-blue-dark-color)"/>}
															disabled={!adhoc.checked || adhoc.disabled}
															locale={locale}
															onChange={(newDate) => {
																const stringDate = newDate ? `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, '0')}-${newDate.getDate().toString().padStart(2, '0')}` : '';
																lastAdhocDate.current = stringDate;
																setAdhoc({...adhoc, date: stringDate});
															}}
															//onCalendarClose={onCalendarClose}
															shouldOpenCalendar={({ reason }) => {
																setFocusAdhocDate(true);
																return true;
															}}
															shouldCloseCalendar={({ reason }) => {
																setFocusAdhocDate(false);
																return setTimeout(() => {
																	if (lastAdhocDate.current === selectedUser.rasAdhocExp) {
																		return true;
																	}
																	const e = {
																		currentTarget: {
																			name: 'adhocDate',
																			value: lastAdhocDate.current
																		}
																	}
																	handleChangeAdhoc(e);
																	return true
																}, 200);
															}}
															onFocus={(event) => {
																setFocusAdhocDate(true);
															}}
														/>
													</span>
													{/*errors.adHoc && 
														<p className="fn-smtxt input-error" dangerouslySetInnerHTML={{__html: errors.adHoc}}/>
													*/}
												</span>
											</span>
										</div>
									</div>
									<div className="col-12 mt-3">
                                        <div className='row'>
                                            <div className="col-lg-8 col-md-12">
                                                <MultiSelect
                                                    name="accountFunction"
                                                    placeholder={t("users.conf.func")}
                                                    isLoading={toggle}
                                                    disabled={!user?.roles.includes('ROLE_222')}
                                                    value={functions.options}
                                                    defaultValue={functions.options}
                                                    options={FunctionOptions} 
                                                    onChange={handleChangeAccountFunctions}
                                                    onBlur={handleSaveFunctions}
                                                />                                                
                                            </div>
                                            <div className="col-lg-4 col-md-12">
                                                <div className="bm-input-wrapper">
                                                    <select
                                                        name="accountType"
                                                        id="accountType"
                                                        value={selectedUser?.type || "default"}
                                                        onChange={handleChangeAccountType}
                                                        className={`nw-select`}
                                                        disabled={toggle || !user?.roles.includes('ROLE_221')}
                                                    >
                                                        <option className="nw-grey-dark" disabled value="default" name="typeDefault">
                                                            {t("users.conf.type.def")}
                                                        </option>
                                                        {ACCOUNT_TYPES.map((type)=>{
                                                            return <option 
                                                                title={t(`users.conf.type.${type}.tt`)} 
                                                                key={uuidv4()} 
                                                                value={type}
                                                                className={`${selectedUser.type === type ? "nw-green-dark" : ""} fn-code`}
                                                            >
                                                                {t(`users.conf.type.${type}`)}
                                                            </option>
                                                        })}
                                                    </select>
                                                    <label htmlFor="accountType">{t("users.conf.type")}</label>
                                                </div>
                                                
                                            </div>
                                        </div>   
                                    </div>
								</div>
								{teams && <ManageTeams key={uuidv4()} user={selectedUser} teams={teams}/>}
							</>
						}
						{selectedUser?.id &&
							<>
								<div className="row">
									<div className="col-12">
										<h2 className="bm-sep-1">{t(`users.opt.acc`)}</h2>
									</div>
									<div className="col-12">
										{access && !toggle && 
											<Access
												user={user}
												data={access}
												selectedUser={selectedUser}
                                                isLoading={toggle}
												plugins={
													user.roles?.includes('ROLE_90') && ![DEFAULT_SELECT.id, user.id].includes(selectedUser?.id) ?
													[...DEFAULT_TREE_PLUGINS, 'checkbox']
													:
													DEFAULT_TREE_PLUGINS
												 }
											/>
										}
									</div>
								</div>
							</>
						}
					</div>
				</div>
				</>
			}
		</>
	)
}

const findAdhocDate = (date) => {
	const rasAdhocExp = date ? date?.split('T')[0] : '';
	if (rasAdhocExp && !rasAdhocExp.startsWith("2199-12-31")) {
		const fullDate = new Date(rasAdhocExp);
		return `${fullDate.getFullYear()}-${(fullDate.getMonth() + 1).toString().padStart(2, '0')}-${fullDate.getDate().toString().padStart(2, '0')}`;
	}
	return '' ;
}