import { useEffect } from 'react';

function useFocusModal(modal, input) {
    /* eslint-disable */
    useEffect(() => {
        const focus = () => {
            input?.current?.focus();
        }

        if (modal?.current) {
            const modalCurrent = modal.current;
            modalCurrent?.addEventListener('shown.bs.modal', focus);

            return () => {
                modalCurrent?.removeEventListener('shown.bs.modal', focus);
            };
        }
    }, []);
    /* eslint-enable */
}

export default useFocusModal;