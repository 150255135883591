import { useTranslation } from "react-i18next";
import { useParams, Link } from 'react-router-dom';
import { useState, useEffect } from "react";
import UserAPI from "Services/UsersAPI";
import Spinner, {LoadingError} from "Components/Loading/Spinner";
import {DOMAIN_NAME} from "config/nw-api";
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import { useSelector } from 'react-redux';


export default function LicenseAddUserConfirm() {
    const { t} = useTranslation();
    document.title = t("licenses.confirm")+ " – nanoways.com";
	const {isAuthenticated} = useSelector((state) => state.auth);

    const [data, setData] = useState(undefined);
    const [status, setStatus] = useState(null);
    let params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [serverError, setServerError] = useState(null);
    const reloadPage = () => {
        setServerError(null);
        setData(undefined);
    }
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [checkboxAgreeStyle, setCheckboxAgreeStyle] = useState({});

    const handleHover = (e)=>{
        if (e.type === "mouseenter" && !acceptTerms) {
            setCheckboxAgreeStyle({backgroundColor: "var(--nw-red-light-color)", borderColor: "var(--nw-red-dark-color)", color: "var(--nw-red-dark-color)"});
        }else if(e.type === "mouseleave"){
            // backgroundColor: "#ffe0e0",  acceptTerms ? "var(--nw-blue-color)" : "transparent"
            setCheckboxAgreeStyle({borderColor: "var(--nw-grey-close-light-color)", color: "var(--nw-blackgrey-color)"})
        } 
    }
    
    /* eslint-disable */
    useEffect(()=>{
        if (data === undefined) {
            UserAPI.checkAddLicenseUserToken(params.token)
            .then(response => {
                //console.log({...response.data});
                setData(response.data);
                setServerError(null);
            })
            .catch(error => {
                if(!error.response || error.response?.data?.status === 500){
                    setError("err.500");
                    setServerError("nw.err.server.txt");
                } else {
                    const data = error.response?.data;
                    if (data.type === 'TokenNotFound') {
                        setData(null);
                        setError('licenses.confirm.err.notFound'); 
                    } else if (data.type === 'TokenExpired') {
                        setData(null);
                        setError('licenses.confirm.err.expire');
                    } else {
                        setData(null);
                        setError("err.unknown");
                        setServerError("err.unknown");
                    }
                }
            })
        }
    }, [data])

    const submitAddLicense = (e) => {
        e.preventDefault();
        const action = e.currentTarget?.name;
        setIsLoading(action);
        const payload = {
            urlCallback:DOMAIN_NAME + t('link.licenses.url'),
            action: action,
            acceptTerms
        }

        UserAPI.confirmAddLicense(params.token, payload, isAuthenticated)
            .then(response => {
                console.log(response.data);
                setStatus(response.data);
                setServerError(null);
            })
            .catch(error => {
                if(!error.response || error.response?.data?.status === 500){
                    setError("err.500");
                    setServerError("nw.err.server.txt");
                } else {
                    const data = error.response?.data;
                    if (data.type === 'TokenNotFound') {
                        setData(null);
                        setError('licenses.confirm.err.notFound'); 
                    } else if (data.type === 'TokenExpired') {
                        setData(null);
                        setError('licenses.confirm.err.expire');
                    } else {
                        setData(null);
                        setError("err.unknown");
                        setServerError("err.unknown");
                    }
                }
            }).finally(()=>{
                setIsLoading(false);
            })
    }
    /* eslint-enable */

    return (serverError ? 
        <LoadingError h2={t("nw.err.server")} txt={t(serverError)} reload={reloadPage} btn={t("nw.err.server.btn")}/>
        :
        <>
            {data === undefined ? <Spinner className={"overlay"}/> :
            <div className="m-0 p-0 vertical-center">
                <div className="bm-main">
                    <div className="bm-card">
                        <div>
                            <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
                            <h1>{t("licenses.confirm")}</h1>
                            {error ? <>                        
                                <h2 className="bm-text-danger bm-sep-1">
                                    {(error && t('licenses.confirm.err'))}
                                </h2>
                                
                                <p dangerouslySetInnerHTML={{__html:  t(error) }} />

                                <div className="bm-nav-card-bottom">
                                    <a href={t("link.root.url")} role="button">
                                        <button className="bm-btn-mute">{t("nw.btn.goto")}</button>
                                    </a>
                                </div>
                            </>                                
                            :
                            <>
                                <h2 className="bm-sep-1">
                                    {status === "confirm" ? t('licenses.confirm.ok') : status === "cancel" ? t('licenses.confirm.no') : t('licenses.confirm.subTitle')}
                                </h2>

                                <p dangerouslySetInnerHTML={{__html: 
                                    status === "confirm" ? 
                                        t('licenses.confirm.ok.txt', {accountEmail: data?.email}) : status === "cancel" ? 
                                        t('licenses.confirm.no.txt') : t('licenses.confirm.txt', {accountEmail: data?.email})
                                }} />

                                {!status && data?.currentKey && <div>
                                    <div className="d-flex align-items-center mb-4">
                                        <span title={t(`confirm.term.tt`)}>
                                            <input
                                                style={checkboxAgreeStyle}
                                                disabled={isLoading}
                                                name='acceptTerms'
                                                id='acceptTerms'
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={acceptTerms}
                                                onChange={() => setAcceptTerms(!acceptTerms)}
                                                value={acceptTerms}
                                            />
                                            <label className="form-check-label ms-2" htmlFor='acceptTerms'>
                                                <span style={{color: checkboxAgreeStyle.color}} dangerouslySetInnerHTML={{__html: t(`licenses.confirm.term`)}}/>
                                            </label>
                                        </span>
                                    </div>
                                    <div className="row nw-license-detail">
                                        <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                            {t("clear.select.info.lk")}
                                        </div>
                                        <div title={data.currentKey} className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                            {data.currentKey}
                                        </div>
                                    </div>
                                    <div className="row nw-license-detail">
                                        <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                            {t("clear.select.info.name")}
                                        </div>
                                        <div title={data.productName} className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                            {/*<span dangerouslySetInnerHTML={{__html: data.product.name.replace(/\./g, "&#8203;.")}} />*/}
                                            {data.productName}
                                        </div>
                                    </div>
                                    {data.licenseType &&
                                        <div className="row nw-license-detail">
                                            <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                                {t("clear.select.info.type")}
                                            </div>
                                            <div title={t('st.'+data.licenseType)}  className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                                {t('st.'+data.licenseType)}
                                            </div>
                                        </div>
                                    }
                                    {data.licenseCaption &&
                                        <div className="row nw-license-detail">
                                            <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                                {t("clear.select.info.cap")}
                                            </div>
                                            <div title={data.licenseCaption}  className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                                {data.licenseCaption}
                                            </div>
                                        </div>
                                    }
                                    <div className="row nw-license-detail">
                                        <div className="fn-smtxt-bold col-sm-4 col-xs-12 px-0">
                                            {t("licenses.confirm.user")}
                                        </div>
                                        <div title={data.userdata1} className="col-sm-8 col-xs-12 px-0 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                            {data.userdata1 ?? t('gen.text.unavailable.abbr')}
                                        </div>
                                    </div>
                                    <div className="mt-3">{t('licenses.confirm.term.txt', {accountEmail: data.email})}</div>
                                </div>}

                                
                                <div className="bm-nav-card-bottom">
                                    {status ? 
                                    <a href={t("link.root.url")} role="button">
                                        <button className="bm-btn-mute">{t("nw.btn.goto")}</button>
                                    </a>
                                    :
                                    <>
                                        {/*<button
                                            onClick={submitAddLicense}
                                            name="confirm"
                                            disabled={(isLoading || !acceptTerms) && "disabled"} 
                                            className="bm-btn-blue-out bm-btn-card-next bm-btn-card-next d-flex justify-content-center ms-1 mb-2"
                                        >
                                            {isLoading === "confirm" &&
                                                <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                            }
                                            <span className={isLoading === "confirm" ? "invisible" : ""}>{t("licenses.confirm.btn.ok")}</span>
                                        </button>*/}
                                 
                                        <span onMouseEnter={handleHover} onMouseLeave={handleHover}>
                                            <OverlayTrigger placement="top" trigger={(!acceptTerms) ? ["hover", "focus", "click"] : []} rootClose={true} rootCloseEvent="mousedown" overlay={<Tooltip className="nw-tooltip">{t("licenses.confirm.btn.ok.tt")}</Tooltip>}>
                                                <button
                                                    onClick={submitAddLicense}
                                                    name="confirm"
                                                    disabled={(isLoading || !acceptTerms) ? "disabled" : ""}
                                                    className={`${(!acceptTerms) ? "btn-disabled" : "bm-btn-blue-out"} bm-btn-card-next d-flex justify-content-center ms-1 mb-2`}
                                                    //title={!acceptTerms ? t("clear.select.btn.agreeTT") : ""}
                                                >
                                                    {isLoading === "confirm" &&
                                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                                    }
                                                    <span className={isLoading === "confirm" ? "invisible" : ""}>{t("licenses.confirm.btn.ok")}</span>
                                                </button>
                                            </OverlayTrigger>
                                        </span>

                                        <button
                                            onClick={submitAddLicense}
                                            name="cancel"
                                            disabled={isLoading && "disabled"} 
                                            className="mt-sm-0 bm-btn-grey-out d-flex justify-content-center"
                                        >
                                            {isLoading === "cancel" &&
                                                <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                            }
                                            <span className={isLoading === "cancel" ? "invisible" : ""}>{t("gen.button.cancel")}</span>
                                        </button>
                                        <div className="bm-clearer"></div>
                                    </>}
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
