let apiUrl = "https://i18n.nanoways.com/api/";

if (process.env.NODE_ENV === 'development') {
    apiUrl = "https://localhost:8001/api/";
} else if(window.location.href.includes('newdev.nanoways')){
    apiUrl = "https://i18ndev.nanoways.com/api/";
}

export const API_I18N_DOMAIN = apiUrl;
/*Recource Projects*/
export const API_I18N_PROJECTS = API_I18N_DOMAIN + "v1/projects";
export const API_I18N_DOMAINS = API_I18N_DOMAIN + "v1/domains";