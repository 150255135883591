import { useState, useEffect, useRef } from "react";
import LICENSEAPI from 'Services/LicenseAPI';
import Field from "Components/Forms/Field";
import { useTranslation } from "react-i18next";
import Spinner from 'Components/Loading/Spinner';
import { fixInput } from 'Services/utils';
import {ReactComponent as CheckedSvg} from "assets/images/icon/checked.svg";
import useFocusModal from "Hook/useFocusModal";
import {DOMAIN_NAME} from "config/nw-api";

export default function LicenseAddModal(props) {
    const { t } = useTranslation();
    const licenseInputRef = useRef(null);
    const modalRef = useRef(null);
    useFocusModal(modalRef, licenseInputRef);
    const [license, setLicense] = useState({key : "",});
    const [errors, setErrors] = useState({key : "",});
    const [isLoading, setIsLoading] = useState(false);
    const [info, setInfo] = useState(null);

    const [cursor, setCursor] = useState({
        target: undefined,
        position: 0
    });

    /* eslint-disable */
    useEffect(() => {
        // After change Input value return cursor to the last position.
        cursor.target && (cursor.target.selectionEnd = cursor.position);
    },[license])
    /* eslint-enable */

    const handleChange = (e) => {
        Object.defineProperty(e.target, "defaultValue", {
          configurable: true,
          get() { return "" },
          set() {},
        });
        const {name, value, selectionStart} = e.currentTarget;
        let valueLength = value.length;
        
        let newValue = fixInput({value:value, maxLength:23});

        //Return cursor to its position if user try to correct value
        let cursorPosition = selectionStart;
        if(valueLength === cursorPosition){
            cursorPosition = newValue.length;
        }

        setLicense({...license,
             [name]: newValue,
             placeholder:(newValue+t("clear.enterlk.key.hint").slice(newValue.length))
        });
        //Update Dynamic placeholder
        setCursor({...cursor, target: e.currentTarget, position: cursorPosition});
    };
    
    const inputFocus = (e) => {
        if (!e.target.value) {
            setLicense({...license, placeholder: t("clear.enterlk.key.hint")});
        }
    };
    const inputFocusout = (e) => {
        if (!e.target.value) {
            setLicense({...license, placeholder: ""});
        }
    };
   
    const handleCancel = () =>{
        setLicense({key: license.key});
        setInfo(null);
    }

    const attributes = {
        pattern:'([A-Za-z0-9]{5}-?){3}[A-Za-z0-9]{5}',
        autoComplete:"on",
    }

    const dataRef = useRef(null);

    const onCloseModal = () => {
        setInfo(null);
        setLicense({key : "", placeholder: " "});
        setErrors({key : ""});
        dataRef.current && props.reloadTab(null, {name:props.tabName, newLicense:dataRef.current});
        dataRef.current = null;
    }

    // Manage form submission
    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);
        if (!license.key) {
            setErrors({...errors, key:t("nw.input.err.required")});
            setIsLoading(false);
        } else if (!license.key.match(/^([A-Za-z0-9]{5}-?){3}[A-Za-z0-9]{5}$/)) {
            setErrors({...errors, key:t("clear.enterlk.err.invalid")});
            setIsLoading(false);
        } else{
            setErrors({key : ""});
            const payload = {
                urlCallback:{"confirm_license":DOMAIN_NAME + t('link.lic.confirmUser').replace(':token', '')},
                checked: !!license.checked
            }
            LICENSEAPI.addUser(license.key, payload)
            .then((res)=>{
                if (res.status === 204) {
                    //License already exists in the list
                    setErrors({...errors, key:t("licenses.list.opt.add.err.found"), lastValue: license.key});
                }else if(res.status === 200){
                    const {data} = res;
                    
                    if(data.type === 'check'){
                        setLicense({...license, checked: true})
                        setInfo({
                            h3: "licenses.list.opt.add.form.confirm.ask",
                            newKey: null,
                            input: {disabled: true},
                            p: {
                                i18nKey: "licenses.list.opt.add.form.confirm.ask.txt",
                                data: {licenseEmail: data.licenseEmail}
                            },
                            btns:{
                                confirm: true,
                                cancel: true,
                            }
                        });
                    }else if(['wait', 'sent'].includes(data.type)){
                        const i18nKey = data.type === "wait" ? "wait" : "sent"
                        setInfo({
                            h3: `licenses.list.opt.add.form.confirm.${i18nKey}`,
                            newKey: null,
                            input: {disabled: true},
                            p: {
                                i18nKey: `licenses.list.opt.add.form.confirm.${i18nKey}.txt`,
                                data: {licenseEmail: data.licenseEmail, hours: data.hours}
                            },
                            btns:{
                                close: true
                            }
                        });
                    }else if (data.currentKey !== license.key) {
                        setInfo({
                            newKey: data.currentKey,
                            input: {disabled: true, checked:true},
                            p: {
                                i18nKey: "licenses.list.opt.add.form.ok",
                                data: {key: license.key},
                            },
                            btns:{
                                close: true
                            }
                        });
                        //mark license to add it from parent to list
                        dataRef.current = data;
                    }else{
                        setInfo({
                            newKey: null,
                            input: {disabled: true, checked:true},
                            p: {
                                i18nKey: "licenses.list.opt.add.form.ok",
                                data: {key: license.key},
                            },
                            btns:{
                                close: true
                            }
                        });
                        //mark license to add it from parent to list
                        dataRef.current = data;
                    }
                }
            }).catch((err)=>{
                if(err.response?.status === 404){
                    setErrors({...errors, key:t("clear.enterlk.err.invalid"), lastValue: license.key});
                }else if(err.response?.status === 409){
                    const message = err.response?.data?.message?.toLowerCase();
                    const i18nKey = message === "license is used" ?
                        "used" : message === "product is old" ?
                            "productOld" : message === "license wait confirm" ? 
                                "wait" : message === "license is occupied" ? 
                                    "email" : "invalid"
                    setErrors({...errors, key: t(`licenses.list.opt.add.err.${i18nKey}`)})
                }else{
                    setErrors({...errors, key:t("err.500"), lastValue: license.key});
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
    };

    return (
        <>            
            <div ref={modalRef} className="modal fade" id="licenseAddModal" tabIndex="-1" aria-labelledby="licenseAddModalLabel" aria-hidden="false" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">
                        <h2 className="bm-card-header_small" id="licenseAddModalLabel">
                            {t("licenses.list.opt.add.form")}
                        </h2>
                        <a
                            disabled={isLoading && "disabled"}
                            href="/#"
                            className="bm-close-x"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={onCloseModal}
                        >
                            &#10005;
                        </a>
                        {info ? 
                        <>
                            {info?.h3 && <h3 className="bm-sep-1 mt-0 text-start">{t(info?.h3)}</h3>}
                            {info?.input && <div className="bm-input-wrapper position-relative">
                                {info?.input.checked && <div className="position-absolute top-50 end-0 translate-middle ">
                                    <CheckedSvg width="24"/>
                                </div>}
                                <input
                                    id="validKey"
                                    name="validKey"
                                    type="text"
                                    value={license.key}
                                    className="bm-input pe-5"
                                    disabled={info.input.disabled}
                                />
                                <label htmlFor='validKey'>{t("clear.enterlk.key")}</label>
                            </div>}
                            <p className="fn-txt text-start" dangerouslySetInnerHTML={{__html: t(info?.p?.i18nKey, info?.p?.data)}} />

                            {info?.newKey && <p className="fn-txt text-start" dangerouslySetInnerHTML={{__html: t("licenses.list.opt.add.form.info", {oldKey: license.key, currentKey: info.newKey})}} />}

                            {info?.btns && <div className="row">
                                {info?.btns?.close && <div className="col">
                                    <button
                                        onClick={onCloseModal}
                                        id="closeLicenseAddModal"
                                        className="bm-btn-blue bm-btn-card-next"
                                        data-bs-dismiss="modal"
                                    >
                                        {t("gen.button.close")}
                                    </button>
                                </div>}
                                {info?.btns?.cancel && <div className="col-6">
                                    <button 
                                        onClick={handleCancel}
                                        className="bm-btn-grey-out bm-btn-card-prev px-3"
                                        disabled={isLoading}
                                    >
                                        {t("licenses.list.opt.add.form.btn.no")}
                                    </button>
                                </div>}
                                {info?.btns?.confirm && <div className="col-6">
                                    <button onClick={handleSubmit} disabled={isLoading && "disabled"} className="bm-btn-blue-out bm-btn-card-next px-3 d-flex justify-content-center">
                                        {isLoading &&
                                            <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                        }
                                        <span className={isLoading ? "invisible" : ""}>{t("licenses.list.opt.add.form.btn.ok")}</span>
                                    </button>
                                </div>}
                            </div>}
                        </>
                        :
                        <form onSubmit={handleSubmit} autoComplete="on">
                            <div className="autocomplete-container" style={{textAlign: "left"}}>
                                <div className="autocomplete">{license.placeholder}</div>
                                <Field
                                    refInput={licenseInputRef}
                                    label={t("clear.enterlk.key")}
                                    id="key"
                                    name="key"
                                    disabled={isLoading}
                                    value={license.key}
                                    onChange={handleChange}
                                    onFocus={inputFocus}
                                    onBlur={inputFocusout}
                                    error={errors.key}
                                    attrs={attributes}
                                    placeholder=" "
                                    inputClass={""}
                                />
                            </div>
                            <div className="row">
                                <div className="col-12 text-start">
                                    <p className="m-0 p-0">{t("licenses.list.opt.add.form.txt")}</p>
                                </div>
                            </div>
                        
                            <div className="bm-nav-card-bottom">
                                <button type="submit" disabled={isLoading && "disabled"} className="bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                                    {isLoading &&
                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                    }
                                    <span className={isLoading ? "invisible" : ""}>{t("licenses.list.opt.add.form.btn")}</span>
                                </button>
                            </div>
                        </form>}
                    </div>
                </div>
            </div>
        </>
    )
}
