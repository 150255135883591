export default function useIsReloaded(){
    const navigationEntries = performance.getEntriesByType("navigation");

    if (navigationEntries.length > 0) {
        return (navigationEntries[0].type === "reload");
    }

    console.log("No navigation entries found.");

    return false;
}