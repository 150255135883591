import {API_TEAMUSER_DELETE, API_TEAMUSER_ADD} from 'config/nw-api';
import AxiosPrivate from 'Services/Common/AxiosPrivate'

/*function all() {
    return AxiosPrivate.get(API_TEAMS);
}*/

function deleteTeamUser(id) {
    return AxiosPrivate.delete(API_TEAMUSER_DELETE.replace(':id', id));
}

function addTeamUser(data) {
    return AxiosPrivate.post(API_TEAMUSER_ADD, data);
}

const TeamAPI = {
    //all,
    deleteTeamUser,
    addTeamUser
};

export default TeamAPI;

