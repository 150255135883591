import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import TEAMAPI from "Services/TeamAPI";
import {v4 as uuidv4} from 'uuid';
import Field from "Components/Forms/Field";
import Spinner from 'Components/Loading/Spinner';
import {ReactComponent as PlusSvg} from "assets/images/icon/plus.svg";
import {ReactComponent as TrashSvg} from "assets/images/icon/trash.svg";
import {checkEmail } from "Components/Tools/Text";
import ConfirmModal from 'Components/ConfirmModal';


const ManageTeams = (props) => {

	const { t } = useTranslation();
    const [teams, setTeams] = useState(props.teams);
    const [selectedTeam, setSelectedTeam] = useState({value:"default", error: ""});
    const [email, setEmail] = useState({value: null, error: ""});
    const [order, setOrder] = useState({value: "", error: ""});
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const confirmRef = useRef();
    const hasLabels = useRef(null);

	const handleInputChange = ({currentTarget}) => {
        const {value, name} = currentTarget;
        if (name === "email") {
            setEmail({...email, value, error: ""});
            return;
        }
        if (name === "order") {
            if (/^\d*\.?\d*$/.test(value)) {
                setOrder({...order, value, error: ""});
            }else{
                setOrder({...order, error: "users.teams.add.order.err"});
            }
            return;
        }
        if (name === "teams") {            
            setSelectedTeam({...selectedTeam, value:currentTarget.value, error:""});
            hasLabels.current = null;
        }
        //
	};

	const handleTeamSubmit = async e => {
        e.preventDefault();
        let errors = null;

        if (selectedTeam.value === "default") {
            setSelectedTeam({...selectedTeam, error: 'nw.input.err.required'});
            errors = true;
        }

        if (!order.value) {
            setOrder({...order, error: "nw.input.err.required"});
            errors = true;
        }

        if (email.value && !checkEmail(email.value)) {
            setEmail({...email, error: "nw.input.mail.err.invalid"});
            errors = true;
        }

        if ([null, ''].includes(email.value)) {
            setEmail({...email, value: props.user?.email});
        }

        if (errors === null) {
            setLoading({rowNumber:"add"});
            setSelectedTeam({...selectedTeam, error:""});

            TEAMAPI.addTeamUser({
                userId: props.user?.id,
                teamId: selectedTeam.value,
                email: email.value,
                order: order.value,
            }).then((res) => {
                //let newTeams = [];
                teams.forEach((team) => {
                    if (team.id === parseInt(selectedTeam.value)) {
                        team.teamUsers.push(res.data);
                        team.teamUsers.sort((a, b) => a.assistantOrder - b.assistantOrder);
                    }
                    //newTeams.push(team)
                })
                setTeams([...teams]);
            }).catch((err)=>{
                console.log('response: ', err.response);
                console.log('err: ', err);
                setSelectedTeam({...selectedTeam, error:"err.500"});
            }).finally(()=>{
                setLoading(false)
            });
        }
	};

    const [removedTeamUser, setRemovedTeamUser] = useState(null);
	const handleRemoveTeam = (e, data) => {
        setLoading({rowNumber: data.rowNumber});
        setRemovedTeamUser(data)
        setApiError(null);
        showConfirmModal(data);
	};

    const showConfirmModal = (data) => {
        confirmRef.current.click();
    }

    // Used by child ConfirmModal
    const confirm = (confirmed) => {
        if (confirmed) {
            TEAMAPI.deleteTeamUser(removedTeamUser?.teamUser?.id)
            .then((res) => {
                teams.forEach((team) => {
                    const teamUser = team.teamUsers.filter((teamUser) => {
                        return teamUser.id !== removedTeamUser?.teamUser?.id
                    })
                    team.teamUsers = teamUser;
                })
                setTeams([...teams]);
            }).catch((err)=>{
                console.log('response: ', err.response);
                console.log('err: ', err);
                setApiError({rowNumber:removedTeamUser.rowNumber, txt:"err.500"});
            }).finally(()=>{
                setLoading(false);
            });
        }else{
            setLoading(false);
        }
        setRemovedTeamUser(null);
    }

    let listCounter = 0;
    const teamsUserList = (teamUser, team, rowNumber, isLastRow) => {
        const username = teamUser.user.name || teamUser.user.email;
        const showTeam = selectedTeam.value === "default";
        return <div className="row" name={`teamUser-${rowNumber}`} style={{marginBottom:"5px"}} key={uuidv4()}>
                    <div className="col-md-4 mb-1">
                        <Field
                            //title={teamUser.user.id}
                            type="text"
                            disabled
                            name="teamsUser"
                            value={showTeam ? team.name : username}
                            className="col-5"
                            classes="mb-0"
                            //inputClass={`${teamUser.assistantOrder ? "":"nw-grey-very-light"}`}
                        />
                    </div>
                    <div className="col-md-5 mb-1">
                        <Field
                            type="text"
                            disabled
                            name="emailsUser"
                            value={teamUser.assistantEmail || ''}
                            inputClass={`${teamUser.assistantOrder ? "":"nw-grey-very-light"}`}
                            classes="mb-0"
                        />
                    </div>

                    <div className="col-md-2 mb-1">
                        <Field
                            name="orderUser"
                            type="text"
                            disabled
                            placeholder={t("users.teams.add.order.hint")}
                            value={teamUser.assistantOrder}
                            inputClass={`${teamUser.assistantOrder ? "":"nw-orange-dark"}`}
                            classes="mb-0"
                        />
                    </div>
                    <div className={`${isLastRow ? "mb-0": "mb-2"} col-md-1`}>
                        <button 
                            name="deleteTeam"
                            type="submit"
                            disabled={loading.rowNumber === rowNumber ? "disabled" : ""}
                            style={{minHeight: "49.33px"}}
                            className={`${loading.rowNumber === rowNumber? "bm-btn-red" : "bm-btn-rm"} p-2 float-end w-100`}
                            onClick={(e) => handleRemoveTeam(e, {teamUser, rowNumber, username, team})}
                        >
                            {loading.rowNumber === rowNumber ?
                                <Spinner as="span" for="btn" iconStyle={{radius: "24px", borderColor: "white"}}/>
                                :
                                <TrashSvg className={loading.rowNumber === rowNumber ? "invisible" : ""} height="24"  fill="#fff"/> 
                            }
                        </button>
                    </div>
                    
                    {apiError?.rowNumber === rowNumber && <div style={{marginTop: "-14px"}} className="col-12 fn-smtxt invalid-feedback d-block mb-2">{t(apiError?.txt)}</div>}
                </div>
    }

    return ( 
        <>
            {teams && 
                <div className="row">
                    <div className="col-12">
                        <h2 className="bm-sep-1">{t(`users.teams`)}</h2>
                    </div>
                    <div className="col-12">
                        <form onSubmit={handleTeamSubmit} autoComplete="on">
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <select
                                        name="teams"
                                        value={selectedTeam.value}
                                        //style={{width: `${(data.team?.name.length)+0.1}ch`}}
                                        onChange={(e) => handleInputChange(e)}
                                        className={`nw-select ${selectedTeam.value === "default" ? "nw-grey" : ""} ${selectedTeam.error ? "bm-input-is-invalid" : ""} `}
                                        disabled={loading.rowNumber === "add" ?"disabled":""}
                                    >
                                        <option value="default" style={{color: "var(--nw-grey-color)"}}>
                                            {selectedTeam.value === "default" ? t("users.teams.add.team.sel") : t("users.teams.add.team.all")}
                                        </option>
                                        {
                                        teams.map((team)=>{
                                            return  (
                                                <option 
                                                    key={uuidv4()}
                                                    value={team.id}
                                                    title={team.name}
                                                    className="bm-marg-bott-1rem text-black"
                                                >
                                                    {team.name}
                                                </option>
                                            )
                                        })
                                        }
                                    </select>
                                    {selectedTeam.value !== "default" && <label htmlFor="teams">{t("ras.opt.team.sel")}</label>}
                                    {selectedTeam.error && <p className="fn-smtxt invalid-feedback mb-1">{t(selectedTeam.error)}</p>}
                                </div>

                                <div className="col-md-5 mb-1">
                                    <Field
                                        label={t("nw.input.mail")}
                                        id="email"
                                        name="email"
                                        placeholder={t("nw.input.mail.hint")}
                                        value={email.value === null ? props.user?.email : email.value}
                                        classes="mb-0"
                                        onChange={handleInputChange}
                                        type="text"
                                        error={t(email.error)}
                                        disabled={loading.rowNumber === "add" ?"disabled":""}
                                    />
                                </div>

                                <div className="col-md-2 mb-1">
                                    <Field
                                        label={t("users.teams.add.order")}
                                        id="order"
                                        name="order"
                                        placeholder={t("users.teams.add.order.hint")}
                                        value={order.value}
                                        classes="mb-0"
                                        onChange={handleInputChange}
                                        type="text"
                                        error={t(order.error)}
                                        disabled={loading.rowNumber === "add" ?"disabled":""}
                                    />
                                </div>

                                <div className="col-md-1 mb-3">
                                    <button 
                                        type="submit"
                                        disabled={loading?.rowNumber === "add" ? "disabled" : ""}
                                        style={{minHeight: "49.33px"}}
                                        className={`bm-btn-blue p-2 float-end w-100`}
                                    >
                                        {loading?.rowNumber === "add" ?
                                            <Spinner as="span" for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                            :
                                            <PlusSvg className={loading?.rowNumber === "add" ? "invisible" : ""} height="24"  fill="#fff"/> 
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-12">
                        {teams?.length > 0 && 
                            <>
                                {teams.map((team, key) => {
                                    return team.teamUsers.map((teamUser) => {
                                        if (!teamUser.user) {
                                            return null;
                                        }
                                        //return only one user emails if no team is selected
                                        // return all users emails if team selected
                                        if((selectedTeam.value === "default" && teamUser.user?.id === parseInt(props.user?.id)) || 
                                            (selectedTeam.value !== "default" && team.id === parseInt(selectedTeam.value))
                                        ) {
                                            //show labels only in the first line
                                            const data = hasLabels.current === null ? 
                                                <div key={uuidv4()}>
                                                <div className="row">                                
                                                    <div className="col-md-4">
                                                        <label className="mb-2 mt-2" htmlFor="teamsUser">
                                                            {selectedTeam.value === "default" ? t("users.teams.list.team") : t("users.teams.list.usr")}
                                                        </label>
                                                    </div>
                
                                                    <div className="col-md-5">
                                                        <label className="mb-2 mt-2" htmlFor="emailsUser">{t("users.teams.list.emailT")}</label>
                                                    </div>
                
                                                    <div className="col-md-2">
                                                        <label className="mb-2 mt-2" htmlFor="orderUser">{t("users.teams.list.order")}</label>
                                                    </div>
                
                                                    <div className="col-md-1">
                                                        <label htmlFor="deleteTeam" className="d-none mb-2">{t("T_ACTION")}</label>
                                                    </div>
                                                </div>
                                                {teamsUserList(teamUser, team, listCounter++, key === teams?.length-1)}
                                                </div>
                                                :
                                                teamsUserList(teamUser, team, listCounter++, key === teams?.length-1)
                                            ;
                                            hasLabels.current = true;

                                            return data;
                                            
                                        }
                                        return null;
                                    })    
                                })}
                                <ConfirmModal
                                    btnStyles={{position: "fixed",top: "50%",zIndex: "-100"}}
                                    confirmRef={confirmRef} 
                                    classes="float-end bm-btn-white"
                                    backdrop={"static"}
                                    confirm={confirm}
                                    txt={{
                                        title:t("users.teams.delete"),
                                        body:t("users.teams.delete.txt", {
                                                teamName: removedTeamUser?.team?.name,
                                                username: removedTeamUser?.username,
                                                //yes:t("teams.delete.yes"),
                                                //no:t("teams.delete.no")
                                            }),
                                        yes:t("users.teams.delete.yes"),
                                        no:t("users.teams.delete.no"),
                                    }}
                                />
                            </>
                        }
                    </div>
                </div>
            }
        </>
    ) 
}

export default ManageTeams;