import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: null,
    email: null,
    name: null,
    publicName: null,
    languages: null,
    accounts: [],
    functions: [],
    roles: []
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUser: (state, action) => {
            state.id = action?.payload.id || null;
            state.email = action?.payload.email || null;
            state.name = action?.payload.name || null;
            state.publicName = action?.payload.publicName || null;
            state.languages = action?.payload.languages || [];
            state.functions = action?.payload.functions || [];
            state.roles = action?.payload.roles || [];
            state.accounts = action?.payload.accounts || [];
        },
        update: (state, action) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                state[key] = value;
            })
        },
        removeUser: (state) => {
            state.id = null;
            state.email = null;
            state.name  = null;
            state.publicName  = null;
            state.languages = null;
            state.roles = [];
            state.accounts = [];
        },
    },
    
});

export const { getUser, removeUser, update} = userSlice.actions;

export default userSlice;