import AxiosPrivate from "./Common/AxiosPrivate";
import {
    API_P2P,
} from "config/nw-api";


async function get(handle) {
    return await AxiosPrivate.get(API_P2P.replace(":handle", handle));
}

async function update(handle, data = {}) {
    return await AxiosPrivate.put(API_P2P.replace(":handle", handle), data);
}

async function remove(handle, data) {
    return await AxiosPrivate.delete(API_P2P.replace(":handle", handle), {data});
}

const P2PAPI = {
    get,
    update,
    remove
};

export default P2PAPI;