import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";


const TextareaDiv = (props) => {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(props.isEditable || false);
    const [text, setText] = useState(props.attr?.value? props.attr.value : '');

    const handleDoubleClick = () => {
        setIsEditing(true);
        props.setTextarea(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
        props.setTextarea(false);
        props.update(text);
    };

    const handleChange = (e) => {
        setText(e.target.value);
    };

    useEffect(()=>{
        let mounted = true;

        if (mounted) {
            setIsEditing(props.isEditable);
        }

        return () => { mounted = false; }
    }, [props.isEditable])

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (e.ctrlKey) {
                const target = e.target;
                const selectionStart = target.selectionStart;
                const selectionEnd = target.selectionEnd;
                const textBeforeCursor = target?.value?.substring(0, selectionStart);
                const textAfterCursor = target?.value?.substring(selectionEnd);
                target.value = textBeforeCursor + "\n" + textAfterCursor;
                //ToSelect the rest of text do not set target.selectionEnd
                target.selectionStart = target.selectionEnd = selectionStart +1; // Move cursor to new line
                setText(target.value);
            }else{
                setIsEditing(false);
                props.setTextarea(false);
                props.update(text);
            }
        }
    };

    /*const renderTextWithLineBreaks = (text) => {
        const processedText = text.replace(/\n/g, '<br />');
        return { __html: processedText };
    };
    <div
        style={{ whiteSpace: 'pre-line' }}
        dangerouslySetInnerHTML={this.renderTextWithLineBreaks(textWithLineBreaks)}
    />
    */

    return ( 
        <>
        {isEditing ? 
            <div className="bm-input-wrapper w-100">
                <textarea
                    autoFocus
                    title={t("nw.txt.saveTT")}
                    value={text}
                    name={props.attr?.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    className="bm-input"
                    placeholder={props.attr.placeholder}
                    rows={'1'}//Fix the height to prevent elements from sliding
                />
                <label htmlFor={props.attr?.name}>{props.attr.label}</label>
            </div>
        :
            <div
                onDoubleClick={handleDoubleClick}
                title={t("nw.txt.editTT")}
                style={{ whiteSpace: 'pre-line', minHeight: "69px" }}//minHeight used to Fix the height to prevent elements from sliding
            >
                {text ?
                    text
                    :
                    <span style={{color:"#606060"}}>{props.attr.placeholder}</span>
                }
            </div>
        }
    </>) 
}

export default TextareaDiv;