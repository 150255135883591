export const sortArray = (arr, attr = null) => {
    arr.sort((a, b) => {
        const nameA = attr ? a[attr]?.toUpperCase() : a?.toUpperCase();
        const nameB = attr ? b[attr]?.toUpperCase() : b?.toUpperCase();
      
        if (nameA > nameB) {
              return 1;
        } else if (nameA < nameB) {
              return -1;
        }
        
        return 0;
    });
}