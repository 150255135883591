import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import "./index.css";

const InputDropDown = (props) => {
    const { t } = useTranslation();
    const inputDropDownRef = useRef();
    const options = props.options;
    const [filtredOptions, setFiltredOptions] = useState(options);
    const [value, setValue] = useState(filtredOptions[0]);
    const [showList, setShowList] = useState(null);
    const [selectedIdx, setSelectedIdx] = useState(null);
    const [err, setErr] = useState(props.error);

    /* eslint-disable */
    useEffect(() => {
		let isMounted = true;
        
        if (isMounted) {
           setErr(props.error);
        }

		return () => { isMounted = false };
    }, [props.error])

    useEffect(() => {
		let isMounted = true;
        
        if (isMounted) {
           props.setOriginValue(value);
        }

		return () => { isMounted = false };
    }, [value])
    /* eslint-enable */

    const handleMouseChangeOption = (e) => {
        setValue(e.currentTarget.getAttribute('data-email'));
    }

    return (
            <div style={{position: "relative"}}>
                <span 
                    onClick={()=>{
                        inputDropDownRef.current?.focus()
                    }} 
                    style={{position: "absolute", right: "7px", top: "8px"}} className="arrowDownIcon"
                ></span>

                {/*
                    options={options ?? []}
                    value={{value:value.value, label: value.value}}
                    user={user}
                    access={{
                        allEmails: canAddAllEmails,
                        domainEmails: canAddOnlyDomainsEmails,
                        editEmail: hasEditEmail
                    }}
                    setOriginValue={(e)=> {
                        setValue({...value, value:e.value});
                    }}
                 */}
                <input
                    ref={inputDropDownRef}
                    autoComplete={"off"}
                    type={props.type}
                    name={props.name}
                    id={props.id || props.name}
                    disabled={props.disabled}
                    tittle={t("clear.select.opt.email.tt")}
                    placeholder={props.placeholder}
                    value={value}
                    className={"input-small border-focus fn-code float-end nowrap " + (err.msg && "bm-input-is-invalid ")}
                    onChange={(e)=> {
                        setValue(e.currentTarget.value);
                        props.hasFilter && setFiltredOptions([...options.filter(email => email.includes(e.currentTarget.value))]);
                        setSelectedIdx(null);
                    }}
                    onKeyDown={ (e) => {
                        let idx = selectedIdx;
                        if (e.key === 'ArrowDown') {
                            setShowList(true);
                            idx = idx === null || idx >= filtredOptions?.length-1 ? 0 : idx+1;
                            if (filtredOptions[idx]) {
                                setSelectedIdx(idx);
                                setValue(filtredOptions[idx]);
                            }
                        }
                        if (e.key === 'ArrowUp') {
                            setShowList(true);
                            idx = idx === null || idx === 0 ? filtredOptions?.length-1 : idx-1;
                            if (filtredOptions[idx]) {
                                setSelectedIdx(idx);
                                setValue(filtredOptions[idx]);
                            }
                        }
                        if (e.shiftKey && e.key === 'Delete' && props.delete && filtredOptions[idx] && !props.cantDelete?.includes(filtredOptions[idx])) {
                            props.delete(filtredOptions[idx]);
                            setFiltredOptions(filtredOptions.filter(item => item !== filtredOptions[idx]));
                            setValue('');
                        }
                        if (['Enter', 'Escape', 'Tab'].includes(e.key)) {
                            e.currentTarget.blur();
                        }
                    }}
                    onFocus={(e)=> {
                        setShowList(true);
                        e.currentTarget?.select();
                        setSelectedIdx(null);
                    }}
                    /*onMouseDown={(e)=> {
                        setShowList(!showList);
                    }}*/
                    onBlur={(e)=> {
                        setSelectedIdx(null);
                        //Timer allow onMouseEnter event to select the new option before lose focus on element
                        setTimeout(() => {
                            setShowList(false);
                        }, 150);
                    }}
                />
                {filtredOptions?.length > 0 && showList &&
                    <div className={`p-1 inputDropdown-list`} style={{position: "absolute", zIndex: 5, width: "100%", marginTop: "27.33px"}}>
                        {filtredOptions?.map((option, idx)=>{
                            return  (
                                <div  
                                    key={'selectEmail_'+idx}
                                    data-email={option}
                                    title={option}
                                    className={`${props.boldOptions?.includes(option) ? "fn-smtxt-bold" : ""} input-dd-bg ps-1`}
                                    style={selectedIdx === idx ?
                                        {
                                            background: "var(--nw-grey-mute-light-color)",
                                            border: "1px solid var(--nw-grey-mute-light-color)",
                                            borderRadius: ".2rem"}
                                        :
                                        {}
                                    }
                                    onClick={handleMouseChangeOption}
                                   //onMouseEnter={handleMouseChangeOption} //uncomment to select option on hover
                                >
                                    {option}
                                </div>
                            )
                        })}
                    </div>
                }
                {err?.msg?.length > 0 && <div className="fn-smtxt invalid-feedback mb-1" dangerouslySetInnerHTML={{__html: t(err.msg, err.vars)}}/>}
            </div>
    );
}

export default InputDropDown;