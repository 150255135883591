import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import "./Translations/i18n";
import { PersistGate } from 'redux-persist/integration/react';
import {store, persistor} from "Reduxx/store";
import {Provider} from 'react-redux';
import ErrorBoundary from "Components/ErrorBoundary";
import '@fortawesome/fontawesome-free/css/all.min.css';

ReactDOM.render(
	/* <React.StrictMode>
	<App />
	</React.StrictMode>, */
		<BrowserRouter>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
                    <ErrorBoundary >
                        <App />
                        <div 
                            id="measure-layer"
                            style={{
                                display: "inline-block",
                                position: "absolute",
                                visibility: "hidden", 
                                zIndex: "-1"
                            }}
                        />
                    </ErrorBoundary>
				</PersistGate>
			</Provider>
		</BrowserRouter>,
	document.getElementById('root')
);
