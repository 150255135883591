import AxiosPrivate from "./Common/AxiosPrivate";
import {
    API_JOURNAL,
} from "config/nw-api";


async function get(criteria) {
    let url = API_JOURNAL.replace(":tabename", criteria.tabname).replace(":limit", criteria.limit);
    const data = criteria.search ?{search: criteria.search} : []
    
    return await AxiosPrivate.post(url, data);
}

const JOURNALAPI = {
    get
};

export default JOURNALAPI;