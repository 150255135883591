import Select, { components }  from 'react-select';
import {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";


const customStyles = {
    control: (provided, state) =>{
        return {
            ...provided,
            border: state.isFocused ? '1px solid var(--nw-blue-color)' : "1px solid var(--nw-grey-mute-light-color)" ,//provided.border,
            boxShadow: "0",
            paddingTop: "6px !important",
            paddingBottom: "6px !important",
        }
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? data.color
                : isFocused
                ? "var(--nw-blue-light-color)"
                : undefined,
            color: isDisabled
                ? '#ccc'
                : isSelected
                ? data.color !== 'var(--nw-blue-light-color)'
                ? 'white'
                : 'black'
                : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                ? isSelected
                    ? data.color
                    : "var(--nw-blue-light-color)"
                : undefined,
            },
        };
    },
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: data.hideRemove ? "var(--nw-grey-light-color)" : data.bgColor ? data.bgColor :"var(--nw-green-light-color)",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "black",
        backgroundColor: data.hideRemove ? "var(--nw-grey-light-color)" : data.bgColor ? data.bgColor :"var(--nw-green-light-color)",
    }),
    multiValueRemove: (styles, { data }) => {
        if (data.hideRemove) {//deactive "remove button'
            return {
                ...styles,
                color: data.color,
                display: 'none',
                width: 0,
                height: 0,
            }
        }
        return styles;
    },
};

export default function MultiSelect(props) {
	const { t } = useTranslation();

    const [labelStyle, setLabelStyle] = useState({
        position: "absolute",
        padding: "2px 5px",
        opacity: 0,
        transformOrigin: "left",
        left: "12px",
        top: "-11px",
        color: "var(--nw-blue-color)",
        background: "#ffffff",
        fontSize: "0.7em",
        zIndex: 1
    });

    /* eslint-disable */
    useEffect(()=>{
        setLabelStyle({...labelStyle, opacity: props.value?.length ? 1 : 0})
    }, [props.value])
    /* eslint-enable */

    return (
        <div style={{position: "relative"}}>
            <span style={labelStyle} htmlFor="multiSelect__control">{props.placeholder}</span>
            <Select
                onBlur={props.onBlur}
                onChange={props.onChange}
                isSearchable={false}
                value={props.value}
                name={props.name}
                id={props.id || props.name}
                key={props.name}
                isLoading={props.isLoading}
                isDisabled={props.isLoading || props.disabled}
                isMulti
                //isOptionDisabled={props.disabledOption ? (option) => option.value === props.disabledOption?.value : false}
                options={props.options} 
                defaultValue={props.defaultValue || []}
                placeholder={props.placeholder}
                //isOptionDisabled={(option)=> input.disabled.includes(option.value)} //use for creatable select
                classNamePrefix="multiSelect"
                styles={{...customStyles, ...props.customStyles}}
                noOptionsMessage={() => t("nw.multiSel.noOpt")}
                components={props.selectIcon ? {DropdownIndicator: (currentProps) => customDropdownIndicator(currentProps, props.selectIcon)} : {}}
            />
        </div>
    )
}

const customDropdownIndicator = (props, icon) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {icon}
          <span>&#x25BC;</span>
        </components.DropdownIndicator>
      )
    );
};