import {Navigate, Outlet, useLocation} from "react-router-dom";
//import {location} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {useFlashContext}  from 'Contexts/FlashContext';

export default function ProtectedRoute ({isAllowed, redirectPath = '/login', children,  isAuthenticated}) {
    const { t } = useTranslation();
    const location = useLocation(); //location.pathname //use to get resource e.g:/dashboard/accounts/1
    const switchedUser = sessionStorage.getItem('switchedAs');
    const {setFlash} = useFlashContext();

    if (switchedUser?.length < 1) {
        setFlash({
            alert: "warning",
            message: t("err.403", {url: window.location.href}),
            redirect: isAuthenticated ? null : location.pathname
        });
    }
    
    return !isAllowed ? (
        <Navigate to={redirectPath} replace/>
    ) : (
        children ? children : <Outlet />
    );
};