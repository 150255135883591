let apiUrl = "https://api.nanoways.com/api/";
let domainName = window.location.protocol ? window.location.protocol+"//" : "https://";
domainName +=  window.location.host;

if (process.env.NODE_ENV === 'development') {
    domainName = "http://localhost:3000";
    apiUrl = "https://localhost:8000/api/";
} else if(window.location.href.includes('newdev.nanoways')){
    domainName = "https://newdev.nanoways.com";
    apiUrl = "https://apidev.nanoways.com/api/";
}

export const DOMAIN_NAME = domainName;
export const API_URL = apiUrl;
/*Variable*/
export const RELOAD_RAS=5;
export const RELOAD_RAS_UNIT="s";
export const LICENSE_LIST_NUMBER=20;
export const JOURNAL_LIST_NUMBER=20;
/* UI supported languages*/
export const SUPPORTEDLNGS = ["en", "es","fr", "de", "it", "pt", "tr", "zh_CN", "zh_TW", "i18n"];

/*Recource User*/
export const API_LOGIN = API_URL + "v1/authentication_token";
export const API_USERS_REGISTER = API_URL + "v1/users/register";
export const API_USERS_ACCESS = API_URL + "v1/users/access";
export const API_USERS_ACCOUNT_DATA = API_URL + "v1/users/:id/account-data";
export const API_USERS_ACCOUNT_UPDATE = API_URL + "v1/users/:id/account-data";
//export const API_USERS_WITH_ACCESS = API_URL + "v1/users/:id/access";
export const API_USERS_ACCESS_UPDATE = API_URL + "v1/users/:id/access";
export const API_ACCESS_ROLEGRP_SWITCH = API_URL + "v1/users/:id/switch/role-grp";
export const API_PROFILE = API_URL + "v1/users/profile";
export const API_PASSWORD_UPDATE = API_URL + "v1/users/password";
export const API_CONFIRM_REGISTRATION = API_URL + "v1/users/confirm/";
export const API_PASSWORD_FORGOTTEN = API_URL + "v1/users/forgotten-password";
export const API_RESET_PASSWORD = API_URL + "v1/users/reset-password";
export const API_REFRESH_TOKEN = API_URL + "v1/token/refresh";
export const API_INVALIDATE_TOKEN = API_URL + "v1/token/invalidate";
//export const API_UPDATE_PASSWORD = API_URL + "users/update-password/";

/*Check*/
export const API_CHECK_LINK = API_URL + "v1/check/link/:token";

/*Resource LicenseUserConfirmation */
export const API_LICENSE_USER_CONFIRMATION = API_URL + "v1/license-user-confirmation/:token";

/*Recource Download*/
//export const API_LICENSE_CLEAR = API_URL + "v1/esd/downloads/defaults";
export const API_CHECK_DOWNLOAD_PACKET = API_URL + "v1/downloads/:id/packet";
export const API_DOWNLOAD_LICENSE_OPTIONS = API_URL + "v1/esd/downloads/:id/license/options";
export const API_LICENSE_CHECK_UPGRADECODE = API_URL + "v1/license-enhancement/";
export const API_LICENSE_CHECK_UPGRADE_PRODUCTS = API_URL + "v1/esd/Licenses/:key/upgrade/:code/check";
export const API_LICENSE_UPGRADE = API_URL + "v1/esd/Licenses/upgrade";
export const API_LICENSE_CLEAR_CUSTOM_OPTIONS = API_URL + "v1/esd/downloads/with-options";

/*Recource License*/
export const API_LICENSE_CHECK = API_URL + "v1/licenses/:key/check";
export const API_LICENSES_DETAILS = API_URL + "v1/licenses/:key/details";
export const API_LICENSES_PANEL = API_URL + "v1/licenses/:key/panel/:tabName";
export const API_LICENSES_TAB = API_URL + "v1/licenses/:key/:tab";
export const API_LICENSE_OPTIONS = API_URL + "v1/esd/licenses/:key/releases/options";
export const API_LICENSE_UPDATE_DATA = API_URL + "v1/self-register/license";
export const API_LICENSE_UPDATE_OWNER = API_URL + "v1/licenses/:key/user/:email";
export const API_LICENSE_ADD_USER = API_URL + "v1/user/add/license/:key";
export const API_LICENSES = API_URL + "v1/user/licenses/:limit/:tabename";

/* Journal */
export const API_JOURNAL = API_URL + "v1/journal/:limit/:tabename";

/*Recource User*/
export const API_ACCOUNT = API_URL + "v1/accounts/:email";
export const API_USER_INFO = API_URL + "v1/user/info";
export const API_USER_UPDATE_License = API_URL + "v1/user/update-license";
export const API_IMPERSONATE = API_URL + "v1/impersonate/user";

/*Access */
export const API_ACCESSES = API_URL + "v1/accesses";
export const API_ACCESS_UPDATE = API_URL + "v1/accesses/:id";
export const API_ACCESSES_FOR_USERS = API_URL + "v1/access/for-users";
export const API_ACCESSES_NODE = API_URL + "v1/accesses/node/:id";
export const API_ACCESSES_RENAME = API_URL + "v1/access/rename";
export const API_ACCESSES_CREATE = API_URL + "v1/access/create";
export const API_ACCESSES_REMOVE = API_URL + "v1/access/remove/";
export const API_ACCESS_TREE_UPDATE = API_URL + "v1/access-tree/update";

/*Recource Downloads*/
export const API_DOWNLOAD = API_URL + "v1/downloads/:pathname";
export const API_DOWNLOADS_LIST = API_URL + "v1/esd/downloads/code/:code";
export const API_GENERATE_EXPIRED_DOWNLOAD = API_URL + "v1/esd/downloads/:id/recreate";
export const API_DOWNLOADS_START = API_URL + "v1/esd/download-logs/startTime";
export const API_DOWNLOADS_END = API_URL + "v1/esd/download-logs/endTime";
export const API_DOWNLOADS_ERR = API_URL + "v1/esd/download-logs/err";

/*Recource RAS*/
export const API_SLOTS_LIST = API_URL + "v2/ras/slots/:tabName/team/:id";
export const API_HOSTS_TERAM_LIST = API_URL + "v2/ras/hosts/team/:id";
export const API_ASSISTANT_PAIR = API_URL + "v2/ras/assistant/pair/host/:hostId/slots/:handle";
export const API_ASSISTANT_UNPAIR = API_URL + "v2/ras/unpair/:handle";
export const API_ASSISTANT_SWITCH_FLAGS = API_URL + "v2/ras/slots/:handle/:flag";
export const API_ASSISTANT_HANGUP = API_URL + "v2/ras/hangup/:handle";
export const API_HOSTS_ADD = API_URL + "v2/ras/hosts";
export const API_HOSTS_DELETE = API_URL + "v2/ras/hosts";
export const API_TEAMS_ASSISTANT = API_URL + "v2/ras/teams";
export const API_TEAMS_AND_SLOT = API_URL + "v2/ras/teams/:handle";
export const API_P2P = API_URL + "v1/ras/p2p/:handle";
export const API_P2P_SLOT = API_URL + "v1/ras/slots/:handle/p2p";

/*Resource Team*/
//export const API_TEAMS = API_URL + "v1/teams";
export const API_TEAMUSER_DELETE = API_URL + "v1/teamUsers/:id";
export const API_TEAMUSER_ADD = API_URL + "v1/teamUsers";

/*Resource ActTx*/
export const API_UPDATE_ACT_TX = API_URL + "v1/acttx/:id/:status";

/*Resource LicenseSales*/
export const API_LICENSE_SALES_BUYER = API_URL + "v1/licenseSales/user/check-email/:email/:saleType";
export const API_LICENSE_CUSTOMER_SALE = API_URL + "v1/sell/licenses/to/customer";
export const API_LICENSE_RESELLER_SALE = API_URL + "v1/sell/licenses/to/reseller";
export const API_LICENSE_SALE_ACTION = API_URL + "v1/licenseSales/action/:action";

/* UI error */
export const API_UI_ERROR = API_URL + "v1/ui/error";