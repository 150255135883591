import {useState, useEffect} from "react";
import ACCESSAPI from 'Services/AccessAPI';
import {Tree} from './Tree';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import Spinner, {LoadingError} from 'Components/Loading/Spinner';
//import {Node} from './Node';
import NodeList from './NodeList';
import Field from 'Components/Forms/Field';
import MultiSelect from 'Components/Forms/MultiSelect';
import {useFlashContext}  from 'Contexts/FlashContext';

export default function TreeList() {
    const { t } = useTranslation();
    document.title = t("right.title")+ " – nanoways.com";
    const user = useSelector((state) => state.user);
  
	const [data, setData]= useState(null);
    const {flash, setFlash} = useFlashContext();

    const [reload, setReload] = useState(false);
    const [serverError, setServerError] = useState(false);
    const reloadPage = () => {
		setServerError(false);
        setReload(!reload);
    }
	//acc.page
	const hasPageAccess = user.roles?.includes('ROLE_77');
	const [selectedNode, setSelectedNode]= useState(null);

	const handleFlash = (error) => {
		setFlash(error);
	}
	
	const typeSearchOptions = [
		{value: 'disabled', label: <span title={t("right.node.type.off.tt")}><i className="fa-solid fa-ban nw-grey-dark me-2"></i>{t("right.node.type.off")}</span>,color: "var(--nw-grey-dark-color)", bgColor: "var(--nw-grey-light-color)"},
		{value: 'usrGrp', label: <span title={t("right.node.type.usrGrp.tt")}><i className="fa-solid fa-user-group nw-blue-dark me-2"></i>{t("right.node.type.usrGrp")}</span>,/*color: "var(--nw-green-dark-color)",*/ bgColor: "var(--nw-green-light-color)"},
		{value: 'usr', label: <span title={t("right.node.type.usr.tt")}><i className="fa-solid fa-user nw-blue-dark me-2"></i>{t("right.node.type.usr")}</span>,/*color: "var(--nw-green-dark-color)",*/ bgColor: "var(--nw-green-light-color)"},
		{value: 'rightGrp', label: <span title={t("right.node.type.rightGrp.tt")}><i className="fa-solid fa-layer-group nw-blue-dark me-2"></i>{t("right.node.type.rightGrp")}</span>,/*color: "var(--nw-green-dark-color)",*/ bgColor: "var(--nw-green-light-color)"},
		{value: 'right', label: <span title={t("right.node.type.right.tt")}><i className="fa-solid fa-circle-check nw-blue-dark me-2"></i>{t("right.node.type.right")}</span>,/*color: "var(--nw-green-dark-color)",*/ bgColor: "var(--nw-green-light-color)"},
	];
    const [, ...typeSearchDefault] = typeSearchOptions; //As default remove disabled rights
	const [filter, setFilter] = useState([]);
	const [criteria, setCriteria] = useState({
        disabled: false,
        types: []
    });
    const [isLoading, setIsLoading] = useState(false);

	const handleMultiSelectChange = (options, action)=>{
		setFilter([...options]);
        
        const newCriteria = {
            disabled: false,
            types: []
        }
        options.forEach(type => {
            if (type.value === "disabled") {
                newCriteria.disabled = true;
            }else{
                newCriteria.types.push(type.value);
            }
        });

        setCriteria({...newCriteria});
        setData(false)
	}

    /* eslint-disable */
	useEffect(() => {
		let isMounted = true;
        
		if (!data && isMounted && hasPageAccess) {
            setIsLoading(true);
            setSelectedNode(null);
			ACCESSAPI.getAll(criteria).then((res) => {
				const arr = res.data;
				//setAccess([])
				setData(arr)
			}).catch((err)=>{
				console.log('response: ', err.response);
				console.log('err: ', err);
				if(err.message?.includes('Network Error')){
					setServerError(true);
				} else {
                    setFlash({alert:"danger", message: t("err.unknown")});                    
                }
            }).finally(()=>{
                setIsLoading(false);
            });
		}

		return () => { isMounted = false }; // cleanup toggles value, if unmounted
	}, [reload, criteria]);
    /* eslint-enable */

    if(!hasPageAccess){
        return <LoadingError h2={t("nw.err.noAccess")} txt={t("nw.err.noAccess.txt")}/>
    }

	const handleTree = (functionName, params = null) => {
		if (functionName === 'selectNode'&& params.id && user?.roles.includes('ROLE_79')) {
			setSelectedNode([]);
			ACCESSAPI.getNodes(params.id).then((res) => {
				setSelectedNode([...res.data]);
			}).catch((err)=>{
				handleFlash({alert:"warning", message: t("err.500")});
			})
		}else if(functionName === "removeNode"){
			setSelectedNode(null);
		}
	}

	return (<>
        {serverError ?
            <LoadingError h2={t("nw.err.server")} txt={t("nw.err.server.txt")} reload={reloadPage} btn={t("nw.err.server.btn")}/>
			:
			<>
				{data === null && !flash && <Spinner className={"overlay"}/>}
				<div className="row pt-5 m-0 ps-lg-5">
					<div className="col-12 my-2 mb-5">
						<h1 className="text-center">{t("right.title")}</h1>
						{/*data.length>0 && <Tree data={data} selectedNode={selectedNode} handleFlash={handleFlash} refresh={(e) => refresh(e)}/>*/}
						{/*acc.three */}
						{user?.roles.includes('ROLE_78') &&
							
							<div className="row">
								<div className="col-lg-8">
                                    
                                    <div className="row">
                                        <div className="col-12 col-lg-5">
                                            <Field
                                                label={t("right.tree.filter")}
                                                placeholder={t("right.tree.filter.hint.textORid")}
                                                id="searchTree"
                                                class="search-tree"
                                            />
                                        </div>
                                        <div className="col-12 col-lg-7">
                                            <MultiSelect 
                                                name="typeSearch"
                                                placeholder={t("right.node.type")}
                                                //isLoading={toggle}
                                                value={filter}
                                                defaultValue={typeSearchDefault}
                                                options={typeSearchOptions} 
                                                onChange={handleMultiSelectChange}
                                                customStyles={multiSelectStyles}
                                                isLoading={isLoading}
                                            />
                                        </div>
                                    </div>
									{data?.accesses?.length>0 && data?.types?.length>0 &&
                                        <Tree 
                                            user={user}
                                            data={data}
                                            handleTree={handleTree}
                                            translate={t}
                                            handleFlash={handleFlash}
                                            criteria={criteria}
                                        />
                                    }
								</div>
								<div className="col-lg-4 mt-xl-0 mt-lg-0 mt-4">
									{user?.roles.includes('ROLE_79') && (
										(selectedNode === null && <p className='text-center text-warning fw-bold'>{t("right.node")}</p>) ||
										(selectedNode.length>0 &&
											<NodeList
												user={user}
												data={selectedNode}
												translate={t}
											/>
										) 
									|| 
										<p className='text-center text-warning fw-bold'>{}</p>
									)}
								</div>
							</div>
						}
					</div>
				</div>
			</>
        }		
	</>);
};

const multiSelectStyles = {
    control: (provided, state) => ( {
        ...provided,
        border: state.isFocused ? '1px solid var(--nw-blue-color)' : "1px solid var(--nw-grey-mute-light-color)" ,//provided.border,
        boxShadow: "0",
        paddingTop: "6px !important",
        paddingBottom: "6px !important",
        backgroundColor: "var(--nw-grey-light-color)"
    }),
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
};