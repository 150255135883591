import { useState, useRef, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import RasList from './RasList';
import RASAPI from "Services/RASAPI";
import { Container, Row, Tabs, Tab } from 'react-bootstrap';
import {ReactComponent as ReloadSvg} from "assets/images/icon/reload-16.svg";
import {v4 as uuidv4} from 'uuid';
import {RELOAD_RAS, RELOAD_RAS_UNIT} from 'config/nw-api';
import HostModal from './HostModal';
import DeleteHost from './DeleteHost';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Spinner, {LoadingError} from "Components/Loading/Spinner";
import {moveToBeginning, countOccurrences} from 'Services/utils';
import {useFlashContext}  from 'Contexts/FlashContext';


//const tabs = ["waitActive", "wait", "active", "served", "recent"]; //access name contains tabs name (ex: ROLE_ras.active)
const tabs = ["waitActive", "served", "recent"]; //access name contains tabs name (ex: ROLE_ras.active)

export default function Ras() {
    const { t } = useTranslation();
	const params = useParams();
    document.title = t("ras.title")+ " – nanoways.com";
    const user = useSelector((state) => state.user);
    const {setFlash} = useFlashContext();
	const [tabKey, setTabKey] = useState(tabs.find(
        (tab) => {
            if ((tab === "waitActive" && user?.roles.includes('ROLE_111')) || 
                (tab === "served" && user?.roles.includes('ROLE_54')) ||
                (tab === "recent" && user?.roles.includes('ROLE_55'))
            ) {
                return true;
            }
            return false
        }
    ));
    const tabsRef = useRef([]);
    const tabsLoadRef = useRef([]);
    const tabsTextRef = useRef([]);
    const handleMouseEnter = (name) => {
        tabsLoadRef.current[name]?.classList.remove('invisible');
        tabsTextRef.current[name]?.classList.add('invisible');
    };
    const handleMouseLeave = (name) => {
        tabsLoadRef.current[name]?.classList.add('invisible');
        tabsTextRef.current[name]?.classList.remove('invisible');
    };
    //const [activesTabs, setActivesTabs] = useState([tabs[0]]);
    const changeTab = (key) =>{
        setSlots(null);
        if (countOccurrences(window.location.href, t("link.ras.url"), '/') > 0 ) {
            window.history.pushState(null, '', t("link.ras.url"));
        }
        setTabKey(key);
       /* if (!activesTabs.includes(key)) {
            setActivesTabs([...activesTabs, key]);
        }*/
    }
    const [reload, setReload] = useState(false);
    const [serverError, setServerError] = useState(false);
    const reloadPage = () => {
        setServerError(false);
        setReload(!reload);
    }

    const [teams, setTeams] = useState(null);
    const [fetchedSlot, setFetchedSlot] = useState(null);
    const defaultTeam = {id: "default", name: t("ras.opt.team.sel")};
    //const [hosts, setHosts] = useState();
    const [data, setData] = useState({
        team: defaultTeam,
        hosts: null,
        reloadAuto: localStorage.getItem('rasReload') === 'true'? true:false
    });
    const [slotsLength, setSlotsLength] = useState({});
    const handleSlotsLengthChange = (name, length) => {
        if(name && slotsLength[name] !== length){
            setSlotsLength({...slotsLength, [name]: length});
        }
    };
    const [slots, setSlots] = useState(null);
	
	const [reloadAuto, setReloadAuto] = useState((localStorage.getItem('rasReload') === 'true')? true:false);
	const handleReloadChange = (e) => {
		localStorage.setItem('rasReload', !reloadAuto);
		localStorage.setItem('rasReload2', !reloadAuto);
		setReloadAuto(!reloadAuto);
        reloadAuto && setSlots(null); //By disabled reload slots
	}
    
	const addHostBtn = useRef(null);
	const addHost = (host) => {
        host.user.id = user.id;
        const updatedHosts = data.hosts.filter(item => item.id !== host.id); //[...data.hosts];
        updatedHosts.unshift(host);
        //sort hosts alphabetically & update Host list
        setData({...data, hosts: [...updatedHosts].sort((a, b) => a.name.localeCompare(b.name))});
        localStorage.setItem('rasSelectedHost', host.id);
        tabsRef.current[tabKey].addHost(host);
	}
    const removeHosts = (hostsIds) => {
        //const newHosts = data.hosts.filter((host)=> !hostsIds.includes(host.id));
        setData({...data, hosts: data.hosts.filter((host)=> !hostsIds.includes(host.id))})
    }
    
    const deleteHostBtn = useRef(null);
	const onChangeTeam = (e) => {
		const selectedTeam = teams.find(team => team.id === parseInt(e.currentTarget.value));
        setData({...data, team:selectedTeam});
        setSlots(null);
        localStorage.setItem('rasSelectedTeam', selectedTeam?.id);
        setSlotsLength({tabKey: slotsLength[tabKey]});
        if (countOccurrences(window.location.href, t("link.ras.url"), '/') > 0 ) {
            window.history.pushState(null, '', t("link.ras.url"));
        }
	}
	const hasPageAccess = user.roles?.includes('ROLE_51');//ras.page
 
    /* eslint-disable */
	useEffect(() => {
		let isMounted = true;
		if (!teams && isMounted && hasPageAccess) {
            RASAPI.teams(params.handle).then((res) => {
                const teamsData = res.data;
                setTeams(teamsData);
                if (teamsData.length > 0) {
                    const storedTeamId = parseInt(localStorage.getItem("rasSelectedTeam"));
                    let adHocTeam = null;
                    let storedTeam = null;
                    for (const team of teamsData) {
                        if (team.id === 0) {
                            adHocTeam = team;
                        }else if(team.id === storedTeamId){
                            storedTeam = team;
                        }
                    }
                    const selectedTeam = storedTeam ?? adHocTeam ?? teamsData[0];
                     
                    if(params.handle){
                        if (selectedTeam.slots?.length > 0) {
                            setFetchedSlot(selectedTeam.slots[0]);
                            if(selectedTeam.slots[0].status === 3){
                                if (selectedTeam.slots[0].durationSecond > 0 ) {
                                    setTabKey("served");
                                }else{
                                    setTabKey("recent");
                                }
                            }
                        }else{
                            setFlash({alert: "warning", message: t("ras.err.handle", {handle: params.handle})});
                        }
                    }
                    RASAPI.hosts(teamsData[0].id).then((res) => {
                        const hosts = res.data;
                        for (let i = 0; i < hosts.length; i++) {
                            if (hosts[i].id === 0) {
                                hosts[i].name = t("ras.slots.cols.host.vnc");
                                hosts[i].user = { email:user.email};
                                break;
                            }
                        }
                        hosts.sort((a, b) => {
                            //Put VNC-web host at first position
                            if (a.id === 0 && b.id !== 0) {
                                return -1;
                            } else if (a.id !== 0 && b.id === 0) {
                                return 1;
                            }
                            
                            //Put private hosts after vnc
                            if (a.team === null && b.team !== null) {
                                return -1;
                            } else if (a.team !== null && b.team === null) {
                                return 1;
                            }

                            return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
                        });
                        setData({...data, hosts:hosts, team: selectedTeam});
                        //setHosts(arrHosts);
                    }).catch((err)=>{
                        console.log('response: ', err.response);
                        console.log('err: ', err);
                        if (err.response) {
                            setFlash({alert:"danger", message: t("err.500")});
                        }else if(err.message?.includes('Network Error')){
                            console.log("network error like timeout, connection refused etc...");                          
                            setServerError(true);
                        }else{
                            setFlash({alert:"danger", message: t("err.unknown")});
                        }
                    });	
                }else{
                    console.log("No Team found");
                }
            }).catch((err)=>{
                console.log('response: ', err.response);
                console.log('err: ', err);
                if (err.response) {
                    setFlash({alert:"danger", message: t("err.500")});
                }else if(err.message?.includes('Network Error')){
                    console.log("network error like timeout, connection refused etc...");                          
                    setServerError(true);
                }else{
                    setFlash({alert:"danger", message: t("err.unknown")});
                }
            });
        }

        return () => { isMounted = false }; // cleanup toggles value, if unmounted
	}, [reload]);
    
	useEffect(() => {
		let isMounted = true;

		if (data.team?.id !== defaultTeam.id && slots === null && isMounted) {
			RASAPI.slots(data.team?.id, tabKey).then((res) => {
                let table = []
				if (res.data.length > 0) {
					//Detrminate if Handle still exists in the URL
					if(countOccurrences(window.location.href, t("link.ras.url"), '/') > 0 && params.handle){
						table = moveToBeginning(res.data, fetchedSlot?.id) ;
					}else{
						table = res.data;
					}
                    const rasSelectedHost = localStorage.getItem('rasSelectedHost');
                    const defaultHost = rasSelectedHost ? data.hosts?.find(host=> host.id === parseInt(rasSelectedHost)) : null;
                    if (defaultHost) {
                        table = table.map(slot => ({
                            ...slot,
                            host: slot.host === null ? defaultHost : slot.host
                        }));
                    }

				    //sessionStorage.setItem('rasListTeam'+ data.team?.id, JSON.stringify(table));//Workaround to fix phantom slot in RasList
					setSlots([...table]);
					handleSlotsLengthChange(tabKey, table.length);
					//document.getElementById(`count${props.name}`).innerText = `(${table.length})`;	
				}else{
					setSlots(table);
					handleSlotsLengthChange(tabKey, 0);
				}
			}).catch((err)=>{
                console.log(err);
				const {response} = err;
				if (response) {
					console.log('Response: ', response);
					setFlash({alert:"danger", message: t("err.unexpected")});
				} else {
					console.log('NoResponse: ', "Network error like timeout, connection refused etc...");
					console.log(err);
					setFlash({alert:"danger", message: t("err.500")});
				}
			});
		}

		return () => { isMounted = false };
	}, [slots, data.team, tabKey]);
    /* eslint-enable */

    if(!hasPageAccess){
        return <LoadingError h2={t("nw.err.noAccess")} txt={t("nw.err.noAccess.txt")}/>
    }
			
    return (
        <>
            {serverError ? 
                <LoadingError h2={t("nw.err.server")} txt={t("nw.err.server.txt")} reload={reloadPage} btn={t("nw.err.server.btn")}/>
                :
                <>
                    {teams === null && <Spinner className={"overlay"}/>}
                    <div className={`${teams ? " bg-white min-vh-100":""} p-5`}> {/*Correct height by loading (remove scroll) */}
                        <div className="row mb-5">
                            <div className="col-12 px-0 mx-0">
                                <h1 className="text-center">{t("ras.title")}</h1>
                                {teams?.length > 0 ? 
                                    <>
                                        <div className="row px-0 d-flex align-items-center">
                                            <div className="col-md-6 py-2 px-0">
                                                <span className="pe-3">{t("ras.opt.team")}</span>
                                                <select
                                                    key={uuidv4()}
                                                    name="teams"
                                                    value={data.team?.id}
                                                    style={{width: `${(data.team?.name.length)+0.1}ch`}}
                                                    onChange={(e) => onChangeTeam(e)}
                                                    className="py-2 ps-2 pe-5 w-auto"
                                                >
                                                    <option disabled value="default">{t("ras.opt.team.sel")}</option>
                                                    {
                                                        teams.map((team)=>{
                                                            return  (
                                                                <option 
                                                                    key={uuidv4()}
                                                                    value={team.id}
                                                                    title={team.name}
                                                                    className="fn-code"
                                                                >
                                                                    {team.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-md-6 py-2 px-0">
                                                <div className="float-md-end">
                                                    <label title={t("ras.opt.reload.tt", {time: RELOAD_RAS + RELOAD_RAS_UNIT})} htmlFor="switchReload" className="switch-checkbox">
                                                        <input 
                                                            type="checkbox"
                                                            name="switchReload"
                                                            id="switchReload"
                                                            onChange={handleReloadChange}
                                                            className="switch-checkbox-input"
                                                            value={reloadAuto}
                                                            checked={reloadAuto} 
                                                        />
                                                        <div className="switch-checkbox-custom"></div>
                                                        <span className="switch-checkbox-label">{t("ras.opt.reload")}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                    
                                        {data.team?.id !== "default" &&
                                        <div className="col-12 tabs">
                                            <Container className="default">
                                                <Row>
                                                    <Tabs
                                                        id="controlled-tab"
                                                        activeKey={tabKey}
                                                        onSelect={(k) => changeTab(k)}
                                                        className=""
                                                    >
                                                    {tabs.map((tabName)=>{
                                                        if (
                                                            (tabName === "waitActive" && user?.roles.includes('ROLE_111')) || 
                                                            (tabName === "served" && user?.roles.includes('ROLE_54')) ||
                                                            (tabName === "recent" && user?.roles.includes('ROLE_55'))
                                                        ) {
                                                            return  (
                                                                <Tab 
                                                                    disabled={slots === null ? true :false}
                                                                    key={uuidv4()}
                                                                    tabClassName={`${tabKey === tabName && "nw-bg-blue-light"} "rounded-0 rounded-top"`}
                                                                    eventKey={tabName}
                                                                    title={
                                                                        <span
                                                                            className='position-relative'
                                                                            onMouseEnter={slots && tabKey === tabName ? (e)=>handleMouseEnter(tabName): null}
                                                                            onMouseLeave={slots && tabKey === tabName ? (e)=>handleMouseLeave(tabName) : null}
                                                                        >
                                                                            {t(`ras.tabs.${tabName}`)}
                                                                            <span
                                                                                ref={el => tabsLoadRef.current[tabName] = el}
                                                                                name={tabName}
                                                                                style={{marginTop: "-4px"}}
                                                                                className={`${slots === null && tabKey === tabName? '': 'invisible '} position-absolute end-0`}
                                                                                onClick={() => setSlots(null)}
                                                                            >
                                                                                {tabKey === tabName && 
                                                                                    <ReloadSvg className={`${slots === null && tabKey === tabName? 'loader-elt ' : ''}`} fill="var(--nw-blue-color)"/>
                                                                                }
                                                                            </span>
                                                                            
                                                                            <span 
                                                                                ref={el => tabsTextRef.current[tabName] = el}
                                                                                className={`${slots === null && tabKey === tabName? 'invisible': ''} ms-1`}
                                                                            >
                                                                                {slotsLength[tabName] !== undefined ?"(" +slotsLength[tabName]+")": tabKey === tabName ? "(0)" : ""}
                                                                            </span>
                                                                        </span>
                                                                    }
                                                                >
                                                                </Tab>
                                                            ) 
                                                        }
                                                        return null;
                                                    })}
                                                    </Tabs>
                                                    <RasList
                                                        name={tabKey}
                                                        data={data}
                                                        tableData={slots}
                                                        fetchedSlot={fetchedSlot}
                                                        handleSlotsLengthChange={handleSlotsLengthChange}
                                                        formHost={addHostBtn}
                                                        formDeleteHost={deleteHostBtn}
                                                        ref={el => tabsRef.current[tabKey] = el}
                                                    />
                                                </Row>
                                            </Container>
                                        </div>
                                        }
                                    </>
                                :
                                    <div className="pt-5 mt-5 h4 text-center nw-red-dark">{teams && t("ras.err.team")}</div>
                                }
                            </div>
                        </div>
                    </div>
                    
                    <a href="/#" ref={addHostBtn} className="invisible" data-bs-toggle="modal" data-bs-target="#hostModal">[ADD HOST]</a>
                    <HostModal addHost={addHost} hosts={data.hosts} team={data.team?.id}/>

                    <a href="/#" ref={deleteHostBtn} className="invisible" data-bs-toggle="modal" data-bs-target="#deleteHostModal">[DELETE HOST]</a>
                    <DeleteHost removeHosts={removeHosts} hosts={data.hosts?.filter(host => host.user?.id === user.id)}/>
                </>
            } 
        </>
    )
}
