import { useState, useRef } from 'react';
import Field from 'Components/Forms/Field';
import PasswordField from 'Components/Forms/PasswordField';
//Import translation to find all countries
import UsersAPI from "Services/UsersAPI";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PasswordChecklist from "react-password-checklist";
import { checkPwd } from 'Components/Tools/Text';
import Spinner from 'Components/Loading/Spinner';
//import Checked from "assets/images/icon/checked.svg";

export default function Pwd() {
    const { t } = useTranslation();
    document.title = t("profile")+ " – nanoways.com";
    const navigate = useNavigate();
    const formRef = useRef();

    const [pwd, setPwd] = useState({
        oldPwd: "",
        newPwd: "",
        confirmPwd: "",
    });

    const [errors, setErrors] = useState({
        oldPwd: "",
        newPwd: "",
        confirmPwd: "",
        api:""
    });
    const [isLoading, setIsLoading] = useState(false);

    // Manage input changing in form
    const handleChange = (e) => {
        const {name, value} = e.currentTarget;
        setPwd({...pwd, [name]: value});
    };

    // Manage form submission
    const handleSubmit = async event => {
        setIsLoading(true);
        setErrors({});
        let currentErrors = {};
        event.preventDefault();
        for (const name in pwd) {
            if (!pwd[name]) {
                currentErrors[name] = "nw.input.err.required";
            } 
        }
        
        if (pwd.confirmPwd.length> 0 && pwd.newPwd !== pwd.confirmPwd) {
            currentErrors.newPwd = "nw.input.pwd.confirm.err";
        } else if (pwd.newPwd && !checkPwd(pwd.newPwd)) {
            currentErrors.newPwd = "nw.input.pwd.err.strong";
        }/*else if (pwd.newPwd.length>0 && pwd.newPwd === pwdPwd.old) {
            setPwd({old: "",new: "",confirm: ""});
            formRef.current.reset();
            currentErrors.used = "used";
        }*/

        setErrors(currentErrors);
      
        if (Object.keys(currentErrors).length === 0) {
            await UsersAPI.updatePassword(pwd)
            .then((resp)=>{
                setIsLoading(false);
                let flash = {alert: "success", message: t("user.pwd.reset.ok")+`<br/><a className="dropdown-item" href="/#" data-bs-toggle="modal" data-bs-target="#signInModal">${t("user.menu.signIn")}</a>`, logout: true};
                navigate("/", {replace: true, state:flash});
            })
            .catch((err)=>{
                setIsLoading(false);
                if(!err.response || err.response.data.status === 500){
                    console.log(err.message);
                    setErrors({api:"err.500"});
                } else if(err.response.status === 403) {
                    setErrors({oldPwd:"nw.input.pwd.err.invalid"});
                } else if(err.response.status === 422) {
                    setErrors({newPwd:"nw.input.pwd.err.strong"});
                }else{
                    console.log(err.response);
                }
            })
        }else{
            setIsLoading(false);
        }
    };

    const [newPwdType, setNewPwdType] = useState();
    const [setOldPwdType] = useState();
    
    return (
        <>
            <div className="m-0 p-0 vertical-center">
                <div className="bm-main">
                    <div className="bm-card">
                        <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
                        <h1>{t("profile")}</h1>
                        <h2 className="bm-sep-1">{t("profile.pwd")}</h2>

                        {errors.api && <p className="fn-smtxt invalid-feedback d-block">{t(errors.api)}</p>}
                        <form ref={formRef} onSubmit={handleSubmit} className="pwd-Reset-form fn-smtxt">
                            <PasswordField
                                id="oldPwd"
                                name="oldPwd"
                                label={t("nw.input.pwd.old")}
                                placeholder={t("nw.input.pwd.old.hint")}
                                value={pwd.oldPwd}
                                disabled={isLoading && "disabled"}
                                onChange={handleChange}
                                attrs={{autoComplete:"new-password", autoFocus:"on"}}
                                error={t(errors.oldPwd)}
                                shareType={setOldPwdType}
                            />
                            <PasswordField
                                id="newPwd"
                                name="newPwd"
                                label={t("nw.input.pwd.new")}
                                placeholder={t("nw.input.pwd.new.hint")}
                                value={pwd.newPwd}
                                disabled={isLoading && "disabled"}
                                onChange={handleChange}
                                attrs={{autoComplete:"new-password"}}
                                error={t(errors.newPwd, {num:8})}
                                shareType={setNewPwdType}
                            />
                            <Field
                                id="confirmPwd"
                                name="confirmPwd"
                                label={t("nw.input.pwd.confirm")}
                                type={newPwdType}
                                placeholder={t("nw.input.pwd.confirm.hint")}
                                value={pwd.confirmPwd}
                                disabled={isLoading && "disabled"}
                                onChange={handleChange}
                                error={t(errors.confirmPwd)}
                            />
                            <div className="bm-register-checklist">
                                <PasswordChecklist
                                    rules={["capital","lowercase","number","minLength","match"]}
                                    minLength={8}
                                    value={pwd.newPwd}
                                    valueAgain={pwd.confirmPwd}
                                    onChange={(isValid) => {}}
                                    messages={{
                                        capital: t("nw.checkPwd.capital"),
                                        lowercase: t("nw.checkPwd.lower"),
                                        number: t("nw.checkPwd.num"),
                                        minLength: t("nw.checkPwd.min", { num: 8}),
                                        match: t("nw.checkPwd.match"),
                                        //specialChar: t("nw.checkPwd.char"),
                                    }}
                                />
                            </div>
                            <div className="bm-nav-card-bottom align-items-center">
                                <button type="submit" disabled={isLoading ? "disabled" : ""} className="bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                                    {isLoading &&
                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                    }
                                    <span className={isLoading ? "invisible" : ""}>{t("profile.pwd.btn")}</span>
                                </button>
                                <div className="bm-clearer"></div>
                            </div>                            
                        </form>
                    </div> 
                </div>
            </div>
        </>
    )
}
