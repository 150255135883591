
import {useState, useEffect, useRef} from 'react';
import { useTranslation } from "react-i18next";
import './index.css';

const SearchInput = (props) => {
    const { t } = useTranslation();
	const [searchText, setSearchText] = useState(props.text || '');
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current?.value.trim() !== '' && props.selectAll) {
            // Select all text in the input field
            inputRef.current?.select();
        }
    }, [props.selectAll])

    const deleteText = (e) => {
        setSearchText('');
        props.reloadTab(e);
        //Try pass value to filter input
        /*const element = document.querySelector('[aria-label="Search"]');

        if (element) {
            element.setAttribute('value',  '')
            element.value ='';
        }*/
    }

    const searchData = (e) => {
        props.reloadTab(e, {search:searchText});
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            props.reloadTab(e, {search:searchText});
        }
    };

    const handleChangeSearch = (e) => {
        setSearchText(e.currentTarget.value);
        //Try to filter by searching data but event will be not triggered in filter input
        /*const element = document.querySelector('[aria-label="Search"]');
        // Check if the element is found
        if (element) {
            const value = e.currentTarget.value;
            element.focus()
            element.setAttribute('value',  e.currentTarget.value)
            element.value = e.currentTarget.value;
            element._wrapperState.initiaValue = value
            // Trigger the onChange event
            const event = new Event('change', { bubbles: true, composed: false });
            element.dispatchEvent(event);
            e.currentTarget.focus()

        } else {
            console.log("Element not found");
        }*/
    }

    return <div style={{ position: "relative", paddingTop: "3px", marginRight: "8px",marginLeft: "8px"}} className="mt-1"> 
        <span 
            className={`${searchText?.length > 0 && 'nw-opacity-6'}`}//'nw-opacity-6'
            style={{
                marginTop: "2px",
                position: "absolute",
                cursor: "pointer",
                zIndex: 1
            }}
            onClick={searchData}
        >
            <i title={searchText?.length > 0 ? t("nw.list.opt.search") : t("nw.list.opt.search.refresh")} className={`${searchText?.length > 0 ? 'left-icon fas fa-search' : 'fa-solid fa-rotate'} nw-blue-dark`}></i>
        </span>
        <input
            id={props.inputId}
            ref={inputRef}
            autoFocus={props.text ? true :false}
            className="search-input-table nw-bg-blue-light"
            type="text"
            placeholder={t('nw.list.opt.search')}
            title={`${t("nw.list.opt.search.tt")} \n ${props.tooltipList?.length > 0 ? props.tooltipList.map((item) => "\n- "+t(item)).join(''): ""}`}
            onChange={handleChangeSearch}
            onKeyDown={searchText?.length > 0 ? handleKeyPress : null}
            value={searchText}
        />
        <span 
            className={`${searchText?.length > 0 && 'nw-opacity-6'}`}//'nw-opacity-6'
            style={{
                marginTop: "2px",
                position: "absolute",
                right: "5px",
                cursor: searchText?.length > 0 ? "pointer" : "auto",
            }}
            onClick={searchText?.length > 0 ? deleteText : null}
        >
            <i className={`${searchText?.length > 0 ? 'nw-blue-dark': 'nw-grey'} left-icon fas fa-close`} ></i>
        </span>
    </div>;
};

export default SearchInput;