import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import { SUPPORTEDLNGS } from "config/nw-api";
import TRANSLATIONS_DE from "./de/translation.json";
import TRANSLATIONS_EN from "./en/translation.json";
import TRANSLATIONS_ES from "./es/translation.json";
import TRANSLATIONS_FR from "./fr/translation.json";
import TRANSLATIONS_IT from "./it/translation.json";
import TRANSLATIONS_PT from "./pt/translation.json";
import TRANSLATIONS_TR from "./tr/translation.json";
import TRANSLATIONS_zh_CN from "./zh_CN/translation.json";
import TRANSLATIONS_zh_TW from "./zh_TW/translation.json";
import TRANSLATIONS_I18N from "./i18n/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //debug:true,
    // order and from where user language should be detected
    //order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain','querystring'],
    detection: {
		order: ['querystring', 'localStorage', 'navigator', 'htmlTag', 'sessionStorage'],
		// keys or params to lookup language from
		lookupQuerystring: 'lng',
		lookupCookie: 'i18next',
		lookupLocalStorage: 'i18nextLng',
		lookupSessionStorage: 'i18nextLng',
		lookupFromPathIndex: 0,
		lookupFromSubdomainIndex: 0,

		// cache user language on
		caches: ['localStorage'],
		excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

		// optional expire and domain for set cookie
		cookieMinutes: 10,
		cookieDomain: 'nanoways.com',

		// optional htmlTag with lang attribute, the default is:
		htmlTag: document.documentElement,

		// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
		cookieOptions: { path: '/', sameSite: 'strict' },
    },
    
    parseMissingKeyHandler: (key, defaultValue) => {
      return process.env.NODE_ENV === 'development' ? key : defaultValue;
    },
    
    //overloadTranslationOptionHandler:args => {return { defaultValue: '' }}, //Used for all keys
    //lng: "en", // if you're using LanguageDetector, do not define the lng option
    supportedLngs: SUPPORTEDLNGS,
    fallbackLng: "de",// use en if detected lng is not available
    resources: {
		de: {translation: TRANSLATIONS_DE},
		en: {translation: TRANSLATIONS_EN},
		es: {translation: TRANSLATIONS_ES},
		fr: {translation: TRANSLATIONS_FR},
		it: {translation: TRANSLATIONS_IT},
		pt: {translation: TRANSLATIONS_PT},
		tr: {translation: TRANSLATIONS_TR},
		'zh_CN': {translation: TRANSLATIONS_zh_CN},
		'zh_TW': {translation: TRANSLATIONS_zh_TW},
		i18n: {translation: TRANSLATIONS_I18N}
    },
    interpolation: {
		format: function (value, format, lng) {
			if (value instanceof Date) return moment(value).format(format);
			if (typeof value === "number")
				return new Intl.NumberFormat(lng).format(value);
			return value;
		},
		escapeValue: false,
    },
  });

export { i18n };
