
import {useState, useRef} from 'react';
import { useTranslation } from "react-i18next";
import { localeDatetime } from "Components/Tools/DateTime";
import { useSelector } from 'react-redux';
import ActTxAPI from 'Services/ActTxAPI';

const type = ['Dauerhaft','Deaktivierung','Einzel'];

export default function ActivationDetails(props) {
    const { t } = useTranslation();
    const [activations, setActivations] = useState(props.data.license?.actTxes);
    const systems = useRef(props.data.systems);
    const SystemsAlias = useRef(props.data.license?.actTxAliases);
	const user = useSelector((state) => state.user);
    const editIgnoreField = user?.roles.includes('ROLE_161');
    const showIgnoreField = user?.roles.includes('ROLE_160');
    //const closeBtn = useRef(null);
    //closeBtn.current.click();
    const isActive = (act) => {
        if(act.referenceObject){
            return act.referenceObject.action === 1 ? false : true;
        }
        return act.action === 1 ? false : true;
    }
    const handleChangeCheckbox = (actId, key, value) =>{
        const newStatus = (value !== 'regular') ?  'regular' :'canceled';
        const data = [...activations];
        data[key].status = newStatus;
        setActivations([...data]);
        ActTxAPI.update({id:actId, status: newStatus})
        .then((res) => {
            console.log(res);
        }).catch((err)=>{
            console.log(err);
            //setError("network error like timeout, connection refused etc...");
            const {response} = err;
            if (response) {
            } else {
                console.log("network error like timeout, connection refused etc...");
            }
        });
    }

    return (
        <div className="my-3">
            <h3 className="text-center h2">{t("licenses.list.detail.activation")}</h3>
            {activations && <>
                <div className="row px-5 pt-2 m-0">
                    <div className="fw-bold col-2 nowrap">Date</div>
                    <div className="fw-bold col-3 nowrap">Product</div>
                    <div className="fw-bold col-1 nowrap">System/Cap</div>
                    <div className="fw-bold col-1 nowrap">Type</div>
                    <div className="fw-bold col-2 nowrap">Active</div>
                    {showIgnoreField && <div className="fw-bold col-1 nowrap">Ignore</div>}
                    <div className="fw-bold col-2 nowrap">Last change</div>
                </div>
                <div className="bm-sep-2 mt-1"/>
                {
                    activations?.map((activation, idx) => {

                        const active = isActive(activation);
                        const alias = SystemsAlias.current?.find(item => item.smbiosid === activation.systemId32)?.name
                        //TODO: change font color (green, red, grey, blue, orange)? 
                        //TODO: show hide column 
                        //  style={{color: `${activation.action === 0 ? "var(--nw-green-color)": ""}`}}
                        return <div key={'down'+idx} className="row px-5 py-2 m-0">
                            <div className="col-2 nowrap">
                                {localeDatetime(activation.createdAt)}
                            </div>
                            <div className="col-3 nowrap">
                                {activation.productAnt?.name}	
                            </div>
                            <div className="col-1 nowrap">
                                {alias ?? "System"+systems.current[activation.systemId32]}	
                            </div>
                            <div className="col-1 nowrap">
                                {type[activation.action]}
                            </div>
                            <div className={`${active ? "text-decoration-line-through" : ""} col-2 nowrap`}>
                                {active ? 'nein': 'ja'}	
                            </div>
                            {showIgnoreField && <div className="col-1 nowrap">
                                <input 
                                    disabled={!editIgnoreField}
                                    type="checkbox"
                                    name={`ignore${idx}`}
                                    id={`ignore${idx}`}
                                    onChange={() => handleChangeCheckbox(activation.id, idx, activation.status)}
                                    className="form-check-input"
                                    //value={activation.status === 'regular'}
                                    checked={activation.status === 'regular'} 
                                />
                            </div>}
                            <div className="col-2 nowrap">
                                {activation.statusChangeTime ? localeDatetime(activation.statusChangeTime): t("gen.text.unavailable.abbr")}		
                            </div>											
                        </div>
                    })
                }
            </>}
        </div>
    )
}
