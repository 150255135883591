import 'variables.css';
//import {v4 as uuidv4} from 'uuid';
import { useTranslation } from "react-i18next";
import {useState, useEffect, useRef} from 'react';
import MaterialTable, { MTableToolbar, MTableAction } from '@material-table/core';
import LICENSEAPI from 'Services/LicenseAPI';
import LicenseAddModal from "./Components/LicenseAddModal";
import LicenseEditModal from "./Components/LicenseEditModal";
import SoldToResellerModal from "./Components/SoldToResellerModal";
import SoldToCustomerModal from "./Components/SoldToCustomerModal";
import ConfirmModal from "./Components/ConfirmModal";
import { forwardRef } from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import {ReactComponent as DownloadSvg} from "assets/images/icon/cloud-download.svg";
import {ReactComponent as ListSvg} from "assets/images/icon/list.svg";
import {ReactComponent as CopySvg} from "assets/images/icon/copy.svg";
import {ReactComponent as InfoSvg} from "assets/images/icon/info.svg";
import {ReactComponent as PlusSvg} from "assets/images/icon/plus.svg";
import {ReactComponent as EditSvg} from "assets/images/icon/edit-pen.svg";
import {ReactComponent as RetractSvg} from "assets/images/icon/retract.svg";
import {ReactComponent as RejectSvg} from "assets/images/icon/x-circle-.svg";
import {ReactComponent as AcceptSvg} from "assets/images/icon/check-circle.svg";
import {ReactComponent as PlusInfoSVG}  from "assets/images/icon/plus.circle.svg";
import {ReactComponent as UpdateAgentSVG}  from "assets/images/icon/cirrarr.down.16.svg";
import {ReactComponent as NoUpdateAgentSVG}  from "assets/images/icon/cirarr.right.16.svg";
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Check from '@material-ui/icons/Check';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { SliceText, compareVersions, languagesToList } from 'Components/Tools/Text';
import {localeDatetime} from 'Components/Tools/DateTime';
import useScreenSize from 'Hook/useScreenSize';
import {LICENSE_LIST_NUMBER} from 'config/nw-api';
import SearchInput from "Components/MaterialTable/SearchInput";
import ChangeLimit from "Components/MaterialTable/ChangeLimit";
import {getNotExpiredLocalStorage, setHideColsLocalStorage} from "Services/utils";
import FLAGS from "assets/images";
import Panel from './Components/Panel';

let tableIcons = {
    //ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={{marginTop:"1px"}} className="nw-blue"/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} className="nw-green-dark"/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} htmlColor="red"/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} style={{width:"0px", heigh:"0px"}} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} className="nw-blue"/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} data-hide="small" ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} data-hide="small" ref={ref} />),
};
//First import custom Css variables before read rootStyles
const blueColor = getComputedStyle(document.documentElement).getPropertyValue('--nw-blue-color');

const theme = createTheme({
    palette: {
		primary: {main: blueColor,},
		fade: {main: blueColor,},
    },
});

const searchInputId = 'searchLic';
const HIDDEN_COLS_EXP_SECONDS = 1209600; //14 Days in seconds

const LicenseList = forwardRef((props, ref) => {
	//button needs event will be im component used
	const [columns, setColumns] = useState([]);
    const storageHidddenCols = getNotExpiredLocalStorage('lic_hide_', props.name);
    const allCheckedRef = useRef(null);
    const [sellData, setSellData] = useState({licenses: [], products: []});

    /* eslint-disable */
	useEffect(() => {
		if (props.colsStructure) {
			const cols = [];
			const hiddenCols = [];
			for (const colName in props.colsStructure) {
				const data = props.colsStructure[colName];
                if (colName === 'col_check') {
					let hide = false;

					if (data[0] === '') {
						hiddenCols.push("selectOnList");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("selectOnList") : hide;
					cols.push({
						hidden: hide,
                        hiddenByColumnsButton:true,
						title: <span className='checkAllList' title={t("licenses.list.cols.key.titleTT")}>
                            <input
                                ref={allCheckedRef}
                                type='checkbox'
                                onChange={(e) => {
                                    e.stopPropagation();
                                    const allChecked = allCheckedRef.current?.checked;

                                    if (!allChecked) {
                                        setSellData({licenses: [], products:[]});
                                    }

                                    const products = [];
                                    const licensesList = [];

                                    setTableData(prevData => {
                                        return prevData.map(item => {
                                            if (item.waitingSales === false) {
                                                if (allChecked) {
                                                    licensesList.push(item);

                                                    const existingProductIndex = products.findIndex(product => product.name === item.productName);
                                                    if (existingProductIndex !== -1) {
                                                        products[existingProductIndex].countKeys++;
                                                    } else {
                                                        products.push({ name: item.productName, countKeys: 1 });
                                                    }
                                                }

                                                return {...item, isChecked: allChecked};
                                            }

                                            return {...item, isChecked: false};
                                        });
                                    });
    
                                    setSellData({licenses: licensesList, products:products});
                                }}
                            />
                            </span>,
						field: "selectOnList",
						disableClick:true,
						grouping: false,
						width: `${data[1]}%`,
						initialEditValue: "",
						render: function(rowData){
                            const isWaitingForSale = rowData.waitingSales > 0;

							return <input 
                                disabled ={isWaitingForSale}
                                type="checkbox"
                                checked={rowData.isChecked || false}
                                className={`${isWaitingForSale ? "disabledElt":""}`}
                                onChange={(e)=>{
                                    let allAreChecked = true;
                                    const products = [];
                                    const licensesList = [];
                                    setTableData(prevData => {
                                        return prevData.map(item => {
                                            allAreChecked = allAreChecked && item.isChecked;
                                            const existingProductIndex = products.findIndex(product => product.name === item.productName);
                                            if (item.currentKey === rowData?.currentKey) {
                                                allAreChecked = allAreChecked && !item.isChecked;
                                                if (!item.isChecked) {
                                                    licensesList.push(item);
                                                    if (existingProductIndex !== -1) {
                                                        products[existingProductIndex].countKeys++;
                                                    } else {
                                                        products.push({name: item.productName, countKeys: 1 });
                                                    }
                                                }else{
                                                    if (existingProductIndex !== -1) {
                                                        const count = products[existingProductIndex].countKeys;
                                                        if(count === 1){
                                                            products.splice(existingProductIndex, 1);
                                                        }else{
                                                            products[existingProductIndex].countKeys = count-1;
                                                        }
                                                        
                                                    }
                                                }

                                                return {...item,
                                                    isChecked: item.isChecked ? false : true,
                                                };
                                            }else{
                                                if (item.isChecked) {
                                                    licensesList.push(item);
                                                    if (existingProductIndex !== -1) {
                                                        products[existingProductIndex].countKeys++;
                                                    } else {
                                                        products.push({name: item.productName, countKeys: 1 });
                                                    }
                                                }
                                            }

                                            return item;
                                        });
                                    });
                                    allCheckedRef.current.checked = allAreChecked;
                                    setSellData({licenses: licensesList, products:products});
                                }} 
                            />
						},
					});
				}
				if (colName === 'col_licenseKey') {
					let hide = false;

					if (data[0] === '') {
						hiddenCols.push("currentKey");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("currentKey") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.key.titleTT")}>{t("licenses.list.cols.key")}</span>,
						field: "currentKey",
						disableClick:true,
						//grouping: false,
						width: `${data[1]}%`,
						initialEditValue: "",
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						render: function(rowData){
							const license = rowData.currentKey;
			
							if (license) {
								return <>
								<div className='nowrap pe-2 mt-0'>
									<span
										id={'iconLicense'+license}
										className='me-1'
										title={t("licenses.list.cols.key.tt", {key: license})}
										//className={`${license === isHovered ? '':'d-none'}`}
										//style={{position: "absolute",top:"5px", right:"10px"}}
										onMouseUp={(e) => handleMouseUp(e, license)}
										onMouseDown={(e) => handleMouseDown(e, license)}
									>
										<CopySvg
											ref={el => copyIconsRef.current[license] = el}
											className='isNotClickable m-0 p-0 border-0 bg-transparent'
											width="24"
											fill="var(--nw-blue-color)"
										/>
									</span>
									<span title={license}>{license}</span>
								</div>
								
								</>
							}
							return null
						},
					});
				}
				if (colName === 'col_updateAgent') {
					let hide = false;

					if (data[0] === '') {
						hiddenCols.push("col_updateAgent");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("col_updateAgent") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.newAgent.titleTT")}>{t("licenses.list.cols.newAgent")}</span>,
						field: "updateAgent",
						disableClick:true,
						//grouping: false,
						width: `${data[1]}%`,
						initialEditValue: "",
						render: function(rowData){
                            return rowData.updateAgent ? <UpdateAgentSVG width="20" height="20"/> : <NoUpdateAgentSVG width="20" height="20"/>
						},
					});
				}
				if (colName === 'col_caption') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("caption");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("caption") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.caption.titleTT")}>{t("licenses.list.cols.caption")}</span>,
						field: "caption",
						width: `${data[1]}%`,
						initialEditValue: "",
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						render: function(rowData){
							if (rowData.caption === '') {
								return <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>;
							}
							return <div className='nowrap' title={rowData.caption}>{rowData.caption}</div>;
						}
					})
				}
				if (colName === 'col_productName') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("productName");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("productName") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.prod.titleTT")}>{t("licenses.list.cols.prod")}</span>,
						field: "productName",
						width: `${data[1]}%`,
						initialEditValue: <span></span>,//Return empty span to avoid displaying emptyValue
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						editable: "never",
						render: function(rowData){
							return <div className='nowrap'>
								{props.hasAccess.details && <span className='me-1' title={t("licenses.list.cols.prod.iconTT")}>
									<ListSvg 
                                        width="24"
                                        className="mb-0 p-0 border-0 bg-transparent"
                                        fill="var(--nw-blue-color)"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            window.open(t("link.licenseDetails.url").replace(':key', rowData.currentKey), '_blank');
                                        }}
                                    />
								</span>}
								<span title={rowData.productName}>{rowData.productName}</span>
							</div>
						},
					})
				}
				if (colName === 'col_version') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("ver");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("ver") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.ver.titleTT")}>{t("licenses.list.cols.ver")}</span>,
						field: "ver",
						width: `${data[1]}%`,
						initialEditValue: <span></span>,//Return empty span to avoid displaying emptyValue
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						editable: "never",
						render: function(rowData){
							const hasUpdate = compareVersions(rowData?.ver, rowData?.lastVersions[0]);
							const tooltipData = highestVersionsTable(rowData.highestVersions, rowData.lastVersions, t);
							const ver = <span className={`${hasUpdate > 0 ?"nw-blue" : "nw-green-dark"}`}>{rowData?.ver}</span>;

							if (tooltipData === null) {
								return ver;
							}
							const tooltip = (<Tooltip className="nw-tooltip-dark mt-0">{tooltipData}</Tooltip>);
			
							return <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={tooltip}>{ver}</OverlayTrigger>
						},
					})
				}
				if (colName === 'col_owner') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("owner");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("owner") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.owner.titleTT")}>{t("licenses.list.cols.owner")}</span>,
						field: "owner",
						width: `${data[1]}%`,
						initialEditValue: <span></span>,//Return hidden span to avoid displaying emptyValue
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						editable: "never",
						render: function(rowData){
							return rowData.owner ? <div className='nowrap' title={rowData.owner}>{rowData.owner}</div> : <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>;
						}
					})
				}
				if (['col_agent', 'col_seller'].includes(colName)) {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("agent");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("agent") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.provider.titleTT")}>{t("licenses.list.cols.provider")}</span>,
						field: "agent",
						width: `${data[1]}%`,
						initialEditValue: <span></span>,//Return hidden span to avoid displaying emptyValue
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						editable: "never",
						render: function(rowData){
							return rowData.agent ? <div className='nowrap' title={rowData.agent}>{rowData.agent}</div> : <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>;
						}
					})
				}
				if (['col_buyer'].includes(colName)) {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("buyer");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("buyer") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.buyer.titleTT")}>{t("licenses.list.cols.buyer")}</span>,
						field: "buyer",
						width: `${data[1]}%`,
						initialEditValue: <span></span>,
						emptyValue: <span className="nw-grey-dark">{t("licenses.list.cols.buyer.customer")}</span>,
						editable: "never",
						render: function(rowData){
							return rowData.buyer ? <div className='nowrap' title={rowData.buyer}>{rowData.buyer}</div> : <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>;
						}
					})
				}
				if (colName === 'col_publisher') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("publisher");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("publisher") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.manuf.titleTT")}>{t("licenses.list.cols.manuf")}</span>,
						field: "publisher",
						width: `${data[1]}%`,
						initialEditValue: <span></span>,//Return hidden span to avoid displaying emptyValue
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						editable: "never",
						render: function(rowData){
							return rowData.publisher ? <div className='nowrap' title={rowData.provider}>{rowData.publisher}</div> : <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>;
						}
					})
				}
				if (colName === 'col_sellDate') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("sellDate");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("sellDate") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.sell.titleTT")}>{t("licenses.list.cols.sell")}</span>,
						field: "sellDate",
						width: `${data[1]}%`,
						initialEditValue: <span></span>,//Return hidden span to avoid displaying emptyValue
						emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						render: function(rowData){
							const sellDate = localeDatetime(rowData.sellDate);
							const unknown = <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>;
							return <span title={sellDate.includes('1970')? "" : sellDate }>
								{rowData.sellDate ? rowData.sellDate.includes('1970') ? unknown : SliceText({text:sellDate, length: 10, append:""}) : unknown}
							</span>
						}
					})
				}
				if (colName === 'col_createDate') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("createDate");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("createDate") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.createDate.titleTT")}>{t("licenses.list.cols.createDate")}</span>,
						field: "createDate",
						width: `${data[1]}%`,
						initialEditValue: <span></span>,//Return hidden span to avoid displaying emptyValue
						//emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
						render: function(rowData){
							const createDate = localeDatetime(rowData.createdAt);
							const unknown = <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>;
							return <span title={createDate}>
								{rowData.createdAt ? SliceText({text:createDate, length: 10, append:""}) : unknown}
							</span>
						}
					})
				}
				if (colName === 'col_language') {
                    let hide = false;
                    if (data[0] === '') {
                      hiddenCols.push("language");
                      hide = false;
                    }
                    hide = storageHidddenCols ? storageHidddenCols.includes("language") : hide;
                    cols.push({
                        hidden: hide,
                        title: <span title={t("journal.list.cols.lang.titleTT")}>{t("journal.list.cols.lang")}</span>,
                        field: "language",
                        width: `${data[1]}%`,
                        initialEditValue: <span></span>,
                        emptyValue: <span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>,
                        render: function(rowData){
                            if (rowData.language === '') {
                                return '';
                            }
                            /*let languages =  t('gen.lang.titleTT')+ '\n\n';
                            rowData.languages?.forEach(lang => {
                                languages += "- " +t("gen.lang."+lang)+"\n";
                            });*/
                            let languages = languagesToList(rowData.languages, rowData.language, t)
            
                            return <div className='nowrap'>
                                <img
                                    title={t("languages."+rowData.language)}
                                    src={FLAGS.svg[rowData.language]}
                                    className="flag"
                                    height="16" 
                                    alt={t("languages."+rowData.language)}
                                    loading="lazy"
                                />
                                
                                {rowData.languages?.length > 1 && <PlusInfoSVG
                                    title={languages}
                                    className="ms-1"
                                    fill="var(--nw-grey-color)"
                                    width={12}
                                    style={{marginTop: "-2px"}}
                                />}
                            </div>
                        },
                    })
                }
				if (colName === 'col_action') {
					let hide = false;
					if (data[0] === '') {
						hiddenCols.push("action");
						hide = false;
					}
					hide = storageHidddenCols ? storageHidddenCols.includes("action") : hide;
					cols.push({
						hidden: hide,
						title: <span title={t("licenses.list.cols.action.titleTT")}>{t("licenses.list.cols.action")}</span>,
						field: "action",
						width: `${data[1]}%`,
						initialEditValue: <span></span>,//Return hidden span to avoid displaying emptyValue
						//hiddenByColumnsButton:true,
						disableClick:true,
						render: function(rowData){
							const iconWidth = 784<=screenSize.width && screenSize.width<950 ? 18 : 24;
							let edit = '';
							//let info = <InfoSvg width={iconWidth} className="me-2 border-0 bg-transparent invisible" fill="var(--nw-grey-color)"/>;
							if (!props.hasAccess.editCaption && !props.hasAccess.editSellDate) {
								edit = '';
							}else if (props.hasAccess.editCaption || !rowData.sellDate?.includes('1970')) {
								edit = <EditSvg onClick={e => {e.stopPropagation();setLicenseEdit(rowData);}} title={t("licenses.list.cols.action.tt.edit")} data-bs-toggle="modal" data-bs-target="#licenseEditModal" width="20" className="cursor-pointer me-2 border-0 bg-transparent" fill="var(--nw-blue-color)"/>;
							}else{
								edit = <EditSvg width="20" className="me-2 border-0" fill="var(--nw-grey-color)"/>;
							}

							let download = '';
							if (props.hasAccess.download) {
								download = <DownloadSvg 
									onClick={e => {
										e.stopPropagation();
										requestDownload.current.href = t("link.lic.clear.lic")+`/${rowData.currentKey}`;
										requestDownload.current.click();
									}}
									title={t("licenses.list.cols.action.tt.clear")} width={iconWidth} className="cursor-pointer mt-1 me-2 border-0 bg-transparent" fill="var(--nw-blue-color)"/>
								;
							}

							let manage = '';
							if(props.hasAccess.manage) {
								manage = <ListSvg 
									onClick={e => {
										e.stopPropagation();
										requestSearch.current.href = `https://www.nanoways.com/esd/restricted/lic/view.php?key=${rowData.currentKey}`;
										requestSearch.current.click();
									}} 
									title={t("licenses.list.cols.action.tt.man")}
									width={iconWidth} className="cursor-pointer me-2 border-0 bg-transparent" fill="var(--nw-blue-color)"
                                />;								
							}
                            let info = '';
							if(props.hasAccess.info) {
                                info = <InfoSvg
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        requestSearch.current.href = `https://pre.nanoways.com/check/${rowData.currentKey}`;
                                        requestSearch.current.click();
                                    }}
                                    title={t("licenses.list.cols.action.tt.status")} width={iconWidth} style={{padding: "0.10rem"}} className="cursor-pointer me-1 border-0 bg-transparent" fill="var(--nw-blue-color)"
                                />;
                            }
                            let retract = '';
							if(props.hasAccess.retract && props.name === "sellerWait") {
                                retract = <RetractSvg
                                    onClick={e => {
                                        e.stopPropagation();
                                        setConfirmData({
                                            action: 'retracted',
                                            modal:{
                                                header: 'licenses.list.opt.sell.confirm',
                                                title: 'licenses.list.opt.sell.confirm.retract',
                                                body: "licenses.list.opt.sell.confirm.retract.txt",
                                                yes: "licenses.list.opt.sell.confirm.retract.btn",
                                                no: "gen.button.cancel",
                                            },
                                            rowData
                                        })
                                    }}
                                    title={t("licenses.list.cols.action.retract.tt")}
                                    data-bs-toggle="modal" data-bs-target="#confirmModal" width="20" className="cursor-pointer me-2 border-0 bg-transparent" fill="var(--nw-red-light-2-color)"
                                />;
                            }
                            let reject = '';
							if(props.hasAccess.reject && props.name === "buyerWait") {
                                reject = <RejectSvg
                                    onClick={e => {
                                        e.stopPropagation();
                                        setConfirmData({
                                            action: 'rejected',
                                            modal:{
                                                header: 'licenses.list.opt.sell.confirm',
                                                title: 'licenses.list.opt.sell.confirm.reject',
                                                body: "licenses.list.opt.sell.confirm.reject.txt",
                                                yes: "licenses.list.opt.sell.confirm.reject.btn",
                                                no: "gen.button.cancel",
                                            },
                                            rowData
                                        })
                                    }}
                                    title={t("licenses.list.cols.action.reject.tt")}
                                    data-bs-toggle="modal" data-bs-target="#confirmModal" width="20" className="cursor-pointer me-2 border-0 bg-transparent" fill="var(--nw-red-light-2-color)"
                                />;
                            }
                            let accept = '';
							if(props.hasAccess.accept && props.name === "buyerWait") {
                                accept = <AcceptSvg
                                    onClick={e => {
                                        e.stopPropagation();
                                        setConfirmData({
                                            action: 'accepted',
                                            modal:{
                                                header: 'licenses.list.opt.sell.confirm',
                                                title: 'licenses.list.opt.sell.confirm.accept',
                                                body: "licenses.list.opt.sell.confirm.accept.txt",
                                                yes: "licenses.list.opt.sell.confirm.accept.btn",
                                                no: "gen.button.cancel",
                                            },
                                            rowData
                                        })
                                    }}
                                    title={t("licenses.list.cols.action.accept.tt")}
                                    data-bs-toggle="modal" data-bs-target="#confirmModal" width="20" className="cursor-pointer me-2 border-0 bg-transparent" fill="var(--nw-blue-color)"
                                />;
                            }

							return <div className=''>
								{edit}
								{download}
								{manage}
								{info}
								{retract}
								{reject}
								{accept}
							</div>
						}
					})
				}
			}
			if (!storageHidddenCols) {
                setHideColsLocalStorage('lic_hide_', props.name, hiddenCols, HIDDEN_COLS_EXP_SECONDS);
			}
			setColumns(cols);
		}
	}, []);
    /* eslint-enable */

    const { t } = useTranslation();
    const user = useSelector((state) => state.user);
    const isB2BReseller = user?.functions.includes("sellerB2B") || user?.functions.includes("publisher")
	const requestDownload = useRef(null);
	const requestSearch = useRef(null);
	const [licenseEdit, setLicenseEdit] = useState(null);

    const [tableData, setTableData] = useState(props.licenses);
	const count = props.licenses?.length;
	
	/* eslint-disable */
	/***********Manage license Key copy clipboard***********/
    const copyIconsRef = useRef([]);
	const handleMouseUp = (e, license) => {
		for (const key in copyIconsRef.current) {
			if (key === license) {
				copyIconsRef.current[key]?.setAttribute('fill', 'var(--nw-green-color)');
			}else{
				copyIconsRef.current[key]?.setAttribute('fill', 'var(--nw-blue-color)');
			}
		}
	}
	const handleMouseDown = (e, license) => {
		navigator.clipboard.writeText(license);
	}
	/***********Manage license Key copy clipboard***********/
	const screenSize = useScreenSize();
    /* eslint-enable */

	const paginationSize = (limit, order = 'asc') => {
		let options = [5,10,25,50,100,250];
		if (LICENSE_LIST_NUMBER>0) {
			options = [...options, LICENSE_LIST_NUMBER].sort((a, b) => a - b);
		}
		const newOptions = [];
		for (const option of options) {
			if ( (order === 'asc' && option < limit)||(order === 'desc' && option > limit)) {
				newOptions.push(option)
			}
		}
		newOptions.push(limit);

		return newOptions;
	}
	const tableRef = useRef();
	const panelDetails = useRef({});
	const [isLoadingPanel, setIsLoadingPanel] = useState({key: '',show:false});
	const openDetailPanelForRow = (currentKey, togglePanel) => {
		if (tableRef.current) {
            const tableData = tableRef.current?.dataManager?.data;
            const rowIndex = tableData?.findIndex((data) => data.currentKey === currentKey);
            //if (document.getElementById('licenseDetailsPanel') === null) => panel is opned
            if (rowIndex !== -1 && currentKey !== isLoadingPanel.key && currentKey) {
                setIsLoadingPanel({...isLoadingPanel, key:currentKey, show:true});
                LICENSEAPI.licensesPanel(currentKey, props.name)
                    .then((res)=>{
                        if(res.status === 200){
                            panelDetails.current = {...res.data};
                            setIsLoadingPanel({...isLoadingPanel, key:currentKey, show:false});
                            togglePanel();
                        }else{
                            console.log(res);
                        }
                    }).catch((err)=>{
                        console.log(err);
                        setIsLoadingPanel({...isLoadingPanel, key:currentKey, show:false});
                        togglePanel();
                    })
            }else{
                togglePanel();
            }
		}
	};

    const options = {}
    if (count > 0) {
        options.pageSize = LICENSE_LIST_NUMBER > count ? count : LICENSE_LIST_NUMBER;
    }

    const handleChangeRowByPanel = (data, rowData) => {
        if (panelDetails.current) {
            if (data.caption !== undefined) {
                panelDetails.current.licenseDetails.license.caption.value = data.caption;
            }
            if (data.anatPassword !== undefined) {
                panelDetails.current.licenseDetails.license.anatPassword.value = data.anatPassword;
            }
            if (data.sellDate !== undefined) {
                panelDetails.current.licenseDetails.license.sellDate.value = data.sellDate;
            }
        }
        
        setTableData(prevData => {
            return prevData.map(item => {
                if (item.currentKey === data?.currentKey) {
                    return {...item,
                        caption: data.caption ?? item.caption,
                        sellDate: data.sellDate ?? item.sellDate,
                    };
                }
                return item;
            });
        });
    }
    const [confirmData, setConfirmData] = useState(null);

  	return (columns &&
		<>
			<MuiThemeProvider theme={theme}>
			<MaterialTable
				tableRef={tableRef}
				icons={tableIcons}
				columns={columns} 
				data={tableData?? []}
				style={{padding:0}}
				title={t(`licenses.list.tabs.${props.name}.desc`)}
				isLoading={tableData === undefined || isLoadingPanel.show}
				totalCount={count}
				options={{
					...options,
					tableLayout: "fixed",
					grouping: true,
					draggable: true,
					//showGroupingCount: true,
					paging: true,//tableData?.length>0? true:false,
					paginationType: 'stepped',
					pageSizeOptions: paginationSize(parseInt(count)),
					paginationPosition:"both", //"top" | "bottom"
					//paginationAlignment: "flex-end", //"flex-start" | "center"
					search: false,
					//searchText: searchingText,
					actionsColumnIndex: -1,
					headerStyle: {fontFamily: "roboto-bold", fontSize: "1rem", backgroundColor: "var(--nw-blue-light-color)", overflow: "hidden", textOverflow: "... ", whiteSpace: "nowrap"},
					addRowPosition: "first",
					emptyRowsWhenPaging:false,
					padding: "dense",//default
					columnsButton: true,
					detailPanelType: "single",//only one panel will be open at any given time.
					actionsCellStyle: {width: "10%",paddingLeft: 0,paddingRight: 0},
					//Change color of row
					rowStyle: (rowData) => {
						//let style = {backgroundColor: '#fff'};
						let style = {};
						if (rowData.backgroundColor) {
							style = {background: rowData.backgroundColor};
						}
						if (rowData.waitingSales > 0) {
							style = {background:  'var(--nw-yellow-light-color)'};
						}
						return style;
					},
				}}
				
				components={{
					Action: propsAction => {
						if(propsAction.action?.type === "search"){
							return <SearchInput tooltipList={props.searchInCols} inputId={searchInputId} text={props.searchText} selectAll={!!props.searchText} reloadTab={props.reloadTab}/>;
						}else if(propsAction.action?.type === "add"){
							return <OverlayTrigger placement="bottom" trigger={["hover", "click"]} overlay={<Tooltip className="nw-tooltip-dark">{t("licenses.list.opt.add.tt")}</Tooltip>}>
								<button type="button" data-bs-toggle="modal" data-bs-target="#licenseAddModal" className="bm-btn-green-action" style={{marginRight: "8px", marginTop: "6px",height: "30px", padding: "0 10px"}}>
									<PlusSvg style={{marginTop: "-3px", marginRight: "10px"}} width="12" height="12" fill="#fff"/>
									{t("licenses.list.opt.add")}
								</button>
							</OverlayTrigger>
						}else if(propsAction.action?.type === "sell"){
							return <OverlayTrigger placement="top" trigger={["hover", "click"]} overlay={<Tooltip className="nw-tooltip-dark">{t("licenses.list.opt.sell.tt")}</Tooltip>}>
							<div className="dropdown" style={{marginTop: "9px"}}>
								<span className={`${sellData.products?.length> 0 ? "bm-enabled-action dropdown-toggle" : "bm-disabled-action"} bm-dropDown-action border border-0 rounded-1`} data-bs-toggle="dropdown" aria-expanded="true">
									{t("licenses.list.opt.sell")}
								</span >
								<ul className="dropdown-menu">
								{['reseller', 'customer'].map((opt) => {
                                    const name = "licenses.list.opt.sell.opt."+opt;

                                    if (opt === 'reseller' && !isB2BReseller) {
                                        return null;
                                    }

									return (
										<li
                                            data-bs-toggle="modal"
                                            data-bs-target={'#'+opt+'Modal'}
                                            key={'soldTo-'+opt}
                                            style={{cursor: "pointer"}}
                                        >
											<span className="dropdown-item" title={t(name+".tt")}>{t(name)}</span>
										</li>
									)
								})}
								</ul>
							</div>
						</OverlayTrigger>
						}else{
							return <MTableAction {...propsAction} />;
						}
					},
					Toolbar: (props) => {
						return <div className="nw-toolBar" style={{backgroundColor: "var(--nw-blue-light-color)"}}>
							<MTableToolbar {...props} />
						</div>;
					}
				}}
				detailPanel={[{
                    disabled: true,
                    icon: '',
                    openIcon: '',
                    render :({rowData}) => {
                        return <Panel updateRow={handleChangeRowByPanel} data={panelDetails.current} rowData={rowData}/>
                    }
                }]}
                //Open License Details tab on Row click
				onRowClick={props.hasAccess.panel ? 
					(e, rowData, togglePanel) => {
						//avoid opening tab by svg click(copy license)
						/*if (rowData.currentKey && !e.target?.classList?.contains("isNotClickable")) {
							window.open(t("link.licenseDetails.url").replace(':key', rowData.currentKey), '_blank');
						}*/
						//togglePanel();
						openDetailPanelForRow(rowData.currentKey, togglePanel);
					}
				:
					null
				}				
				onChangeColumnHidden={(column, hidden) =>{
					column.hidden = hidden;
					const storageHidddenCols = getNotExpiredLocalStorage('lic_hide_', props.name);

					if (storageHidddenCols) {
						let hiddenCols = JSON.parse(storageHidddenCols);

						if (hidden && !hiddenCols.includes(column.field)) {
							hiddenCols.push(column.field)
						}else{
							let filetred = hiddenCols.filter(function(value){ 
								return value !== column.field;
							});
							hiddenCols = filetred;
						}
                        
                        setHideColsLocalStorage('lic_hide_', props.name, hiddenCols, HIDDEN_COLS_EXP_SECONDS);
					}
				}}
				actions={[
					{
						isFreeAction: true,
						icon: () => <ChangeLimit count={count} name={props.name} reloadTab={props.reloadTab} list={[5, 10, 25, 50, 100, 250]} optsuffixId='selectLimit' searchTextId={searchInputId}/>
					},
					props.hasAccess.add && "user" === props.name && tableData !== undefined? {
						isFreeAction: true,
						type: "add",
					} : null,
					['reseller'].includes(props.name) ? {
						isFreeAction: true,
						type: "sell",
					} : null,
					//Action element is added in components.Action (MaterialTable props)
					props.searchInCols?.length > 0 ? {
						isFreeAction: true,
						type: "search",
					} : null,
				]}
				localization={{
					header: {//style={{marginTop: "18px"}}
						actions: t("licenses.list.cols.action")
					},
					toolbar:{
						addRemoveColumns: t("nw.list.opt.col"),
						showColumnsTitle: t("nw.list.opt.col.tt"),
						searchPlaceholder: t("nw.list.opt.filter"),
						searchTooltip: t("nw.list.opt.filter.tt"),
					},
					grouping: {
						placeholder: t("nw.list.opt.group.hint"),
						groupedBy: t("nw.list.opt.group.by")
					},
					body: {
						addTooltip: t("licenses.list.opt.add.tt"),
						editTooltip: t("licenses.list.cols.action.tt.edit"),
						editRow:{
							saveTooltip: t("licenses.list.cols.action.tt.save"),
							cancelTooltip: t("licenses.list.cols.action.tt.cancel")
						},
						emptyDataSourceMessage: tableData ? 
							t("nw.list.err.empty")
							: 
							<table style={{width:"100%", margin: "15px 0 15px 0"}}>
								<tbody><tr><td style={{height: "300px"}}></td></tr></tbody>
							</table>
						,
					},
					pagination:{
						labelRowsPerPage: screenSize.width > 650 ? t("nw.paging.rows.label"): "",
						labelRows: t("nw.paging.rows"),
						//labelRowsSelect: t("nw.paging.rows.label"),
						//labelDisplayedRows: ({from, to, count}) => `${from}-${to} ${t("paging.of")} ${count}`,
						labelDisplayedRows: "{from}-{to} / {count}",
						firstAriaLabel: t("nw.paging.first"),
						firstTooltip: t("nw.paging.first"),
						previousAriaLabel: t("nw.paging.prev"),
						previousTooltip: t("nw.paging.prev"),
						nextAriaLabel: t("nw.paging.next"),
						nextTooltip: t("nw.paging.next"),
						lastAriaLabel: t("nw.paging.last"),
						lastTooltip: t("nw.paging.last"),
					}
				}}
			/>
			</MuiThemeProvider>
			<a ref={requestSearch} className="d-none" style={{zIndex: -5}} href="/#" rel="noopener noreferrer" target="_blank">Request Search</a>
			{props.name === 'user' && <LicenseAddModal reloadTab={props.reloadTab} tabName={props.name}/>}
			{tableData?.length> 0 && <>
                <a ref={requestDownload} className="d-none" style={{zIndex: -5}} href="/#" rel="noopener noreferrer" target="_blank">Request Download</a>

			    {['reseller'].includes(props.name) && <SoldToCustomerModal reloadTab={props.reloadTab} tabName={props.name} data={sellData} access={props.hasAccess}/>}

			    {['reseller'].includes(props.name) && isB2BReseller && <SoldToResellerModal reloadTab={props.reloadTab} tabName={props.name} data={sellData} access={props.hasAccess}/>}

                {['sellerWait', 'buyerWait'].includes(props.name) && <ConfirmModal reloadTab={props.reloadTab} tabName={props.name} data={confirmData}/>}

			    <LicenseEditModal reloadTab={props.reloadTab} tabName={props.name} oldData={licenseEdit} hasAccess= {props.hasAccess}/>
            </>}
		</>
  	)
})

export default LicenseList;

const highestVersionsTable = (highests, currentVersions, t) => {
	const rows = ['s0', 's1', 's2', 's3'];
	const columns = [t('licenses.list.cols.ver.tt.currentVer'), t('licenses.list.cols.ver.tt.upVer')];
	let foundCurrentVer = false;
  
	// Create a table based on the comparison
	const tableData = rows.map((row, index) => {
		const currentVersion = currentVersions[index];
		const highestVersion = highests[index];
		if (currentVersion && highestVersion) {
			foundCurrentVer = true;
			return (
				<tr key={index}>
					<td className='pe-2 fw-bold'>{t(`clear.select.opt.stage.${index}`)}</td>
					<td className='pe-2 '>{currentVersion || t("gen.text.unavailable.abbr")}</td>
					<td className='pe-2'>{highestVersion || t("gen.text.unavailable.abbr")}</td>
				</tr>
			);
		}
		return null;
	});
  
	return (foundCurrentVer ?
		<table className="text-start">
			<thead>
				<tr>
					<th className='pe-2'></th>
					<th className='pe-2'>{columns[0]}</th>
					<th className='pe-2'>{columns[1]}</th>
				</tr>
			</thead>
			<tbody>{tableData}</tbody>
		</table>
		:
		null
	);
};