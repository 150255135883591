import { useEffect, useRef, useState } from 'react';
//import  RFBConnection from 'novnc/core/rfb'
//import 'novnc/core/rfb.css';
import RFB from '@novnc/novnc/core/rfb';
//import noVNC from 'novnc';
import useScreenSize from 'Hook/useScreenSize';
import { useTranslation } from "react-i18next";

const VncViewer = (props) => {
    
    /*useEffect(() => {
        const rfb = new RFB(document.getElementById('remote-desktop-container'), 'ws:localhost:3000');
        // Replace 'your-vnc-server-address' and 'port' with your actual VNC server details.
      }, []);
    
      return (
        <div id="remote-desktop-container"></div>
      );*/

    const { t } = useTranslation();
    const vncContainerRef = useRef();
    const screenSize = useScreenSize();
    const [rfb, setRfb] = useState(null);
    //console.log(rfb);
	/*
	useEffect(() => {
		const rfb = RFB.createRFBClient(vncContainerRef.current, vncUrl, {
			shared: true, // Set to 'true' if I you want multiple users to view/control the VNC session simultaneously.
			credentials: {
				password: 'your_vnc_password', // Replace this with the VNC server password.
			},
			encrypt: true, // Enable encryption if your VNC server supports it.
			wsProtocols: ['binary', 'base64'], // Use binary and base64 encoding.
			onNotification: (msg, level, options) => {
				console.log('VNC Notification:', msg, level, options);
			},
		});

		return () => {
			rfb.disconnect(); // Cleanup on unmounting the component.
		};
	}, [vncUrl]);
	*/
	/*useEffect(() => {
		const rfb = new RFB(document.getElementById('vnc-container'), 'ws://localhost:5900');
		return () => {
		  rfb.disconnect();
		};
	  }, []);
	
	  return <div id="vnc-container" />;*/

    /* eslint-disable */
	useEffect(() => {
		let isMounted = true;
        const pageTitle = document.getElementById('vncTitle');

        if (props.dataChannel && isMounted) {
            let rfb;
            let desktopName = t("ras.slots.cols.host.vnc")+ " – nanoways.com";

            // When this function is called we have
            // successfully connected to a server
            function connectedToServer(e) {
                props.isLoading(false);
                status("Connected to " + desktopName);
                if (desktopName) {
                    //status("Tab name changed after successful connexion (connectedToServer())" + desktopName);
                    document.title = desktopName + " – nanoways.com";
                    if (pageTitle) {
                        pageTitle.innerHTML = desktopName;
                    } 
                    sessionStorage.setItem(`vncTitle-${props.handle}`, desktopName);
                }
            }

            // This function is called when we are disconnected
            function disconnectedFromServer(e) {
                props.resetP2P();
                if (e.detail.clean) {
                    status("Disconnected");
                    props.setError('ras.vncWeb.info.con.stop');
                } else {
                    status("Something went wrong, connection is closed");
                    props.setError('ras.vncWeb.info.con.err');
                }
            }

            // When this function is called, the server requires
            // credentials to authenticate
            function credentialsAreRequired(e) {
                const password = prompt("Password Required:");
                rfb.sendCredentials({ password: password });
            }

            // When this function is called we have received
            // a desktop name from the server
            function updateDesktopName(e) {
                desktopName = e.detail?.name;
                if(desktopName){
                    //status('Tab name changed By updateDesktopName(): '+ desktopName);
                    document.title = desktopName + " – nanoways.com";
                    if (pageTitle) {
                        pageTitle.innerHTML = desktopName;
                    } 
                    sessionStorage.setItem(`vncTitle-${props.handle}`,desktopName);
                }
            }

            // Since most operating systems will catch Ctrl+Alt+Del
            // before they get a chance to be intercepted by the browser,
            // we provide a way to emulate this key sequence.
            function sendCtrlAltDel() {
                rfb.sendCtrlAltDel();
                return false;
            }

            // Show a status text in the top bar
            function status(text) {
                console.log(text);
                //document.getElementById('status').textContent = text;
            }

            // This function extracts the value of one variable from the
            // query string. If the variable isn't defined in the URL
            // it returns the default value instead.
            function readQueryVariable(name, defaultValue) {
                // A URL with a query parameter can look like this (But will most probably get logged on the http server):
                // https://www.example.com?myqueryparam=myvalue
                //
                // For privacy (Using a hastag #, the parameters will not be sent to the server)
                // the url can be requested in the following way:
                // https://www.example.com#myqueryparam=myvalue&password=secreatvalue
                //
                // Even Mixing public and non public parameters will work:
                // https://www.example.com?nonsecretparam=example.com#password=secreatvalue
                //
                // Note that we use location.href instead of location.search
                // because Firefox < 53 has a bug w.r.t location.search
                const re = new RegExp('.*[?&]' + name + '=([^&#]*)'),
                    match = ''.concat(document.location.href, window.location.hash).match(re);

                if (match) {
                    // We have to decode the URL since want the cleartext value
                    return decodeURIComponent(match[1]);
                }

                return defaultValue;
            }

            //document.getElementById('sendCtrlAltDelButton').onclick = sendCtrlAltDel;

            // | | |         | | |
            // | | | Connect | | |
            // v v v         v v v

            status("Connecting");

            let width = vncContainerRef.current.offsetWidth;
            let height = vncContainerRef.current.offsetHeight;
            const clientWidth = vncContainerRef.current.clientWidth;
            const clientHeight = vncContainerRef.current.clientHeight;
            //console.log(vncContainerRef.current);
            //console.log({ width, height});
            //console.log({ clientWidth, clientHeight});

            const r = vncContainerRef.current.getBoundingClientRect();
            console.log(r);

            // Creating a new RFB object will start a new connection
            rfb = new RFB(vncContainerRef.current,props.dataChannel);
            setRfb(rfb);
            props.setRFB(rfb);
                        //{ credentials: { password: 'test' } });

            // Add listeners to important events from the RFB module
            rfb.addEventListener("connect",  connectedToServer);
            rfb.addEventListener("disconnect", disconnectedFromServer);
            rfb.addEventListener("credentialsrequired", credentialsAreRequired);
            rfb.addEventListener("desktopname", updateDesktopName);

            /* Note: don't enable clipping or scaling yet as that will lead to a scaling
                    factor of 0 at this point in time, thus not displaying anything */
            rfb.scaleViewport = false;
        }

		return () => { isMounted = false };
	}, []);
    /* eslint-enable */

    useEffect(() => {
        const canvas = document.getElementById("screen")?.getElementsByTagName('canvas')[0];
        if(canvas && rfb && rfb._showDotCursor === false){
            //canvas.style.cursor = "auto";
            rfb._canvas.style.cursor = "auto"
        }
        console.log(rfb);
    }, [rfb])

	return (
        <>
            <h6 id="vncTitle" className='text-center mb-1' style={{/*width: rfb?._fbWidth > screenSize.width ? `${rfb?._fbWidth}px` : "auto"*/}}>{document.title}</h6>
            <div className="m-0 p-0 vertical-center">
                <div
                    style={{zIndex: "101"}} //hide footer
                    className={`${rfb?._fbWidth >= screenSize.width ? "" : "min-vw-100-scrollbar" }  min-vh-100`}
                    //style={`${screenSize.width > 750 ? {paddingBottom: "40px"} : {paddingBottom: "24px"} }`}
                    //style={{paddingBottom: "40px"}} //PaddingBottom if footer is displayed
                >
                    <div 
                        className="min-vh-100-bar" 
                        //style={{outline: "4px solid #3cb33c"}}
                    >
                        <div id="screen" ref={vncContainerRef} />
                    </div>
                </div>
            </div>
        </>
	)
};

export default VncViewer;