import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import Field from "../../Components/Forms/Field";
import LicenseAPI from "../../Services/LicenseAPI";
import { useTranslation } from "react-i18next";
import { fixInput } from '../../Services/utils';
import Spinner from 'Components/Loading/Spinner';
import { useSelector } from 'react-redux';
//import { useSelector, useDispatch } from "react-redux";
//import { changeKey } from "../../reduxx/actions/license";

export default function LicenseClearing() {
	const {isAuthenticated} = useSelector((state) => state.auth);
    let params = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const submitBtn = useRef(null);
    
    document.title = t("clear.title")+ " – nanoways.com  ";
    //const key = useSelector((state) => state.license.key);
    //const dispatch = useDispatch();

    const [license, setLicense] = useState({
        key: location.state?.lastKey ?? fixInput({value:params.key, maxLength:23}) ?? "",
        placeholder: " ",
        tooltip: ""
    });
    const [errors, setErrors] = useState({
        key: "",
        api: ""
    });
    
    const [toggle, setToggle] = useState(false);

    const [cursor, setCursor] = useState({
        target: undefined,
        position: 0
    });

    /* eslint-disable */
    useEffect(() => {
        // After change Input value return cursor to the last position.
        cursor.target && (cursor.target.selectionEnd = cursor.position);
    },[license])
    useEffect(() => {
        if (params?.key || location.state?.lastKey) {
            submitBtn.current.click();
        }
    },[])
    /* eslint-enable */

    // Manage input changing in form
    const handleChange = (e) => {
        params.key = "";
        /*
        * Controlled Input 
        * What is the current behavior?
        * Browser doesn’t show any autofill suggestions on an input field with type "text" as soon as you connect the state with the attribute "value". means when the value is handled with state  
        * Solutions:
        *   1-Can be resolved with useRef()
        *       import {useRef} from 'react';
        *       Add: const inputEl = useRef(null);
        *       In the Field Tag uncomment attribute 'value=...' & add useref={inputEl}
        *   2- Can be resolved with Object.defineProperty
        */
        Object.defineProperty(e.target, "defaultValue", {
          configurable: true,
          get() { return "" },
          set() {},
        });
        const {name, value, selectionStart} = e.currentTarget;
        let valueLength = value.length;
        
        let newValue = fixInput({value:value, maxLength:23});

        //Return cursor to its position if user try to correct value
        let cursorPosition = selectionStart;
        if(valueLength === cursorPosition){
            cursorPosition = newValue.length;
        }

        setLicense({...license,
             [name]: newValue,
             placeholder:(newValue+t("clear.enterlk.key.hint").slice(newValue.length))
        });
        //Update Dynamic placeholder
        setCursor({...cursor, target: e.currentTarget, position: cursorPosition});
    };
    
    const inputFocus = (e) => {
        if (!e.target.value) {
            setLicense({...license, placeholder: t("clear.enterlk.key.hint")});
        }
    };
    
    const inputFocusout = (e) => {
        if (!e.target.value) {
            setLicense({...license, placeholder: ""});
        }
    };

    // Manage form submission
    const handleSubmit = async event => {
        setToggle(true);
        setErrors({});
        event.preventDefault();

        if (!license.key) {
            setErrors({...errors, key:t("nw.input.err.required")});
            setToggle(false);
        } else if (!license.key.match(/^([A-Za-z0-9]{5}-?){3}[A-Za-z0-9]{5}$/)) {
            setErrors({...errors, key:t("clear.enterlk.err.invalid")});
            setToggle(false);
        } else {
            try{
                let response = await LicenseAPI.licenseOptions(license.key, isAuthenticated);
                let state = {default:response.data};//, {replace: true,
                navigate(t("link.lic.clear.opt"), {state:state, replace: (params.key ? true:false)});
            }
            catch(error) {
                setToggle(false);
                if(!error.response || error.response.data.status === 500){
                    console.log(error.message);
                    setErrors({...errors, api:t("err.500")});
                } else {
                    const data = error?.response?.data;
                    if(data?.message === undefined){
                        console.log(data);
                        setErrors({...errors, api:t("err.unknown")});
                    } else {
                        const {message, err, info} = data;
                        const msg = message?.toLowerCase();
                        if(msg === "license is not found") {
                            setErrors({...errors, key:t("clear.enterlk.err.invalid")});
                        } else if(msg === "product is not found") {
                            setErrors({...errors, key:t("clear.enterlk.err.noProd")});
                        } else if(msg === "download not allowed") {
                            setErrors({...errors, key:t("clear.enterlk.err.noDownload")});
                        } else if(msg === "expired") {
                            navigate(t("link.err.url"), {state:{errGroup:'expired', info:info, lastKey:license.key, docTitleI18n: "clear.title"}, replace: (params.key ? true:false)});
                        } else if(msg === "maxclear") {
                            navigate(t("link.err.url"), {state:{errGroup:'maxclear', lastKey:license.key, docTitleI18n: "clear.title"}, replace: (params.key ? true:false)});
                        } else if(msg === "norelease") {
                            navigate(t("link.err.url"), {state:{errGroup:data.i18nKey, info:info, lastKey:license.key, docTitleI18n: "clear.title"}, replace: (params.key ? true:false)});
                        } else if(msg === "barred") {
                            navigate(t("link.err.url"), {state:{errGroup:data.i18nKey, info:info, lastKey:license.key, docTitleI18n: "clear.title"}, replace: (params.key ? true:false)});
                        } else if(msg === "empty user data"){
                            navigate(t("link.lic.userdata"), {state:{license:license.key, product:info.product, oldData: data.info?.license}, replace: (params.key ? true:false)});
                        } else if(err?.toLowerCase() === "unsold"){
                            const errGroup = msg === 'invalid license sold date'? 'invalid' : 'missing';
                            navigate(t("link.err.url"), {state:{errGroup:errGroup, docTitleI18n: "clear.title",info:info}, replace: (params.key ? true:false)});
                        }
                    }
                }
            }
        }
    };

    const attributes = {
        pattern:'([A-Za-z0-9]{5}-?){3}[A-Za-z0-9]{5}',
        //minLength: 20,
        //maxLength: 23,
        autoComplete:"on",
        autoFocus:"on",
        //title:t("clear.enterlk.key.hint")
    }

    return ( 
        <div className="m-0 p-0 vertical-center">
            <div className="bm-main">
                <div className="bm-card">

                    <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>

                    <h1 className="">{ t("clear.title") }</h1>
                    <h2 className="bm-sep-1">{t("clear.enterlk")}</h2>
                    <p>{t("clear.enterlk.txt")}</p>

                    {errors.api &&
                        <p className="fn-smtxt invalid-feedback d-block" dangerouslySetInnerHTML={{__html: errors.api}}/>
                    }

                    <form onSubmit={handleSubmit} autoComplete="on">
                        <div className="autocomplete-container">
                            <div className="autocomplete">{license.placeholder}</div>
                            <Field
                                name="key"
                                label={t("clear.enterlk.key")}
                                disabled={toggle}
                                id="key"
                                value={license.key}
                                onChange={handleChange}
                                onFocus={inputFocus}
                                onBlur={inputFocusout}
                                error={errors.key}
                                attrs={attributes}
                                placeholder=" "
                                inputClass={params.key ? "nw-green-dark" : ""}
                            />
                        </div>
                    
                        <div className="bm-nav-card-bottom mt-5">
                            <button ref={submitBtn} type="submit" disabled={toggle && "disabled"} className="bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                                {toggle &&
                                    <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                }
                                <span className={toggle ? "invisible" : ""}>{t("gen.button.continue")}</span>
                            </button>
                            <div className="bm-clearer"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>      
    )
}
