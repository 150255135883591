
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Field from "Components/Forms/Field";
import { fixInput } from 'Services/utils';

export default function LicenseInput(props) {
    const {t} = useTranslation();
    const [license, setLicense] = useState({
        currentKey: "",
        placeholder: " ",
        tooltip: ""
    });
    const [cursor, setCursor] = useState({
        target: undefined,
        position: 0
    });

    /* eslint-disable */
    useEffect(() => {
        // After change Input value return cursor to the last position.
        if (cursor.target) {
            cursor.target.selectionEnd = cursor.position;
        }
    },[license]);
    /* eslint-enable */

    
    const inputFocus = (e) => {
        if (!e.target.value) {
           setLicense({...license, placeholder: t("clear.enterlk.key.hint")});
        }
    };
    const inputFocusout = (e) => {
        if (!e.target.value) {
            setLicense({...license, placeholder: ""});
        }
    };
    
    // Manage input changing in form
    const handleChange = (e) => {
        Object.defineProperty(e.target, "defaultValue", {
            configurable: true,
            get() { return "" },
            set() {},
        });
        const {value, selectionStart} = e.target;
        const valueLength = value.length;
        const newValue = fixInput({value:value, maxLength:23});

        //Return cursor to its position if user try to correct value
        let cursorPosition = selectionStart;
        if(valueLength === cursorPosition){
            cursorPosition = newValue.length;
        }
        
        props.change(newValue);
        setLicense({...license,
            currentKey: newValue,
            placeholder:(newValue+t("clear.enterlk.key.hint").slice(newValue.length))
        });
        //Update Dynamic placeholder
        setCursor({...cursor, target: e.target, position: cursorPosition});
    };

    const attributes = {
        pattern:'([A-Za-z0-9]{5}-?){3}[A-Za-z0-9]{5}',
        minLength: 23,
    }

    return (
    <>
        <div className="autocomplete-container my-0 mx-0 px-0">
            <div className="autocomplete" style={{letterSpacing: "0em", minWidth: "310px"}}>{license.placeholder}</div>
            <Field
                refInput={props.refInput}
                name="currentKey"
                id="currentKey"
                label={t("clear.enterlk.key")}
                disabled={props.isLoading && "disabled"}
                attrs={attributes}
                classes={'my-0 py-0'}
                onFocus={(e) => inputFocus(e)}
                onBlur ={(e) => inputFocusout(e)}
                value={license.currentKey}
                onChange={(e)=> handleChange(e)}
                error={props.error && props.error}
                autoComplete="on"
                placeholder=" "
                //inputClass="nw-green-dark"
                style={{letterSpacing: "0em", minWidth: "310px"}}
            />
        </div>
    </>)
}