import {useState, useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';
import { useTranslation } from "react-i18next";
import FLAGS from "assets/images";
import { SUPPORTEDLNGS } from "config/nw-api";
import { useSelector } from 'react-redux';
import UsersAPI from "Services/UsersAPI";
import { useDispatch } from 'react-redux';
import { update } from 'Reduxx/Reducers/UserSlice';

function LanguageSwitcher() {
	const { t, i18n } = useTranslation();
	const languages = useSelector((state) => state.user?.languages);
	const user = useSelector((state) => state.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const [activeLanguage, setActiveLanguage] = useState(i18n.language);
    const dispatch = useDispatch();

	useEffect(()=>{
		if(isAuthenticated && languages){
			 //if culture & default Language are null take browser|localstorage default language
			const userLanguage = SUPPORTEDLNGS.find((lang)=> lang === languages?.default) ?? 
				SUPPORTEDLNGS.find((lang)=> lang === languages?.culture) ??
				i18n.language;
				
			setActiveLanguage(userLanguage);
			i18n.changeLanguage(userLanguage);
		}
	},[languages, i18n, isAuthenticated])
	const handleOnclick = (e) => {
		e.preventDefault();
		const lang = e.target.getAttribute('data-lang');
		setActiveLanguage(lang);
		i18n.changeLanguage(lang);

		isAuthenticated && dispatch(update({languages: {...languages, default: lang}}));
		if (languages) {
			UsersAPI.updateProfile({"langDef": lang})
				.then((res)=>{
				})
				.catch((err)=>{
					console.log(err, err.response);
				})
		}
	}; 

	const languageList = SUPPORTEDLNGS.map((lang)=>{
		if (!user.roles?.includes('ROLE_133') && lang === "i18n" && process.env.NODE_ENV !== 'development'){
			return null;
		}
		return  (activeLanguage !== lang && 
			<li 
				key={uuidv4()}
				className="dropdown-item"
				data-lang={lang}
				onClick={handleOnclick}
			>
			<img 
				src={FLAGS.svg[lang]}
				className="flags me-2"
				alt={lang}
				loading="lazy"
			/> 
				{t("lang."+lang)}
			</li>
		)
	});

	return (
		<div className="dropdown">
			<a
				className="text-reset default-flag hidden-arrow"
				href="/#"
				id="navbarDropdownMenuLink"
				role="button"
				data-bs-toggle="dropdown"
				aria-expanded="false"
			>
			<img
				src={FLAGS.svg[activeLanguage]}
				className="flag"
				height="16" 
				alt="default language"
				loading="lazy"
			/>
			</a>
			<ul
				className="dropdown-menu dropdown-menu-end p-0"
				aria-labelledby="navbarDropdownMenuLink"
			>
				{languageList}
			</ul>
		</div>
	);
}

export default LanguageSwitcher;