import React from 'react';
import { useParams } from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import { useTranslation } from "react-i18next";
import {useState, useEffect} from 'react';
//import MaterialTable, { MTableToolbar } from 'material-table';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import RASAPI from "Services/RASAPI";
import {ReactComponent as PairSvg} from "assets/images/icon/slot-pair.svg";
import {ReactComponent as ConnectSvg} from "assets/images/icon/slot-connect.svg";
import {ReactComponent as HangupSvg} from "assets/images/icon/slot-hangup.svg";
import {ReactComponent as WaitSvg} from "assets/images/icon/wait.svg";
import {ReactComponent as EstablishSvg} from "assets/images/icon/establish.svg";
import {ReactComponent as AssignSvg} from "assets/images/icon/assign.svg";
import {ReactComponent as TerminSvg} from "assets/images/icon/finish.svg";
import {ReactComponent as LicenseeSvg} from "assets/images/icon/ras-licensee.svg";
import {ReactComponent as ComputerSvg} from "assets/images/icon/ras-computer.svg";
import {ReactComponent as SoftwareSvg} from "assets/images/icon/ras-software.svg";
import { forwardRef, useRef } from 'react';
import ConfirmModal from 'Components/ConfirmModal';
import {RELOAD_RAS} from 'config/nw-api';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useSelector } from 'react-redux';
import {localeDatetime} from 'Components/Tools/DateTime';
import {countOccurrences} from 'Services/utils';
import useTabCommunication from 'Hook/useTabCommunication';

const tableIcons = {
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} className="nw-blue"/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} className="nw-blue"/>),
};

const RasList = forwardRef((props, ref) => {
    const { t } = useTranslation();
	const params = useParams();
	const vncRef = useRef(null);
    const {tabsData, sendTabsData} = useTabCommunication('RAS');
	const [toggle, setToggle] = useState(false);
	const user = useSelector((state) => state.user);
	const HOST_DEFAULT_OPT = {id: "default", name: t("ras.slots.cols.host.sel"), address: "", viewStatus: 0};
	const [data, setData] = useState({...props.data});
    /**
     * Workaround to fix phantom slot
     * Bug: after refreshtoken the state of tableData is lost and the first list will be shown
     * Fix: store the last list in session use to to set tableData
     * 
     * solution: use websockets to send state direct from Backend
    */
    /*let sessionList = null;
    try {
        if (data.team?.id >= 0 && data.team?.id !== 'default') {
            const list = sessionStorage.getItem('rasListTeam'+ data.team?.id)
            sessionList = list ? JSON.parse(list) : null;
        }
    } catch (error) {
        console.log(error);
    }*/

    const [tableData, setTableData] = useState(props.tableData ?? null);//sessionList ?? props.tableData ?? null);

	const [defaultHost] = useState(
		data.hosts?.find(host=> host.id === parseInt(localStorage.getItem('rasSelectedHost'))) ?? 
		HOST_DEFAULT_OPT
	);

    /* eslint-disable */
	useEffect(()=>{
		if (tabsData?.name === 'disconnectSlot' && tabsData.slotHandle) {
			updateSlot({action: 'flag/4'}, {handle: tabsData.slotHandle});
		}
	}, [tabsData])
    /* eslint-enable */

	const [emptyDataMsg, setEmptyDataMsg] = useState(t("ras.slots.err.empty")); //set after setTableData([])
	const setError = (err) => {
        //data.team?.id >= 0 && sessionStorage.setItem('rasListTeam'+ data.team?.id, JSON.stringify([]));
		setTableData([]);
		setEmptyDataMsg(err);
	}

	const [action, setAction] = useState({
		txt: {},
		confirm: null
	});
    const confirmRef = useRef();
    const confirm = (agree, slot, btn) => {
        if (agree && slot) {
			if (!slot.handle) {
				console.log(slot.handle);
			} else{
				updateSlot(btn, slot);
			}
        }
    }

	//AutoReload enable/disable after open hosts list dropdown
	const onBlurSelectChange = (e) => {
		setTableData([...tableData]);
	}	
	const onFocusSelectChange = (e) => {
		clearTimeout(timer);
        controller.current?.abort();
	}
	//Set Timer to reload table by autoReload
	let timer = null;
    if (tableData) {
        var dialogHang = {
			title:t("ras.slots.cols.action.confirm.hang"),
			body:t("ras.slots.cols.action.confirm.hang.txt"),
            yes:t("ras.slots.cols.action.confirm.btn.yes"),
            no:t("ras.slots.cols.action.confirm.btn.no"),
        }
        var dialogUnpair = {
			title:t("ras.slots.cols.action.confirm.unpair"),
			bodyI18N:"ras.slots.cols.action.confirm.unpair.txt",//use the key to pass variable later to function t();
            yes:t("ras.slots.cols.action.confirm.btn.yes"),
            no:t("ras.slots.cols.action.confirm.btn.no"),
        } 
        var dialogDisconnect = {
			title:t("ras.slots.cols.action.confirm.disc"),
			bodyI18N:"ras.slots.cols.action.confirm.disc.txt",//use the key to pass variable later to function t();
            yes:t("ras.slots.cols.action.confirm.btn.yes"),
            no:t("ras.slots.cols.action.confirm.btn.no"),
        } 
        var dialogConnect = {
			title:t("ras.slots.cols.action.confirm.con"),
			bodyI18N:"ras.slots.cols.action.confirm.con.txt",//use the key to pass variable later to function t();
            yes:t("ras.slots.cols.action.confirm.btn.yes"),
            no:t("ras.slots.cols.action.confirm.btn.no"),
        } 
    }

    let columns = [
        {
			title: t("ras.slots.cols.id"),
			field: "id",
			width: "5%",
			render: function(rowData){
				//<Tooltip title={rowData.handle}><span>{rowData.id}</span></Tooltip>
				let tooltip = user?.roles.includes('ROLE_112') ? `${t("ras.slots.cols.id.tt")}${rowData.id}\n` : "";
				tooltip += `${t("ras.slots.cols.id.tt.handle")}${rowData.handle}`;
                return <span title={tooltip}>{rowData.idExtern || rowData.id}</span>
			},
		},
        {
			title: t("ras.slots.cols.status"),
			field: "status",
			width: "8%",
			customFilterAndSearch: function(term,rowData){
				term = term?.toLowerCase();
				if ((t("ras.slots.cols.status.wait")?.toLowerCase()?.includes(term) && rowData.status === 0) || 
				(t("ras.slots.cols.status.assign")?.toLowerCase()?.includes(term) && rowData.status === 1) ||
				(t("ras.slots.cols.status.establish")?.toLowerCase()?.includes(term) && rowData.status === 2) ||
				(t("ras.slots.cols.status.term")?.toLowerCase()?.includes(term) && rowData.status === 3)) {
					return true;
				}
			},
			render: function(rowData){
				let created = rowData.initializedAt? localeDatetime(rowData.initializedAt) : t("gen.text.unavailable.abbr");
				let response = rowData.responseAt? localeDatetime(rowData.responseAt) : '-';
				let terminated = rowData.hangedUpAt? localeDatetime(rowData.hangedUpAt) : '-';
				let status = (rowData.status === 0) ? t("ras.slots.cols.status.wait") : (rowData.status === 1) ? t("ras.slots.cols.status.assign") : (rowData.status === 2) ? t("ras.slots.cols.status.establish") : t("ras.slots.cols.status.term");
				
				let tooltip = `${t("ras.slots.cols.status.tt.create")}${created}\n${t("ras.slots.cols.status.tt.status")}${status}\n${t("ras.slots.cols.status.tt.response")}${response}\n${t("ras.slots.cols.status.tt.term")}${terminated}`;
				if (rowData.status === 0) {
					return <span title={tooltip}><WaitSvg fill="orange"/></span>;
				}
				if (rowData.status === 1) {
					return <span title={tooltip}><AssignSvg fill="green"/></span>;
				}
				if (rowData.status === 2) {
					return <span title={tooltip}><EstablishSvg fill="green"/></span>;
				}
				if (rowData.status === 3) {
					return <span title={tooltip}><TerminSvg fill="red"/></span>;
				}
			}
		},
        {
			title: t("ras.slots.cols.start"),
			field: "start",
			width: "10%",
			customFilterAndSearch: function(term, rowData){
				if (localeDatetime(rowData.initializedAt)?.toLowerCase()?.includes(term?.toLowerCase())) {
					return true;
				}
			},
			render: function(rowData){
				return <span>{rowData.initializedAt? localeDatetime(rowData.initializedAt):t("gen.text.unavailable.abbr")}</span>
			}
		},
        {
			title: t("ras.slots.cols.licensee"),
			field: "info",
			width: "20%",
			customFilterAndSearch: function(term,rowData){
				let arr = [];
				arr = arr.concat(rowData.clientInfo?.license);
				arr = arr.concat(rowData.clientInfo?.system);
				arr = arr.concat(rowData.clientInfo?.runtime);
				
				return arr.find(obj => {
					if (obj) {
						for (const val of Object.values(obj)) {
							if (val?.toLowerCase()?.includes(term?.toLowerCase())) {
								return true;
							}
						}
					}
					return false;
				});
			},
			render: function(rowData){
				//License
				let licenseName = rowData.clientInfo?.license?.name?? t("gen.text.unavailable.abbr");
				let tooltipLicense = t("ras.slots.cols.licensee.tt.lic");
				tooltipLicense += licenseName ?? t("gen.text.unavailable.abbr");
				tooltipLicense += "\n";
				tooltipLicense += "M2M: " + rowData.m2m ?? t("gen.text.unavailable.abbr");
				//System
				let system = rowData.clientInfo?.system;
				let runtime = rowData.clientInfo?.runtime;
				let hostName = runtime?.hostName;
				let product = system?.product;
				let uuid = system?.uuid;
				let serial = system?.serial;
				let startup = runtime?.startup;
				let manufacturer = system?.manufacturer;
				let systemValue = hostName?? product ?? uuid ?? serial ?? startup ?? t("gen.text.unavailable.abbr");
				let tooltipSystem = t("ras.slots.cols.licensee.tt.sys.manu");
				tooltipSystem += manufacturer?? t("gen.text.unavailable.abbr");
				tooltipSystem += "\n";
				tooltipSystem += t("ras.slots.cols.licensee.tt.sys.prod");
				tooltipSystem += product?? t("gen.text.unavailable.abbr");
				tooltipSystem += "\n";
				tooltipSystem += t("ras.slots.cols.licensee.tt.sys.uuid");
				tooltipSystem += uuid?? t("gen.text.unavailable.abbr");
				tooltipSystem += "\n";
				tooltipSystem += t("ras.slots.cols.licensee.tt.sys.ser");
				tooltipSystem += serial?? t("gen.text.unavailable.abbr");
				tooltipSystem += "\n";
				tooltipSystem += t("ras.slots.cols.licensee.tt.sys.time");
				tooltipSystem += startup ? localeDatetime(startup) : t("gen.text.unavailable.abbr");

				//Product
				let productName = rowData.clientInfo?.product?.name;
				let productVersion = rowData.clientInfo?.product?.version;
				let productValue = (productName || productVersion) ? '':t("gen.text.unavailable.abbr");
				productValue += productName ?? '';
				productValue += productVersion ?? '';
				let tooltipProduct = rowData.clientInfo?.runtime?.platform;
				tooltipProduct = tooltipProduct ?t("ras.slots.cols.licensee.tt.platform")+ tooltipProduct : '';

				return <>
						<p className={licenseName === t("gen.text.unavailable.abbr")? "m-0 nw-grey-dark":"m-0 mb-1"} title={tooltipLicense}>
							<LicenseeSvg width="16" fill="var(--nw-blue-dark-color)"/> {licenseName}
						</p>
						<p className={systemValue === t("gen.text.unavailable.abbr")? "m-0 nw-grey-dark":"m-0 mb-1"} title={tooltipSystem}>
							<ComputerSvg width="16" fill="var(--nw-blue-dark-color)"/> {systemValue}
						</p>
						<p className={productValue === t("gen.text.unavailable.abbr")? "m-0 nw-grey-dark":"m-0"} title={tooltipProduct}>
							<SoftwareSvg width="16" fill="var(--nw-blue-dark-color)"/> {productValue}
						</p>
					</>
			}
		},
        {
			title: t("ras.slots.cols.assistant").replace(/ (?=[^ ]*$)/, '\u00A0'),
			field: "assistant",
			width: "12%",
			customFilterAndSearch: function(term, rowData){
				let name = rowData.assistant?.publiceName ?? rowData.user?.publiceName;
				if (name?.toLowerCase()?.includes(term?.toLowerCase())) {
					return true;
				}
			},
			render: function(rowData){
				let duration = rowData?.durationSecond * 1000;
				let jsonHistory = rowData.assistantHistory;
				let tooltipHistory = '';
				let lastAssistant = rowData.user ? rowData.user?.publicName ?? rowData.user?.username ?? rowData.user?.email : null;
				//let lastDuration = duration;
				//let totalHistoryDuration = 0;
				let totalDiff = 0;//New

				try{
					if (jsonHistory) {
						const now = new Date();
						const history = JSON.parse(jsonHistory);
						let dateTemp = null;
						for (const key in history) {
							let endTime = history[key].end ? new Date(history[key].end): new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

							let dateSlotStart = history[key].start.slice(0,10);
							if (dateSlotStart !== dateTemp) {
								tooltipHistory += dateSlotStart +"\n"
								dateTemp = dateSlotStart;
							}

							let diff = Math.abs(endTime - new Date(history[key].start));
							//totalHistoryDuration += diff;
                            totalDiff += diff;//new

							const total = new Date(diff);
							if(!isNaN(total)){
								//const durationTemp = total;
								//slot is already served by assistants and actually is serving by new assitant
								if (duration && parseInt(key) === 0 && history[key].end?.length === 0) {
									duration = duration + diff;
								}

								//first duration will be setting from the first assistant in the history starttime and endtime is now()
								if (duration === 0) {
									duration += diff;
								}
								tooltipHistory += "+ ";
								tooltipHistory += secondsToDHMS(diff/1000)//durationTemp.toISOString().slice(11, 16);
								tooltipHistory += "(";
								tooltipHistory += localeDatetime(history[key].start,true, false);
								//tooltipHistory += localeDatetime(history[key].start,'+00:00').slice(11, 16);
								tooltipHistory += " - ";
								tooltipHistory += history[key].end ? localeDatetime(history[key].end, true, false) : " ??:??";
								//tooltipHistory +=  history[key].end ? localeDatetime( history[key].end,'+00:00').slice(11, 16) : " ??:??";
								tooltipHistory += ") - ";
								tooltipHistory +=  history[key].assistantPublicName === "" ? t("gen.text.unavailable.abbr") :  history[key].assistantPublicName;
								tooltipHistory += "\n";
							}
							if ( history[key].assistantPublicName && lastAssistant === null) {
								lastAssistant = history[key].assistantPublicName;
							}
						}
					}
				}catch(e){
					console.log(`RAS Error Parsing AssistantHistory: slot id (${rowData.id})`);
					console.log(e);
				}
				if (totalDiff) {//if(totalHistoryDuration){
					//totalHistoryDuration = new Date(totalHistoryDuration);
					//tooltipHistory = t("ras.slots.cols.assistant.tt") + totalHistoryDuration.toISOString().slice(11,16) + " h\n\n" + tooltipHistory;
                    tooltipHistory = t("ras.slots.cols.assistant.tt") + secondsToDHMS(totalDiff/1000) + "\n\n" + tooltipHistory;
				}
				return <span title={tooltipHistory}>
						{lastAssistant ? 
							lastAssistant + (duration > 0 ? " | " + (secondsToDHMS(duration/1000)) : "") /*: new Date(lastDuration).toISOString().slice(11, 16) + ' h')*/
						: 
							<span className="nw-grey-dark">{t("gen.text.unavailable.abbr")}</span>
						}
				</span>;
			}
		},
		props.name === "waitActive" ?
        {
			title: t("ras.slots.cols.host"),
			field: "host",
			width: "10%",
			customFilterAndSearch: function(term,rowData){
				if (rowData.host?.name?.toLowerCase().includes(term?.toLowerCase()) && rowData.status !== 3) {
					return true;
				}
			},
			render: function(rowData){
				if(data.hosts && rowData.status !== 3){
					const isPrivate = rowData.host?.user?.id !== user.id && rowData.host?.team?.id !== data?.team?.id && (rowData.host?.id !== 0);

					let selectTitle = '';
					if (rowData.host) {
						rowData.host.address = !isPrivate ? rowData.host.address : t("ras.slots.cols.host.private");
						selectTitle = rowData.host?.id === 'default' ? '': rowData.host?.id === 0 ? `${t(rowData.host.name)}\n${rowData.host.address}` : `${rowData.host.name}\n${rowData.host.address}`;
					}

					return <select
							disabled={
								(rowData.status !== 0 || rowData.assistant !== null || rowData.user !== null || toggle.handle === rowData.handle) ? "disabled" : ""
							}
							key={uuidv4()}
							name={`SelectHost${rowData.id}`}
							value={rowData.host?.viewStatus === 0 ? rowData.host?.id : defaultHost.id}
							title={selectTitle}
							//style={{width: `${(rowData.host?.name?.length)+1.2}ch`}}
							style={{maxWidth:"85%", marginTop: "6px"}}
							onChange={(e) => onChangeHost(e,rowData)}
							onBlur ={onBlurSelectChange}
							onFocus={onFocusSelectChange}
						>
						{(isPrivate || rowData.host?.viewStatus === 1) && 
							<option 
								style={{display: "none"}}
								className="fn-code"
								title={rowData.host?.address}
								value={rowData.host?.id}
							>{rowData.host?.name} {isPrivate && rowData.user? "#" : ""}</option>
						}
						{//ras.host.edit
						user?.roles.includes('ROLE_59') && 
							<optgroup label={t("ras.slots.cols.host.grp.func")}>
								<option value="new" className="nw-green-dark fw-bold">{t("ras.slots.cols.host.grp.func.new")}</option>
								{data.hosts.find(host => host.user?.id === user.id) &&
									<option value="delete" className="nw-red-dark fw-bold">{t("ras.slots.cols.host.grp.func.del")}</option>
								}
							</optgroup>
						}
						
						<option disabled value="default" style={{display: "none"}}>{t("ras.slots.cols.host.sel")}</option>
						
						<optgroup label={t("ras.slots.cols.host.grp.user")}>
							{data.hosts.map((host) =>{
								if ((host.id !== 0 || (rowData.webVNC && user?.roles.includes('ROLE_128'))) && host.team === null) {
									return <option
										key={uuidv4()}
										value={host.id}
										title={`${host.name}\n${host.address}`}
										className={`${host.id === 0 ? "nw-orange-dark fw-bold": ""} fn-code`}
									>
										{host.name}
									</option>
								}
								return null;
							})}
						</optgroup>
						
						<optgroup label={t("ras.slots.cols.host.grp.team")}>
							{data.hosts.map((host) =>{
								if (host.team !== null) {
									return <option 
										key={uuidv4()}
										value={host.id}
										title={`${host.name}\n${host.address}`}
										className={`${host.id === 0 ? "nw-orange-dark fw-bold": ""} fn-code`}
									>
										{host.name}
									</option>
								}
								return null;
							})}
						</optgroup>
					</select>
				}else{
					return <></>
				}
			}
		}
		: 
		null,
		//ras.action.edit
		user?.roles.includes('ROLE_57') && props.name === "waitActive" ?
        {
			title: t("ras.slots.cols.action"),
			field: "action",
			width: "10%",
			render: function(rowData){
				let pairBtn = "";
				let attrPairBtn = {color: "",title: "",action: "",invisible: "invisible"};
				let connectBtn = "";
				let attrConnectBtn = {color: "",title: "",action: "",invisible: "invisible"};
				let hangupBtn = "";
				let attrHangupBtn = {color: "",title: "",action: "",invisible: "invisible"};
				let disconnect = true;
				if (rowData.host !== null) {
					//Assign/Pair/Unpair user
					if (rowData.assistant === null && rowData.user === null) {
						if ((rowData.status === 0 && !(rowData.host?.user?.email !== user.email && rowData.host?.team?.id !== data?.team?.id )) || rowData.host.id === 0) {
							attrPairBtn = {color: "gray",title: t("ras.slots.cols.action.tt.pair"),action: "pair", openVNC: rowData.host?.id === 0};
						}
					}else{
						/*/
						if (!(rowData.flags & 2)) {
							if (rowData.status === 0) {
								attrPairBtn = {color: "var(--nw-orange-color)",title: t("ras.slots.cols.action.tt.unpair"), action: "unpair"}
							}else if(rowData.status === 1){
								attrPairBtn = {color: "var(--nw-green-dark-color)",title: t("ras.slots.cols.action.tt.unpair"), action: "unpair"}
							}else if(rowData.status === 2){
								attrPairBtn = {color: "var(--nw-green-dark-color)",title: t("ras.slots.cols.action.tt.unpairOn"), action: "flag/2"}
								attrPairBtn = {color: "var(--nw-green-dark-color)",title: t("ras.slots.cols.action.tt.unpair"), action: "unpair"}
							}
						}else{
							if ([0,1,2].includes(rowData.status)) {
								attrPairBtn = {color: "var(--nw-red-dark-color)",title: t("ras.slots.cols.action.tt.unpairOff"), action: "flag/2"} 
							}
						}*/
						if (rowData.status === 0) {
							attrPairBtn = {color: "var(--nw-orange-color)",title: t("ras.slots.cols.action.tt.unpair"), action: "unpair", closeVNC: rowData.host?.id === 0}
						}else if([1,2].includes(rowData.status)){
							attrPairBtn = {color: "var(--nw-green-dark-color)",title: t("ras.slots.cols.action.tt.unpair"), action: "unpair", closeVNC: rowData.host?.id === 0}
						}
						//(["hangup", "unpair"].includes(btn.action) && (data.hostId === 0 || data.host?.id === 0))
					}
					//TODO: CODE REF NEEDS
					//Connect/WaitConnect user
					if (rowData.status === 1 && !(rowData.flags & 6) && (rowData.flags & 1)) {//Send Ready to Disconnect
						attrConnectBtn = {color: "var(--nw-orange-color)",title: t("ras.slots.cols.action.tt.conOff"),action: "flag/1", closeVNC: rowData.host?.id === 0};
					//}else if(([1,2].includes(rowData.status)) && !(rowData.flags & 7)){ //Send Ready to Connect
					}else if((2 === rowData.status) && !(rowData.flags & 7)){ //Send Ready to Connect
						attrConnectBtn = {color: "gray",title: t("ras.slots.cols.action.tt.conOn"),action: "flag/1", openVNC: rowData.host?.id === 0};
						disconnect = false;
					}else if(rowData.status === 2 && !(rowData.flags & 6) && (rowData.flags & 1)){
						attrConnectBtn = {color: "var(--nw-green-dark-color)",title: t("ras.slots.cols.action.tt.discOn"),action: "flag/4", closeVNC: rowData.host?.id === 0};
					//}else if([1,2].includes(rowData.status) && (rowData.flags & 4)){ //Connect
					}else if(2 === rowData.status && (rowData.flags & 4)){ //Connect
						attrConnectBtn = {color: "var(--nw-yellow-light-2-color)",title: t("ras.slots.cols.action.tt.discOff"),action: "flag/4", openVNC: rowData.host?.id === 0};
						disconnect = false;
					//}else if([1,2].includes(rowData.status)){
					}else if([1,2].includes(rowData.status)){ //Send Ready to Connect
						attrConnectBtn = {color: "gray",title: t("ras.slots.cols.action.tt.conOn"),action: "flag/1", openVNC: rowData.host?.id === 0};
						disconnect = false;
						//attrConnectBtn = {color: "var(--nw-green-dark-color)",title: t("ras.slots.cols.action.tt.discOn"),action: "flag/4", closeVNC: rowData.host?.id === 0};
					}
				}
				// Stop user
				if (rowData.status !== 3) {
					attrHangupBtn = {color: "red", title: t("ras.slots.cols.action.tt.hang"),action: "hangup", closeVNC: rowData.host?.id === 0};
				}
				let assistant = rowData.user?.publicName ?? rowData.user?.name ??  rowData.user?.email;
				let confirmUnpair = null;
				let confirmConDisconnect = null; //dialogConnect

				//Add confirm to unpair if assitant !== user
				if (rowData.user?.email !== user.email && (rowData.user !== null || rowData.assistant !== null)) {
					dialogUnpair.body = t(dialogUnpair.bodyI18N, {username: assistant});
					confirmUnpair = (rowData.user?.email === user.email) ? null : {confirm:(agree)=>confirm(agree, rowData, attrPairBtn), txt: dialogUnpair};
					
					dialogDisconnect.body =  t(
						disconnect ?dialogDisconnect.bodyI18N : dialogConnect.bodyI18N,
						{username: assistant}
					);
					confirmConDisconnect = (rowData.user?.email === user.email) ? null : {confirm:(agree)=>confirm(agree, rowData, attrConnectBtn), txt: dialogDisconnect};
				}

				pairBtn = <PairSvg
					className={"m-0 p-0 border-0 bg-transparent "+ attrPairBtn.invisible}
					onClick={e => onSlotUpdate(e, rowData, attrPairBtn, confirmUnpair)} 
					fill={attrPairBtn.color} 
					title={attrPairBtn.title} 
					role="button"
					name="pair"
				/>

				connectBtn = <ConnectSvg
					className={"m-0 p-0 border-0 bg-transparent "+ attrConnectBtn.invisible}
					onClick={e => onSlotUpdate(e, rowData, attrConnectBtn, confirmConDisconnect)}
					fill={attrConnectBtn.color}
					title={attrConnectBtn.title}
					role="button"
					name="connect"
				/>

				hangupBtn = <HangupSvg
					//data-action={attrHangupBtn.action}
					className={"m-0 p-0 border-0 bg-transparent "+ attrHangupBtn.invisible}
					fill={attrHangupBtn.color} 
					title={attrHangupBtn.title} 
					role="button"
					name="hangup"
					onClick={e => onSlotUpdate(e, rowData, attrHangupBtn, {confirm:(agree)=>confirm(agree, rowData, {action: "hangup"}), txt:dialogHang})} 
				/>

				const disbaleBtns = toggle ? {pointerEvents: "none", opacity: "0.5"} : {};

				return <>
					<span className={`${toggle?.handle === rowData.handle && ["pair", "unpair"].includes(toggle.action) && "loader-elt"} d-inline-block me-2`} style={disbaleBtns}>{pairBtn}</span>
					<span className={`${toggle?.handle === rowData.handle && toggle.action?.startsWith('flag/') &&"loader-elt"} d-inline-block`} style={disbaleBtns}>{connectBtn}</span>
					<span className={`${toggle?.handle === rowData.handle && toggle.action === "hangup" && "loader-elt"} d-inline-block ms-lg-4 ms-md-2 ms-sm-2`} style={disbaleBtns}>{hangupBtn}</span>
				</>
			}
		}
		: 
		null,
    ];
	columns = columns.filter(item => item !== null);

	const onSlotUpdate = (e, rowData, btn, confirmData = null) => {
		let slot = null;
		if(["pair", "unpair", "hangup"].includes(btn.action) || btn.action?.startsWith('flag/')){
			if (!rowData.handle || rowData.host?.id === null) {
				console.log('Undefined Handle/host');
				setError(t("err.unexpected"));
				console.log(rowData.host?.id);
				console.log(rowData.handle);
			} else{
				slot = {
					handle: rowData.handle,
					hostId: rowData.host?.id
				}
				//Show confirm Modal
				if (confirmData) {
					setAction(confirmData);
                    confirmRef.current.click();

					return;
				}
				confirm(true, slot, btn);
			}
		}
	}

	const onChangeHost = (e, rowData) => {
		if (e.currentTarget.value === "new") {
			props.formHost?.current?.click();
			return;
		}
		if (e.currentTarget.value === "delete") {
			props.formDeleteHost?.current?.click();
			return;
		}
		const selectedHost = data?.hosts?.find(host => host.id === parseInt(e.currentTarget.value));
		
		if (selectedHost) {
			localStorage.setItem('rasSelectedHost', selectedHost?.id);
			const newTable = tableData.map(slot =>slot.id === rowData.id ? { ...slot, host: selectedHost } : slot);
            //sessionStorage.setItem('rasListTeam'+ data.team?.id, JSON.stringify(newTable));
            setTableData([...newTable]);
		
		}
	}

	const updateSlot = (btn, data) => {
		setToggle({action: btn.action, handle:data.handle});
		RASAPI.updateSlot(btn.action, data)
		.then((res) => {
			if (btn.action === "hangup") {
				const updatedData = tableData.filter((slot) => slot.id !== res.data.id);
                //sessionStorage.setItem('rasListTeam'+ data.team?.id, JSON.stringify(updatedData));		
				setTableData([...updatedData]);
			}else{
				const newSlot = res.data;
				if (newSlot.host?.id === 0 && btn.openVNC && vncRef.current) {
					vncRef.current.href = t("link.ras.vnc.url")+"/"+newSlot.handle;
					vncRef.current?.click();
				}

                const newTable = tableData.map(slot => {
                    if (!newSlot.host && defaultHost.id !== HOST_DEFAULT_OPT.id) {
                        newSlot.host = defaultHost;
                    }
                    if (slot.id === newSlot?.id) {
                        return newSlot;
                    }
                    return slot;
                });
                
                //sessionStorage.setItem('rasListTeam'+ data.team?.id, JSON.stringify(newTable));	
                setTableData([...newTable]);
			}
			//Close VNC Tabs
			if(btn.closeVNC){
				console.log('close Slot tabs');
				sendTabsData('closeTab', `/${data.handle}`);
			}
		})
		.catch((err)=> {
			console.log(err);
			setError(t("err.unexpected"));
            setTableData([...tableData]);
		}).finally(()=>{
			setToggle(false);
		});
	}

	useEffect(() => {
		setData({...props.data});

		return () => {
			setData({team:null, hosts:[], reloadAuto: false});
		};
	}, [props.data]);
	useEffect(() => {
		setTableData(props.tableData);

		return () => {
			setTableData(null);
		};
	}, [props.tableData]);
    /* eslint-disable */

    const controller = useRef(null);

    useEffect(() => {
        let mounted = true;
        if (toggle === false && mounted) {
			let from = null;
			if (tableData?.length > 0) {
				//find last time to update slots
				from = "0";
				for (let i = 0; i < tableData.length; i++) {
					const slot = tableData[i];
					if (slot.changedAt && slot.changedAt >= from) {
						from = slot.changedAt;
					}else if(slot.initializedAt >= from){
						from = slot.initializedAt;
					}
				}
			}

            timer = setTimeout(() => {
                controller.current = new AbortController(); //abort Request if user update slot
                if (data.team?.id !== "default" && localStorage.getItem('rasReload') === 'true') {
					RASAPI.slots(data.team?.id, props.name, from, {signal: controller.current?.signal})
                    .then((res) => {
						let table = [];
						if (res.data.length > 0) {
							table = res.data;
							if (defaultHost) {
								table = table?.map(slot => ({
									...slot,
									host: slot.host === null ? defaultHost : slot.host,
									isNew: slot.initializedAt > from
								}));
							}
						}
                        //sessionStorage.setItem('rasListTeam'+ data.team?.id, JSON.stringify(table));	
                        setTableData([...table]);
                        props.handleSlotsLengthChange(props.name, table.length);
					}).catch((err)=>{
                        setTableData([...tableData]);
						const {response} = err;
                        if (response) {
							console.log(response);
						} else {
							console.log(err);
							console.log("network error like timeout, connection refused etc...");
						}
					});
				}

            }, RELOAD_RAS*1000);

            return ()=>{
				clearTimeout(timer);
                mounted = false;
                //controller.current?.abort();
			}
        }
    }, [tableData, toggle]);

	//Hide columns from stored value in Localstorage 
	useEffect(()=>{
		let localHiddenCol = [];
		if (localStorage.getItem('hideRasColumn')) {
			localHiddenCol = JSON.parse(localStorage.getItem('hideRasColumn'));
		}
		for (const column of columns) {
			if (localHiddenCol.includes(column.field)) {
				//column.hiddenByColumnsButton = true;
				column.hidden = true;
			}
		}
	})
    /* eslint-enable */

    const tableRef = useRef(null);

  	return (
		<>
			<a ref={vncRef} className="d-none" href="/#" target="_blank">VNC CLICK HERE</a>
			<ConfirmModal btnStyles={{display: "none"}} confirmRef={confirmRef} id="confirmAction" txt={action.txt} classes="nw-rm-btn p-0" confirm={action.confirm}/>

			{<MaterialTable
                tableRef={tableRef}
				icons={tableIcons} 
				columns={columns} 
				data={tableData?? []}
				style={{padding:0}}
				title={t(`ras.tabs.${props.name}.desc`)}
				isLoading={tableData ?false:true}
				onSearchChange={(txt) => {
					if (txt) {
                        controller.current?.abort();
						clearTimeout(timer);
					}else{
                        console.log('change not');
						setTableData([...tableData]);
					}
				}}
				options={{
					tableLayout: "fixed",
					search: true,
					//searchAutoFocus: true,
					sorting: false,
					filtering: false,
					actionsColumnIndex: -1,
					paging: false,
					draggable: false,
					columnsButton: true,
					headerStyle: {fontFamily: "roboto-bold", fontSize: "1rem", backgroundColor: "var(--nw-blue-light-color)", overflow: "hidden", textOverflow: "... ", whiteSpace: "nowrap"},
					rowStyle: (rowData) => {
						let style = {backgroundColor: '#fff'};
						if (rowData.isNew || rowData.loadTimestamp !== undefined) {
							style = {backgroundColor: 'var(--nw-blue-light-1-color)'};
						}else if(countOccurrences(window.location.href, t("link.ras.url"), '/') > 0 && params.handle === rowData.handle) {// Slot Link+handle
							style = {backgroundColor: 'var(--nw-yellow-light-1-color)'};
						}else if(props.name === "waitActive" && 0 === rowData.status) { //Slots wait
							style = {backgroundColor: 'var(--nw-yellow-light-1-color)'};
						}
						return style;
					},
				}}
				actions={[
				/*{
					icon: () => <Laptop style={{fill: "var(--nw-blue-color)"}}/>,
					tooltip: t("ras.slots.cols.host.new.tt"),
					onClick: (e) =>  props.formHost.current.click(),//console.log(addHost.current),
					isFreeAction:true
				}*/
				]}
				components={{
					Toolbar: (props) => {
						return (
							<div className="nw-toolBar" style={{ backgroundColor: "var(--nw-blue-light-color)" }}>
								<MTableToolbar {...props} />
							</div>
						);
					}
				}}
				
				onChangeColumnHidden={(column, hidden) =>{
					column.hidden = hidden;
					let arr = [];
					if (localStorage.getItem('hideRasColumn')) {
						arr = JSON.parse(localStorage.getItem('hideRasColumn'));
					}
					if (hidden && !arr.includes(column.field)) {
						arr.push(column.field)
					}else{
						let filetred = arr.filter(function(value){ 
							return value !== column.field;
						});
						arr = filetred;
					}
					arr = JSON.stringify(arr);

					localStorage.setItem('hideRasColumn', arr)
				}}

				localization={{
					body: {
						emptyDataSourceMessage: tableData ? 
							emptyDataMsg 
							: /* use only div if loadingSlots() is not used anymore */
							<table style={{width:"100%", margin: "15px 0 15px 0"}}>
								<tbody><tr><td style={{height: "300px"}}></td></tr></tbody>
							</table>
						,
					},
					toolbar:{
						addRemoveColumns: t("ras.slots.opt.col"),
						showColumnsTitle: t("ras.slots.opt.col.tt"),
						searchPlaceholder: t("ras.slots.opt.filter"),
						searchTooltip: t("ras.slots.opt.filter.tt"),
					}
				}}
				/*actions={
					[
						{icon: () => <Stop />, tooltip: 'Stop', onClick: (event, rowData) => {}},
					]
				}*/
			/>}
		</>
  	)
})

export default RasList;

function secondsToDHMS(totalseconds) {
    const day = 86400;
    const hour = 3600;
    const minute = 60;

    let daysout = Math.floor(totalseconds / day);
    let hoursout = Math.floor((totalseconds - daysout * day)/hour);
    let minutesout = Math.floor((totalseconds - daysout * day - hoursout * hour)/minute);
    //const secondsout = totalseconds - daysout * day - hoursout * hour - minutesout * minute;
    hoursout = daysout*24 + hoursout;
    let result = hoursout ? Math.abs(hoursout) >= 10 ? hoursout + ':': '0'+hoursout : '00:';
    result += minutesout ? Math.abs(minutesout) >= 10 ? minutesout : '0'+minutesout : '00';
    result += ' h';

    return result;
}
