import { useState, useEffect } from 'react';
import Field from 'Components/Forms/Field';
//Import translation to find all countries
import DATA_I18N from 'Translations/i18n/translation.json';
import UsersAPI from "Services/UsersAPI";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {v4 as uuidv4} from 'uuid';
import Checked from "assets/images/icon/checked.svg";
import Warn from "assets/images/icon/warn.svg";
import { useDispatch } from 'react-redux';
import { update } from 'Reduxx/Reducers/UserSlice';
import { useSelector } from 'react-redux';
import {hasMinOneRole} from "Services/utils";
import Spinner, {LoadingError} from "Components/Loading/Spinner";
import { SUPPORTEDLNGS } from "config/nw-api";
import {useFlashContext}  from 'Contexts/FlashContext';
import DatePicker from 'react-date-picker';
import {ReactComponent as Calendar} from "assets/images/icon/calendar.svg";
import { i18nLocale } from "Components/Tools/DateTime";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import cn from 'react-phone-input-2/lang/cn.json';
import de from 'react-phone-input-2/lang/de.json';
import es from 'react-phone-input-2/lang/es.json';
import fr from 'react-phone-input-2/lang/fr.json';
import it from 'react-phone-input-2/lang/it.json';
import pl from 'react-phone-input-2/lang/pl.json';
import pt from 'react-phone-input-2/lang/pt.json';
import tr from 'react-phone-input-2/lang/tr.json';

const PHONE_TRANS = {cn, de, es, fr, it, pl, pt,tr}

function compare(obj1, obj2){
    for (const key in obj1) {
        if (Object.hasOwnProperty.call(obj1, key)) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
    }
    return true;
}

export default function Profile() {
    const { t, i18n } = useTranslation();
    document.title = t("profile")+ " – nanoways.com";
    const user = useSelector((state) => state.user);
    const [languages, setLanguages] = useState([]);
    const [updated, setUpdated] = useState(false);
    const {flash, setFlash} = useFlashContext();
    const [data, setData] = useState({
        name: "",
        publicName: "",
        email: null,
        langCult: "default",
        langDef: "",
        address: "",
        phone: "",
        birthday: "",
        notifyUpdate: "",
        notifyUpdateLicense: "",
        notifyChargeableUpdate: "",
    });
    const [stages, setStages] = useState([]);
    const [lastData, setLastData] = useState({saved:true, profile:{}}); //check if data is not stored in DB
    const [errors, setErrors] = useState({
        name: "",
        publicName: "",
        email: "",
        langCult: "",
        address: "",
        phone: "",
        birthday: "",
        notifyUpdate: "",
        notifyUpdateLicense: "",
        notifyChargeableUpdate: "",
        api:""
    });

    const [toggle, setToggle] = useState(false);
    //const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [reload, setReload] = useState(false);
    const [serverError, setServerError] = useState(false);
    const reloadPage = () => {
        setServerError(false);
        setReload(!reload);
    }

	const hasPageAccess = user.roles?.includes('ROLE_34');//profile.page

    /* eslint-disable */
	useEffect(() => {
		let isMounted = true;
		if (!data.email && isMounted && hasPageAccess) {
			UsersAPI.profile()
            .then((resp)=>{
                const profile = resp.data?.user;
                const userdata = {
                    name: profile.name ?? "",
                    publicName: profile.publicName ?? "",
                    email: profile.email ?? "",
                    langCult: profile.langCult??"default",
                    address: profile.address ?? "",
                    phone: profile.phone ?? "",
                    birthday: profile.birthday ?? "",
                    notifyUpdate: profile.notifyUpdate,
                    notifyUpdateLicense: profile.notifyUpdateLicense,
                    notifyChargeableUpdate: profile.notifyChargeableUpdate,
                    notifyStageMax: profile.notifyStageMax ?? "default",
                }
                setData(userdata);
                setStages(resp.data.stages);
                setLastData({ saved: true, profile: {...userdata}});
            }).catch((err)=>{
				console.log('response: ', err.message);
				console.log('err: ', err);
				if(err.message?.includes('Network Error')){
					setServerError(true);
				} else {
                    setFlash({alert:"danger", message: t("err.unknown")});                    
                }
            });
            
            let lang = [];
            Object.keys(DATA_I18N).forEach(function(key){
                if(key.includes("languages.")){
                    lang.push(DATA_I18N[key]);
                }
            });
            lang = lang.sort(function (a, b) {
                if (t(`languages.${a}`) < t(`languages.${b}`)) {
                    return -1;
                }
                if (t(`languages.${a}`) > t(`languages.${b}`)) {
                    return 1;
                }
                return 0;
            });
            setLanguages(lang);
		}

		return () => { isMounted = false }; // cleanup toggles value, if unmounted
	}, [reload])
    /* eslint-enable */

    // Manage input changing in form
    const handleChange = (e) => {
        setUpdated(false);
        const {name, value} = e.currentTarget;
        let isEqual = true;
        if (e.currentTarget.type === "checkbox") {
            //data[name] = e.currentTarget.checked;
            setData({...data, [name]: e.currentTarget.checked});
            isEqual = compare(lastData.profile, {...data, [name]: e.currentTarget.checked});
        }else if(['name', 'email', 'langCult', 'address', 'phone', 'birthday', 'publicName', 'notifyStageMax'].includes(name)){
            setData({...data, [name]: value});
            isEqual = compare(lastData.profile, {...data, [name]: value});
        }
        //check if Data is diffrent as stored Data
        if(isEqual) {
            setLastData({...lastData, saved: true});
        }else{
            setLastData({...lastData, saved: false});
        }
    };

    const handleChangeBirthday = (date) => {
        setErrors({...errors, birthday: ""});
        const today = new Date();
        if (date > today) {
            setErrors({...errors, birthday: "licenses.list.cols.action.editForm.sellDate.err.future"});

           return;
        }
        const stringDate = date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : '';
        
        handleChange({currentTarget:{name: 'birthday', value: stringDate}});
    }

    // Manage form submission
    const handleSubmit = async event => {
        event.preventDefault();
        setToggle(true);
        setErrors({});
        let currentErrors = {};
        for (const name in data) {
            if (name === "name" && data[name]?.length > 100) {
                currentErrors[name] = "nw.checkPwd.min";
            }
        }

        setErrors(currentErrors);

        if (Object.keys(currentErrors).length === 0) {
            const langDef = SUPPORTEDLNGS.find((lang)=> lang === data?.langCult);
            ///when langCul is supported by UI change user.langDef in DB
            if (langDef) {
                data.langDef = langDef;
            }
            await UsersAPI.updateProfile(data)
            .then((resp)=>{
                setToggle(false);
                setUpdated(true);
                setLastData({...lastData, saved:true, profile: data});
                dispatch(update({publicName:data.publicName, name:data.name}));
                if (data.langCult !== user?.languages?.culture) {
                    const userLangs = {...user?.languages, culture: data.langCult};
                    //when langCul is supported by UI switch UI language
                    if (langDef) {
                        userLangs.default = data.langDef;
                    }
                    dispatch(update({languages: userLangs}));
                }
            })
            .catch((err)=>{
                setToggle(false);
                if(!err.response || err.response.data.status === 500){
                    console.log(err.message);
                    setErrors({...errors, api:t("err.500")});
                } else {
                    setFlash({alert:"warning", message: t("err.unknown")});                    
                }
            })
        }else{
            setToggle(false);
        }
    };

    /* eslint-disable */
    useEffect(()=>{
        let isMounted = true;
        //Adapt code here when UI support new languages
        const updatePhoneData = (lang) => {
            let localization = null;
            let country = lang;
            switch (lang) {
              case "zh_CN": 
                localization = cn;
                country =  "cn";
                break;
              case "zh_TW":
                localization = cn;
                country =  "tw";
                break;
              default :
                country = lang === 'en'? 'gb' : lang;
                localization = PHONE_TRANS[lang];//Lang should be import from "'react-phone-input-2/lang/tr.json'"
                break;
            }

            setPhoneData({localization, country, key: 'phone-'+ phoneData.counter+1, counter: phoneData.counter+1})
        }

        if (isMounted) {
            updatePhoneData(i18n.language);
        }

        return  () => {isMounted=false;}
    },[i18n.language])
    /* eslint-enable */
    const [phoneLabelColor, setPhoneLabelColor] =  useState('var(--nw-grey-mute-light-color)');
    const [phoneData, setPhoneData] = useState({
        localization: null, 
        country: 'de',
        key:'phone-0',//Key used as key in Component <PhoneInput key={phoneData.key} ...> to force render element if localization is changed
        counter: 0
    });
    const [birthdayStyle, setBirthdayStyle] = useState({
        label:{color: 'var(--nw-grey-mute-light-color)'},
        input:{borderColor: 'var(--nw-grey-mute-light-color)'}
    })

    if(!hasPageAccess){
        return <LoadingError h2={t("nw.err.noAccess")} txt={t("nw.err.noAccess.txt")}/>
    }

    return (
        <>
            {serverError  ? 
                <LoadingError h2={t("nw.err.server")} txt={t("nw.err.server.txt")} reload={reloadPage} btn={t("nw.err.server.btn")}/>
                :
                <>
                    {!data?.email && !flash && <Spinner className="overlay"/>}
                    <div className="m-0 p-0 vertical-center">
                        <div className="bm-main">
                            <div className="bm-card">
                                <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
                                <h1>{t("profile")}</h1>
                                {data?.email && 
                                <>
                                    <h2 className="bm-sep-1">{t("profile.data")}</h2>
                                    {errors.api && <p className="fn-smtxt invalid-feedback d-block">{t(errors.api)}</p>}
                                        
                                    <form onSubmit={handleSubmit} autoComplete="on">
                                        {//profile.name
                                            user?.roles.includes('ROLE_36') && 
                                            <Field
                                                name="name"
                                                disabled={user?.roles.includes('ROLE_45') ? "": "disabled" }//profile.name.edit
                                                placeholder={t("profile.data.name.hint")}
                                                label={t("profile.data.name")}
                                                id="name"
                                                value={data.name || ''}
                                                onChange={handleChange}
                                                error={t(errors.name, {num: 100})}
                                            />
                                        }
                                        {//profile.publicName
                                            user?.roles.includes('ROLE_110') && 
                                            <Field
                                                name="publicName"
                                                disabled={user?.roles.includes('ROLE_109') ? "": "disabled" }
                                                placeholder={t("profile.data.publicName.hint")}
                                                label={t("profile.data.publicName")}
                                                id="publicName"
                                                value={data.publicName || ''}
                                                onChange={handleChange}
                                                error={t(errors.publicName, {num: 100})}
                                            />
                                        }
                                        <Field
                                            name="email"
                                            label={t("profile.data.email")}
                                            id="email"
                                            value={data.email || ''}
                                            onChange={handleChange}
                                            //error={t(errors.email)}
                                            disabled={data.email}
                                            title={t("nw.input.off")}
                                        />
                                        
                                        <div className="bm-two-columns">
                                            {//profile.lang
                                            user?.roles.includes('ROLE_107') && 
                                                <div className="bm-two-columns-left bm-input-wrapper">
                                                    <select
                                                        disabled={user?.roles.includes('ROLE_106') ? "": "disabled" }
                                                        id="langCult"
                                                        name="langCult"
                                                        value={data.langCult}
                                                        onChange={handleChange}
                                                        className={`nw-select ${errors.langCult ? "bm-input-is-invalid" : ""}`}
                                                    >
                                                        <option value="default" disabled>{t("selfreg.enterData.userCult")}</option>
                                                        {
                                                            Object.values(languages).map((lang)=>{
                                                                return (
                                                                    <option
                                                                        key={uuidv4()}
                                                                        value={lang}
                                                                        title={lang}
                                                                        className="bm-marg-bott-1rem text-black"
                                                                    >
                                                                        {t(`languages.${lang}`)}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {data.langCult !== "default" && <label htmlFor="langCult">{t("selfreg.enterData.userCult")}</label>}
                                                </div>
                                            }
                                        </div>
                                        {//profile.address
                                            user?.roles.includes('ROLE_215') && 
                                            <div className="bm-input-wrapper w-100">
                                                <textarea
                                                    style={{backgroundColor: "transparent"}}
                                                    disabled={!user?.roles.includes('ROLE_216') || toggle}
                                                    value={data.address}
                                                    name="address"
                                                    onChange={handleChange}
                                                    className="bm-input"
                                                    placeholder={t("profile.data.address.hint")}
                                                    rows='3'
                                                />
                                                <label htmlFor="address">{t("profile.data.address")}</label>
                                            </div>
                                        }
                                        <div className="bm-two-columns">
                                            {//profile.birthday
                                            user?.roles.includes('ROLE_219') && 
                                                <div className="bm-input-wrapper">
                                                    <div style={{marginBottom: "1rem", position: "relative", paddingTop: ".55rem"}}>
                                                        <label 
                                                            htmlFor="birthday"
                                                            style={{
                                                                position: "absolute",
                                                                top: "-13px",
                                                                ...birthdayStyle.label,
                                                                background: "#ffffff",
                                                                opacity: 1,
                                                                transform: "scale(0.7)",
                                                                transformOrigin: "left",
                                                                padding: "2px 5px",
                                                                marginLeft: "11px",
                                                            }}
                                                        >{t("profile.data.birthday")}</label>
                                                        <span
                                                            style={{
                                                                padding: "14px 0px 14px 0px",
                                                                borderWidth: "1px",
                                                                borderStyle: "solid",
                                                                ...birthdayStyle.input,
                                                                borderRadius: ".3rem",
                                                                transition: "all 150ms ease-in-out",
                                                                boxShadow: "none",
                                                                outline: "none",
                                                                backgroundColor: `${!user?.roles.includes('ROLE_220') || toggle ? "rgb(240, 240, 240)" : "#ffffff"}`
                                                            }}
                                                        >
                                                            <DatePicker
                                                                name="birthday"
                                                                className="sellDatePanel"
                                                                maxDate={new Date()}
                                                                minDate={new Date('01-01-1970')}
                                                                value={data.birthday}
                                                                //calendarClassName=""
                                                                //clearIcon={<Clear {...props} htmlColor="red"/> || null}
                                                                //shouldOpenCalendar={(e,reason) => handleFocus(reason)}
                                                                calendarIcon={<Calendar fill="var(--nw-blue-dark-color)"/>}
                                                                disabled={!user?.roles.includes('ROLE_220') || toggle}
                                                                locale={i18nLocale(i18n.language)}
                                                                onChange={handleChangeBirthday}
                                                                //onChange={(value) => {console.log(value)}}
                                                                //onCalendarClose={onCalendarClose}
                                                                //onCalendarOpen={onCalendarOpen}
                                                                shouldOpenCalendar={({ reason }) => {
                                                                    setBirthdayStyle({
                                                                        ...birthdayStyle,
                                                                        label:{...birthdayStyle.label, color: 'var(--nw-blue-color)'},
                                                                        input:{...birthdayStyle.input, borderColor: 'var(--nw-blue-color)'},
                                                                    
                                                                    });
                                                                    return true;
                                                                }}
                                                                shouldCloseCalendar={({ reason }) => {
                                                                    setBirthdayStyle({
                                                                        ...birthdayStyle,
                                                                        label:{...birthdayStyle.label, color: 'var(--nw-grey-mute-light-color)'},
                                                                        input:{...birthdayStyle.input, borderColor: 'var(--nw-grey-mute-light-color)'},
                                                                    
                                                                    })
                                                                    return setTimeout(() => {
                                                                        return true
                                                                    }, 300);
                                                                }}
                                                                onFocus={(event) => {
                                                                    setBirthdayStyle({
                                                                        ...birthdayStyle,
                                                                        label:{...birthdayStyle.label, color: 'var(--nw-blue-color)'},
                                                                        input:{...birthdayStyle.input, borderColor: 'var(--nw-blue-color)'},
                                                                    
                                                                    })
                                                                }}
                                                                onBlur={(event) => {
                                                                    setBirthdayStyle({
                                                                        ...birthdayStyle,
                                                                        label:{...birthdayStyle.label, color: 'var(--nw-grey-mute-light-color)'},
                                                                        input:{...birthdayStyle.input, borderColor: 'var(--nw-grey-mute-light-color)'},
                                                                    
                                                                    })
                                                                }}
                                                            />
                                                        </span>
                                                        {(errors?.birthday)  && <p className="fn-smtxt input-error ps-1 pt-1" dangerouslySetInnerHTML={{__html: t(errors?.birthday)}}/>}
                                                    </div>
                                                </div>
                                            }
                                            {//profile.phone
                                            user?.roles.includes('ROLE_217') && 
                                                <div className="bm-two-columns-right bm-input-wrapper">
                                                    <label style={{color: phoneLabelColor}} htmlFor="phone" className="phone-label fn-code">{t("profile.data.phone")}</label>
                                                    <PhoneInput
                                                        key={phoneData.key}
                                                        localization={{...phoneData.localization}}
                                                        country={phoneData.country}
                                                        inputClass='phoneInput'
                                                        preferredCountries={['de', 'gb','es','fr']}
                                                        //enableSearch={true}
                                                        //searchPlaceholder={"profile.data.phone.search"}
                                                        //searchNotFound ={"profile.data.phone.search.no"}
                                                        specialLabel={''}
                                                        //copyNumbersOnly={false}
                                                        placeholder={t("profile.data.phone.hint")}
                                                        value={data.phone}
                                                        disabled={!user?.roles.includes('ROLE_218')|| toggle}
                                                        onChange={value => handleChange({currentTarget: {name: 'phone', value: value ? '+'+value : ""}})}
                                                        onFocus={() => {setPhoneLabelColor('var(--nw-blue-color)')}}
                                                        onBlur={() => {setPhoneLabelColor('var(--nw-grey-mute-light-color)')}}
                                                    />
                                                </div>
                                            }
                                        </div>

                                        {/*notifications*/}
                                        {user?.roles.includes('ROLE_211') && <>
                                            
                                            <h2 className="bm-sep-1">{t("profile.data.notifyUpdate")}</h2>

                                            {["notifyUpdate", "notifyUpdateLicense", "notifyChargeableUpdate"].map((notify)=>{ 
                                                if (
                                                    (!user?.roles.includes('ROLE_145') && notify ==="notifyChargeableUpdate")
                                                    ||
                                                    (!user?.roles.includes('ROLE_147') && notify ==="notifyUpdateLicense")
                                                ) {
                                                    return null;
                                                }

                                                let i18nKey = "", disabled = "disbaled";
                                                switch (notify) {
                                                    case "notifyUpdate":
                                                        i18nKey = '.gen';
                                                        disabled = user?.roles.includes('ROLE_47') ? "": "disabled";
                                                        break;
                                                    case "notifyUpdateLicense":
                                                        i18nKey = '.lic';
                                                        disabled = user?.roles.includes('ROLE_47') && data["notifyUpdate"]? "": "disabled";
                                                        break;
                                                    case "notifyChargeableUpdate":
                                                        //Add "edit chargeable right" later => && user?.roles.includes('ROLE_146')
                                                        i18nKey = '.pay';
                                                        disabled = user?.roles.includes('ROLE_47') && data["notifyUpdate"]? "": "disabled";
                                                        break;
                                                    default:
                                                }

                                                return  (
                                                    <div key={uuidv4()} className="d-flex align-items-center mb-1">
                                                        <span title={t(`profile.data.notifyUpdate${i18nKey}.tt`)}>
                                                            <input
                                                                disabled={disabled}
                                                                name={notify}
                                                                id={notify}
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                checked={data[notify]}
                                                                onChange={handleChange}
                                                                value={data[notify]}
                                                            />
                                                            <label className="form-check-label ms-2 fn-smtxt-bold" htmlFor={notify}>
                                                                <span dangerouslySetInnerHTML={{__html: t(`profile.data.notifyUpdate${i18nKey}`)}}/>
                                                            </label>
                                                        </span>
                                                    </div>
                                                )
                                            })}
                                            {//Stages
                                            stages.length > 0 &&
                                                <div className="bm-two-columns">
                                                    <div className="bm-two-columns-left bm-input-wrapper mt-3">
                                                        <select
                                                            title={t('profile.data.notifyUpdate.stage.tt')}
                                                            disabled={data.notifyUpdate ? "": "disabled" }
                                                            id="notifyStageMax"
                                                            name="notifyStageMax"
                                                            value={data.notifyStageMax}
                                                            onChange={handleChange}
                                                            className={`nw-select ${errors.langCult ? "bm-input-is-invalid" : ""}`}
                                                        >
                                                            {
                                                                stages.map((stage)=>{
                                                                    return (
                                                                        <option
                                                                            key={uuidv4()}
                                                                            value={stage}
                                                                            //title={stage}
                                                                            className="bm-marg-bott-1rem text-black"
                                                                        >
                                                                            {t(`clear.select.opt.stage.${stage}`)}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {<label htmlFor="notifyStageMax">{t("profile.data.notifyUpdate.stage")}</label>}
                                                    </div>
                                                </div>
                                            }
                                        </>}
                                        {//submit Btn
                                            hasMinOneRole(['ROLE_38', 'ROLE_45', 'ROLE_47', 'ROLE_109', 'ROLE_106'], user?.roles) && 
                                            <div className="bm-nav-card-bottom align-items-center">
                                            {updated && 
                                                <button disabled className="bm-btn-card-prev bg-white border-0 px-0 nw-green-dark">
                                                    {t("profile.data.txt.ok")}
                                                    <img
                                                        src={process.env.PUBLIC_URL + Checked}
                                                        className="ms-2 fade-in"
                                                        style={{marginTop: "-4px"}}
                                                        height="16"
                                                        alt="Icon"
                                                    />
                                                </button>
                                            }
                                            {!lastData.saved && 
                                                <button disabled className="bm-btn-card-prev bg-white border-0 px-0 text-warning">
                                                    {t("profile.data.txt.warn")}
                                                    <img
                                                        src={process.env.PUBLIC_URL + Warn}
                                                        className="ms-2 fade-in"
                                                        style={{marginTop: "-4px"}}
                                                        height="16"
                                                        alt="Icon"
                                                    />
                                                </button>
                                            }
                                            <button type="submit" disabled={(toggle || updated)  && "disabled"} className={`${updated ? "btn-disabled":"bm-btn-blue"}  bm-btn-card-next d-flex justify-content-center`}>
                                                {toggle &&
                                                    <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                                }
                                                <span className={toggle ? "invisible" : ""}>{t("profile.data.btn")}</span>
                                            </button>
                                            <div className="bm-clearer"></div>
                                        </div>
                                        }
                                    </form>
                                </>
                                }
                            </div> 
                        </div>
                    </div>
                </>   
            }
        </>
    )
}
