import { useState, useEffect, useRef } from "react";
import LICENSEAPI from 'Services/LicenseAPI';
import Field from "Components/Forms/Field";
import { useTranslation } from "react-i18next";
import Spinner from 'Components/Loading/Spinner';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import {ReactComponent as Calendar} from "assets/images/icon/calendar.svg";
import { i18nLocale } from "Components/Tools/DateTime";
import useFocusModal from "Hook/useFocusModal";

export default function LicenseEditModal(props) {
    const { t, i18n } = useTranslation();
    const locale = i18nLocale(i18n.language);
    const captionInputRef = useRef(null);
    const modalRef = useRef(null);
    useFocusModal(modalRef, captionInputRef);
    const [license, setLicense] = useState(null);
    const oldData = useRef(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [sellDate, setSellDate] = useState(null);
    const [focusSellDate, setFocusSellDate] = useState(false);
    const sharedDate = useRef(null);

    const handleChangeText = (e) => {
        const {name, value} = e.currentTarget;
        setLicense({...license, [name]: value});
    };
    
    const handleChangeSellDate = (date) => {
        const today = new Date();
        if (date > today) {
            setErrors({...errors, sellDate: t("licenses.list.cols.action.editForm.sellDate.err.future")});
            return;
        }
        if (oldData.current?.sellDate && (date > oldData.current?.sellDate || date === null)) {
            setErrors({...errors, sellDate: t("licenses.list.cols.action.editForm.sellDate.err.change")});
            return;
        }
        const stringDate = date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : null;
        setSellDate(stringDate);
        setLicense({...license, sellDate: stringDate});
    }

    useEffect(()=>{
		const dateClear = document.querySelector('.react-date-picker__clear-button');
        !sellDate ? dateClear?.classList.add('invisible') : dateClear?.classList.remove('invisible');
    })

    useEffect(() => {
        if (props.oldData) {
            const {caption, currentKey, sellDate} = props.oldData;
            const sellDateDatePart = sellDate ? sellDate?.split('T')[0] : sellDate;
            setLicense({caption, sellDate: sellDateDatePart, currentKey});
            setSellDate(props.oldData?.sellDate);
            oldData.current = {caption, currentKey, sellDate: sellDateDatePart};
        }
    }, [props.oldData])

    // Manage form submission
    const handleSubmit = async e => {
        e.preventDefault();
        const today = new Date();
        const newSellDate = license.sellDate ? new Date(license.sellDate) : null;
        const oldSellDate = oldData.sellDate ? new Date(oldData.sellDate) : null;
        if (newSellDate > today) {
            setErrors({...errors, sellDate: t("licenses.list.cols.action.editForm.sellDate.err.future")});
            return;
        }
        if (oldSellDate && (newSellDate > oldSellDate || newSellDate === null)) {
            setErrors({...errors, sellDate: t("licenses.list.cols.action.editForm.sellDate.err.change")});
            return;
        }

        if(oldData.current?.caption !== license?.caption || oldData.current?.sellDate !== license?.sellDate){
            setIsLoading(true);
            setErrors({})
            LICENSEAPI.updatePrivateData(license)
            .then((res)=>{
                setIsLoading(false);
                oldData.current = license;
                if(res.status === 200){
                    closeModalRef.current?.click();
                }else{
                    console.log(res);
                }
            }).catch((err)=>{
                setIsLoading(false);
                if(err.response?.status === 409){
                    setErrors({...errors, sellDate: t(`licenses.list.cols.action.editForm.sellDate.err.invalid`)});
                }else{
                    setErrors({...errors, server:t("err.500")});
                }
            })
        }
    };

    const closeModalRef = useRef(null);

    const onCloseModal = () => {
        const propsSallDate = props.oldData?.sellDate ? props.oldData?.sellDate?.split('T')[0] : props.oldData?.sellDate;
        const hasUpdateTable = oldData.current?.caption !== props.oldData?.caption || oldData.current.sellDate !== propsSallDate;
        setLicense(null);
        setErrors({});
        oldData.current = null;
        hasUpdateTable && props.reloadTab(null, {name:props.tabName, editLicense:license});
    }

    return (
        <>            
            <div ref={modalRef} className="modal fade" id="licenseEditModal" tabIndex="-1" aria-labelledby="licenseEditModalLabel" aria-hidden="false" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">
                        <h2 className="bm-card-header_small" id="licenseEditModalLabel">
                            {t("licenses.list.cols.action.editForm")}
                        </h2>
                        <a
                            ref={closeModalRef}
                            disabled={isLoading && "disabled"}
                            href="/#"
                            className="bm-close-x"
                            type="button"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={onCloseModal}
                        >
                            &#10005;
                        </a>
                        
                        {errors.server && <p className="fn-smtxt input-error" dangerouslySetInnerHTML={{__html: errors.server}}/>}
                        {license && <form className="text-start" onSubmit={handleSubmit} autoComplete="on">
                            {props.hasAccess.editCaption && 
                                <Field
                                    refInput={captionInputRef}
                                    title={t("licenses.list.cols.action.editForm.cap.tt")}
                                    name="caption"
                                    disabled={isLoading}
                                    label={t("licenses.list.cols.action.editForm.cap")}
                                    placeholder={t("licenses.list.cols.action.editForm.cap.hint")}
                                    id="caption"
                                    value={license.caption ?? ''}
                                    onChange={handleChangeText}
                                    error={errors.caption}
                                />
                            }
                            {(['publisher', 'reseller'].includes(props.tabName)) && (sellDate === null || (typeof sellDate === "string" && !oldData.current?.sellDate?.includes('1970'))) &&
                                <div style={{marginBottom: "1rem", position: "relative", paddingTop: ".50rem"}}>
                                    <label 
                                        htmlFor="sellDate"
                                        style={{
                                            position: "absolute",
                                            top: "-13px",
                                            color: `${focusSellDate ? "var(--nw-blue-color)" : "var(--nw-grey-mute-light-color)"}`,
                                            background: "#ffffff",
                                            opacity: 1,
                                            transform: "scale(0.7)",
                                            transformOrigin: "left",
                                            padding: "2px 5px",
                                            marginLeft: "11px",
                                        }}
                                    >{t("licenses.list.cols.action.editForm.sellDate")}</label>
                                    <span
                                        title={t("licenses.list.cols.action.editForm.sellDate.tt")}
                                        style={{
                                            padding: "12px 14px 12px 14px",
                                            border: `1px solid ${focusSellDate ? "var(--nw-blue-color)" : "var(--nw-grey-mute-light-color)"}`,
                                            borderRadius: ".3rem",
                                            transition: "all 150ms ease-in-out",
                                            boxShadow: "none",
                                            outline: "none",
                                            backgroundColor: `${isLoading ? "rgb(240, 240, 240)" : "#ffffff"}`
                                        }}
                                    >
                                        <DatePicker
                                            name="sellDate"
                                            maxDate={oldData.current?.sellDate && !oldData.current.sellDate?.includes('1970') ? new Date(oldData.current.sellDate) : new Date()}
                                            //calendarClassName=""
                                            //clearIcon={<Clear {...props}htmlColor="red"/> || null}
                                            //shouldOpenCalendar={(e,reason) => handleFocus(reason)}
                                            calendarIcon={<Calendar fill="var(--nw-blue-dark-color)"/>}
                                            disabled={isLoading}
                                            locale={locale}
                                            onChange={handleChangeSellDate}
                                            //onCalendarClose={onCalendarClose}
                                            shouldOpenCalendar={({ reason }) => {
                                                setFocusSellDate(true);
                                                return true;
                                            }}
                                            shouldCloseCalendar={({ reason }) => {
                                                setFocusSellDate(false);
                                                return setTimeout(() => {
                                                    if (sellDate === sharedDate.current) {
                                                        return true;
                                                    }
                                                    /*const e = {
                                                        currentTarget: {
                                                            name: 'sellDate',
                                                            value: sharedDate.current
                                                        }
                                                    }
                                                    handleChangeText(e);*/
                                                    return true
                                                }, 300);
                                            }}
                                            onFocus={(event) => {
                                                setFocusSellDate(true);
                                            }}
                                            //shouldCloseCalendar ={({ reason }) => reason !== 'outsideAction'} //Function called before the calendar closes. reason can be "buttonClick", "escape", "outsideAction", or "select". If it returns false, the calendar will not close.
                                            value={!sellDate || oldData.current?.sellDate?.includes('1970') ? "": sellDate}
                                            minDate={new Date('01-01-1970')}
                                        />
                                    </span>
                                    {errors.sellDate && 
                                        <p className="fn-smtxt input-error" dangerouslySetInnerHTML={{__html: errors.sellDate}}/>
                                    }
                                </div>
                            }
                        
                            <div className="bm-nav-card-bottom">
                                <button 
                                    type="submit" 
                                    disabled={isLoading || !(oldData.current?.caption !== license?.caption || oldData.current?.sellDate !== license?.sellDate) ? "disabled" : ""} 
                                    className={`${oldData.current?.caption !== license?.caption || oldData.current?.sellDate !== license?.sellDate ? "bm-btn-blue" : "btn-disabled"} bm-btn-card-next d-flex justify-content-center`}
                                >
                                    {isLoading &&
                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                    }
                                    <span className={isLoading ? "invisible" : ""}>{t("licenses.list.cols.action.editForm.btn")}</span>
                                </button>
                            </div>
                        </form>}
                    </div>
                </div>
            </div>
        </>
    )
}
