import { useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LicenseUpgradeDone() {

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    document.title = t("upgrade.title")+ " – nanoways.com";
    
    /* eslint-disable */
    useEffect(() => {
        !location.state && navigate(t("link.lic.upgrade.code"));
    },[]);
    /* eslint-enable */

    //let mailto = `<a href="mailto:${location.state.downloadInfo.email}">${location.state.downloadInfo.email}</a>`;
    
    /* const handleClick = (e) => {
        console.log(e);
        window.open('','_parent','');
        window.close();
        window.close();
    } */

    return (
        <div className="m-0 p-0 vertical-center">
            <div className="bm-main">
                {location.state &&
                <div className="bm-card">
                    <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>

                    <h1>{ t("upgrade.title") }</h1>

                    <h2 className="bm-sep-1 nw-green-dark">{t("upgrade.done")}</h2>

                    <p className="nw-bold mb-0">{t('upgrade.done.prev')}</p>
                    <p className="mb-4">{location.state.productOld}</p>

                    <p className="nw-bold mb-0">{t('upgrade.done.new')}</p>
                    <p className="mb-4">{location.state.productNew}</p>
                    
                    <p dangerouslySetInnerHTML={{ __html: t('upgrade.done.txt') }}></p>

                    {location.state.newEmail &&
                        <p className="mt-4" dangerouslySetInnerHTML={{ __html: t('upgrade.done.email', {email: location.state.newEmail}) }}></p>
                    }


                    <div className="bm-nav-card-bottom">
                        <Link to={t("link.lic.clear.lic")} state={{lastKey:location.state.key}}>
                            <button className="bm-btn-blue bm-btn-card-next mb-2">{t("upgrade.done.btn")}</button>
                        </Link>
                    
                        <Link to={t("link.root.url")}>
                            <button className="bm-btn-mute bm-btn-card-prev float-sm-start float-end">{t("nw.btn.goto")}</button>
                        </Link>
                        <div className="bm-clearer"></div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}