import {useRef, useEffect, useState} from 'react';
import $ from 'jquery';
import jstree from 'jstree'; // eslint-disable-line
import { useTranslation } from "react-i18next";
import  './access.css';
import ACCESSAPI from "Services/AccessAPI";
import Field from 'Components/Forms/Field';
import TextareaDiv from 'Components/Forms/TextareaDiv';


function NodeList(props) {
	const {t} = useTranslation();
    const treeRef = useRef(null);
    const [data, setData] = useState(props.data);
    const [isTextarea, setIsTextarea] = useState(true);
	
	/* eslint-disable */
    useEffect(() => {
        const isEditable = props.user?.roles.includes('ROLE_82');//acc.node.edit
        const plugins = ["search","types","unique","changed"];
        if (isEditable) {
            plugins.push("checkbox");
        }

        const handleChange = (e, eventData) => {
             // changed.jstree is a event
             if (['deselect_node', 'select_node'].includes(eventData.action) && isEditable) {
                // GET all Active access
                let checkedIds = []; 
                let selectedNodes = $(treeRef.current).jstree("get_selected", true);
                $.each(selectedNodes, function() {
                    let id = this.original.id_origin;
                    if (id !== null) {
                        checkedIds.push(this.original.id_origin);
                    }
                });
                ACCESSAPI.accessTreeUpdate({rootId: data[0].id_origin, rootText: data[0].text, activeIds: checkedIds})
                .then(response => {
                    console.log('Node updated');
                    //refresh();
                })
                .catch(error => {
                    if(!error.response || error.response.data.status === 500){
                        console.log('Node Not updated', error,error.message + " 😥");
                    } else {
                        console.log('Node Not updated', error.response);
                    }
                }) 
            }
        }

        // Initialize jsTree inside the useEffect hook
        $(treeRef.current).jstree({ // config object start
            "core": {                    // core config object
                "mulitple": false,         // disallow multiple selection  
                "animation": 100,          // 200ms is default value
                "check_callback" : true,   // this make contextmenu plugin to work
                "themes": {
                    "variant": "medium",
                    "dots": true
                },
            
                "data": data
            }, // core end
            
            // config object for Checkbox plugin (declared below at plugins options)
            "checkbox": { 
                "keep_selected_style": false,  // default: false
                "three_state": true,           // default: true
                "whole_node": true             // default: true
            },
            
            "conditionalselect" : function (node, event) {
                return false;
            },
            "search" : {
                "case_sensitive": false,
                "show_only_matches": true,
                "search_callback": function(search, node) {
                    const match = search.match(/^#(\d+)$/);
                    if (match && match[1] === node.original.id_origin?.toString()) {
                        return true;
                    }else if (node.text.includes(search)) {
                        return true;
                    }
                    return false;
                }
            },
            "plugins" : plugins
        });
        
        $("#searchNode").on('keyup',function () {
            let search = $(this).val();
            $(treeRef.current).jstree('search', search);
        });
        
        $(treeRef.current).on("changed.jstree", (e, eventData) => handleChange(e, eventData));
            
        return () => {
            // Clean up jsTree when the component unmounts
            $(treeRef.current).jstree('destroy');
        };
    }, [data, props.user]);
	/* eslint-enable */

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setData(props.data);
        }

        return () => {
            mounted = false;
        }
    }, [props.data]);

    useEffect(() => {
        const handleMouseEnter = () => {
            console.log('Mouse entered!');
            setIsTextarea(false);
        };
      
        const element = treeRef.current;
    
        if (element) {
            element.addEventListener('mouseenter', handleMouseEnter);
        
            return () => {
                element.removeEventListener('mouseenter', handleMouseEnter);
            };
        }
    }, [])

    const updateAccess = (txt) => {
        ACCESSAPI.accessUpdate(data[0]?.id_origin, {description: txt})
        .then(response => {
            console.log('Access Description is updated');
        })
        .catch(error => {
            if(!error.response || error.response.data.status === 500){
                console.log('Access Description is Not updated', error,error.message + " 😥");
            } else {
                console.log('Access Description is Not updated', error.response);
            }
        })
    }

    const setTextarea = (value) => {
       setIsTextarea(value);
    }

    return (<>
        <h2 className='mb-3'>{data[0]?.text} ({data[0]?.id_origin})</h2>

        <TextareaDiv isEditable={!data[0]?.description && isTextarea} setTextarea={setTextarea} update={updateAccess} attr={
                {
                    name:"description",
                    value:data[0]?.description,
                    placeholder: t("right.node.desc.hint"),
                    label: t("right.node.desc")
                }
            }
        />
        {data[0].children?.length > 0 && <>

            <div className="my-4">
                <Field label={t("right.node.filter")} placeholder={t("right.node.filter.hint")} id="searchNode" class="search-node" />
            </div>
            <div ref={treeRef} className="overflow-scroll max-height-56"/>
            {/* TODO: calculate screen height px and remove (description.height, navbar.height,footer.height, title.height) => screen height is positive ? maxheight = restScrennHeight : fix height */}
        </>}
    </>);
}

export default NodeList;