import Downloader from "Components/Downloader";
import React, { useState } from "react";
//import { v4 as uuid } from "uuid";

/*
use for more than one file
const useFileDownloader = () => {
	const [files, setFiles] = useState(() => []);
	const [endTime, setEndTime] = useState();

  	const download = (file) =>
	setFiles((fileList) => [...fileList, { ...file, downloadId: uuid() }]);

	const remove = (removeId) =>
		setFiles((files) => [
			...files.filter((file) => file.downloadId !== removeId),
	]);

  	return [
    	(e) => download(e),
    	files.length > 0 ? (
      		<Downloader files={files} remove={(e) => remove(e)} endTime={() => setEndTime(
        		new Date().toISOString())} />
    		) : null,
    	endTime
  	];
};
*/

const useFileDownloader = () => {
  	const [file, setFile] = useState(null);
  	const [downloadEnd, setDownloadEnd] = useState();

  	const handleDownloadEnd = (downloadProgress) => {
		setDownloadEnd(downloadProgress);
  	}

	return [
		(e) => setFile(e),
		file ? (<Downloader file={file} handleDownloadEnd={(d) => handleDownloadEnd(d)}/>) : null,
		downloadEnd,
	];
};

export default useFileDownloader;
