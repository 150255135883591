
import {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import {checkLicenseKey, checkEnhancementKey} from "Components/Tools/Text";
import { useTranslation } from "react-i18next";
import { fixInput } from 'Services/utils';

export default function RedirectOldLoginUrl() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const key = fixInput({value:params.eKeyOrLicenseKey, maxLength:23});
    const [redirectPath, setRedirectPath] = useState(null);

    if (!redirectPath) {
        if (checkLicenseKey(key)) {
            setRedirectPath(t("link.lic.clear.lic")+"/"+key)
        }else if (checkEnhancementKey(key)) {
            setRedirectPath(t("link.lic.upgrade.code")+"/"+key)
        }else{
            setRedirectPath(t("link.root.url"))
        }
    }

    /* eslint-disable */
    useEffect(()=>{
        navigate(redirectPath, {replace: true});
    }, [])
    /* eslint-enable */

    return null;
}