import React, { useEffect, useState, useRef } from 'react';
import {useParams} from 'react-router-dom';
import P2PAPI from "Services/P2PAPI";
import VncViewer from "Components/VncViewer";
import Spinner from "Components/Loading/Spinner";
import RASAPI from 'Services/RASAPI';
import useTabCommunication from 'Hook/useTabCommunication';
import { useTranslation } from "react-i18next";


const VNC = () => {
    const params = useParams();
	const { t } = useTranslation();
    document.title = t("ras.slots.cols.host.vnc")+ " – nanoways.com";
    const {sendTabsData} = useTabCommunication('RAS');
    const [error, setError] = useState(null);
    /*const cookieData = Cookies.get(params.handle);
    console.log(cookieData);
    if (cookieData) {
        sessionStorage.setItem(params.handle, cookieData);
        Cookies.remove(params.handle);
    }
    console.log(cookieData);
    console.log(sessionStorage.getItem(params.handle));*/
    
    //const {servers, slot} = JSON.parse(sessionStorage.getItem(params.handle)) || {servers: null, slot: null};
    const [peerConnectionServers, setPeerConnectionServers] = useState(null);
    const [showConnectBtn, setShowConnectBtn] = useState(false); //eslint-disable-line
    const [p2PSlot, setP2PSlot] = useState(null);
	const [reload, setReload] = useState(false);
	const [rfbdc, setRFBDC] = useState(undefined);
    const peerConnection = useRef(null);
	const iceConnectionStateTimer = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const dataChannel = useRef(null);
    const rfb = useRef(null);
	const timer = useRef(null);
	const descr = useRef(null);
	const cands = useRef([]);

    /* eslint-disable */
    useEffect(() => {
        if (params.handle && p2PSlot === null) {
            RASAPI.p2pSlot(params.handle).then((res)=>{
                const {servers, slot} = res.data;
                if (servers && slot?.status !== 3) {
                    setP2PSlot(slot);
                    setPeerConnectionServers(servers);
                    setReload(!reload)
                }else{
                    setRFBDC(null);
                }
            }).catch((err) => {
                console.log('Error Fetch P2PSlot', err);
                setRFBDC(null);
                setIsLoading(false);
            })
        }
        const handleBeforeUnload = (event) => {
            // Standard-compliant browsers (recent versions of Chrome, Firefox, Safari, etc.)
            //const confirmationMessage = 'Are you sure you want to leave Remote Desktop?';
            // For IE and Firefox
            //(event || window.event).returnValue = confirmationMessage;
            //return confirmationMessage;
            sendTabsData('setData', {name: 'disconnectSlot', slotHandle: params.handle});
        };
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [])

    useEffect(() => {
        const createPeerConnection = async () => {
            try {   
                //throw new Error('Test Error');
                const turnServers = peerConnectionServers.turns?.map(turnserver => {
                    const { server, password, ...rest } = turnserver;
                    return { urls: server, credential: password, ...rest };
                });
                const configuration = {
                    iceServers: [
                        { urls: peerConnectionServers.stun }, 
                        ...turnServers
                        /*{ urls: "stun:srv06.nanoways.com:3478" },
                        {
                            urls: "turn:srv06.nanoways.com:3478",
                            username: "sb",
                            credential: "test1234"
                        } */
                    ]
                };
                peerConnection.current = new RTCPeerConnection(configuration);
                
                dataChannel.current = peerConnection.current.createDataChannel("sendChannel");
                dataChannel.current.onopen = () => {
                    console.log("Opening data channel");
                    if(timer.current){
                        window.clearInterval(timer.current);
                        timer.current = null;
                    }
                    setRFBDC(dataChannel.current);
                }
                
                dataChannel.current.onclose = () => {
                    console.log("Closing data channel");
                    if (rfb.current) {
                        rfb.current.disconnect();
                        rfb.current = null;
                    }
                    setRFBDC(null);
                    setIsLoading(false);
                }

                peerConnection.current.onicecandidate = (event) => {
                    console.log("Got new ICE candidate");
                    if (event.candidate) {
                        console.log(event.candidate);
                        // IceCandidate an den Signalisierungsserver senden
                        sendIceCandidateToServer(event.candidate);
                    }
                };

                /*peerConnection.current.ondatachannel = (event) => {
                    console.log("Got DataChannel event");
                    const channel = event.channel;

                    channel.onopen = () => {
                        dataChannel = channel;
                    };

                    channel.onclose = () => {
                        // RTCDataChannel wurde geschlossen RFB-Objekt schließen
                        if (rfb) {
                            rfb.disconnect();
                            rfb = null;
                        }
                    };
                };*/

                // Add an event listener for iceconnectionstatechange
                peerConnection.current.addEventListener('iceconnectionstatechange', (event) => {
                    console.log('ICE Connection State:', peerConnection.current.iceConnectionState);

                    if (peerConnection.current.iceConnectionState === 'connected') {
                        // Handle the ICE connection start
                        if(iceConnectionStateTimer.current){
                            window.clearInterval(iceConnectionStateTimer.current);
                            iceConnectionStateTimer.current = null;
                        }
                    }else if(peerConnection.current.iceConnectionState === 'disconnected') {
                        // Handle the ICE connection disconnect
                        iceConnectionStateTimer.current = window.setTimeout(() => {
                            console.log('ICE connection disconnected after 500 ms.');
                            setError('ras.vncWeb.info.con.err');
                            reset();
                        }, 500);

                    }else if (peerConnection.current.iceConnectionState === 'failed') {
                        // Handle the ICE connection failure error 
                        console.error('ICE connection failed.');
                        setError('ras.vncWeb.info.con.err');
                        reset();
                    }
                });

                // Add an event listener for icecandidateerror
                peerConnection.current.addEventListener('icecandidateerror', (event) => {
                    console.error('ICE Candidate Error:', event.errorText);

                    // Handle the ICE candidate error here
                    if (event.errorCode !== 701) {
                        reset();
                    }
                });
                
                console.log("Creating offer");

                const offer = await peerConnection.current.createOffer();
                
                console.log("Setting local description locally");
                
                await peerConnection.current.setLocalDescription(offer);
                
                console.log("Setting local description in signaling server");
                
                await P2PAPI.update(
                    p2PSlot.handleP2pBrowser,
                    {password:p2PSlot.pwdP2pBrowser,"local-description": JSON.stringify(offer)}
                ).then((res) => {
                    console.log('Response Data', res.data);
                    console.log('Offer Obj', offer);
                    console.log('Response', res);
                })
                .catch((err) => {
                    console.error(err);
                    reset();
                });

                /*const client = await P2PAPI.get(p2PSlot.handleP2pClient);
                if (client['local-description']) {
                    handleRemoteDescription(client['local-description']);
                }

                if (client['remote-descriptions']){
                    client['remote-descriptions']?.forEach((remoteCandidate) => {
                        handleReceivedIceCandidate(remoteCandidate)
                    })
                        
                }*/

                timer.current = window.setInterval(handleTimeout, 500);
            } catch (error) {
                console.error("Error creating peer connection or handling data:", error);
                reset();
                //setReload(!reload);
            }
        };

        p2PSlot && peerConnectionServers && createPeerConnection();
		
		return () => {
			console.log("Cleanup");
			clearTimer(timer.current);
		}
    }, [reload]);

    useEffect(()=>{
        if (rfbdc === null && params.handle) {
            RASAPI.p2pSlot(params.handle)
            .then((res)=>{
                const {slot} = res.data;
                if (slot?.status !== 3) {
                    setShowConnectBtn(true);
                }else{
                    setShowConnectBtn(false);
                }
            }).catch((err) => {
                console.log('Error Show connect Btn', err);
                setShowConnectBtn(false);
            })
        }
    }, [rfbdc])
    /* eslint-enable */
    const clearTimer = (index) => {
        console.log('Clear Timer: ', index);
        if (index)
        {
            console.log("Clearing interval timer");
            window.clearInterval(index);
        }
    }

    const reset = async () => {
        await P2PAPI.remove(p2PSlot.handleP2pBrowser, {password:p2PSlot.pwdP2pBrowser})
        .then((res)=>{
            console.log('Response Data', res.data);
        }).catch((err) => {
            console.error(err);
        });

        if (timer.current) {
            clearTimer(timer.current);
            timer.current = null;
        }

        dataChannel.current && dataChannel.current?.close();
        dataChannel.current = null;
        peerConnection.current && peerConnection.current?.close();
        peerConnection.current = null;
        descr.current = null;
        cands.current = null;
        (rfbdc !== null) && setRFBDC(null);
        setIsLoading(false);
    }

	const handleTimeout = async () => {
		try {
			const client = await P2PAPI.get(p2PSlot.handleP2pClient);
			if (!client){
				return;
            }
			
			if (client.data['local-description'] && !descr.current)
			{
				await handleRemoteDescription(client.data['local-description']);
				descr.current = client.data['local-description'];
				console.log("Remote Description now available");
			}
			
			/* only add the candidates once the remote description has been set */
			if (!descr.current)
			{
				if (client.data['remote-descriptions'])
					console.log("Not adding remote candidates due to missing remote description");
				return;
			}
			
			if (client.data['remote-descriptions']) {
				client.data['remote-descriptions'].forEach((remoteCandidate) => {
					let found = false;
					cands.current.forEach((cand) => {
						if (cand === remoteCandidate)
							found = true;
					});
					
					if (!found)
					{
						handleReceivedIceCandidate(remoteCandidate);
						cands.current.push(remoteCandidate);
					}
				});
			}
		} catch (error) {
			console.error("Failed to retrieve peer information: ", error);
			window.clearInterval(timer.current);
			timer.current = null;
            reset();
		}
	};

    const handleRemoteDescription = async (remoteDescription) => {
        try {
            await peerConnection.current.setRemoteDescription(JSON.parse(remoteDescription));
            console.log('Remote Description set successfully');
        } catch (error) {
            console.error('Error setting remote description:', error);
        }
    };
	
    const handleReceivedIceCandidate = (iceCandidate) => {
        try {
            let cand = JSON.parse(iceCandidate);
            console.log("Adding remote candidate");
            console.log(cand);
            peerConnection.current.addIceCandidate(cand);
            console.log('Added remote IceCandidate successfully');
        } catch (error) {
            console.error('Error adding remote IceCandidate:', error);
        }
    };

    /* eslint-disable */
    //RemoteDescription und IceCandidates senden
    const sendRemoteDescriptionToServer = (remoteDescription) => {
        // Remotedescription an den Server senden
        P2PAPI.update(p2PSlot.handleP2pBrowser, {password:p2PSlot.pwdP2pBrowser,"remote-descriptions": remoteDescription})
        .then((res)=>{
            console.log('Response Data', res.data);
        }).catch((err) => {
            console.error(err);
            reset();
        });
    };
    /* eslint-enable */

    const sendIceCandidateToServer = (iceCandidate) => {
        //IceCandidate an den Server senden
        P2PAPI.update(p2PSlot.handleP2pBrowser,{password:p2PSlot.pwdP2pBrowser,"remote-descriptions": [ JSON.stringify(iceCandidate)]})
        .then((res)=>{
            console.log('Response Data', res.data);
        }).catch((err) => {
            console.error(err);
            reset();
        });
    };
	
	const setRFB = (arg) => {
		rfb.current = arg;
	}

    return (<>
            {rfbdc === null && !isLoading &&
                <div className="m-0 p-0 vertical-center">
                    <div className="container">
                        <div className="row">
                            {/*!showConnectBtn &&
                            <div className="col-12 text-center my-3">Slot unpaired or terminated</div>      
                            */}
                            <div className="col-12">
                                <h6 className='text-center'>
                                    {sessionStorage.getItem(`vncTitle-${params.handle}`) || t("ras.vncWeb.info")}
                                </h6>
                            </div>
                            <div className="col-12 text-center mb-3">{error && t(error)}</div>
                            {/*show Reconnect button*/}
                            <div className="col-12 text-center">
                                {/*showConnectBtn && 
                                    <button 
                                        type="button"
                                        className="bm-btn-blue mx-1"
                                        onClick={()=>{
                                            reset();
                                            setShowConnectBtn(false);
                                            setRFBDC(undefined);
                                            setReload(!reload);
                                        }}
                                    >Connect</button>
                                */}
                                <button 
                                    type="button"
                                    className="bm-btn-blue mx-1"
                                    onClick={()=>{
                                        sendTabsData('setData', {name: 'disconnectSlot', slotHandle: params.handle});
                                        window.close();
                                    }}
                                >{t('ras.vncWeb.info.btn')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {(rfbdc === undefined || isLoading) && <div className="m-0 p-0 vertical-center"><Spinner className={"overlay"}/></div>}

            {rfbdc && <VncViewer handle={params.handle} dataChannel={rfbdc} setRFB={setRFB} resetP2P={reset} isLoading={setIsLoading} setError={setError}/>}
    </>);
};

export default VNC;
