import axios from "axios";
import {API_CHECK_LINK} from "config/nw-api";

function checkLink(token) {
    let url = API_CHECK_LINK.replace(":token", token);

    return axios.get(url);
}

const checkAPI = {
    checkLink
};

export default checkAPI;