    
function importAll(r, ext) {
    let images = {};
    r?.keys()?.forEach(item => {
        let key = item.replace('./', '');
        key = key?.replace(ext, '');
        images[key] = r(item); 
    });
    return images;
}

const FLAGS = {
    //shiny:importAll(require.context('./flags/shiny/32', false, /\.(png|jpe?g|svg)$/), '.png'),
    svg:importAll(require.context('./flags/svg', false, /\.svg$/), '.svg')
}

export default FLAGS;
