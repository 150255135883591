import { useState, useRef, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import JournalList from './JournalList';
import { Container, Row, Tabs, Tab } from 'react-bootstrap';
import {ReactComponent as ReloadSvg} from "assets/images/icon/reload-16.svg";
import { useSelector } from 'react-redux';
import {v4 as uuidv4} from 'uuid';
import JOURNALAPI from 'Services/JournalAPI';
const tabs = ["licenses", "downloads",]//"licenses", "activations", "uploads"];
const defaultLimit = 10;
const searchColumns = (searchs) => {
    if (!searchs) {
        return [];
    }
    //i18n keys used to parse columns name
    const cols = {
        searchLicenseKey: 'licenses.list.cols.key',
    }

    return searchs.map((search) => {
        const colKey = cols[search];
        return colKey ? colKey : null;
    });
}

export default function Journal() {
    const { t } = useTranslation();
    document.title = t("journal.title")+ " – nanoways.com";
    const user = useSelector((state) => state.user);
    const [searchInCols, setSearchInCols] = useState([]);

	const [tabKey, setTabKey] = useState(user?.roles.includes('ROLE_178') ? tabs[0] : user?.roles.includes('ROLE_179') ? tabs[1] : user?.roles.includes('ROLE_180') ? tabs[2] :user?.roles.includes('ROLE_181') ? tabs[3] : null);// user
    const tabsLoadRef = useRef([]);
    const tabsTextRef = useRef([]);
    const handleMouseEnter = (name) => {
        tabsLoadRef.current[name]?.classList.remove('invisible');
        tabsTextRef.current[name]?.classList.add('invisible');
    };
    const handleMouseLeave = (name) => {
        tabsLoadRef.current[name]?.classList.add('invisible');
        tabsTextRef.current[name]?.classList.remove('invisible');
    };
    const tabsRef = useRef([]);
    const [activesTabs, setActivesTabs] = useState(tabKey ? [tabKey] : []);
	const [data, setData] = useState(undefined);
    const [dataLength, setDataLength] = useState({});//Stock Tabs List length
	const [dataLimit, setDataLimit] = useState(defaultLimit);// user
    const lastSearch = useRef('');
    const currentColsStructure = useRef(null);

    const changeTab = (key) =>{
        lastSearch.current = '';
        setTabKey(key);
        setData(undefined);
        setDataLimit(key === tabs[0] ? 0: defaultLimit);//user
        if (!activesTabs.includes(key)) {
            setActivesTabs([...activesTabs, key]);
        }
    }

    const reloadTab = (e, table) =>{
        const eltId = e?.currentTarget?.id;
        lastSearch.current = '';
        if (eltId?.startsWith('selectLimit')) {// Reload by change limit
            lastSearch.current = table.search;
            setDataLimit(table.limit);
            setData(undefined);
        }else if (table?.search) {//Reload by search
            lastSearch.current = table.search;
            setData(undefined);
        }else{
            lastSearch.current = '';
            setData(undefined);
        }
    }

    /* eslint-disable */
    useEffect(() => {
        let isMounted = true; //note mutable flag to clear error boundaries
        if (isMounted && data === undefined) {
            JOURNALAPI.get({tabname:tabKey, limit: dataLimit, search: lastSearch.current})
            .then((res) => {
                const {list, countAll, colsStructure, searches} = res.data;
                currentColsStructure.current = colsStructure;
                setSearchInCols(searchColumns(searches));
                setDataLength({...dataLength, [tabKey]: countAll});
                setData([...list]);
            }).catch((err)=>{
                const {response} = err;
                if (response) {
                    console.log(response);
                    setDataLength({...dataLength, [tabKey]: 0});
                    setData([]);
                } else {
                    console.log("network error like timeout, connection refused etc...");
                }
            });
        }

        return () => { isMounted = false }; // cleanup toggles value, if unmounted
        
    }, [tabKey, data]);
    /* eslint-enable */
    
    return (
        <>
            <div className="p-5 bg-white min-vh-100">
				<div className="row mb-5">
					<div className="col-12 px-0 mx-0 text-center">
						<h1>{t("journal.title")}</h1>
                        {tabKey ? 
                            <div className="col-12 tabs">
                                <Container className="default">
                                    <Row>
                                        <Tabs id="controlled-tab" activeKey={tabKey} onSelect={(k) => changeTab(k)}>
                                            {//"user", "reseller", "agent", "publisher"
                                                tabs.map((tabName)=>{
                                                    if (
                                                        (tabName === "licenses" && user?.roles.includes('ROLE_178')) ||
                                                        (tabName === "downloads" && user?.roles.includes('ROLE_179')) ||
                                                        (tabName === "activations" && user?.roles.includes('ROLE_180')) ||
                                                        (tabName === "uploads" && user?.roles.includes('ROLE_181'))
                                                    ) {
                                                        return  (
                                                            <Tab 
                                                                disabled={data === undefined ? true :false}
                                                                tabClassName={`${tabKey === tabName && "nw-bg-blue-light"} "rounded-0 rounded-top"`} 
                                                                key={uuidv4()} eventKey={tabName} 
                                                                title={
                                                                    <span
                                                                        className='position-relative'
                                                                        onMouseEnter={data && tabKey === tabName ? (e)=>handleMouseEnter(tabName): null}
                                                                        onMouseLeave={data && tabKey === tabName ? (e)=>handleMouseLeave(tabName) : null}
                                                                    >
                                                                        {t(`journal.list.tabs.${tabName}`)}
                                                                        <span
                                                                            ref={el => tabsLoadRef.current[tabName] = el}
                                                                            name={tabName}
                                                                            style={{marginTop: "-4px"}}
                                                                            className={`${data === undefined && tabKey === tabName? '': 'invisible '} position-absolute end-0`}
                                                                            onClick={() => setData(undefined)}
                                                                        >
                                                                            {tabKey === tabName && 
                                                                                <ReloadSvg className={`${data === undefined && tabKey === tabName? 'loader-elt ' : ''}`} fill="var(--nw-blue-color)"/>
                                                                            }
                                                                        </span>
                                                                        <span 
                                                                            ref={el => tabsTextRef.current[tabName] = el}
                                                                            className={`${data === undefined && tabKey === tabName? 'invisible': ''} ms-1`}
                                                                        >
                                                                            {dataLength[tabName] !== undefined ?"(" +dataLength[tabName]+")": tabKey === tabName ? "(0)" : ""}
                                                                        </span>
                                                                    </span>
                                                                }
                                                            >
                                                                {tabKey === tabName &&
                                                                    <JournalList
                                                                        name={tabName}
                                                                        searchInCols={searchInCols}
                                                                        ref={el => tabsRef.current[tabName] = el}
                                                                        list={data}
                                                                        listLength={dataLength[tabName]}
                                                                        colsStructure={currentColsStructure.current}
                                                                        reloadTab={reloadTab}
                                                                        searchText={lastSearch.current}
                                                                    />
                                                                }
                                                            </Tab>
                                                        )
                                                    }
                                                    return '';
                                                })
                                            }                                        
                                        </Tabs>
                                    </Row>
                                </Container>
                            </div>
                        :
                            <div>{t("nw.list.err.empty")}</div>
                        }
					</div>
                </div>
            </div>
        </>
    )
}