import { useState, useRef } from "react";
import UserApi from "Services/UsersAPI";
//import LicenseApi from "Services/LicenseAPI";
import Field from "Components/Forms/Field";
import PasswordField from "Components/Forms/PasswordField";
import PasswordChecklist from "react-password-checklist";
import { useTranslation } from "react-i18next";
import MailSvg from "assets/images/icon/mail-animation.gif";
import { DOMAIN_NAME } from "config/nw-api";
import useFocusModal from "Hook/useFocusModal";
import { checkPwd, checkEmail } from "Components/Tools/Text";
import Spinner from "Components/Loading/Spinner";
import LicenseInput from "Components/LicenseInput";
import { useSelector } from 'react-redux';
import {useFlashContext}  from 'Contexts/FlashContext';


export default function SignUpModal() {
    const { t, i18n } = useTranslation();
    const loggedUser = useSelector((state) => state.user);
    const {setFlash} = useFlashContext();
    const hasCreateUserAccess = loggedUser?.roles?.includes('ROLE_102') ? true : false;
    const pwdMinLength = 8;
    const [user, setUser] = useState({
        email: "",
        password: "",
        passwordConfirm: "",
        emailLang: i18n.language
    });
    const [errors, setErrors] = useState({
        email: "",
        password: "",
        passwordConfirm: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [formAccepted, setFormAccepted] = useState({
        "height": 0,
        "status":false
    });

    const signUpModal = useRef(null);
    const pwdInput = useRef(null);
    useFocusModal(signUpModal, pwdInput);

    const [license, setLicense] = useState('');
    const [errorLicense, setErrorLicense] = useState(null);
    const licenceInputRef = useRef(null);
    useFocusModal(signUpModal, licenceInputRef);
    /*useEffect(()=>{
        if (license.length === 23) {
            console.log(license.length);
            LicenseApi.licenseCheck(license)
            .then((res) => {
                const {email, hasUser, key} = res.data;
                if (email) {
                    setUser({...user, email})
                }
                console.log(res.data);
            })
        }

    }, [license])*/

    const showForm = () => {
        setFormAccepted(formAccepted.status = false);
        document.getElementById('closeSignUp').click();
    }

    // Manage input changing in form
    const handleChange = (e) => {
        const {name, value} = e.currentTarget;
        setUser({...user, [name]: value});
    };
    // Manage form submission
    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);

        let apiErrors = {};
        setErrorLicense(null);
        if(!hasCreateUserAccess && license.length === 0){
            setErrorLicense(t("nw.input.err.required"));
            apiErrors.license = t("nw.input.err.required");
        }else if(!hasCreateUserAccess && license.length >= 0){
            user.licenseKey = license;
        }

        if (!user.email) {
            apiErrors.email = t("nw.input.err.required");
        } else if (!checkEmail(user.email)) {
            apiErrors.email = t("nw.input.mail.err.invalid");
        }

        if (!user.password) {
            apiErrors.password = t("nw.input.err.required");
        } else if (user.password !== user.passwordConfirm) {
            apiErrors.passwordConfirm = t("nw.input.pwd.confirm.err");
        } else if (!checkPwd(user.password)) {
            apiErrors.password = t("nw.input.pwd.err.strong", {"num": pwdMinLength});
        }
        
        setErrors(apiErrors);

        if (Object.keys(apiErrors).length !== 0 && apiErrors.constructor === Object) {
            setIsLoading(false);
            return;
        }
        user.urlCallback = {"confirm_registration":DOMAIN_NAME + t("link.signupConfirm")};
        user.emailLang = i18n.language;

        UserApi.register(user, hasCreateUserAccess)
        .then(res => { 
            setIsLoading(false);
            if (res.status === 201) {
                if (res.data?.emailIsSent === false) {
                    setFlash({alert: "warning", message: t("err.email")});
                }
                setUser({
                    email: "",
                    password: "",
                    passwordConfirm: "",
                    emailLang: i18n.language
                });
                setFormAccepted({...formAccepted,
                    status: true,
                    height:document.getElementsByClassName('sign-up-form')[0]?.clientHeight
                });
            }else{
                setErrors({...errors, 
                    password: '',
                    email: '',
                    account: t("nw.err.noAccess")}
                );
            }
        })
        .catch(error => {
            setIsLoading(false);
            if(!error.response || error.response.data.status === 500){
                apiErrors.account = t("err.500");
            } else {
                if (error.response?.data?.violations) {
                    const {violations} = error.response.data;
                    let message = violations[0].message;
                    let fieldError = violations[0].propertyPath;
                    if(fieldError === 'password') {
                        if(message.startsWith("Password should contains")) {
                            apiErrors.password = t("nw.input.pwd.err.strong", { num: 8});
                        }
                        if(message.startsWith("The field is")) {
                            apiErrors.password = t("nw.input.err.required");
                        }    
                    }
                    if(fieldError === 'email') {
                        if(message.startsWith("The field is")) {
                            apiErrors.email = t("nw.input.err.required");
                        }
                        if(message.startsWith("This value is already")) {
                            apiErrors.email = t("nw.input.mail.err.unique", {email:user.email});
                        }
                        if(message.startsWith("The email address must have")) {
                            apiErrors.email = t("nw.input.mail.err.invalid");
                        }    
                    }
                } else if(error.response?.data?.message){
                    const msg = error.response?.data?.message?.toLowerCase();
                    if (msg === 'license not found') {
                        setErrorLicense(t("clear.enterlk.err.invalid"));
                    }else if(msg === 'license occupied'){
                        setErrorLicense(t("user.signUp.lic.err.used"));
                    }else if(msg === 'license blocked'){
                        setErrorLicense(t("user.signUp.lic.err.off"));
                    }
                }else{
                    apiErrors.account = t("err.unknown");
                }
            }
            setErrors({...errors, 
                password: apiErrors.password,
                email: apiErrors.email,
                account: apiErrors.account}
            );
        });
    };

    const [passwordConfirmType, setPasswordConfirmType] = useState('password');
    
    return (
        <>            
            <div ref={signUpModal} className="modal fade" id="signUpModal" tabIndex="-1" aria-labelledby="signUpModalLabel" aria-hidden="false">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">
                        <h2 className="bm-card-header_small" id="signUpModalLabel">
                            {t("user.signUp")}
                        </h2>
                        <a href="/#" className="bm-close-x" id="closeSignUp" type="button" data-bs-dismiss="modal" aria-label="Close">&#10005;</a>
                            {!formAccepted.status && 
                                <form onSubmit={handleSubmit} className="sign-up-form fn-smtxt">
                                    {errors.account && <p className="fn-smtxt invalid-feedback d-block" dangerouslySetInnerHTML={{__html: errors.account}}/>}
                                    {!hasCreateUserAccess && <>
                                        <h3 className="bm-sep-1 mt-0">{t("user.signUp.lic")}</h3>
                                        <p>{t("user.signUp.lic.txt")}</p>
                                        <LicenseInput refInput={licenceInputRef} change={setLicense} error={errorLicense} isLoading={isLoading}/>
                                        <h3 className="bm-sep-1" style={{marginTop: "25px"}}>{t("user.signUp.usr")}</h3>
                                    </>}
                                    <Field
                                        refInput={pwdInput}
                                        label={t("nw.input.mail")}
                                        id="signUpEmail"
                                        name="email"
                                        placeholder={t("nw.input.mail.hint")}
                                        value={user.email}
                                        disabled={isLoading && "disabled"}
                                        onChange={handleChange}
                                        type="email" 
                                        error={errors.email}
                                    />
                                    <PasswordField
                                        label={t("nw.input.pwd")}
                                        id="signUpPassword"
                                        name="password"
                                        placeholder={t("nw.input.pwd.hint")}
                                        value={user.password}
                                        disabled={isLoading && "disabled"}
                                        onChange={handleChange}
                                        attrs={{autoComplete:"new-password"}}
                                        error={errors.password}
                                        shareType={setPasswordConfirmType}
                                    />
                                    <Field
                                        name="passwordConfirm"
                                        label={t("nw.input.pwd.confirm")}
                                        type={passwordConfirmType}
                                        placeholder={t("nw.input.pwd.confirm")}
                                        value={user.passwordConfirm}
                                        disabled={isLoading && "disabled"}
                                        onChange={handleChange}
                                        error={errors.passwordConfirm}
                                    />
                                    <div className="bm-register-checklist">
                                    <PasswordChecklist
                                        rules={["capital","lowercase","number","minLength","match"]}
                                        minLength={8}
                                        value={user.password}
                                        valueAgain={user.passwordConfirm}
                                        onChange={(isValid) => {}}
                                        messages={{
                                            capital: t("nw.checkPwd.capital"),
                                            lowercase: t("nw.checkPwd.lower"),
                                            number: t("nw.checkPwd.num"),
                                            minLength: t("nw.checkPwd.min", { num: 8}),
                                            match: t("nw.checkPwd.match"),
                                            //specialChar: t("nw.checkPwd.char"),
                                        }}
                                    />
                                    </div>
                                    <button type="submit" disabled={isLoading && "disabled"} className="bm-btn-blue float-end d-flex justify-content-center text-center">
                                        {isLoading &&
                                            <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "white"}}/>
                                        }
                                        
                                        <span className={isLoading ? "invisible" : ""}>{t("user.signUp.btn")}</span>
                                    </button>
                                    <div className="clearer"></div>
                                </form>  
                            }
                            {formAccepted.status && 
                                <div style={{minHeight: formAccepted.height}} className="d-flex flex-column col-12 mt-0 pt-0 text-center">
                                    <img
                                        src={process.env.PUBLIC_URL + MailSvg}
                                        className="bm-signup-img"
                                        height=""
                                        alt="Mail Icon"
                                        loading="lazy"
                                    />
                                    <p dangerouslySetInnerHTML={{__html: t("user.signUp.ok")}}/>
                                    <button type="button" className="bm-btn-blue bm-marg-top-30 bm-max-width-200 bm-margin-auto" onClick={showForm}>{t("gen.button.close")}</button>
                                </div>
                            }
                    </div>
                </div>
            </div>
        </>
    )
}
