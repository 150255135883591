
//import {useState, useEffect, useRef} from 'react';
import { useTranslation } from "react-i18next";
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import {v4 as uuidv4} from 'uuid';
import './index.css';

const ChangeLimit = (props) => {
    const { t } = useTranslation();

    return <OverlayTrigger placement="left" trigger={["hover", "focus"]} overlay={<Tooltip className="nw-tooltip-dark">{t("nw.list.opt.load.tt")}</Tooltip>}>
        <div className="dropdown m-0 p-0" style={{top: "-1.4px"}} >
            <span className="dropdown-toggle p-1 m-0 badge nw-bg-blue border border-0 rounded-1  nw-opacity-6" data-bs-toggle="dropdown" aria-expanded="true">
                {props.count}
            </span >
            <ul className="dropdown-menu">
            {props.list.map((option) => { //add 0 to array to make all active
                return (
                    <li key={uuidv4()}>
                        <span
                            id={`${props.optsuffixId}${option}`}
                            style={option === props.count ? {backgroundColor: "var(--nw-blue-light-color)"} : {}/* eslint-disable-line*/}
                            className="dropdown-item"
                            data-limit={option}
                            onClick={(e) => {
                                const data = {name:props.name, limit:option}
                                const searchValue = document.getElementById(props.searchTextId)?.value;

                                if(searchValue){
                                    data.search = searchValue;
                                }
                                props.reloadTab(e,data);
                            }}
                            //title={t("nw.list.opt.load.tt.open", {limit: option})}
                            title={t("nw.list.opt.load.tt.open", {limit: option === 0 ? t("nw.list.opt.load.tt.openall"): option})}
                        >
                            {option === 0 ? t("nw.list.opt.load.tt.openall") : option}
                        </span>
                    </li>
                )
            })}
            </ul>
        </div>
    </OverlayTrigger>;
};

export default ChangeLimit;