import {useState, useEffect, useRef} from "react";
import { useLocation, Link, Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DownloadAPI from "Services/DownloadsAPI";

//Timer to check Package
const timer = {animation: 10000, full: 10000, downloaded: 10000} // 10s, 5mn, 18mn
//const timer = {animation: 2000, full: 4000, downloaded: 6000}

export default function LicenseDone() {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const ref = useRef(null);
    const [showProgressBar, setShowProgressBar] = useState(location.state?.downloadInfo?.status === 'failed' ?'full-red':'animation');
    const [fixHeight, setFixheight] = useState({});
    const [finishLast, setFinishLast] = useState(null);
    const [show, setShow] = useState(false);

    document.title = t("clear.title")+ " – nanoways.com";

    /*window.onpopstate = () => {
        if (location.state.default.product.key) {
           navigate(t("link.lic.clear.opt"), {replace: true, state:location.state});
        }else{
            navigate(t("link.lic.clear.lic"));
        }
        //return <Navigate to={t("link.lic.clear.opt")} state={location.state} replace />
    }*/
    //on Reload page F5
    window.onbeforeunload = ()=>{
        localStorage.setItem("unload", Date.now());
    }

    const progressBar = 
        <div className="progressBar">
            <div className="pb-1 fade-in">
                {t("clear.result.done.bar.load")}
            </div>

            <div className="loading-bar-container fade-in">
                <div className="loading-bar-progress loading-bar"></div>
            </div>
        </div>

    const finishBar = 
        <div className="finishBar">
            <div className={`${showProgressBar === 'full-red' ? "nw-red-dark fw-bold" : "nw-green-dark fw-bold"} pb-1 fade-in`} dangerouslySetInnerHTML={{__html:showProgressBar === 'full-red' ? t("clear.result.done.bar.err"):t("clear.result.done.bar.done") + `${location.state?.downloadInfo.removeEmail ? "" : t("clear.result.done.bar.done.suffix")}`}} />

            <div className={`${showProgressBar === 'full-red' ? "nw-bg-red" : "nw-bg-green-dark"} loading-bar-container fade-in`}></div>
        </div>

    const noteBar = <div className="noteBar pt-4 fade-in" dangerouslySetInnerHTML={{__html:t("clear.result.done.note")}} />

    const notice = 
    <div style={{paddingTop: "2rem"}}>
        <div className="notice alert fade-in show mb-0 p-3" style={{border: "1px solid var(--nw-green-dark-color)"}}> {/* for red Border var(--nw-red-dark-color) */}
            <div className="row p-0 align-items-center">
                <p className="nw-green-dark" style={{fontSize: "1.5rem", marginBottom: "0.7rem"}} dangerouslySetInnerHTML={{__html: showProgressBar === 'full-red' ? t("clear.result.done.box.err") : showProgressBar === 'downloaded' ? t("clear.result.done.box.down") : t("clear.result.done.box.ok")}} />
                <div className="nw-green-dark col-12" dangerouslySetInnerHTML={{__html: showProgressBar === 'full-red' ? t("clear.result.done.box.err.txt") : showProgressBar === 'downloaded' ? t("clear.result.done.box.down.txt") : t("clear.result.done.box.ok.txt")}}/>
            </div>
        </div>
    </div>
    
    /* eslint-disable */
    //set Height for infos
    useEffect(()=>{
        let isMounted = true;

        if (isMounted && ref.current && Object.keys(fixHeight).length === 0) {
            let heightInfo = {
                progress: ref.current.getElementsByClassName('progressBar')[0].clientHeight,
                finish: ref.current.getElementsByClassName('finishBar')[0].clientHeight,
                noteBar: ref.current.getElementsByClassName('noteBar')[0].clientHeight,
                notice: ref.current.getElementsByClassName('notice')[0].clientHeight
            };
            var height = heightInfo.notice > heightInfo.noteBar? heightInfo.notice : heightInfo.noteBar;
            height += 35;
            //height += (location.state.downloadInfo.status === "already") ? 0 : heightInfo.progress;
            setFixheight({minHeight: height+"px"});
        }

		return () => {
            isMounted = false
        };
    }, [fixHeight]);

    let last_unload = localStorage.getItem('unload');
    useEffect(()=>{
        let isMounted = true;

		if (isMounted && last_unload){
            //the user reload the page 
            if(Date.now() - parseInt(last_unload,10) <= 1000 && location.state)
            {
                localStorage.removeItem('unload');
                location.state.downloadInfo.removeEmail = true;
                
                console.log('refresh handling code')
            }else{
                // It's a new session, means user came to page, closed the browser, now came back after a long time
            }
		}
        
		return () => {
            isMounted = false
        };
    },[])
    /* eslint-enable */

    const checkDownload = async () => {
        return await DownloadAPI.CheckPaketDone(location.state.downloadInfo?.idx)
        .then(({data}) => {
            //console.log(data, finishLast);
            setShow(true);
            if (data.purgedAt) {
                navigate(t("link.root.url"));
            }
            if (data.packetDoneAt?.includes("2000-01-01")) {
                setShowProgressBar('full-red');
            }else {
                if(showProgressBar === 'animation' && data.packetDoneAt !== null && (data.packetDoneAt >= data.clearLast || data.finishLast === null)){
                    const {code} = location.state.downloadInfo;
                    if (code) {
                        navigate(t("link.download.url")+"/"+code);
                    }else{
                        setShowProgressBar('full');
                    }
                }else if (['full', 'animation', 'downloaded'].includes(showProgressBar) && data.accessLastAt > data.clearLast){
                    //Go to homepage after user start download by ui or direct download link
                    navigate(t("link.root.url"));//after ueser
                    //setShowProgressBar('downloaded');
                }                    
            }
        })
        .catch(error => {
            console.log(error);
            if(!error.response || error.response.data.status === 500){
                console.log(error.message);
            } else {
                console.log(error.response);
            }
        })
    }

    //Check if Packet is built
    /* eslint-disable */
    useEffect(()=>{
        let isMounted = true;
        let interval = undefined;

		if (isMounted){
            interval = setInterval(() => {
                checkDownload();
            }, timer[showProgressBar]);
		}
        
		return () => {
            isMounted = false
            clearInterval(interval);
        };

    }, [showProgressBar, finishLast]);

    useEffect(()=>{
        let isMounted = true;
		if (isMounted && !show){
            checkDownload().finally(() => {setShow(true)})
		}

		return () => { isMounted = false };
    },[])
    /* eslint-enable */

    if (!location.state) {
        return <Navigate to={t("link.root.url")} state={{
            alert: "danger",
            message: t("nw.err.expirePage")
        }} replace />
    } else {
        //let i18nKey = "";

        //Update Download Status
        /*if (!show) {
            checkDownload().finally(() => {setShow(true)})
        }
*/
        if(location.state.downloadInfo.status === "already"){ //Download package is already built
            //i18nKey = ".again";
            if (showProgressBar === 'animation') {
                const {code} = location.state.downloadInfo;
                    if (code) {
                        return <Navigate to={t("link.download.url")+"/"+code} replace/>
                    }else{
                        setShowProgressBar('full');
                        setFinishLast(location.state.downloadInfo.finishLastAt);
                    }
            }
        }
        
        //let last_unload = localStorage.getItem('unload');
        //Show Done Page only User comes direct from option page.
        if((!last_unload || Date.now() - parseInt(last_unload,10) > 1000) && show)
        {
        return (
            <div className="m-0 p-0 vertical-center">     
                <div className="bm-main">
                    {location.state &&
                        <div className="bm-card bm-box-absolute">
                            
                            <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>

                            <h1>{ t("clear.title") }</h1>
                            <h2 className="bm-sep-1">{t("clear.result.done")}</h2>
                            
                            <div className="row">
                                <div className="fn-smtxt-bold col-sm-4 col-xs-12">
                                    {t("clear.select.info.name")}
                                </div>
                                <div title={location.state.downloadInfo.productName} className="col-sm-8 col-xs-12 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                    {location.state.downloadInfo.productName}
                                </div>
                            </div>
                            <div className="row">
                                <div className="fn-smtxt-bold col-sm-4 col-xs-12">
                                    {t("clear.select.info.ver")}
                                </div>
                                <div title={location.state.downloadInfo.releaseVer} className="col-sm-8 col-xs-12 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                    {location.state.downloadInfo.releaseVer}
                                </div>
                            </div>
                            {location.state.downloadInfo.caption &&
                                <div className="row">
                                    <div className="fn-smtxt-bold col-sm-4 col-xs-12">
                                        {t("clear.select.info.cap")}
                                    </div>
                                    <div title={location.state.downloadInfo.caption} className="col-sm-8 col-xs-12 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                        {location.state.downloadInfo.caption}
                                    </div>
                                </div>
                            }
                            
                            <div className="row">
                                <div className="fn-smtxt-bold col-sm-4 col-xs-12">
                                    {t("clear.select.opt.pkg")}
                                </div>
                                <div title={t("clear."+location.state.downloadInfo.methodI18N)} className="col-sm-8 col-xs-12 mb-3 mb-lg-0 mb-xl-0 mb-xxl-0 mb-md-0 nowrap">
                                    {t("clear."+location.state.downloadInfo.methodI18N)}
                                </div>
                            </div>
                            
                            <div className="row mb-4">
                                <div className="fn-smtxt-bold col-sm-4 col-xs-12">
                                    {t("clear.select.info.email")}
                                </div>
                                <div title={location.state.downloadInfo.email} className="col-sm-8 col-xs-12 nowrap">
                                    {location.state.downloadInfo.email}
                                </div>
                            </div>
                            {/*<p className="mt-3 mb-4" dangerouslySetInnerHTML={{ __html: t(`clear.result.done.txt${i18nKey}`)}}></p>*/}

                            {location.state.downloadInfo.status !== "already" && <> 
                                {showProgressBar === 'animation' && progressBar}
                                {showProgressBar === 'full' && finishBar}
                                {showProgressBar === 'full-red' && finishBar}
                            </>}

                            {location.state.downloadInfo.status === "already" && finishBar}

                            <div style={fixHeight}>
                                {(location.state.downloadInfo.status !== "already" && showProgressBar === 'animation') && noteBar}
                                {['full', 'full-red', 'downloaded'].includes(showProgressBar) && notice}
                            </div>

                            <div className="bm-nav-card-bottom">
                                <Link to={t("link.root.url")}>
                                    <button className="bm-btn-blue bm-btn-card-next mb-2">{t("nw.btn.goto")}</button>
                                </Link>
                                <Link to={t("link.lic.clear.lic")} state={location.state.post}>
                                    <button className="bm-btn-blue-out bm-btn-card-prev float-sm-start float-end">{t("clear.result.done.btn")}</button>
                                </Link>
                                <div className="bm-clearer"></div>
                            </div>
                            {/* Calculate height for progressBar, note und notice */}
                            {Object.keys(fixHeight).length === 0 && 
                                <div ref={ref} style={{display: "inline-block",position: "absolute",visibility: "hidden",zIndex: -1,}}>
                                    {progressBar}
                                    {noteBar}
                                    {finishBar}
                                    {notice}
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        )
        }else{
            return <></>
        }
    }
}