import { useState, useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { useTranslation } from "react-i18next";


// Define a custom hook to share data between tabs
function useTabCommunication(channelName, datakey = 'setData') {
    const [tabsData, setData] = useState(null);
    const { t } = useTranslation();

    /* eslint-disable */
    useEffect(() => {
        const channel = new BroadcastChannel(channelName);
        // Define a function to handle incoming messages
        const handleMessage = (e) => {
        //const { key, value } = e.data;
        //Polyfill BroadcastChannel send data instead of event: e.data.key, e.data,value
        const key = e.key;
        const value = e.value;
        // Handle the data as needed
        if (key === 'closeTab' && window.location.pathname.endsWith(value)) {
            window.close();
        }else if(key === 'setData') {
            setData(value);
        }else if(key === 'logout') {
            //!window.location.pathname.startsWith(t("link.ras.vnc.url"))
            document.getElementById('logoutBtn')?.click();
        }else if(typeof window !== 'undefined' && key === 'login' && value !== sessionStorage?.getItem('tabId')) {
            !window.location.pathname.startsWith(t("link.ras.vnc.url")) && window.location.reload();
        }
        };

        // Add an event listener to the channel
        channel?.addEventListener('message', (e) =>  handleMessage(e));

        return () => {
        // Cleanup: remove the event listener and close the channel when the component unmounts
        channel?.removeEventListener('message', handleMessage);
        channel?.close();
        };
    }, [channelName, datakey]);
    /* eslint-enable */

    // Function to send data to other tabs
    const sendTabsData = (key, value) => {
        const channel = new BroadcastChannel(channelName);
        channel?.postMessage({ key, value });
        channel?.close();
    };

    return {tabsData, sendTabsData};
}

export default useTabCommunication;