import axios from "axios";
import AxiosPrivate from "./Common/AxiosPrivate";
import {
    API_DOWNLOAD,
    API_DOWNLOADS_LIST,
    API_DOWNLOADS_START,
    API_DOWNLOADS_ERR,
    API_DOWNLOADS_END,
    API_GENERATE_EXPIRED_DOWNLOAD,
    API_CHECK_DOWNLOAD_PACKET,
    API_DOWNLOAD_LICENSE_OPTIONS
} from "config/nw-api";

async function downloadsList(code) {
    let url = API_DOWNLOADS_LIST.replace(":code", code);
    return axios.get(url);
}

async function downloadStart(download, isAuthenticated = false) {
    if (isAuthenticated) {
        return AxiosPrivate.post(API_DOWNLOADS_START, download, {isPublic: true});
    }
    return axios.post(API_DOWNLOADS_START, download);
}

async function downloadEnd(log, isAuthenticated = false) {
    if (isAuthenticated) {
        return await AxiosPrivate.put(API_DOWNLOADS_END, log, {isPublic: true});
    }
    return await axios.put(API_DOWNLOADS_END, log);
}

async function downloadErr(log, isAuthenticated = false) {
    if (isAuthenticated) {
        return await AxiosPrivate.put(API_DOWNLOADS_ERR, log, {isPublic: true});
    }
    return await axios.put(API_DOWNLOADS_ERR, log);
}
async function generateExpiredDownload(id) {
    let url = API_GENERATE_EXPIRED_DOWNLOAD.replace(':id', id)
    return await axios.post(url);
}

async function CheckPaketDone(id) {
    const instance = axios.create({
        headers: { 'Content-Type': 'application/json'},
    })
    return await instance.get(API_CHECK_DOWNLOAD_PACKET.replace(":id", id));
}

async function getDownload(pathname) {
    return await axios.get(API_DOWNLOAD.replace(":pathname", pathname));
}

async function LicenseOptions(id) {
    const instance = axios.create({
        headers: { 'Content-Type': 'application/json'},
    })
    return await instance.get(API_DOWNLOAD_LICENSE_OPTIONS.replace(":id", id));
}

const downloadsAPI = {
    getDownload,
    downloadsList,
    downloadStart,
    downloadErr,
    downloadEnd,
    generateExpiredDownload,
    CheckPaketDone,
    LicenseOptions
};

export default downloadsAPI;