import axios from "axios";
import {API_REFRESH_TOKEN} from "config/nw-api";
import {store} from "Reduxx/store";
import { loginSuccess } from 'Reduxx/Reducers/AuthSlice';
import { logoutUser } from 'Reduxx/Reducers/Thunks/AuthThunk';
import { API_I18N_DOMAIN } from "config/i18n-api";

const AxiosPrivate = axios.create({withCredentials: true});
  
AxiosPrivate.interceptors.request.use(async (config) => {
    const {accessToken} = store.getState().auth;
    const {email} = store.getState().user;
    const apiURL = config.url;

    if (apiURL.includes(API_I18N_DOMAIN)) {
        config.headers['User-Username'] = email;
    }

    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    
    return config;
});
  
AxiosPrivate.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;

        if (error?.response?.status === 401 && !config.sent && error.response?.data?.message === 'Expired JWT Token') {
            config.sent = true;
            delete axios.defaults.headers["Authorization"];
            
            return await axios.post(API_REFRESH_TOKEN, null, {withCredentials: true})
            .then((res)=>{
                const {token, rTokenExp} = res.data;
                store.dispatch(loginSuccess({accessToken:token, rTokenExp}));
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                };
                return axios(config);
            }).catch((err)=>{
                if (config.signal?.aborted !== true) {
                    store.dispatch(logoutUser(true));
                }
                if(config?.isPublic){
                    delete config.headers.Authorization;
                    return axios(config);
                } 
                return Promise.reject(err);
            }).finally(() => {
            })
        }

        return Promise.reject(error);
    }
);
  
export default AxiosPrivate;