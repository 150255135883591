export function fixInput(input) {
	if (!input.value) {
		return "";
	}
	let newValue = input.value.replace(/ /g,'');
	newValue = newValue?.replace(/-/g, '');
	newValue = newValue?.replaceAll('\u{2011}', ''); //replace no-breaking space
	
	if (newValue.length > 4){  // Remove dash (-) if mistakenly entered.
		newValue = newValue.match(/.{1,5}/g).join('-');
	}

	return newValue.substring(0,input.maxLength).toUpperCase();
}

export function hasMinOneRole(roles, userRoles){
	for (const role of roles) {
		if(userRoles.includes(role)){
			return true;
		}
	}
	return false;
}

export function hasEditRole(start, end, roles){
	const pattern = new RegExp(`^${start}.*${end}$`);
	for (const role of roles) {
		if (pattern.test(role)) {
			return true;
		}
	}
	return false;
}

export const convertSize = (bytes) => {
	if (typeof bytes !== 'number' && isNaN(bytes) && !isFinite(bytes)) {
		return null;
	}
	const units = ['sg', 'KB', 'MB', 'GB', 'TB'];
	let pow = Math.floor((bytes ? Math.log(bytes) : 0) / Math.log(1024));
	pow = Math.min(pow, units.length - 1);
	// Uncomment one of the following alternatives
	//bytes /= (1 << (10 * pow));
	bytes /= Math.pow(1024, pow);
	let decimalFix = 0;
	const integerLength = bytes.toString().split('.')[0].length;
	if (bytes === 0) {
		return null;
	}
	if(integerLength === 2) {
		decimalFix = 1;
	} else if(integerLength < 2) {
		decimalFix = 2;
	}

	const size = bytes.toFixed(decimalFix)

	return {size, decimalFix, pow}
}

export const moveToBeginning = (array, id) => {
    const index = array.findIndex((item) => item.id === id);
    if (index > -1 && id) {
      const newArray = [...array];
      newArray.splice(index, 1);
      newArray.unshift(array[index]);
	  return newArray;
    }
	return array;
};

//How many char(ex:"/") has the str (ex: "/ras/teams/1/handle/gdfkghdl") after the word (ex: "ras") => (ex: return 4)
export const countOccurrences = (str, word, char) => {
	let count = 0;
	const index = str.indexOf(word);
	if (index !== -1) {
		for (let i = index + word.length; i < str.length; i++) {
			if (str[i] === char) {
				count++;
			}
		}
	}
	return count;
}

export const handleCheckboxDivClick = (e, name = null) =>{
	if (e.target.type !== 'checkbox') {
		e.preventDefault();
		const query = `input[type="checkbox"]${name ? `[name="${name}"]` : ""}`;
		e.currentTarget.querySelector(query)?.click();
		/*const checkbox = document.getElementById(id);
		if (checkbox) {
			checkbox.checked = !checkbox?.checked;
		}*/
	}
	//document.getElementById(id)?.click();

	/*if (e.target.type === 'checkbox') {
		e.currentTarget.checked = !!e.currentTarget.checked;
		console.log('checkbox');
		return;
	}
	const checkbox = e.target.querySelector('input[type="checkbox"]');
	
	if (checkbox) {
		checkbox?.click();
		console.log('target');
		return;
	}
	console.log('currentTarget');
	e.currentTarget.querySelector('input[type="checkbox"]')?.click();*/
}

//Detect Device
export const detectDevice = () => {
	let userAgent = navigator?.userAgent?.toLowerCase();
	const device = (s) => {
		if(s !== undefined){
			userAgent = s?.toLowerCase();
		}
		 
		return /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(userAgent) ?
			"phone"
 		: /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent) ? 
		 	"tablet"
		:
			"desktop";
	}

	const devideType = device();
	
	return {
		type: devideType,
		detect: device,
		isMobile: "desktop" !== devideType ? true:false,
		userAgent:userAgent
	}
}

export const setHideColsLocalStorage = (prefix, key, value, expirySeconds) => {
    const now = new Date()
    localStorage.setItem(`${prefix}${key}`, JSON.stringify(value));
    localStorage.setItem(`${prefix}expire`, JSON.stringify(now.getTime() + (expirySeconds * 1000)));
}

export const getNotExpiredLocalStorage = (prefix, key) => {
    const now = new Date()
    const expire = localStorage.getItem(`${prefix}expire`);
    if (now.getTime() > parseInt(expire)) {
        localStorage.removeItem(`${prefix}${key}`)
        return null
    }
    return localStorage.getItem(`${prefix}${key}`);//lic_hide_
}