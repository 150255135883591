import React, {Component, createRef} from 'react';
import $ from 'jquery';
import jstree from 'jstree'; // eslint-disable-line
import  './access.css';
import ACCESSAPI from "Services/AccessAPI";
//import {Node} from './Node';
//import Field from 'Components/Forms/Field';


export class Tree extends Component {
    constructor(props) {
        super(props);
        this.confirmRef = createRef();
        this.state = {
            data: this.props.data,
            user: this.props.user,
            t: this.props.translate,
            criteria: this.props.criteria,
            //selectedNode: {name: '', id: null},
            //node: this.props.selectedNode??[]
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.updateState = this.updateState.bind(this);
        this.readState = this.readState.bind(this);
        //this.refresh = this.refresh.bind(this); //Use to refresh TreeList Comnponent (parent)
    };

    handleSelect(e, data) {
        if(data.node?.original.id_origin){
            this.props.handleTree("selectNode", {id:data.node.original.id_origin});
            /*ACCESSAPI.getNodes(data.node.original.id_origin).then((res) => {
                //this.setState({...this.state, node:{}});
                //this.setState({...this.state, node:res.data});
                this.props.setSelectedNode({});
                this.props.setSelectedNode(res.data);
            }).catch((err)=>{
                //this.setState({...this.state, node:{}});
                this.props.setSelectedNode({});
                this.props.handleFlash({alert:"warning", message: this.props.translate("err.500")});
            })*/
        }
    }

    updateState({name, data}) {
        this.setState({...this.state, [name]:data});
    }

    readState(name) {
        return this.state[name];
    }
    
    componentDidMount() {
        const updateState = this.updateState; // eslint-disable-line
        const readState = this.readState;
        const handleFlash = this.props.handleFlash; //pass flash to parent
        const handleTree = this.props.handleTree;

        const t =  readState("t");
        this.$el = $(this.el);    
        this.$el.jstree({ // config object start
            "core": {
                "data":this.state.data.accesses,              // core config object
                "mulitple": false,       // disallow multiple selection  
                "animation": 100,        // 200ms is default value
                "themes": {
                    "variant": "medium",
                    "dots": true
                },
                //"check_callback" : true, // this make contextmenu plugin to work
                "check_callback" : function (operation, node, node_parent, newName, more) {
                    if (operation === 'rename_node'){
                        if (typeof newName === 'string' && newName.trim().length === 0) {
                            handleFlash({alert:"warning", message: "Name is required"});
                            $('#jsTree').jstree(true).refresh();

                            return false;
                        }
                        let duplicate = $('#jsTree').jstree(true).get_json('#0').children?.find(child => (node.id !== child.id && child.text === newName && node?.icon === child?.icon 
                        ));

                        if(duplicate){
                            handleFlash({
                                alert:"warning",
                                message: `Name "${duplicate.text}" already exists in the type "${node.original.type}"`
                            });
                            $('#jsTree').jstree(true).refresh();

                            return false;
                        }
                    }
                    return true;
                }
            },
            
            // config object for Checkbox plugin (declared below at plugins options)
            "checkbox": { 
              "keep_selected_style": false,  // default: false
              "three_state": true,           // default: true
              "whole_node": true             // default: true
            },

            "contextmenu": readState('criteria')?.disabled ? false : {
                "items": function ($node) {
                    var tree = $("#jsTree").jstree(true);
                    if($node.original.type !== 'root'){
                        if (readState('user')?.roles.includes('ROLE_81')) {
                            return getLeafContextMenu($node, tree);
                        }
                    }else if(readState('user')?.roles.includes('ROLE_81')){
                        return getNodeContextMenu($node, tree, readState("data")?.types);                        
                    }
                }
            },
            'sort' : function(a, b) {
                let a1 = this.get_node(a);
                let b1 = this.get_node(b);
                if (a1.icon === b1.icon){
                    return (a1.original.order > b1.original.order) ? 1 : -1;
                } else {
                    return (a1.original.order > b1.original.order) ? 1 : -1;
                }
            },
            "search" : {
                "case_sensitive": false,
                "show_only_matches": true,
                "search_callback": function(search, node) {
                    const match = search.match(/^#(\d+)$/);
                    if (match && match[1] === node.original.id_origin?.toString()) {
                        node.a_attr.class = (node.parent === '0') ? "nw-blue-dark" : "";

                        return true;
                    }else if (node.text.includes(search)) {
                        node.a_attr.class = (node.parent === '0') ? "nw-blue-dark" : "";

                        return true;
                    }
                    return false;
                }
            },
            //uncomment plugin to use the function filter select
            /*"conditionalselect" : function (node, event) {
                return false;
            },*/
            
            // injecting plugins
            "plugins" : [
                  //"checkbox",
                  "contextmenu",
                  "search",
                  "sort",
                  "types",
                  "changed"
                  // "dnd",
                  // "massload",
                  // "state",
                  // Unique plugin has no options, it just prevents renaming and moving nodes
                  // to a parent, which already contains a node with the same name.
                  //"unique",
                  // "wholerow",
                  //"conditionalselect",
            ]
        }); // config object end

        // Listen for events
        $("#searchTree").on('input',function () {
            let search = $(this).val();
            $('#jsTree').jstree('search', search);
        });

        this.$el.on("changed.jstree", this.handleSelect);

        this.$el.on("delete_node.jstree", function (e, data) {
            ACCESSAPI.remove(data.node.original.id_origin, readState('criteria'))
            .then(response => {
                console.log('Node removed');
                if (response.data.length>0) {
                    $('#jsTree').jstree(true).settings.core.data = response.data;
                    //Remove rights list
                    handleTree("removeNode", {resData:response.data});
                    /*if (readState('node')[0]?.text === data.node.text) {
                        updateState({name: 'node', data: {}});
                    }*/
                }
            })
            .catch(error => {
                if(!error.response || error.response.data.status === 500){
                    handleFlash({alert:"danger", message:error.message});
                    console.log('Node Not removed', error,error.message + " 😥");
                } else {
                    handleFlash({alert:"danger", message:error.response.statusText});
                    console.log('Node Not removed', error.response);
                }
            }).finally(res => {
                $('#jsTree').jstree(true).refresh();
            })
        });
        
        this.$el.on("rename_node.jstree", function (e, data) {
            const parentsText = data.node.original.parentsText;
            
            if (data.node.original.id === undefined) {
                const newAccess = {
                    name: data.text,//.startsWith('New ') ? '-'+data.text : data.text,
                    type: data.node.original.type,
                    ...readState('criteria')
                }
                const t = $("#jsTree").jstree(true);
                
                ACCESSAPI.create(newAccess)
                    .then(response => {
                        
                        //refresh Tree with new Data;
                        t.settings.core.data = response.data.tree;
                        $("#jsTree").one("refresh.jstree", function () {
                            t.select_node(`${response.data.created}`); 
                        });
                        t.refresh(true,true);
                        //TODO: after add data select node and scroll to its position

                        // Select a node after the refresh
                        //t.select_node(response.data.created);
                        //t.refresh(true,true);
                        /*$('#jstree').jstree('refresh');
                        console.log();
                        $("#jsTree").one("refresh.jstree", function () {
                            const element = t.select_node(`${response.data.created}`);
                            console.log( this, $("#jsTree").jstree(true));
                            var newNodeElement = document.getElementById("#" + response.data.created);
                            console.log(newNodeElement);
                            if (newNodeElement) {
                                newNodeElement.scrollIntoView({ behavior: "smooth" });
                            }
                        });*/

                        


        // Find the node you want to scroll to (replace 'targetNodeId' with the actual ID of the node)
        /*var targetNodeId = String(response.data.created);
        var targetNode = $("#jsTree").jstree(true).get_node(targetNodeId);
        console.log(targetNode);

        if (targetNode) {
            // Select the node (to make sure it's visible in the tree)
            $("#jsTree").jstree(true).select_node(targetNode);
            
            
            // Scroll to the selected node using jQuery
            var $nodeElement = $('#' + targetNodeId);
            console.log( $nodeElement, $nodeElement.offset());
            //$nodeElement.scrollIntoView();
            if ($nodeElement.length > 0) {
                $("#jsTree").jstree(true).animate({
                    scrollTop: $nodeElement.offset().top
                }, 500); // You can adjust the scroll duration as needed
            }
        }*/





                        //console.log($('html, body'),$("#jsTree").offset());
                        //console.log($("#jsTree").find("#response.data.created"));
                        //$("#jsTree").find("#response.data.created").scrollIntoView();
                        //$("#jsTree").animate({ scrollTop: $("#jsTree").jstree('search', data.text)?.offset().top}, 'slow');
                    })
                    .catch(error => {
                        if(!error.response || error.response.data.status === 500){
                            handleFlash({alert:"danger", message:error.message});
                            console.log(error,error.message + " 😥");
                        } else {
                            handleFlash({alert:"warning", message:error.response.data});
                            console.log(error.response);
                        }
                        t.refresh(true,true);
                    })
            }else if (data.text !== data.old && !data.text.includes(parentsText)) {
                const postData = {
                    newName: data.text,
                    type: data.node.original.type,
                    original: data.node.original,
                    parent: data.node.parent,
                    parents: data.node.parents,
                }
                ACCESSAPI.rename(postData)
                    .then(response => {
                        handleTree("selectNode", {id:data.node.original.id_origin});
                    })
                    .catch(error => {
                        if(!error.response || error.response.data.status === 500){
                            handleFlash({alert:"danger", message:error.message});
                            console.log(error,error.message + " 😥");
                        } else {
                            handleFlash({alert:"danger", message:error.response.statusText});
                            console.log(error.response);
                        }
                    })
            }
            //Add parentsText after removing it by clicking rename
            if (parentsText && !data.node.text.endsWith(parentsText)) {
                $('#jsTree').jstree('rename_node', data.node.id, data.node.text + parentsText);
            }
        });
        //Use to create Element
        /*$('button').on('click', function (e) {
            $("#jsTree").jstree(true).select_node('child_node_1');
            $("#jsTree").jstree('select_node', 'child_node_1');
            $("#jsTree").reference('#jsTree').select_node('child_node_1');
            console.log(e);
        });*/
/*
        //Remove context Menu from specific node/leafs
        this.$el.on("show_contextmenu.jstree", function (e,data) {
                //disable context menu for root folder
                console.log(data, data.node.text);
                if(data.node.text !== "Root") {
                    $('.vakata-context').remove();
                }
            })
*/

        function createAction($node, tree, $nodeData){
            /*let duplicateName = tree.get_json('#0').children?.find(element => element.text === $nodeData.text);
            if(duplicateName){
                handleFlash({alert:"warning", message: `Name "${duplicateName.text}" already exists`});
            }else{*/
                const nodeid = tree.create_node($node, $nodeData, 'first');
                tree.deselect_all();
                tree.select_node(nodeid);
                tree.edit(nodeid);
            //}
        }

        function getNodeContextMenu($node, tree, types)
		{
            const subMenu = {};
            //Create submenu from Database
            for (const type of types) {
                let name = type?.name?.charAt(0).toUpperCase() + type?.name?.slice(1);
                subMenu[name] = {
                    "seperator_before": false,
                    "seperator_after": true,
                    "icon": type.icon + " nw-green-dark",
                    "label": t(`right.type.${type.i18n}`),
                    action: function (obj) {
                        createAction($node, tree, {text: '', id_origin: null, icon:type.icon, type:type?.name});
                    }
                }
            }

            let menu = {
				"create": {
					"separator_before": false,
					"separator_after": true,
					"label": t("right.tree.menu.create"),
					"action": false,
					"submenu": subMenu,
				},
            }
            
			return menu;
		}
		
		function getLeafContextMenu($node, tree)
		{
			return {
				"Rename": {
					"separator_before": false,
					"separator_after": false,
					"label": t("right.tree.menu.rename"),
					"action": function (obj) {
                        //Remove parentsText before editing name
                        const extraText = $node.original.parentsText;
                        if (extraText && $node.text.endsWith(extraText)) {
                            $node.text = $node.text.slice(0, -extraText.length);
                        }

						tree.edit($node);
					}
				},
				"Remove": {
					"separator_before": false,
					"separator_after": false,
					"label": t("right.tree.menu.remove"),
					"action": function (obj) {
                        $("#confirmModalTxt").html(t('right.tree.menu.remove.confirm.txt', {name:`<i class="${$node.icon}"></i> ${$node.text}`}));

                        document.getElementById("openConfirmModal").click();
                        // Set up a click handler for the modal's "Delete" button
                        $('#deleteAccessButton').on('click', function(e) {
                            //Remove child to fix error "TypeError: "x" is (not) "y": m[obj.children[i]] is undefined"
                            // Delete the node if the user confirms
                            $node.children_d = [];
                            tree.delete_node($node);
                            document.getElementById("closeConfirmModal").click();
                        });
					}
				}
			};
		}
    }

    componentWillUnmount() {
        this.$el.jstree('destroy');  
    }
    
    render() {
        const t  = this.props.translate;
        return(
            <>
                <button id="openConfirmModal" type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#deleteAccessModal"></button>

                <div className="modal fade" id="deleteAccessModal" tabIndex="-1" aria-labelledby="deleteAccessModalLabel" aria-hidden="true">
                    <div className="modal-dialog vertical-center">
                        <div className="modal-content bm-card bg-light">
                        
                            <h2 id="deleteAccessModalLabel" className="bm-card-header_small">{t('right.tree.menu.remove.confirm')}</h2>

                            <a href="/#" className="bm-close-x" type="button" data-bs-dismiss="modal" aria-label="Close">X</a>
            
                            <p id="confirmModalTxt" className="fn-txt bg-light" dangerouslySetInnerHTML={{__html: ''}} />

                            <div className="row">
                                <div className="col-6">
                                    <button id="closeConfirmModal" className="bm-btn-mute bm-btn-card-prev px-3" data-bs-dismiss="modal">
                                        {t("right.tree.menu.remove.confirm.btn.no")}
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button id="deleteAccessButton" className="bm-btn-blue bm-btn-card-next px-3" autoFocus>
                                        {t("right.tree.menu.remove.confirm.btn.yes")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
		        </div>
                {/* Element deplaced to parent search input
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <Field label={t("right.tree.filter")} placeholder={t("right.tree.filter.hint")} id="searchTree" class="search-tree"/>
                    </div>
                </div>*/}
                <div className='overflow-scroll max-height-68' id="jsTree" ref={el => this.el = el} />
            </>
        );
    }
}