import axios from "axios";
import AxiosPrivate from "./Common/AxiosPrivate";
import {
    //API_LICENSE_CLEAR,
    API_LICENSE_CHECK,
    API_LICENSE_OPTIONS,
    API_LICENSE_CLEAR_CUSTOM_OPTIONS,
    API_LICENSE_UPDATE_DATA,
    API_USER_UPDATE_License,
    API_LICENSE_CHECK_UPGRADECODE,
    API_LICENSE_CHECK_UPGRADE_PRODUCTS,
    API_LICENSE_UPGRADE,
    //API_LICENSE_UPDATE_OWNER,
    API_LICENSE_ADD_USER,
    API_LICENSES,
    API_LICENSES_DETAILS,
    API_LICENSES_PANEL,
    API_LICENSES_TAB
} from "config/nw-api";

/*async function licenseClear(license) {
    return axios.post(API_LICENSE_CLEAR, license);
}*/

async function licenseCheck(key) {
    return await axios.get(API_LICENSE_CHECK.replace(":key", key));
}

async function licenseOptions(key, isAuthenticated = false) {
    const url = API_LICENSE_OPTIONS.replace(":key", key);
    if (isAuthenticated) {
        return AxiosPrivate.get(url, {isPublic: true});
    }
    return axios.get(url);
}

function licenseUpdateAndOptions(key, productShortName, isAuthenticated) {
    const url = API_LICENSE_OPTIONS.replace(":key", key) + `/${productShortName}`;
    if (isAuthenticated) {
        return AxiosPrivate.get(url, {isPublic: true});
    }
    return axios.get(url);
}

async function licenseClearWithOption(options, isAuthenticated) {
    if (isAuthenticated) {
        return AxiosPrivate.post(API_LICENSE_CLEAR_CUSTOM_OPTIONS, options, {isPublic: true});
    }
    return axios.post(API_LICENSE_CLEAR_CUSTOM_OPTIONS, options);
}

async function CheckUpgradeCode(code) {
    const instance = axios.create({
        headers: { 'Content-Type': 'application/json'},
    })

    return await instance.get(API_LICENSE_CHECK_UPGRADECODE + code);
}

async function checkUpgradeProducts(key,code, isAuthenticated = false) {
    if (isAuthenticated) {
        return await AxiosPrivate.get(API_LICENSE_CHECK_UPGRADE_PRODUCTS.replace(":key", key).replace(":code", code));
    }
    return await axios.get(API_LICENSE_CHECK_UPGRADE_PRODUCTS.replace(":key", key).replace(":code", code));
}

async function licenseUpgrade(data, isAuthenticated = false) {
    if (isAuthenticated) {
        return AxiosPrivate.post(API_LICENSE_UPGRADE, data, {isPublic: true});
    }
    return axios.post(API_LICENSE_UPGRADE, data);
}

async function licenseUpdateData(data, isAuthenticated = false) {
    if (isAuthenticated) {
        return await AxiosPrivate.post(API_LICENSE_UPDATE_DATA, data, {isPublic: true});
    }
    return await axios.post(API_LICENSE_UPDATE_DATA, data);
}

async function addUser(currentKey, data) {
    return await AxiosPrivate.put(API_LICENSE_ADD_USER.replace(":key", currentKey), data);
}

async function updatePrivateData(data) {
    return await AxiosPrivate.post(API_USER_UPDATE_License, data);
}

async function licenses(criteria) {
    let url = API_LICENSES.replace(":tabename", criteria.tabname).replace(":limit", criteria.limit);
    const data = criteria.search ?{search: criteria.search} : []
    
    return await AxiosPrivate.post(url, data);
}

async function licensesDetails(key) {
    return await AxiosPrivate.get(API_LICENSES_DETAILS.replace(":key", key));
}

async function licensesPanel(key, tabName) {
    return await AxiosPrivate.get(API_LICENSES_PANEL.replace(":key", key).replace(":tabName", tabName));
}

async function licensesTab({key, tab}) {
    return await AxiosPrivate.get(API_LICENSES_TAB.replace(":key", key).replace(":tab", tab));
}

const licenseAPI = {
    //licenseClear,
    licenseCheck,
    licenseClearWithOption,
    licenseUpdateAndOptions,
    licenseOptions,
    licenseUpdateData,
    CheckUpgradeCode,
    checkUpgradeProducts,
    licenseUpgrade,
    licenses,
    addUser,
    updatePrivateData,
    licensesDetails,
    licensesPanel,
    licensesTab
};

export default licenseAPI;