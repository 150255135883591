import AxiosPrivate from "./Common/AxiosPrivate";
import {
    API_SLOTS_LIST,
    API_HOSTS_TERAM_LIST,
    API_ASSISTANT_PAIR,
    API_ASSISTANT_UNPAIR,
    API_ASSISTANT_SWITCH_FLAGS,
    API_ASSISTANT_HANGUP,
    API_HOSTS_ADD,
    API_HOSTS_DELETE,
    API_TEAMS_ASSISTANT,//Remove maybe not used
    API_TEAMS_AND_SLOT,
    API_P2P_SLOT,
} from "config/nw-api";


async function slots(id, name, from = null, header = {}) {
    let url = API_SLOTS_LIST.replace(":id", id).replace(":tabName", name);
    if(from){
        url = url+'/'+from;
    }

    return await AxiosPrivate.get(url, header);
}

async function hosts(id) {
    return await AxiosPrivate.get(API_HOSTS_TERAM_LIST.replace(":id", id));
}

async function teams(handle) {
    if (handle) {
        return await AxiosPrivate.get(API_TEAMS_AND_SLOT.replace(":handle", handle));
    }
    return await AxiosPrivate.get(API_TEAMS_ASSISTANT);
}

async function addHost(host) {
    return await AxiosPrivate.post(API_HOSTS_ADD, host);
}

async function DeleteHosts(hostsIds) {
    return await AxiosPrivate.delete(API_HOSTS_DELETE, {data:hostsIds});
}

async function updateSlot(action, data) {
    let url = '';
    if (action === "pair") {
        url = API_ASSISTANT_PAIR.replace(":handle", data["handle"]);
        url = url.replace(":hostId", data["hostId"]);
    } else if(action === "unpair"){
        url = API_ASSISTANT_UNPAIR.replace(":handle", data["handle"]);
    } else if(action === "hangup"){
        url = API_ASSISTANT_HANGUP.replace(":handle", data.handle);
    } else if(action.startsWith('flag/')){
        url = API_ASSISTANT_SWITCH_FLAGS.replace(":handle", data.handle);
        url = url.replace(":flag", action);
    }

    return await AxiosPrivate.post(url, data);
}
async function p2pSlot(handle) {
    return await AxiosPrivate.get( API_P2P_SLOT.replace(":handle", handle));
}

const rasAPI = {
    slots,
    hosts,
    teams,
    updateSlot,
    addHost,
    DeleteHosts,
    p2pSlot
};

export default rasAPI;