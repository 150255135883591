import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
//import { useParams  } from 'react-router-dom';
import { useEffect, useState } from "react";
import DOWNLOAD_API from "Services/DownloadsAPI";
import Spinner from 'Components/Loading/Spinner';
import {localeDatetime} from 'Components/Tools/DateTime'


const errGroup = {
	'chg': {
		domain: 'nw.lic.barred.chg', errs: ['Upgrade','LangChg', 'Double', 'Wrongly', 'Update', 'UsedUpdate', 'UsedUpgrade'],
	},
	'heal': {
		domain: 'nw.lic.barred.heal', errs: ['NoPay', 'PartPay', 'InvData', 'PartData', 'VATID', 'OnHold'],
	},
	'ult': {
		domain: 'nw.lic.barred.ult', 
		errs: [
			'PirateCopy', 'TempUse', 'Refund', 'Fraud', 'Chargeback', 'Downdate', 'Transfer', 'Unused', 'WrongSalt', 'ActOnly', 'Lost', 'Init', 'Error', 'DSGVO'
		],
	},
	'wait': {
		domain: 'nw.lic.barred.wait', errs: ['Awaiting', 'UpdateHold', 'UpgradeHold'],
	},
	'outdated': {
		domain: 'nw.lic.barred.outdated', errs: ['Outdated'],
	},
	'unsold': {
		domain: 'nw.lic.barred.unsold', errs: ['invalid', 'missing'],
	},
	'gen': {
		domain: 'clear.result.err.general', errs: ['PresetStickOnly', 'PresetStickBox', 'PresetDiskBox', 'PresetDiskStickBox', 'PresetDiskOnly'],
	},
	'lk': {
		domain: 'clear.result.err', errs: ['maxclear', 'expired', 'improper', 'norelease'],//The keys come only from i18N 
	},
	'dl': {
		domain: 'download.err', errs: ['expire', 'noDL', 'forbid', 'noLK'],//The keys come only from i18N 
	},
}

export default function Error(props) {
	const { t } = useTranslation();
	const location = useLocation();
	const params = useParams();
	const [urlParams] = useState({...params});
	const tabTitle = location.state?.docTitleI18n ? location.state?.docTitleI18n : props.data?.h1;
	
    document.title = t(tabTitle) + " – nanoways.com";
	const navigate = useNavigate();
	const [error, setError] = useState({
		group: null,
		h1: null,
		h2: null,
		p: null,
		pContact: null,
		div: null,
		div2: null
	});
	const [loading, setLoading] = useState(false);

	/* eslint-disable */
	useEffect(()=>{
		if(!location.state && !props.data){
			navigate(t("link.root.url"));
		} else if (params && props.data) {
			setError(props.data);
			navigate(t("link.err.url"));
		}

		if (location.state && !error.group) {
			Object.keys(errGroup).forEach((group) => {
				for (const err of errGroup[group].errs) {
					if(err === location.state.errGroup){
						const domain = errGroup[group].domain;
						const vars = location.state.info?.agent ? 
							{
								agentName:location.state.info?.agent?.name,
								agentEmail:location.state.info?.agent?.email,
								pageSubheader: t(`${domain}.${err}`)
							} 
							: 
							undefined
						;

						if(!['gen', 'lk', 'dl', 'unsold'].includes(group)){
							setError({...error,group:group, h1: "nw.lic.barred", h2: domain, p:{txt: `${domain}.txt`,var:vars} , div:{txt:`${domain}.${err}`,var:vars}, div2: {txt:`${domain}.txtBelow`, var:vars}});
						}else if(group === 'gen'){
							setError({...error,group:group, h1: "clear.result.err", h2: domain, p:{txt: `${domain}.txt`,var:vars}} );
						}else if(group === 'lk'){
							setError({...error,group:group, h1: "clear.title", h2: `${domain}.${err}`, p:{txt: `${domain}.${err}.txt`,var:vars}}); //Email subject is eauql to h2
						}else if(group === 'dl'){
							setError({...error,group:group, h1: "download.title", h2: domain, p:{txt: `${domain}.${err}`,var:vars}, pContact: ['expire', 'noDL'].includes(err) ? "" : "nw.err.contact"});
						}else if(group === 'unsold'){
							setError({...error,group:group, h2: domain, p:{txt: `${domain}.txt`, var: {invalid: err === 'invalid'? `<br/>${t(domain+'.invalid', {sellDate: localeDatetime(location.state.info.sellDate, false)})}`: ""} }, div2: {txt:`${domain}.txtBelow`, var:vars}});
						}
						break;
					}
				}
			})
		}
	}, [])
	/* eslint-enable */

	const handleClearLicense = (e) => {
		// Find License Key from download
		if (urlParams.type === 'dl' && urlParams.pathname) {
			e.preventDefault();
			setLoading(true);
			DOWNLOAD_API.getDownload(urlParams.pathname)
			.then((res)=>{
				const LicenseKey = res.data;
				navigate(LicenseKey ? t("link.lic.clear.lic") + `/${LicenseKey}` :  t("link.lic.clear.lic"));
			}).catch((err)=>{
				console.log(err);
				navigate(t("link.lic.clear.lic"));
			})
		}
	}
	
    return (
        <div className="m-0 p-0 vertical-center">
          	{(location.state || error) && 
          		<div className="bm-main">
              		<div className="bm-card">

                		<Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>

                		<h1>{t(error.h1)}</h1>
						
						{error.h2 && <h2 className="bm-sep-1 nw-red-dark" dangerouslySetInnerHTML={{__html: t(error.h2)}}/>}
						
						{error.p && <p dangerouslySetInnerHTML={{__html: `${t(error.p?.txt, error.p?.var)} ${t(error.pContact)}`}}/>}

						{error.div && <div dangerouslySetInnerHTML={{__html: t(error.div?.txt, error.div?.var)}}/>}

						{error.div2 &&
							<div dangerouslySetInnerHTML={{__html:t(error.div2?.txt, error.div2?.var)}}/>
						}
						<div className="mt-5 row d-flex flex-sm-row-reverse p-0 justify-content-end">
							<div className="col-md-6 mb-2">
								{error.group === 'dl' && 
									<Link 
										to={location.state?.option?.product ? t("link.lic.clear.opt"): t("link.lic.clear.lic")} 
										state={location.state?.option?.product ? {default:location.state.option} : {}} 
										replace
										onClick={handleClearLicense}
									>
										<button
											className="bm-btn-blue bm-btn-card-next d-flex justify-content-center"
											disabled={loading ? "disabled" : ""} 
										>
											{loading &&
												<Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
											}
											
											<span className={loading ? "invisible" : ""}>{t("download.err.btn")}</span>
										</button>
									</Link>
								}

								{['chg', 'heal', 'ult', 'wait', 'gen', 'lk'].includes(error.group) && 
									<Link to={t("link.lic.clear.lic")} replace>
										<button className="bm-btn-blue bm-btn-card-next">{t("clear.result.done.btn")}</button>
									</Link>
								}
							</div>

							<div className="col-md-6">
								<Link className="float-end float-lg-start float-md-start" to={t("link.root.url")}>
									<button className="bm-btn-mute">{error.group === 'dl' ? t("nw.btn.goto") : t("nw.btn.back")}</button>
								</Link>
							</div>
						</div>
            		</div>
          		</div>
          	}
        </div>
    )
}