export const convertUtcToSummerLocale = (utcStr, utc = '', locale = undefined) => {
	const utcDateTime = new Date(utcStr+utc); // Create a Date object from the UTC datetime string
	const offsetInMinutes = utcDateTime.getTimezoneOffset(); // Get the timezone offset in minutes
	const localDateTime = new Date(utcDateTime.getTime() - (offsetInMinutes * 60000)); // Subtract the timezone offset to get the local datetime
	const isSummer = (new Date()).getTimezoneOffset() < offsetInMinutes; // Check if the current timezone offset is less than the offset of the local datetime
	//return isSummer ? localDateTime.toLocaleString() : utcDateTime.toLocaleString();
	return isSummer ? removeSeconds(localDateTime, locale) : removeSeconds(utcDateTime, locale);
}

const removeSeconds = (datetime, locale) => {
	locale = locale?.includes('zh') || locale?.includes('i18n') ? 'en': locale;
	//let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	//let localizedDate = date.toLocaleDateString(undefined OR 'de-DE', options);
	const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
	return  datetime.toLocaleDateString(locale, options) +" "+ datetime.getHours().toString().padStart(2, '0')+":"+ datetime.getMinutes().toString().padStart(2, '0')
}

export const localeDatetime = (utc, hasTime = true, hasDate = true) => {
	const utcDateTime = utc instanceof Date ? utc : new Date(utc);
	utcDateTime?.setSeconds(0); //remove seconds

	const dateTimeFormat = new Intl.DateTimeFormat();
	const timeZone = dateTimeFormat.resolvedOptions().timeZone;
	const userLocale = navigator.language || navigator.userLanguage;
	let options = {timeZone: timeZone};
	if (hasTime) {
		options = {...options, hour: 'numeric', minute: 'numeric', hour12: false}
	}
	if (hasDate) {
		options = {...options, year: 'numeric', month: '2-digit', day: '2-digit'}
	}

	return utcDateTime?.toLocaleString(userLocale, options)
}

/**
 * return en language for unkown locale name for example ('i18n' used intern)
 * 
 * @param {string} language 
 * @returns string
 */
export const i18nLocale = (language) => {
	return language?.includes('zh') ||  language?.includes('i18n') ? 'en':  language;
}