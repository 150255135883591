import { useState, useEffect, useRef } from "react";
import UserAPI from "Services/UsersAPI";
import Field from "../../Forms/Field";
import { useTranslation } from "react-i18next";
import MailSvg from 'assets/images/icon/mail-animation.gif';
import { DOMAIN_NAME } from "config/nw-api";
import { useSearchParams } from 'react-router-dom';
import Spinner from 'Components/Loading/Spinner';
import useFocusModal from "Hook/useFocusModal";
import { checkEmail } from 'Components/Tools/Text';
import {useFlashContext}  from 'Contexts/FlashContext';

export default function PwdForgotModal() {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const {setFlash} = useFlashContext();

    const [input, setInput] = useState({
        email: searchParams.get('mail') || "",
        emailLang: i18n.language
    });

    const forgotPwdModal = useRef(null);
    const emailInput = useRef(null);
    useFocusModal(forgotPwdModal, emailInput);
    
    const [sendForm, setSendForm] = useState(false);

    const [errors, setErrors] = useState({
        email: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [formAccepted, setFormAccepted] = useState(false);
    
    /* eslint-disable */
    useEffect(()=>{
        if(searchParams.get('mail')){
            if (!checkEmail(input.email)) {
                setErrors({...errors, email:t("nw.input.mail.err.invalid")});
                setIsLoading(false);
            } else{
                setSendForm(true);
                document.getElementById('forgot-btn').click();
            }
        }
    }, [])
    /* eslint-enable */

    // Manage input changing in form
    const handleChange = (e) => {
        const {name, value} = e.currentTarget;
        setInput({...input, [name]: value});
    };

    const showForm = () => {
        setFormAccepted(false);
        document.getElementById('closePwdForgot').click();
    }

    // Manage form submission
    const handleSubmit = async event => {
        event.preventDefault();
        setIsLoading(true);
        setErrors({});

        if (!input.email) {
            setErrors({...errors, email:t("nw.input.err.required")});
            setIsLoading(false);
        } else if (!checkEmail(input.email)) {
            setErrors({...errors, email:t("nw.input.mail.err.invalid")});
            setIsLoading(false);
        } else{
            input.urlCallback = {"reset_password":DOMAIN_NAME + t("link.pwdReset") + "?" , "forgot_password":DOMAIN_NAME + t("link.pwdForgot.url") + "?"};

            UserAPI.pwdForgot(input)
            .then(res => {
                if (res.data?.emailIsSent === false) {
                    setFlash({alert: "warning", message: t("err.email")});
                }
                setIsLoading(false);
                setInput({
                    email: "",
                    emailLang: i18n.language
                });            
                setFormAccepted(true);
            })
            .catch(error => {
                setIsLoading(false);
                setSendForm(false);
                
                if(!error.response || error.response.data.status === 500){
                    //TODO: return 500 error and show page 500
                    console.log(error.message);
                    setErrors({...errors, email:t("err.500")});
                } else {
                    const data = error.response.data;
                    if (Object.keys(data).length !== 0 && data.type !== undefined) {
                        if (data.type === 'ParamNotFound') {
                            setErrors({...errors, email:t("err.unexpected")});
                        } else if (data.type === 'ResourceNotFound') {
                            setErrors({...errors, email:t("nw.input.mail.err.notFound", { email: input.email})});
                        }
                    } else {
                        setErrors({...errors, email:t("err.unknown")});
                    }
                }
            });
        };
    }

    return (
        <>            
            <div ref={forgotPwdModal} className="modal fade" id="pwdForgotModal" tabIndex="-1" aria-labelledby="pwdForgotModalLabel" aria-hidden="false">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">
                        <h2 className="bm-card-header_small" id="pwdForgotModalLabel">
                            {t("user.pwd.forgot")}
                        </h2>
                        <a href="/#" className="bm-close-x" id="closePwdForgot" type="button" data-bs-dismiss="modal" aria-label="Close">&#10005;</a>
                        
                            {!formAccepted && 
                                <form onSubmit={handleSubmit} className="pwd-forgot-form fn-smtxt">
                                    <Field
                                        refInput={emailInput}
                                        label={t("nw.input.mail")}
                                        id="pwdForgotEmail"
                                        name="email"
                                        placeholder={t("nw.input.mail.hint")}
                                        value={input.email}
                                        disabled={isLoading && "disabled"}
                                        onChange={handleChange}
                                        type="email" 
                                        error={errors.email}
                                        attrs={sendForm && {disabled:"disabled"}}
                                    />

                                    <button id="forgot-btn" type="submit" disabled={isLoading && "disabled"} className="bm-btn-blue bm-btn-card-next d-flex justify-content-center">
                                        {isLoading &&
                                            <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                        }
                                        
                                        <span className={isLoading ? "invisible" : ""}>{t("user.pwd.forgot.btn")}</span>
                                    </button>
                                    <div className="clearer"></div>
                                </form>
                            }
                            {formAccepted && 
                                <div className="d-flex flex-column col-12 mt-0 pt-0 text-center">
                                    <img
                                        src={process.env.PUBLIC_URL + MailSvg}
                                        className="bm-signup-img"
                                        alt="Mail Icon"
                                        loading="lazy"
                                    />
                                    <p dangerouslySetInnerHTML={{__html: t('user.pwd.forgot.ok')}} />
                                    <button type="button" className="bm-btn-blue bm-marg-top-30 bm-max-width-200 bm-margin-auto" onClick={showForm}>{t("gen.button.close")}</button>
                                </div>
                            }
                    </div>
                </div>
            </div>
        </>
    )
}
