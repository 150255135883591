import { useTranslation } from "react-i18next";
import { localeDatetime } from "Components/Tools/DateTime";
import { convertSize } from "Services/utils";

export default function DownloadDetails(props) {
    const { t } = useTranslation();
    //const closeBtn = useRef(null);
    //closeBtn.current.click();

    return (
        <div className="my-3">
            <h3 className="text-center h2">{t("licenses.list.detail.down")}</h3>
            {props.data.license?.downloads && <>
                <div className="row px-5 pt-4 m-0">
                    <div className="fw-bold col-1 nowrap">Serial</div>
                    <div className="fw-bold col-2 nowrap">Clearing</div>
                    <div className="fw-bold col-1 nowrap">Downloads</div>
                    <div className="fw-bold col-1 nowrap">Σ</div>
                    <div className="fw-bold col-2 nowrap">Product name</div>
                    <div className="fw-bold col-1 nowrap">Language</div>
                    <div className="fw-bold col-1 nowrap">Stage</div>
                    <div className="fw-bold col-1 nowrap">Method</div>
                    <div className="fw-bold col-1 nowrap">Code</div>
                    <div className="fw-bold col-1 nowrap">Size</div>
                </div>
                <div className="bm-sep-2 mt-1"/>
                {
                    props.data.license?.downloads?.map((download, idx) => {
                        const tooltip = download.downloadLogs?.map(log => `${localeDatetime(log.createdAt)}`)?.join('\n');
                        let size = t("gen.text.unavailable.abbr");
                        if (download.downloadLogs.length >0) {
                            const convertedSize = convertSize(download.downloadLogs[0].filesize);
                            size = convertedSize ? convertedSize.size + "\u00A0" + t(`gen.var.prefix`).charAt(convertedSize.pow)+t(`gen.var.byte`) : t("gen.text.unavailable.abbr");
                        }

                        return <div key={'down'+idx} className="row px-5 py-2 m-0">
                            <div className="col-1 nowrap">
                                {download.serial}
                            </div>
                            <div className="col-2 nowrap">
                                {download.createdAt ? localeDatetime(download.createdAt): t("gen.text.unavailable.abbr")}	
                            </div>
                            <div className="col-1 nowrap" title={tooltip}>
                                {download.accessLastAt ? localeDatetime(download.accessLastAt): t("gen.text.unavailable.abbr")}
                            </div>
                            <div className="col-1 nowrap">
                                {download.count}	
                            </div>
                            <div className="col-2 nowrap">
                                {download.productRelease?.product?.name}	
                            </div>
                            <div className="col-1 nowrap">
                                {t(`lang.${download.productRelease?.languageI18nKey}`)}		
                            </div>
                            <div className="col-1 nowrap">
                                {t(`clear.select.opt.stage.${download.productRelease?.stage}`)}		
                            </div>
                            <div className="col-1 nowrap">
                                {download.method}		
                            </div>
                            <div className="col-1 nowrap">
                                {download.code}		
                            </div>
                            <div className="col-1 nowrap">
                                {size}		
                            </div>												
                        </div>
                    })
                }
            </>}
        </div>
    )
}
