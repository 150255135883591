import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";

export const SliceText = ({text, length, from, to, append = '...'}) => {
  if (!text) {
    return "";
  }
  if(length){
    return text.length > length ? text.slice(0, length) + append : text;
  }
  if(typeof to === 'string'){
    const parts = text.split(to);
    return parts[1] ? parts[0]+append : parts[0];
  }
  if(typeof to === 'number' && isFinite(to)){
    // Check if to is of type "number" and it is not NaN (which is not an integer)
    return text.substring(from, to);
  }
  return text;
}

export const SliceTextTooltip = ({ text, length, append = '...' }) => {
  /* eslint-disable */
  const [showTooltip, setShowTooltip] = useState(false);
  /* eslint-enable */

  return (
    <div>
      {text.length > length ? (
        <Tooltip title={text}>
          <span onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
            {text.slice(0, length) + append}
          </span>
        </Tooltip>
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
}

export const checkPwd = (pwd) => {
  	return pwd.match(/^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/);
}

export const checkEmail = (email) => {
  // /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/ 
  // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/
  // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{1,63})+$/
  const pattern = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-z0-9-]{2,63})\b/i;
    return email.match(pattern);
}

export const checkLicenseKey = (key) => {
  	return key.match(/^([A-Za-z0-9]{5}-?){3}[A-Za-z0-9]{5}$/);
}

export const checkEnhancementKey = (key) => {
  	return key.match(/^([A-Za-z0-9]{5}-?){1}[A-Za-z0-9]{5}$/);
}
export const languagesToList = (languages, defLang, t) => {
    if (!languages || languages?.length < 1) {
        return '';
    }
    if (!languages.includes(defLang)) {
        languages.unshift(defLang);
    }
    let list = t('gen.lang.titleTT')+ '\n\n';
    list +=  languages?.map(lang => '- ' +t("gen.lang."+lang))?.join('\n');

    return list;
}

export const compareVersions =(v1, v2, isV1Max = false) => {
	if (v2 === null || v2.trim() === "") {
		return 1;
	}

	if (v1 === null || v1.trim() === "") {
		return -1;
	}

	let v1Parts = v1.split('.');
	let v2Parts = v2.split('.');

	while (v1Parts.length < v2Parts.length) {
		v1Parts.push(isV1Max ? '9' : '0');
	}

	while (v2Parts.length < v1Parts.length) {
		v2Parts.push('0');
	}

	const length = v1Parts.length;

	for (let i = 0; i < length; i++) {
		if (!/^\d/.test(v1Parts[i])) {
			v1Parts[i] = '0' + v1Parts[i];
		}

		if (!/^\d/.test(v2Parts[i])) {
			v2Parts[i] = '0' + v2Parts[i];
		}

		if (v1Parts[i].localeCompare(v2Parts[i], undefined, { numeric: true }) === -1) {
			return -1;
		}

		if (v1Parts[i].localeCompare(v2Parts[i], undefined, { numeric: true }) === 1) {
			return 1;
		}
	}

	return 0;
}
