import { useState, useRef } from "react";
import RASAPI from "Services/RASAPI";
import { useTranslation } from "react-i18next";
import Spinner from 'Components/Loading/Spinner';
import {v4 as uuidv4} from 'uuid';
import { handleCheckboxDivClick } from 'Services/utils';

export default function DeleteHost(props) {
    const { t } = useTranslation();
    const [selectedHosts, setSelectedHosts] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [errors, setErrors] = useState(null);
    const closeBtn = useRef(null);

    // Manage input changing in form
    const handleChange = (e) => {
        const {value, checked } = e.currentTarget;
        const hostId = parseInt(value);
        if (checked) {
            setSelectedHosts([...selectedHosts, hostId]);
        } else {
            setSelectedHosts(selectedHosts.filter((id) => id !== hostId));
        }
    };

    // Manage form submission
    const handleSubmit = async e => {
        e.preventDefault();
        setToggle(true);
        RASAPI.DeleteHosts(selectedHosts)
        .then(response => {
            props.removeHosts(selectedHosts);
            setSelectedHosts([]);
            closeBtn.current.click();
        })
        .catch(error => {
            console.log(error,error.response);
            setErrors("err.500")
        }).finally(()=>{
            setToggle(false);
        });
    };

    return (
        <>            
            <div className="modal fade" id="deleteHostModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="deleteHostModalLabel" aria-hidden="false">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">
                        <h2 className="bm-card-header_small" id="deleteHostModalLabel">
                            {t("ras.slots.cols.host.grp.func.del.title")}
                        </h2>
                        <a ref={closeBtn} href="/#" className="bm-close-x" id="closeDeleteHost" type="button" data-bs-dismiss="modal" aria-label="Close">&#10005;</a>
                        
                        <form onSubmit={handleSubmit} className="host-form fn-smtxt">
                            {errors && 
                                <p 
                                    className="fn-smtxt invalid-feedback d-block" dangerouslySetInnerHTML={{__html: t(errors)}}
                                />
                            }
                            <div className="row">
                                {props.hosts?.map((host)=>{
                                    return  (
                                        <div key={uuidv4()} className="col-6 mb-2" onClick={handleCheckboxDivClick}>
                                            <input
                                                className="form-check-input red"
                                                checked={selectedHosts.includes(host.id)} //value is treated as String
                                                onChange={handleChange}
                                                type="checkbox"
                                                id={host.name+"_"+host.id}
                                                name={host.name+"_"+host.id}
                                                value={host.id}
                                            />
                                            <label className="form-check-label" htmlFor={host.name+"_"+host.id}>{host.name}</label>
                                        </div>
                                    )
                                })}
                                {(props.hosts?.length < 1) && <div>{t("ras.slots.cols.host.grp.func.del.empty")}</div>}
                            </div>

                            <div className="text-center mt-4">
                                <button 
                                    type="submit"
                                    disabled={toggle || props.hosts?.length<1 || selectedHosts.length<1 ? "disabled" : ""} 
                                    className={`${props.hosts?.length<1 || selectedHosts.length<1? "btn-disabled": "bm-btn-red"} bm-btn-card-next d-flex justify-content-center`}
                                >
                                    {toggle &&
                                        <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                    }
                                    <span className={toggle ? "invisible" : ""}>
                                        {t("ras.slots.cols.host.grp.func.del.btn")}
                                    </span>
                                </button>
                                <div className="clearer"></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
