import axios from "axios";
import {
    API_USERS_REGISTER,
    API_USERS_ACCESS,
    API_USERS_ACCOUNT_DATA,
    //API_USERS_WITH_ACCESS,
    API_USERS_ACCESS_UPDATE,
    API_PROFILE,
    API_PASSWORD_FORGOTTEN,
    API_CONFIRM_REGISTRATION,
    API_RESET_PASSWORD,
    API_PASSWORD_UPDATE,
    API_USERS_ACCOUNT_UPDATE,
    API_LICENSE_USER_CONFIRMATION,
    API_IMPERSONATE,
} from "config/nw-api";
import AxiosPrivate from "./Common/AxiosPrivate";

function register(data, hasAccess) {
    if (hasAccess) {
        return AxiosPrivate.post(API_USERS_REGISTER, data, {isPublic: true});
    }
    return axios.post(API_USERS_REGISTER, data);
}

function confirmRegister(token, lang, callback) {
    return axios.post(`${API_CONFIRM_REGISTRATION}${token}/${lang}`, callback);
}

function pwdForgot(data) {
    return axios.post(API_PASSWORD_FORGOTTEN, data);
}

function pwdReset(data) {
    return axios.post(API_RESET_PASSWORD, data);
}

function profile() {
    return AxiosPrivate.get(API_PROFILE);
}

function updateProfile(data) {
    return AxiosPrivate.put(API_PROFILE, data);
}

function updatePassword(data) {
    return AxiosPrivate.put(API_PASSWORD_UPDATE, data);
}

function usersUpdateData(id, data) {
    return AxiosPrivate.put(API_USERS_ACCOUNT_UPDATE.replace(':id', id), data);
}

function usersAccess(filter) {
    return AxiosPrivate.post(API_USERS_ACCESS, filter);
}

/*function userWithAccess(id) {//NOT Used
    return AxiosPrivate.get(API_USERS_WITH_ACCESS.replace(':id', id));
}*/

function userAccountData(id) {
    return AxiosPrivate.get(API_USERS_ACCOUNT_DATA.replace(':id', id));
}

function accessUpdate(id, data) {
    return AxiosPrivate.put(API_USERS_ACCESS_UPDATE.replace(':id', id), data);
}

function checkAddLicenseUserToken(token, data) {
    return axios.get(API_LICENSE_USER_CONFIRMATION.replace(':token', token), data);
}

function confirmAddLicense(token, data, isAuthenticated = false) {
    if (isAuthenticated) {
        return AxiosPrivate.put(API_LICENSE_USER_CONFIRMATION.replace(':token', token), data, {isPublic: true});
    }
    return axios.put(API_LICENSE_USER_CONFIRMATION.replace(':token', token), data);
}

function impersonateTo(email, exit, prevImpersonator) {
    if (exit) {
        return AxiosPrivate.get(API_IMPERSONATE+"/exit/"+email);
    }
    return AxiosPrivate.post(API_IMPERSONATE+"?_switch_user="+email, {prevImpersonator});
}

const userAPI = {
    register,
    usersAccess,
    accessUpdate,
    confirmAddLicense,
    checkAddLicenseUserToken,
    //userWithAccess,
    confirmRegister,
    pwdForgot,
    pwdReset,
    profile,
    updateProfile,
    updatePassword,
    userAccountData,
    usersUpdateData,
    impersonateTo
};

export default userAPI;