import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";

export default function InfoPage(){
  let params = useParams();
  const { t } = useTranslation();
  if (params.i18nKey === "terms-of-use") {
        params.i18nKey = "terms";
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
        <div className="m-0 p-0 vertical-center">
          <div className="bm-main">
              <div className="bm-card">
                <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
						{/* <h1 className="display-4 text-center fn-smtxt-bolder py-3">
							{params.page}
						</h1> */}
              
				<div dangerouslySetInnerHTML={{__html: t('legal.'+ params.i18nKey)}} />
              </div>
          </div>
        </div>
  )
}