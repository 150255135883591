import { useEffect } from "react";
import {Link, useNavigate} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "Translations/i18n";
//import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import { toggleLogin } from 'Reduxx/Reducers/AuthSlice';
import {store} from "Reduxx/store";
import { logoutUser } from 'Reduxx/Reducers/Thunks/AuthThunk';
import { useDispatch, useSelector } from 'react-redux';
import useTabCommunication from "Hook/useTabCommunication";
import {useFlashContext}  from 'Contexts/FlashContext';


export default function Home(props) {
    //const location = useLocation();
	const navigate = useNavigate();
    const {t} = useTranslation();
    document.title = t("start")+ " – nanoways.com";
    const dispatch = useDispatch();
    
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const showLogin = useSelector((state) => state.auth.showLogin);
    const {flash, setFlash} = useFlashContext();

    const user = useSelector((state) => state.user);
    const {sendTabsData} = useTabCommunication('NAVBAR');

    const logout = () => {
        dispatch(logoutUser());
    }
    //Redirect user to the last rejected protected route.
    useEffect(() => {
        if(flash?.redirect && isAuthenticated){
            navigate(flash.redirect)
        }
    }, [flash, navigate, isAuthenticated])

    /* eslint-disable */
    useEffect(() => {
        if (props.notFound) {
            setFlash(props.notFound);
        }
        if (!isAuthenticated) {
            if(props.login || showLogin || flash?.redirect === t("link.profil.url")){
                document.querySelector('[data-bs-target="#signInModal"]')?.click();
                store.dispatch(toggleLogin(false));
            }else if(props.pwdForgot){
                document.querySelector('[data-bs-target="#pwdForgotModal"]')?.click();
            }else if(props.pwdReset){
                document.querySelector('[data-bs-target="#pwdResetModal"]')?.click();
            }
        }else{
            if(props.pwdForgot || props.pwdReset){
                setFlash({
                    message: "Use the link below before to access your url",
                    alert: "warning",
                    redirect:{
                        name: t("user.menu.logout"),
                        path: window.location.href
                    }
                });
            }
            if (flash?.logout) {
                dispatch(logoutUser());
            }
        }
    }, [])
    useEffect(() => {
        const SwitchedUser = sessionStorage?.getItem('switchedAs'); //set in AuthThunk
		let isMounted = true;
        
        if (SwitchedUser && isMounted) {
            const account = user?.name?.length > 0 ? `(${user.name}) ${user.email}` : user.email;
            setFlash({
                message: t("user.switch.info", {account: account}),
                alert: "success",
            });
            sendTabsData('login', sessionStorage?.getItem('tabId'));
            sessionStorage.removeItem('switchedAs');
        }

		return () => { isMounted = false };
    }, [user])
    /* eslint-enable */

    return (
        <>
            <div className="m-0 p-0 vertical-center">
                <div className="bm-main">
                    <div className='bm-card'>            

                        <h1 className="bm-align-center bm-card-header">{t("start.welcome")}</h1>

                        <p>{t("start.welcome.txt")}</p>

                        <h2 className="bm-sep-1">{t("start.opt.clear")}</h2>

                        <div className="bm-two-columns">                
                            <div className="bm-two-columns-left">
                                <p>{t("start.opt.clear.txt")}</p>
                            </div>
                            
                            <div className="bm-two-columns-right">
                                <Link className="bm-btn-blue" to={t("link.lic.clear.lic")}>
                                    <button className="bm-btn-blue">{t("start.opt.clear.btn")}</button>
                                </Link>
                            </div>
                        </div>

                        <h2 className="bm-sep-1">{t("start.opt.upgrade")}</h2>

                        <div className="bm-two-columns">          
                            <div className="bm-two-columns-left">
                                <p>{t("start.opt.upgrade.txt")}</p>
                            </div>

                            <div className="bm-two-columns-right">
                                <Link className="bm-btn-blue" to={t("link.lic.upgrade.code")}>
                                    <button className="bm-btn-blue">{t("start.opt.upgrade.btn")}</button>
                                </Link>
                            </div>
                        </div>      
                        <div className="bm-sep-1"></div>
                        <h2 className="bm-align-center bm-marg-top-30">{isAuthenticated ? t("start.opt.logout"): t("start.opt.auth")}</h2>
                        <p className="bm-align-center bm-max-width-450 bm-margin-auto">{isAuthenticated ? t("start.opt.logout.txt"): t("start.opt.auth.txt")}</p>
                        <div className="bm-align-center bm-marg-top-30">
                            {isAuthenticated ?
                            <button onClick={logout} className="btn-disabled-home bm-btn-marg">{t("start.opt.logout.btn")}</button>
                            :
                            <>
                                {/*<OverlayTrigger placement="top" trigger={["hover", "focus", "click"]} rootClose={true} rootCloseEvent="mousedown" overlay={<Tooltip className="nw-tooltip">{t("start.opt.auth.signUp.tt")}</Tooltip>}>
                                    <button className="btn-disabled-home bm-btn-marg">{t("start.opt.auth.signUp")}</button>
                            </OverlayTrigger>*/}

                                <a data-bs-toggle="modal" data-bs-target="#signUpModal" href="/#" role="button"><button className="bm-btn-green-out bm-btn-marg">{t("start.opt.auth.signUp")}</button></a>

                                <a id="loginLink" data-bs-toggle="modal" data-bs-target="#signInModal" href="/#"><button className="bm-btn-blue bm-btn-marg">{t("start.opt.auth.signIn")}</button></a>
                            </>
                            }
                        </div>                 
                    </div>
                </div>
            </div>
            
        </>
    )
}
