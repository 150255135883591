import {useRef, useEffect, useState} from 'react';
import $ from 'jquery';
import jstree from 'jstree'; // eslint-disable-line
import  './index.css';
import 'jstree/dist/themes/default/style.min.css';
import USERS_API from "Services/UsersAPI";
import ACCESS_API from "Services/AccessAPI";
import Field from 'Components/Forms/Field';
import { useTranslation } from "react-i18next";
import MultiSelect from 'Components/Forms/MultiSelect';
import {v4 as uuidv4} from 'uuid';


function Access(props) {
	const {t} = useTranslation();
    const treeRef = useRef(null);
    const [data, setData] = useState(props.data);
    const [roleGroups, setRoleGroups] = useState(null);
    const [selectedRoleGrps, setSelectedRoleGrps] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const typeSearchOptions = [
		{value: 'user group', label: <span title={t("right.node.type.usrGrp.tt")}><i className="fa-solid fa-user-group nw-blue-dark me-2"></i>{t("right.node.type.usrGrp")}</span>,/*color: "var(--nw-green-dark-color)",*/ bgColor: "var(--nw-green-light-color)"},
		{value: 'role', label: <span title={t("right.node.type.usr.tt")}><i className="fa-solid fa-user nw-blue-dark me-2"></i>{t("right.node.type.usr")}</span>,/*color: "var(--nw-green-dark-color)",*/ bgColor: "var(--nw-green-light-color)"},
		{value: 'right group', label: <span title={t("right.node.type.rightGrp.tt")}><i className="fa-solid fa-layer-group nw-blue-dark me-2"></i>{t("right.node.type.rightGrp")}</span>,/*color: "var(--nw-green-dark-color)",*/ bgColor: "var(--nw-green-light-color)"},
		{value: 'right', label: <span title={t("right.node.type.right.tt")}><i className="fa-solid fa-circle-check nw-blue-dark me-2"></i>{t("right.node.type.right")}</span>,/*color: "var(--nw-green-dark-color)",*/ bgColor: "var(--nw-green-light-color)"},
	];
    const [, ...typeSearchDefault] = typeSearchOptions; //As default remove disabled rights
	const [filter, setFilter] = useState([]);

	const handleMultiSelectChange = (options, action)=>{
		setFilter([...options]);
        const newData = filterData(data, options);

        $(treeRef.current).jstree(true).settings.core.data = newData; // Update jsTree data
        $(treeRef.current).jstree(true).refresh(); // Refresh jsTree to reflect changes
	}
	
    /* eslint-disable */
    useEffect(() => {
        const handleChange = (e, data) => {
             // changed.jstree is a event
             if (['deselect_node', 'select_node'].includes(data.action) && props.selectedUser?.id !== props.user.id) {
                // GET all Active access
                let checkedAccess = []; 
                let selectedNodes = $(treeRef.current).jstree("get_selected", true);
                $.each(selectedNodes, function() {
                    let id = this.original.id_origin;
                    if (id !== null) {
                        //checkedAccess.push({id:this.original.id_origin, name:this.original.name});
                        checkedAccess.push(this.original.id_origin);
                    }
                });
                
               if (props.selectedUser?.id !== "default") {
                    USERS_API.accessUpdate(props.selectedUser?.id, checkedAccess)
                    .then(res => {
                        const newAccess = res.data;
                        const newSelectedRoles = data.action === 'select_node' ?
                        [...selectedRoleGrps, ...roleGroups?.filter(right => right.id_origin === data.node?.original?.id_origin)]
                        :
                        [...selectedRoleGrps?.filter(right => right.id_origin !== data.node?.original?.id_origin)];

                        
                        setSelectedRoleGrps([...newSelectedRoles]);  
                        
                        $(treeRef.current).find("ul>li>a.jstree-anchor").each(function(index) {
                            $(this).removeClass("nw-green-dark");
                            if (newAccess.includes(parseInt($(this).attr("data-id")))) {
                               // console.log($(this).attr("data-id"));
                                $(this).addClass("nw-green-dark");
                            }
                        });
                    })
                    .catch(err => {
                        if(!err.response || err.response.data.status === 500){
                            console.log('User Access Not updated', err, err.message + " 😥");
                        } else {
                            console.log('User Access Not updated', err.response);
                        }
                    })
                } 
            }
        }

        // Initialize jsTree inside the useEffect hook
        $(treeRef.current).jstree({ // config object start
            "core": {                    // core config object
                "mulitple": false,         // disallow multiple selection  
                "animation": 100,          // 200ms is default value
                "check_callback" : true,   // this make contextmenu plugin to work
                "themes": {
                    "variant": "medium",
                    "dots": true
                },
            
                "data": filterData(data, filter)
            }, // core end
            
            // config object for Checkbox plugin (declared below at plugins options)
            "checkbox": { 
                "keep_selected_style": false,  // default: false
                "three_state": true,           // default: true
                "whole_node": true             // default: true
            },
            
            "conditionalselect" : function (node, event) {
                return false;
            },
            "search" : {
                "case_sensitive": false,
                "show_only_matches": true,
                "search_callback": function(search, node) {
                    const match = search.match(/^#(\d+)$/);
                    if (match && match[1] === node.original.id_origin?.toString()) {
                        return true;
                    }else if (node.text.includes(search)) {
                        return true;
                    }
                    return false;
                }
            },
            
            // injecting plugins
            // Unique plugin has no options, it just prevents renaming and moving nodes
            // to a parent, which already contains a node with the same name.
            //"checkbox","contextmenu","dnd", "massload","search", "sort","state","types","unique","wholerow","conditionalselect","changed"
            "plugins" : props.plugins
    
        });
        
        
        $("#searchNode").on('keyup',function () {
            let search = $(this).val();
            $(treeRef.current).jstree('search', search);
        });
        
        $(treeRef.current).on("changed.jstree", (e, data) => handleChange(e, data));
            
        return () => {
            // Clean up jsTree when the component unmounts
            $(treeRef.current).jstree('destroy');
        };
    }, [data]);
    /* eslint-enable */

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            const newRoleGroup = data?.children?.length > 0 ? data?.children?.filter(right => right.type === 'user group'): [];
            setRoleGroups([...newRoleGroup])
            setSelectedRoleGrps(newRoleGroup?.filter(right => right.state?.selected === true));
        }

        return () => {
            mounted = false;
        }
    }, [data])

    useEffect(()=>{
        setData(props.data);
    }, [props.data])

    const handleChangeSwitchRoleGroup = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const originalId = parseInt(e.currentTarget.value);
        //const newSelected = roleGroups?.filter(right => right.id_origin === originalId);

        const newData = data;
        const payload = {
            disable: selectedRoleGrps[0]?.id_origin,
            enable: originalId,
        }
        newData.children = newData.children?.filter((right) => {
            if (right.type === "user group") {
                right.state.selected = false;
            }
            right.state.selected = right.id_origin === originalId  ?  true : right.state.selected;

            return right;
        })
        
        ACCESS_API.switchRoleGroup(props.selectedUser?.id, payload)
        .then(res => {
            //console.log(res)
        })
        .catch(err => {
            console.log(err)

        }).finally(()=>{
            setIsLoading(false);
        })
        setData({...newData});
        
        /*setRoleGroups(roleGroups?.filter(right => {
            right.state.selected = right.id_origin === originalId ? true : false;

            return right;
        }))
        
        setSelectedRoleGrps(newSelected);*/
    }

    return (
        <div className='row'>
            {props.user?.roles.includes('ROLE_212') && roleGroups?.length > 0 &&
                <div className="col-12 mb-2 bm-input-wrapper">
                    <select
                        disabled={selectedRoleGrps?.length > 1 || ["default", props.user.id].includes(props.selectedUser?.id) || isLoading ? "disabled":""}
                        name="switchRoleGroup"
                        id="switchRoleGroup"
                        placeholder={t("users.roleGrp.hint")}
                        value={selectedRoleGrps.length === 1 ? selectedRoleGrps[0]?.id_origin : "default"}
                        title={t("users.roleGrp.tt")}
                        onChange={handleChangeSwitchRoleGroup}
                        className={`nw-select`}
                    >
                        {selectedRoleGrps?.length > 1 ? 
                            <option className="nw-grey-dark" disabled value="default">
                                {t("users.roleGrp.hint.multi")}
                            </option>
                        :
                        <option className="nw-grey-dark" disabled value="default">
                            {t("users.roleGrp.hint")}
                        </option>
                        }
                        {(roleGroups?.length > 0 ) && roleGroups.map((roleGrp)=>{
                            return  (
                                <option 
                                    //title={forgeinUser.email}
                                    key={uuidv4()}
                                    value={roleGrp.id_origin}
                                    className={`${selectedRoleGrps.length === 1 && roleGrp.id_origin === selectedRoleGrps[0].id_origin ? "nw-green-dark ":"text-black "} fn-code`}
                                >
                                    {roleGrp.name}&nbsp;&nbsp;&nbsp;
                                </option>
                            )
                        })}
                    </select>
                    {selectedRoleGrps.length === 1 && <label htmlFor="switchRoleGroup" className='ms-2'>{t("users.roleGrp.hint")}</label>}
                </div>
            }
            <div className="col-lg-6 col-12">
                <Field label={t("right.tree.filter")} placeholder={t("right.tree.filter.hint")} id="searchNode" class="search-node" />
            </div>
            {<div className="col-lg-6 col-12">
                <MultiSelect 
                    name="typeSearch"
                    placeholder={t("right.node.type")}
                    value={filter}
                    defaultValue={typeSearchDefault}
                    options={typeSearchOptions} 
                    onChange={handleMultiSelectChange}
                    isLoading={props.toggle}
                />
            </div>}
            <div className='col-12 mt-2'>
                <div ref={treeRef} />
            </div>
        </div>
    );
}

export default Access;

const filterData = (data, filters) => {
        
    const newCriteria = [];
    filters?.forEach(type => {
        newCriteria.push(type.value);
    });

    let newData = {...data};
    if (newCriteria.length > 0 && data?.children.length > 0) {
        newData = {...data, children:data?.children?.filter(right => newCriteria?.includes(right.type))};
    }

    return newData;
}