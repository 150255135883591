import { useState } from "react";
import RASAPI from "Services/RASAPI";
import Field from "Components/Forms/Field";
import { useTranslation } from "react-i18next";
import CheckedSvg from 'assets/images/icon/checked.svg';
import Spinner from 'Components/Loading/Spinner';
import { handleCheckboxDivClick } from 'Services/utils';

export default function HostModal(props) {
    const { t } = useTranslation();
    const [host, setHost] = useState({
        name: "",
        address: "",
        allTeam: false,
        overwrite: false,
        addressIsChecked: false
    });
    const [errors, setErrors] = useState({
        name: "",
        address: "",
        api: null
    });
    const [toggle, setToggle] = useState(false);
    const [formAccepted, setFormAccepted] = useState({
        "height": 0,
        "status":false
    });
    const [lastName, setLastName] = useState(null);
    const [lastAddress, setLastAddress] = useState(null);

    const showForm = () => {
        setFormAccepted(formAccepted.status = false);
        setHost({
            name: "",
            address: "",
            allTeam: false,
            overwrite: false,
            addressIsChecked: false
        });
        document.getElementById('closeHost').click();
    }

    // Manage input changing in form
    const handleChange = (e) => {
        const {name, value} = e.currentTarget;

        if (name === "allTeam") {
            host.allTeam = !host.allTeam;
            setHost({...host});
        }else{
            if (name === 'name' && value?.toLowerCase() === lastName?.toLowerCase()) {
                setHost({...host, [name]: value, overwrite: true});
            }else{
                setHost({...host, [name]: value, overwrite: false});
            }
        }
    };

    // Manage form submission
    const handleSubmit = async e => {
        e.preventDefault();
        setToggle(true);

        let apiErrors = {};

        if (!host.name) {
            apiErrors.name = "nw.input.err.required";
        }
        if (!host.address) {
            apiErrors.address = "nw.input.err.required";
        }
        
        setErrors(apiErrors);

        if (Object.keys(apiErrors).length !== 0 && apiErrors.constructor === Object) {
            setToggle(false);
            return;
        }

        host.team = props.team;
        host.overwrite = (host?.name?.toLowerCase() === lastName?.toLowerCase()) ? true: false;

        RASAPI.addHost(host)
        .then(response => { 
            setToggle(false);
            setFormAccepted({...formAccepted,
                status: true,
                height:document.getElementsByClassName('host-form')[0].clientHeight
            });
            props.addHost(response.data);
            setLastName(null);
            setLastAddress(null);
            setHost({
                name: "",
                address: "",
                allTeam: false,
                overwrite: false,
                addressIsChecked: false
            })
        })
        .catch(error => {
            setToggle(false);
            apiErrors.gen = {};
            if(!error.response || error.response?.status === 500){
                apiErrors.gen.txt = "err.500";
            } else {
                console.log(error.response);
                const dataType = error.response.data?.type?.toLowerCase();
                if (dataType === 'hostpaired') {
                    apiErrors.gen.txt = "ras.slots.cols.host.grp.func.new.err.paired";
                    //setLastName(host.name);
                    //setHost({...host, overwrite: true});
                } else if (dataType === 'nameduplicated') {
                    apiErrors.gen.txt = "ras.slots.cols.host.grp.func.new.err.name";
                    setLastName(host.name);
                    setHost({...host, overwrite: true, addressIsChecked: true});
                }  else if (dataType === 'addressduplicated') {
                    apiErrors.gen.txt = "ras.slots.cols.host.grp.func.new.err.addr";
                    apiErrors.gen.var = {hostName: error.response.data.hostName};
                    setLastAddress(host.address);
                    setHost({...host, addressIsChecked: true})
                } else {
                    apiErrors.gen.txt = "err.unknown";
                }
            }
            setErrors({api: apiErrors.gen});
        });
    };

    return (
        <>            
            <div className="modal fade" id="hostModal" tabIndex="-1" aria-labelledby="hostModalLabel" aria-hidden="false">
                <div className="modal-dialog vertical-center">
                    <div className="modal-content bm-card">
                        <h2 className="bm-card-header_small" id="hostModalLabel">
                            {t("ras.slots.cols.host.grp.func.new.form")}
                        </h2>
                        <a href="/#" className="bm-close-x" id="closeHost" type="button" data-bs-dismiss="modal" aria-label="Close">&#10005;</a>
                        
                            {!formAccepted.status && 
                                <form onSubmit={handleSubmit} className="host-form fn-smtxt">
                                    {errors.api && 
                                        <p 
                                            className="fn-smtxt invalid-feedback d-block" dangerouslySetInnerHTML={{__html: t(errors.api.txt, errors.api.var||{})}}
                                        />
                                    }
                                    <Field
                                        title={t("ras.slots.cols.host.grp.func.new.form.name.tt")}
                                        label={t("ras.slots.cols.host.grp.func.new.form.name.short")}
                                        id="hostName"
                                        name="name"
                                        placeholder={t("ras.slots.cols.host.grp.func.new.form.name.hint")}
                                        value={host.name}
                                        onChange={handleChange}
                                        error={t(errors.name)}
                                    />
                                    <Field
                                        title={t("ras.slots.cols.host.grp.func.new.form.adr.tt")}
                                        label={t("ras.slots.cols.host.grp.func.new.form.adr.short")}
                                        id="hostAddress"
                                        name="address"
                                        placeholder={t("ras.slots.cols.host.grp.func.new.form.adr.hint")}
                                        value={host.address}
                                        onChange={handleChange}
                                        error={t(errors.address)}
                                    />

                                    {props.team !== 0 && <div onClick={handleCheckboxDivClick}>
                                        <input
                                            className="form-check-input"
                                            checked={host.allTeam}
                                            onChange={handleChange}
                                            type="checkbox"
                                            id="allTeam"
                                            name="allTeam"
                                            value={host.allTeam}
                                        />

                                        <label className="form-check-label ms-2" htmlFor="allTeam">
                                        <span dangerouslySetInnerHTML={{__html: t("ras.slots.cols.host.grp.func.new.form.team")}}/>
                                        </label>
                                    </div>}

                                    <div className="text-center mt-4">
                                        <button type="submit" disabled={toggle ? "disabled" : ""} className={`${host.overwrite || (host.addressIsChecked && host.address === lastAddress)? "bm-btn-red" : "bm-btn-blue"}  bm-btn-card-next d-flex justify-content-center`}>
                                            {toggle &&
                                                <Spinner for="btn" iconStyle={{radius: "24px", borderColor: "var(--nw-blue-light-color)"}}/>
                                            }
                                            <span className={toggle ? "invisible" : ""}>
                                                {
                                                    host.overwrite ?
                                                    t("ras.slots.cols.host.grp.func.new.form.btn.overwrite") :
                                                    (host.addressIsChecked && host.address === lastAddress) ?
                                                    t("ras.slots.cols.host.grp.func.new.form.btn.new") :
                                                    t("ras.slots.cols.host.grp.func.new.form.btn")
                                                }
                                            </span>
                                        </button>
                                        <div className="clearer"></div>
                                    </div>
                                </form>  
                            }
                            {formAccepted.status && 
                                <div style={{minHeight: formAccepted.height}} className="d-flex flex-column col-12 mt-0 pt-0 text-center">
                                    <img
                                        src={process.env.PUBLIC_URL + CheckedSvg}
                                        className="bm-signup-img"
                                        height="64"
                                        alt="Checked Icon"
                                        loading="lazy"
                                    />
                                    <p dangerouslySetInnerHTML={{__html: "Host is successfully Added"}}/>
                                    <button type="button" className="bm-btn-blue bm-marg-top-30 bm-max-width-200 bm-margin-auto" onClick={showForm}>{t("gen.button.close")}</button>
                                </div>
                            }
                    </div>
                </div>
            </div>
        </>
    )
}
